import AjaxWrapper from "Cheops/AjaxWrapper";

type Callback = (...args: any) => any;

function metricsRequest(url: string, cb: Callback): void {
    const {referrer} = document;
    AjaxWrapper.post(
        url,
        {referrer},
        {send_auth_token: true}
    )
        .then( cb )
        .catch( cb )
    ;
}

export function metricsLoadWrapper(cb: Callback ): void {
    try {
        const url = new URL(location.href);
        const params = url.searchParams;
        if (params.has('bannerId') || params.has('xid') ) {
            const xid = params.get('bannerId') || params.get('xid');
            const next = () => {
                params.delete('bannerId');
                params.delete('xid');
                location.href = url.href;
            };
            return metricsRequest(
                `${CONFIG.Api.noopolis.url}/metrics?xid=${xid}`,
                next
            );
        } else {
            return metricsRequest(
                `${CONFIG.Api.noopolis.url}/metrics`,
                cb
            );
        }
    } catch (e) {
        console.error('metricsBeforeLoad', e);
    }
    cb();
    return;
}
