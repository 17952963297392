import React from "react";

import "./SolveResult.less";

export default class SolveResult extends React.Component {


    static VERDICT_NONE = 'none';
    static VERDICT_NONE_UNSCORED = 'noneUnscored';
    static VERDICT_OK = 'ok';
    static VERDICT_OK_FIRST_TRY = 'ok_first_try';
    static VERDICT_PARTLY = 'partly';
    static VERDICT_PARTLY_CAN_AGAIN = 'partly_can_again';
    static VERDICT_WRONG = 'wrong';
    static VERDICT_REPEATED = 'repeated';
    static VERDICT_WRONG_CAN_AGAIN = 'wrong_can_again';
    static VERDICT_UNSCORED = 'unscored';

    static defaultProps = {
        verdict: null,
    };

    render() {

        if (!this.props.verdict) {

            return "";

        }

        return (
            <div className={`solve_result solve_result--verdict-${this.props.verdict}`} />
        );

    }

}
