import ymInternal from "react-yandex-metrika";

function ym(name:string, ...args: any[]): void {
    if (CONFIG.YandexMetrikaId) {
        ymInternal(name, ...args);
    }
}

export {ym};


