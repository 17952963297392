import React from "react";
import BemClassName from "Cheops/BemClassName";
import ResultTable from "Cheops/components/ResultTable/ResultTable";

export default class ResultTableHeaderSidebar extends React.Component {

    static defaultProps = {
        sidebar_open: false,
    };

    render() {


        let result_table_header_sidebar_class = new BemClassName("result_table_header_sidebar");

        result_table_header_sidebar_class.appendStatusIf(this.props.mode === ResultTable.MODE.NOO, "student");
        result_table_header_sidebar_class.appendStatusIf(this.props.sidebar_open, 'open');

        return (
            <div className={result_table_header_sidebar_class}>
                <table>
                    <tbody>
                    <tr>
                        <td className="result_table_header_sidebar__rating" />
                        <td className="result_table_header_sidebar__long_cell">
                            {this.props.sidebar_open
                            && <img className="result_table_header_sidebar__close"
                                    src={require("../../../img/close_cross.svg")}
                                    onClick={() => this.props.toggleSidebar()}
                            />}
                        </td>
                        <td className="result_table_header_sidebar__total">Итог</td>
                        {this.props.report.groups.map((group) => {

                            let styles = {};

                            if (group.color) {

                                styles.background = group.color;

                            }

                            return <td className="result_table_header_sidebar__stats" key={group.id}>
                                <div className="result_table_header_sidebar__stats_inner" style={styles}>
                                    {!!group.abbr && group.abbr}
                                </div>
                            </td>;

                        })}
                        <td className="result_table_header_sidebar__stats">Ср.</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        );

    }

}
