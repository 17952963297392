import React, {FC} from 'react';
import bnc         from 'bnc';
import                  './style.less';

const block = new bnc('course-sidebar');

type Props = {
    header:             React.ReactNode,
    children:           React.ReactNode,
    moduleList:         React.ReactNode,
    showModuleList:     boolean,
    headerExpanded:     boolean,
    moduleListExpanded: boolean,
};

export const CourseSidebar:FC<Props> = ({header, showModuleList, children, headerExpanded, moduleListExpanded, moduleList}) => {

    const getHasChildren = (children: React.ReactNode): boolean =>
        children
            ? Array.isArray(children)
                ? children.some(child => getHasChildren(child))
                : true
            : false
    ;

    const hasChildren = getHasChildren(children);

    const isExpanded = (!!moduleList && moduleListExpanded) || hasChildren || headerExpanded;

    return (
        <div className={block + block.bod('expanded', isExpanded)}>
            {header}
            {showModuleList ? moduleList : null}
            {children}
        </div>
    );
};
