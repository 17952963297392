import React from 'react';
import ReactDOMServer from 'react-dom/server';
import ProgressCircle from "Cheops/components/ProgressCircle/ProgressCircle";
import GraphCommonStyles from "Cheops/components/Graph/GraphCommonStyles";

const VERSION = CONFIG.Version ? `/${CONFIG.Version}` : "";

export default function () {

    return GraphCommonStyles()
        .selector('edge')
        .css({
            opacity: 0.7,
            'target-arrow-color': '#88CC00',
            'line-color': '#88CC00',
        })
        .selector('.module')
        .css({
            'background-image': (el) => renderModuleBg(el),
            'background-position-y': '10px 11px',

        })
        .selector('.module_not_available')
        .css({
            'text-opacity': 0.4,
            'background-image-opacity': "0.5 1",
        })
        .selector('.module_not_available:selected')
        .css({
            'text-opacity': 1,
            'background-image-opacity': "1 1",
        })
        .selector('.module.is_ext_child')
        .css({
            'background-width': '38px',
            'background-height': '38px',
            'background-position-y': '10px 11px',
        })
        .selector('edge.type_extension')
        .css({
            opacity: 0,
        })
        .selector('edge.parent_is_not_available')
        .css({
            opacity: 0.1,
            'target-arrow-color': '#ffffff',
            'line-color': '#ffffff',
        });

}


function renderModuleBg(el) {


    let bg = `url(${VERSION}/assets/img/graph_marker.svg)`;

    if (el.hasClass('is_ext_child')) {

        bg = 'url(data:image/gif;base64,R0lGODlhAQABAIAAAP)'; // empty

        if (el.selected()) {

            bg = `url(${VERSION}/assets/img/graph_marker_small_selected.svg)`;

        }

    } else if (el.selected()) {

        bg = `url(${VERSION}/assets/img/graph_marker_selected.svg)`;

    }

    bg += ` ${getProgressSvg(el)}`;

    return bg;

}

function getProgressSvg(el) {

    let svg = "<svg></svg>";

    let el_data = el.data();

    if (el_data.module_progress) {

        svg = ReactDOMServer.renderToStaticMarkup(
            <ProgressCircle progress={el_data.module_progress} starred={el_data.starred}
                            forceShowBorder={!el.hasClass('is_ext_child')}
            />,
        );

    }
    return `data:image/svg+xml;base64,${btoa(svg)}`;

}
