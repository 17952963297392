import React                from "react";
import Marked               from "@sirius/ui-shared/src/components/DisplayEngine/Marked";
import {getFractionLatex}   from "Cheops/actions/noopolis-ts";
import Answers              from "Cheops/components/ModulePassing/Answers/Answers";
import SolveResult          from "Cheops/components/ModulePassing/SolveResult";
import Helpers              from "Cheops/Helpers";
import                          "./AutoGradeTask.less";

export default class AutoGradeTask extends React.Component {

    static defaultProps = {
        sendAnswer: () => {
        },
        status: {},
        task_obj: {},
        verdict: null,
        sendDisabled: false,
    };

    constructor(props) {

        super(props);

        this.state = {
            user_answer: null,
            answersIsFilled: false,
            answersHasError: false,
        };

    }


    changeAnswer = (answer, done) => {

        this.setState({user_answer: answer}, done);

    };


    setAnswerIsFilled = (answersIsFilled, answersHasError) => {

        this.setState({answersIsFilled, answersHasError});

    };


    render() {

        const {answersIsFilled, answersHasError} = this.state;


        let user_answer = this.state.user_answer;

        if (user_answer === null) {

            user_answer = undefined;

        }

        let has_answers = false;

        if (this.state.user_answer !== null && this.state.user_answer !== "" && typeof this.state.user_answer !== 'undefined') {

            if (typeof this.state.user_answer === 'object') {

                if (this.state.user_answer.length) {

                    has_answers = true;

                }

            } else {

                has_answers = true;

            }

        }

        if (has_answers && this.props.task_obj.type === 'match' && !this.props.task_obj.answersData.partialAnswer) {

            for (let answer of this.props.task_obj.answersData.answers) {

                if (this.state.user_answer.findIndex((u_answer) => u_answer.indexOf(answer.id) >= 0) === -1) {

                    has_answers = false;
                    break;

                }

            }

        }

        let maxTies = parseInt(this.props.task_obj.maxTries);
        let currentTry = parseInt(this.props.status.currentTaskCurrentTry) || parseInt(this.props.currentTry) || 0;

        let tries_text = '';

        if (isFinite(maxTies) && isFinite(currentTry)) {

            tries_text = `${maxTies - currentTry} ${Helpers.formatPlural((maxTies - currentTry), ['попытка', 'попытки', 'попыток'])}`;

        }


        return (
            <div className="autograde_task">

                <SolveResult verdict={this.props.verdict} />

                <div className="task__text">
                    <div className="module_passing_content_inner__description">
                        <Marked>{this.props.task_obj.description}</Marked>
                    </div>
                </div>
                <div className="element_verdict">
                    {this.props.verdict !== SolveResult.VERDICT_OK &&
                    tries_text}
                </div>
                <Answers
                    answerType={this.props.task_obj.type}
                    answersData={this.props.task_obj.answersData}
                    userAnswer={user_answer}
                    onAnswer={this.changeAnswer}
                    getFractionLatex={getFractionLatex}
                    onAnswerIsFilledChange={this.setAnswerIsFilled}
                />
                <div className="course_autograde_page__bottom_button_wrapper">
                    <button className="course_autograde_page__bottom_button"
                            disabled={this.props.sendDisabled || !has_answers || !answersIsFilled || answersHasError}
                            onClick={() => this.props.sendAnswer(this.state.user_answer)}
                    >
                        Ответить
                    </button>
                </div>
            </div>);

    }

}
