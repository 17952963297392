import React from "react";
import "./AutoGradeHeader.less";
import ColorUtils from "Cheops/Utils/ColorUtils";
import BemClassName from "../../BemClassName";

export default class AutoGradeHeader extends React.Component {


    static defaultProps = {
        back_path: null,
        darkTheme: false,
        transparentTheme: false,
        onPauseClick: null,
    };

    render() {

        let className = new BemClassName('autograde_header');

        let wrapper_styles = {};
        className.appendStatusIf(this.props.darkTheme, 'dark');
        className.appendStatusIf(this.props.transparentTheme, 'transparent');

        if (this.props.darkTheme) {

            wrapper_styles.background = ColorUtils.veryDarken(this.props.courseColor);

        }

        return (<div className={className}>
            <div className="autograde_header__wrapper" style={wrapper_styles}>
                {!!this.props.back_path
                && <a href={this.props.back_path} className='autograde_header__back_arrow' />}

                {this.props.children}

                {!!this.props.onPauseClick
                && <div className="autograde_header__pause" onClick={this.props.onPauseClick}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M8 19C9.1 19 10 18.1 10 17V7C10 5.9 9.1 5 8 5C6.9 5 6 5.9 6 7V17C6 18.1 6.9 19 8 19ZM14 7V17C14 18.1 14.9 19 16 19C17.1 19 18 18.1 18 17V7C18 5.9 17.1 5 16 5C14.9 5 14 5.9 14 7Z"
                            fill="white"
                        />
                    </svg>
                </div>}
            </div>
        </div>);

    }

}
