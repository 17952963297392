import React              from 'react';
import bnc                from 'bnc';
import {Icon}             from '@sirius/ui-lib/src/blocks/Controls/Icon';
import {HBox, MGap, VBox} from '@sirius/ui-lib/src/blocks/Layout';
import ProgressCircle     from 'Cheops/components/ProgressCircle/ProgressCircle';
import                         './style.less';

const block = new bnc('textbook-module-card');

type Props = {
    module:         NooBack.Content.CourseModuleInfo;
    progress:       NooBack.ModuleSummary;
    link:           string;
    hideComplexity: boolean;
};

type ComplexityProps = {
    title: string;
    level: number;
};


const ModuleComplexity = ({title = 'Для творчества', level = 1}: ComplexityProps) =>
    <>
        {
            [...Array(level)].map(
                (e,i) => <Icon key={i} icon = 'star_outline' size={'xxs'}/>
            )
        }
        <MGap/>
        {title}
    </>
;

export const TextbookModuleCard = ({module, progress, link, hideComplexity = false}: Props) => {

    const {title, complexityTitle, level} = module;

    return(
        <div className = {block}>

            <a className = {
                    block.el('link') +
                    block.el('link').bod('locked', !progress.hash)
               }
               href      = {link}
            >
                <div className = {block.el('progress')}>
                    <ProgressCircle progress    = {progress}
                                    show_border = {true}
                                    whiteBg     = {true}
                    />
                </div>

                <VBox className = {block.el('content-wrapper')}>
                    <p className = {block.el('title')}>
                        {title}
                    </p>
                    {
                        !hideComplexity &&
                        <HBox className = {block.el('complexity')} >
                            <ModuleComplexity title = {complexityTitle}
                                              level = {level}
                            />
                        </HBox>
                    }
                </VBox>
            </a>
        </div>
    );
};
