import React            from "react";
import * as ReactDOM    from "react-dom";
import cloneDeep        from "lodash/cloneDeep";
import ProgressCircle   from "Cheops/components/ProgressCircle/ProgressCircle";
import Helpers          from "Cheops/Helpers";
import BemClassName     from "Cheops/BemClassName";
import ColorUtils       from "Cheops/Utils/ColorUtils";
import {Swipeable}      from "./Swipeable";
import                      "./AutoGradeFinished.less";


export default class AutoGradeFinished extends React.Component {

    static defaultProps = {
        courseHref: null,
        onDiscard: null,
        onCommit: null,
        status: null,
        showActionButtons: false,
    };

    constructor(props) {

        super(props);

        this.state = {
            modules_is_open: false,
        };

    }

    componentDidMount() {

        window.addEventListener("click", this.closeModules);
        this.handleWindowHeight();

    }

    componentWillUnmount() {

        window.removeEventListener("click", this.closeModules);
        document.body.style.setProperty('overscroll-behavior', 'auto');

    }

    handleWindowHeight = () => {

        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);

    };

    closeModules = (e) => {

        let this_node = ReactDOM.findDOMNode(this);
        let modules = this_node.querySelector(".autograde_finished__modules");
        if (modules) {

            if (!e.path.includes(modules) && this.state.modules_is_open) {

                this.swipeDownModules();

            }

        }

    };

    swipeUpModules = () => {

        if (!this.state.modules_is_open) {

            this.setState({modules_is_open: true});
            document.body.style.setProperty('overscroll-behavior', 'none');

        }

    };

    swipeDownModules = () => {

        if (this.state.modules_is_open) {

            this.setState({modules_is_open: false});
            document.body.style.setProperty('overscroll-behavior', 'auto');

        }

    };

    render() {

        let moduleDoneIds = this.props.status.modulesDone.map((module) => module.id);

        let modulesProgress = cloneDeep(this.props.modulesProgress);

        for (let [index, module] of Object.entries(modulesProgress)) {

            if (!moduleDoneIds.includes(module.id)) {

                continue;

            }

            modulesProgress[index].isAchieved = true;

        }

        let isParentMod = Helpers.isParentMode();

        let dots = [];


        for (let i = 1; i <= this.props.status.modulesTotal; i++) {

            let className = new BemClassName('autograde_finished__module_dot');

            className.appendStatusIf(this.props.status.modulesDone.length >= i, 'filled');

            dots.push(<div className={className} key={i} />);

        }

        let wrapper_styles = {background: ColorUtils.darken(this.props.courseColor)};

        let autograde_finished__modules_wrapper_class = new BemClassName("autograde_finished__modules_wrapper");
        let autograde_finished__modules_class = new BemClassName("autograde_finished__modules");

        if (this.state.modules_is_open) {

            autograde_finished__modules_wrapper_class.appendStatus("open");
            autograde_finished__modules_class.appendStatus("open");

        }

        return (<div className="autograde_finished" style={wrapper_styles}>
            <div className="autograde_finished__name">
                Вы завершили тест
            </div>
            <div className="autograde_finished__stats">
                <div className="autograde_finished__stats_titles">
                    <div>Тем в тесте</div>
                    <div>Зачтено тем</div>
                </div>
                <div className="autograde_finished__stats_data">
                    <div>{this.props.status.modulesTotal}</div>
                    <div>{this.props.status.modulesDone.length}</div>
                </div>
            </div>
            <div className="autograde_finished__module_dots">
                {dots}
            </div>
            {!!this.props.status.modulesDone.length
            && <div className={autograde_finished__modules_wrapper_class}>
                <div className={autograde_finished__modules_class}>
                    {!this.state.modules_is_open
                    && <div className="autograde_finished__modules_open">
                        <div className="autograde_finished__modules_open_rectangle"
                             onClick={() => this.setState({modules_is_open: true})}
                        />
                    </div>}

                    {this.state.modules_is_open
                    && <div className="autograde_finished__modules_title">
                        <div className="autograde_finished__modules_title_text">Зачтённые темы</div>
                        <div className="autograde_finished__modules_close"
                             onClick={this.swipeDownModules}
                        >
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                 xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M13.2997 0.709727C12.9097 0.319727 12.2797 0.319727 11.8897 0.709727L6.99973 5.58973L2.10973 0.699727C1.71973 0.309727 1.08973 0.309727 0.699727 0.699727C0.309727 1.08973 0.309727 1.71973 0.699727 2.10973L5.58973 6.99973L0.699727 11.8897C0.309727 12.2797 0.309727 12.9097 0.699727 13.2997C1.08973 13.6897 1.71973 13.6897 2.10973 13.2997L6.99973 8.40973L11.8897 13.2997C12.2797 13.6897 12.9097 13.6897 13.2997 13.2997C13.6897 12.9097 13.6897 12.2797 13.2997 11.8897L8.40973 6.99973L13.2997 2.10973C13.6797 1.72973 13.6797 1.08973 13.2997 0.709727Z"
                                    fill="#8C8695"
                                />
                            </svg>
                        </div>
                    </div>}

                    <Swipeable onSwipedUp={this.swipeUpModules} onSwipedDown={this.swipeDownModules}>
                        <div className="autograde_finished__modules_list" onClick={this.swipeUpModules}>
                            {this.props.status.modulesDone.map((module) => <div key={module.id}
                                                                                className="autograde_finished__module"
                            >
                                {module.title}
                                <ProgressCircle progress={modulesProgress[module.id]}
                                                forceShowBorder
                                />
                            </div>)}
                        </div>
                    </Swipeable>
                </div>
            </div>}

            {this.props.showActionButtons
            && this.props.status.modulesDone.length > 0
            && !isParentMod
            && <div className="autograde_finished__buttons_wrapper">
                <a className="autograde_finished__cancel_button" href={this.props.courseHref}>
                    Не применять результаты
                </a>
                <button className="autograde_finished__accept_button" onClick={this.props.onCommit}>
                    Применить результаты
                </button>
            </div>}

            {(!this.props.showActionButtons
                || !(this.props.status.modulesDone.length > 0)
                || isParentMod)
            && <div className="autograde_finished__buttons_wrapper">
                <a className="autograde_finished__accept_button" href={this.props.courseHref}>
                    Вернуться в курс
                </a>
            </div>}
        </div>);

    }

}
