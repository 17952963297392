import React, {FC}                  from 'react';
import {coursePageBlock}            from '../blocks';

export const TeacherCoursePageSection:FC<{title: string}> = ({title, children}) =>
    <section className={coursePageBlock.el('section')}>
        <p className={coursePageBlock.el('subtitle')}>
            {title}
        </p>
        <div className={coursePageBlock.el('wrapper')}>
            {children}
        </div>
    </section>
;
