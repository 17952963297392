/* eslint-disable */
import React                        from "react";
import {connect}                    from "react-redux";
import {bindActionCreators}         from "redux";
import {withRouter}                 from "react-router";
import clonedeep                    from "lodash/cloneDeep";
import isEqual                      from "lodash/isEqual";
import Marked                       from "@sirius/ui-shared/src/components/DisplayEngine/Marked";
import Answers                      from "Cheops/components/ModulePassing/Answers/Answers";
import * as noo_actions             from "Cheops/actions/noopolis";
import {getFractionLatex}           from "Cheops/actions/noopolis-ts";
import Helpers                      from "Cheops/Helpers";
import BemClassName                 from "Cheops/BemClassName";
import Solutions                    from "Cheops/components/ModulePassing/Solutions";
import ElementVerdictBlock          from "Cheops/components/ModulePassing/ElementVerdictBlock";
import TaskButtons                  from "Cheops/components/Task/TaskButtons";
import SolveResult                  from "Cheops/components/ModulePassing/SolveResult";
import TaskExplanations             from "Cheops/components/ModulePassing/TaskExplanations";
import {TASK_ANSWER_TYPES}          from "Lab/constants";
import {ELEMENT_VERDICT}            from "Cheops/constants";
import {TaskAnswerType}             from "Cheops/Model/Task";
import ProgrammingAnswerLimits      from "Cheops/components/ModulePassing/Answers/AnswerTypes/ProgrammingAnswer/ProgrammingAnswerLimits";
import ProgrammingAnswerExamples    from "Cheops/components/ModulePassing/Answers/AnswerTypes/ProgrammingAnswer/ProgrammingAnswerExamples";
import MarkedWithAnswers            from "Smt/MarkedWithAnswers";
import {ProfCheopsGreeting}         from "Cheops/components/ProfCheopsGreeting";
import Hints                        from "./Hints";

class Task extends React.Component {

    static defaultProps = {
        showHint: null,
        cancelHintRequest: null,
        reduceHints: null,
        hintsExpanded: false,
    };


    constructor(props) {

        super(props);

        this.state = {
            is_loaded: false,
            user_answer: null,
            send_force_disabled: false,
            isSavingInProgress: false,
            showed_result_popup_status: null,
            comments_thread: [],
            formVisible: false,
            solutionIsNotEditable: false,
            answersIsFilled: false,
            answersHasError: false,
        };

    }


    async UNSAFE_componentWillMount() {

        await this.getTaskData();

        document.addEventListener('click', this.closePopup, false);

        this.props.current_element_learn[this.props.module_id] && this.startCheckingTasks();

    }


    componentWillUnmount() {

        clearTimeout(this.checkSolutionsTimeout);
        document.removeEventListener('click', this.closePopup, false);

    }


    checkSolutionsTimeout = null;


    startCheckingTasks() {

        const elementLearns = this.props.current_element_learn[this.props.module_id];

        const idsToCheck = [];
        for (const elementLearn of Object.values(elementLearns)) {

            if (elementLearn.type !== 'task') {

                continue;

            }

            const taskContent = this.props.contents_data[elementLearn.hash]?.task;

            if (!taskContent) {

                continue;

            }

            if (taskContent.type.includes(TASK_ANSWER_TYPES.PROGRAMMING) && elementLearn.solution && elementLearn.solution.answers) {

                for (const answer of elementLearn.solution.answers) {

                    if (answer && answer.verdict === ELEMENT_VERDICT.NOTREADY) {

                        idsToCheck.push(answer.id);

                    }

                }

            } else {
                continue;
            }

        }

        if (idsToCheck.length > 0) {
            clearTimeout(this.checkSolutionsTimeout);

            this.checkTasks(idsToCheck);

        }

    }

    checkTasks = async (ids) => {

        const {
            course_id,
            module_id,
            element_progress,
        } = this.props;

        const response = await noo_actions.checkTask(this.props.course_id, ids);

        const hasNotReadyTasks = Object.values(response.result).some((result) => result.verdict === ELEMENT_VERDICT.NOTREADY);

        if (hasNotReadyTasks) {

            this.checkSolutionsTimeout = setTimeout(() => {
                this.checkTasks(ids);
            }, 5000);

        } else {

            for (const result of response.result) {

                clearTimeout(this.checkSolutionsTimeout);

                this.checkSolutionsTimeout = null;

                await this.props.getModuleLearnData(course_id, module_id);

                if (element_progress.type !== result.elementType || element_progress.id !== result.elementId) {

                    await this.props.getElementLearnData(course_id, module_id, result.elementId, result.elementType);

                } else {

                    await this.getTaskData(true);

                }
            }

        }

    };

    getTaskData = async (force = false) => {

        this.setState({is_loaded: false});

        let current_element_learn;

        if (this.props.current_element_learn[this.props.module_id]) {

            if (this.props.current_element_learn[this.props.module_id][this.props.element_progress.type + this.props.element_progress.id]) {

                current_element_learn = this.props.current_element_learn[this.props.module_id][this.props.element_progress.type + this.props.element_progress.id];

            }

        }


        if (!current_element_learn || force) {
            const {match, element_progress, module_id, course_id} = this.props;

            const taskId = !current_element_learn && match.params.element_id
                ? match.params.element_id
                : element_progress.id
            ;
            const moduleId = !current_element_learn && match.params.module_id
                ? match.params.module_id
                : module_id
            ;

            await this.props.getElementLearnData(course_id, moduleId, taskId, element_progress.type);

            if (this.props.current_element_learn[moduleId]) {
                current_element_learn = this.props.current_element_learn[moduleId][element_progress.type + element_progress.id];
            }
        }


        if (current_element_learn && !this.props.contents_data[current_element_learn.hash]) {

            await this.props.getElementContent(current_element_learn.hash);

        }


        let comments_thread = [];

        if (this.props.element_content.hasComments) {

            comments_thread = (await this.props.getElementLearnDataThread(this.props.course_id, this.props.module_id, this.props.element_progress.id, this.props.element_progress.type)).value.success;

            if (comments_thread.comments && !Helpers.isParentMode()) {


                for (let comment of comments_thread.comments) {

                    if (!comment.isRead) {

                        noo_actions.markThreadAsRead(this.props.course_id, this.props.module_id, this.props.element_progress.type, this.props.element_progress.id);
                        break;

                    }

                }

            }

        }

        if (current_element_learn && current_element_learn.reviewStatus === "reviewed" && !Helpers.isParentMode()) {

            let new_status = (await noo_actions.markReviewAsRead(this.props.course_id, this.props.module_id, this.props.element_progress.id)).success;


            let element_learn = clonedeep(current_element_learn);
            let current_module_learn = clonedeep(this.props.current_module_learn);

            element_learn.reviewStatus = new_status;


            for (let element of current_module_learn.elements) {

                if (element.id === element_learn.id && element.type === element_learn.type) {

                    element.reviewStatus = new_status;

                }

            }

            await this.props.updateElementLearnData(element_learn.id, this.props.module_id, element_learn);
            await this.props.updateModuleLearnData(current_module_learn);

        }


        let user_answer = this.state.user_answer;
        let send_force_disabled = this.state.send_force_disabled;

        if (current_element_learn && current_element_learn.solution && current_element_learn.solution.answers && !this.props.element_content.type.includes(TASK_ANSWER_TYPES.DETAILED)) {

            if (current_element_learn.solution.answers && current_element_learn.solution.answers.length) {

                user_answer = current_element_learn.solution.answers[0].answer;

            }

            if (!current_element_learn.isClosed) {

                send_force_disabled = true;

            }

        }

        this.setState({
            comments_thread,
            user_answer,
            send_force_disabled,
            answersIsFilled: !!user_answer,
            is_loaded: true,
        });

    };

    closePopup = (e) => {

        let window = document.querySelector(".contest_solution_popup");

        if (this.state.showed_result_popup_status !== null && e.target !== window) {

            this.setState({showed_result_popup_status: null});

        }

    };


    changeAnswer = (answer, done) => {


        let send_force_disabled = false;

        if (this.props.element_content.type.includes(TASK_ANSWER_TYPES.PROGRAMMING)) {

            const current_element_learn = this.props.current_element_learn[this.props.module_id][this.props.element_progress.type + this.props.element_progress.id];

            if (current_element_learn.solution && current_element_learn.solution.answers.length > 0) {

                if (current_element_learn.solution.answers[0].answer && isEqual(current_element_learn.solution.answers[0].answer, answer)) {

                    send_force_disabled = true;

                }

            }

        }

        this.setState({user_answer: answer, send_force_disabled}, done);

    };


    async sendAnswer() {

        const {isSavingInProgress, user_answer} = this.state;

        if (isSavingInProgress) {

            return;

        }

        if (typeof user_answer === 'undefined' || user_answer === null || user_answer === "") {

            return;

        }

        if (typeof user_answer === 'object') {

            if (user_answer.length === 0) {

                return;

            }

        }

        this.setState({isSavingInProgress: true, solutionIsNotEditable: true});

        let result = await noo_actions.solveTask(this.props.course_id, this.props.module_id, this.props.element_progress.id, this.state.user_answer);

        this.props.updateStoreDataFromDiff(result);

        this.setState({formVisible: false});

        let element_content = this.props.contents_data[this.props.element_progress.hash].task;

        let {showed_result_popup_status, send_force_disabled} = this.state;

        if (element_content.checkType !== 'manual') {

            if (result.isSolved) {

                send_force_disabled = false;

            }

            if (!element_content.isAdvanced && !this.props.element_content.type.includes(TASK_ANSWER_TYPES.PROGRAMMING)) {

                showed_result_popup_status = result.verdict;

                let progress = this.props.element_progress;

                let maxTies;
                let currentTry;

                if (progress.taskInfo) {

                    maxTies = parseInt(progress.taskInfo.maxTries);
                    currentTry = parseInt(progress.taskInfo.currentTry);

                    if (currentTry === 1 && result.verdict === SolveResult.VERDICT_OK) {

                        showed_result_popup_status = SolveResult.VERDICT_OK_FIRST_TRY;

                    }

                    if (((maxTies - currentTry) > 0 || !maxTies) && result.verdict === SolveResult.VERDICT_WRONG) {

                        showed_result_popup_status = SolveResult.VERDICT_WRONG_CAN_AGAIN;

                    }

                    if (((maxTies - currentTry) > 0 || !maxTies) && result.verdict === SolveResult.VERDICT_PARTLY) {

                        showed_result_popup_status = SolveResult.VERDICT_PARTLY_CAN_AGAIN;

                    }

                    if ((result.verdict === SolveResult.VERDICT_PARTLY || result.verdict === SolveResult.VERDICT_WRONG) && !result.id) {

                        showed_result_popup_status = SolveResult.VERDICT_REPEATED;

                    }

                }


            }

        }

        this.setState({
            isSavingInProgress: false,
            showed_result_popup_status,
            send_force_disabled,
            solutionIsNotEditable: false,
        });


        setTimeout(() => {

            this.setState({showed_result_popup_status: null});

        }, 2000);


        if (this.props.element_content.type.includes(TASK_ANSWER_TYPES.DETAILED)) {

            await this.getTaskData();

        }


        this.startCheckingTasks();

    }

    sendComment = async (task_type, comment) => {

        await noo_actions.putCommentToThread(this.props.course_id, this.props.module_id, task_type, this.props.element_progress.id, comment);

        await this.getTaskData();

    };


    openAnswerForm = () => {

        this.setState({formVisible: true});

    };

    closeAnswerForm = () => {

        this.setState({formVisible: false});

    };


    setAnswerIsFilled = (answersIsFilled, answersHasError) => {

        this.setState({answersIsFilled, answersHasError});

    };

    render() {

        let {user_answer, answersHasError, answersIsFilled, isSavingInProgress} = this.state;


        if (!this.state.is_loaded) {

            return <></>;

        }

        let active_task = this.props.element_content;

        let wrapper_class = "task";

        let send_disabled = false;
        let send_button_text = "Ответить";


        if (this.props.element_progress.isClosed) {

            send_button_text = "Далее";

        }

        if (Helpers.isParentMode() && !this.props.element_progress.isClosed) {

            send_disabled = true;

        }

        // if (this.state.send_force_disabled && !this.props.element_progress.isClosed) {
        //
        //     send_disabled = true;
        //
        // }


        if (this.props.element_progress.solution?.answers?.length > 0) {

            if (isEqual(this.props.element_progress.solution.answers[0].answer, user_answer)) {

                send_disabled = true;

            }

        }

        if (this.props.element_progress.verdict === ELEMENT_VERDICT.NOTREADY) {

            send_disabled = true;

        }


        let current_element_learn = this.props.current_element_learn[this.props.module_id][this.props.element_progress.type + this.props.element_progress.id];

        if (((this.props.module_availability !== null) && this.props.module_availability.locked) || current_element_learn.availability.locked) {

            if (send_button_text !== "Далее") { // TODO remove this...

                send_disabled = true;

            }

        }


        if (answersHasError || !answersIsFilled) {

            send_disabled = true;

        }


        if (isSavingInProgress) {

            send_disabled = true;

        }

        let task_title = "";


        if (!this.props.module_content.info.displaySettings.hideTaskHeader && (this.props.task_number !== null)) {

            task_title = <span>
                Задача
                {` ${this.props.task_number}`}
            </span>;

            if (current_element_learn.availability.locked) {

                task_title = <span>
                Задача
                    {` ${this.props.task_number}`}
                    <span className="detailed_answer__task_title_description">
                        (закрыта)
                    </span>
                </span>;

            }

        }


        let task_scores_class = new BemClassName("detailed_answer__task_scores");
        let task_scores_text = "";

        if (!this.props.module_content.info.displaySettings.hideScore && this.props.element_progress.max > 0) {

            task_scores_text = `До ${this.props.element_progress.max} ${Helpers.formatPlural(this.props.element_progress.max, ["балла", "баллов", "баллов"])}`;

            if (this.props.module_element_learn.reviewStatus && (this.props.module_element_learn.reviewStatus === "reviewed" || this.props.module_element_learn.reviewStatus === "read")) {

                task_scores_text = `${this.props.element_progress.current} из ${this.props.element_progress.max}`;
                task_scores_class.appendStatus("graduated");

            }

        }

        const moduleContentDescriptionClassName = new BemClassName('module_passing_content_inner__description');
        moduleContentDescriptionClassName.appendStatusIf(!!active_task?.layout?.inline, 'inline');

        const hasExamples = !!this.props.element_content.answersData[0].examples;
        const hasLimits   = !!this.props.element_content.answersData[0].limits;

        return (
            <div className={wrapper_class}>

                {this.state.showed_result_popup_status
                && <SolveResult verdict={this.state.showed_result_popup_status} />}

                {this.props.module_content.info.displaySettings
                && (!this.props.module_content.info.displaySettings.hideScore
                    || !this.props.module_content.info.displaySettings.hideTaskHeader)
                && <div className="detailed_answer__task_header">
                    <div className="detailed_answer__task_title">{task_title}</div>
                    <div className={task_scores_class}>{task_scores_text}</div>
                </div>}

                <div className="task__text">
                    <div className={`${moduleContentDescriptionClassName}`}>
                        <MarkedWithAnswers
                            allMustBeFilled
                            renderAnswers={!!active_task.layout?.inline}
                            element_content={this.props.element_content}
                            userAnswer={user_answer}
                            answerType={active_task.type}
                            element_progress={this.props.element_progress}
                            showResults={this.props.element_progress.isClosed}
                            taskNum={this.props.selected_element_num}
                            answersData={active_task.answersData}
                            getFractionLatex={getFractionLatex}
                            onAnswerIsFilledChange={this.setAnswerIsFilled}
                            onAnswer={this.changeAnswer}
                            courseId={this.props.course_id}
                            moduleId={this.props.module_id}
                            elementLearn={current_element_learn}
                            currentElementLearnThread={this.state.comments_thread}
                            isLocked={current_element_learn.availability.locked}
                            solutionIsNotEditable={this.state.solutionIsNotEditable}
                            sendComment={(task_type, comment) => this.sendComment(task_type, comment)}
                            sendAnswer={() => this.sendAnswer()}
                            formVisible={this.state.formVisible}
                            openAnswerForm={this.openAnswerForm}
                            closeAnswerForm={this.closeAnswerForm}
                        >
                            {active_task.description}
                        </MarkedWithAnswers>
                        {hasExamples && <ProgrammingAnswerExamples examples = {this.props.element_content.answersData[0].examples} />}
                        {hasLimits   && <ProgrammingAnswerLimits   limits   = {this.props.element_content.answersData[0].limits}   />}
                    </div>
                </div>

                <TaskExplanations
                    taskVideos={this.props.task_videos}
                    getTaskVideo={this.props.getTaskVideo}
                    courseId={this.props.course_id}
                    moduleId={this.props.module_id}
                    elementContent={this.props.element_content}
                    elementLearn={current_element_learn}
                    moduleContent={this.props.module_content.info}
                />

                <ElementVerdictBlock
                    taskType={active_task.type}
                    elementProgress={this.props.element_progress}
                />

                {((!this.props.element_progress.isClosed
                    || active_task.type.includes(TaskAnswerType.DETAILED)
                    || active_task.type.includes(TaskAnswerType.PROGRAMMING)// PROGRAMMING still editable when element isClosed
                ) && !active_task.layout?.inline)
                && <Answers
                    allMustBeFilled
                    taskNum={this.props.selected_element_num}
                    courseId={this.props.course_id}
                    moduleId={this.props.module_id}
                    answerType={active_task.type}
                    answersData={active_task.answersData}
                    userAnswer={user_answer}
                    elementLearn={current_element_learn}
                    currentElementLearnThread={this.state.comments_thread}
                    elementContent={this.props.element_content}
                    isLocked={current_element_learn.availability.locked}
                    solutionIsNotEditable={this.state.solutionIsNotEditable}
                    sendComment={(task_type, comment) => this.sendComment(task_type, comment)}
                    onAnswer={this.changeAnswer}
                    sendAnswer={() => this.sendAnswer()}
                    formVisible={this.state.formVisible}
                    openAnswerForm={this.openAnswerForm}
                    closeAnswerForm={this.closeAnswerForm}
                    getFractionLatex={getFractionLatex}
                    onAnswerIsFilledChange={this.setAnswerIsFilled}
                />}

                {((this.props.element_progress.isClosed
                    && !active_task.type.includes(TaskAnswerType.DETAILED)
                    && !active_task.type.includes(TaskAnswerType.PROGRAMMING)
                ) && !active_task.layout?.inline)
                && <Solutions
                    element_progress={this.props.element_progress}
                    element_content={this.props.element_content}
                    course_id={this.props.course_id}
                    module_id={this.props.module_id}
                />}

                {(!this.props.module_availability.locked && current_element_learn.availability.locked
                    && !!this.props.module_content.info.displaySettings.hideTaskHeader)
                && <div className="task__locked">
                    <ProfCheopsGreeting>
                        <Marked>{current_element_learn.availability.locked.message}</Marked>
                    </ProfCheopsGreeting>
                </div>}

                {!active_task.type.includes(TaskAnswerType.DETAILED)
                && !active_task.type.includes(TaskAnswerType.DETAILED_NO_FILE)
                && !active_task.type.includes(TaskAnswerType.NONE)
                && <TaskButtons
                    checkType={this.props.element_content.checkType}
                    answerType={active_task.type}
                    elementProgress={this.props.element_progress}
                    sendingDisabled={send_disabled}
                    goToNextElement={() => this.props.goToNextElement()}
                    onAnswer={() => this.sendAnswer()}
                    openAnswerForm={this.openAnswerForm}
                    closeAnswerForm={this.closeAnswerForm}
                    formVisible={this.state.formVisible}
                />}

                {this.props.hintsExpanded
                && <Hints hints={this.props.hints}
                          reduceHints={this.props.reduceHints}
                          showHintPopup={this.props.showHintPopup}
                          cancelHintRequest={this.props.cancelHintRequest}
                          sendHintRequest={this.props.showHint}
                          newHintsAllowed={this.props.newHintsAllowed}
                />}

            </div>
        );

    }

}


const mapStateToProps = (state) => {

    return {
        task_videos: state.nooReducer.task_videos,
        current_element_learn: state.nooReducer.current_element_learn,
        current_module_learn: state.nooReducer.current_module_learn,
        contents_data: state.nooReducer.contents_data,
        current_element_learn_thread: state.nooReducer.current_element_learn_thread,
    };

};

const mapDispatchToProps = (dispatch) => {

    return {
        updateElementLearnData: (element_id, module_id, data) => dispatch(noo_actions.updateElementLearnData(element_id, module_id, data)),
        updateModuleLearnData: (learn_obj) => dispatch(noo_actions.updateModuleLearnData(learn_obj)),
        getTaskVideo: bindActionCreators(noo_actions.getTaskVideo, dispatch),
        getElementLearnData: bindActionCreators(noo_actions.getElementLearnData, dispatch),
        getModuleLearnData: bindActionCreators(noo_actions.getModuleLearnData, dispatch),
        getElementLearnDataThread: bindActionCreators(noo_actions.getElementLearnDataThread, dispatch),
        getElementContent: bindActionCreators(noo_actions.getElementContent, dispatch),
        getModuleContent: bindActionCreators(noo_actions.getModuleContent, dispatch),
    };

};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Task));
