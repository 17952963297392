import React                                  from "react";
import {NavLink, useParams, Redirect}         from "react-router-dom";
import {HBox,VBox, BoxAlignment}              from "@sirius/ui-lib/src/blocks/Layout";
import {IconButton, Icon}                     from "@sirius/ui-lib/src/blocks/Controls";

import {
    TOCSidebarProps,
    TOCSidebarItemProps,
    tocIterable
}                                             from "./@types";
import {sidebarEl}                            from "./block";

export const TOCSidebarItem: React.FC<TOCSidebarItemProps> = ({name, courseId, position, onClick}) =>
    <NavLink to              = {`/course/${courseId}/chapter/${position}`}
             className       = {`${sidebarEl.mod('item')}`}
             activeClassName = {`${sidebarEl.mod('item-selected')}`}
             onClick         = {onClick}
    >
        <HBox className={sidebarEl.mod('item-icon')}>
            <Icon icon={'subject'} size={'m'}/>
        </HBox>
        <HBox className={`${sidebarEl.mod('item-title')} text-m`}>
            {name}
        </HBox>
    </NavLink>
;


const chapterFilter = ({type}:tocIterable[number])=> type === "chapter";

export const TOCSidebar: React.FC<TOCSidebarProps> = ({toc,courseId, expanded, onClose}) => {
    const chapters      = (toc as tocIterable).filter( chapterFilter );
    const {chapter_num} = useParams<{chapter_num:string}>();
    return chapters.length > 0
            ?   chapter_num === void(0)
                ?   <Redirect to={`/course/${courseId}/chapter/0`}/>
                :   <VBox className={sidebarEl + sidebarEl.bod('hide', !expanded)}>
                        <HBox align={BoxAlignment.end} className={sidebarEl.mod('close-button')}>
                            <IconButton icon     = {'close'}
                                        size     = {'s'}
                                        onAction = {onClose}
                            />
                        </HBox>
                        {
                            chapters.map(
                                (node, i) =>
                                    <TOCSidebarItem
                                        {...node}
                                        key      = {i}
                                        courseId = {courseId}
                                        position = {i}
                                        onClick  = {onClose}
                                    />
                            )
                        }
                    </VBox>
            :   null
    ;
}
