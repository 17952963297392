import React from "react";
import connect from "react-redux/es/connect/connect";
import CourseHeader from "Cheops/components/CoursePage/CourseHeader";
import {Footer} from "Cheops/components/Footer";
import {bindActionCreators} from "redux";
import * as noo_actions from "Cheops/actions/noopolis";
import CircularProgress from "Smt/Ui";
import NotificationsList from "Cheops/components/Notifications/NotificationsList";
import Helpers from "Cheops/Helpers";
import Marked from "@sirius/ui-shared/src/components/DisplayEngine/Marked";
import {META_THEME_COLOR_DEFAULT} from "Cheops/constants";
import {cluster} from "CheopsRoot/clusters/current";


class SectionTextPage extends React.Component {

    constructor(props) {

        super(props);


        this.state = {
            is_loading: true,
            show_notifications: false,
        };

    }


    async componentWillMount() {

        await this.getData();

        let course_contents = this.props.course_contents[this.props.current_course.hash];
        document.querySelector("meta[name=theme-color]").setAttribute("content", course_contents.color);

    }

    async componentDidUpdate({match}) {

        if (this.props.match.params.module_id !== match.params.module_id) {

            await this.getData();

        }

        if (this.props.match.params.course_id !== match.params.course_id) {

            await this.getData();

        }

        if (this.props.match.params.text_index !== match.params.text_index) {

            let module_hash = this.props.current_module_learn_by_skin.summary.hash;

            let widget = this.props.contents_data[module_hash].widgets[this.props.match.params.text_index];

            if (!widget || !widget.text) {

                Helpers.renderErrorPage(404);

            }

        }

    }

    componentWillUnmount() {

        document.querySelector("meta[name=theme-color]").setAttribute("content", META_THEME_COLOR_DEFAULT);

    }


    async getData() {

        this.setState({is_loading: true});

        let course_id = parseInt(this.props.match.params.course_id);
        let module_id = parseInt(this.props.match.params.module_id);

        await this.props.getCourseData(course_id);
        await this.props.getModuleLearnDataBySkin(course_id, module_id, "contest");

        if (!this.props.course_contents[this.props.current_course.hash]) {

            await this.props.getCourseContent(this.props.current_course.hash);

        }

        let module_hash = this.props.current_module_learn_by_skin.summary.hash;

        await this.props.getModuleContent(module_hash);

        let widget = this.props.contents_data[module_hash].widgets[this.props.match.params.text_index];

        if (!widget || !widget.text) {

            Helpers.renderErrorPage(404);

        }

        this.setState({is_loading: false});

    }

    render() {

        if (this.state.is_loading) {

            return (<CircularProgress centerOfWindow={true} />);

        }

        let module_hash = this.props.current_module_learn_by_skin.summary.hash;

        let widget = this.props.contents_data[module_hash].widgets[this.props.match.params.text_index].text;

        let course_contents = this.props.course_contents[this.props.current_course.hash];

        return (<div className="notification_page">
            <CourseHeader
                show_notifications={true}
                expanded={true}
                title_align="left"
                title={widget.title}
                showNotifications={() => this.setState({show_notifications: true})}
                back_path={`/#/course/${this.props.match.params.course_id}/section_${this.props.match.params.module_id}`}
                course_color={course_contents.color}
            />

            <div className="notification_page__wrapper">
                <div className="notification_page__content">
                    <Marked>{widget.text.text}</Marked>
                </div>
            </div>

            {this.state.show_notifications
            && <NotificationsList hideNotifications={() => this.setState({show_notifications: false})} />}

            {
                cluster.type === "courses" &&
                <Footer />
            }
        </div>);

    }

}


const mapStateToProps = (state) => ({
    current_course: state.nooReducer.current_course,
    current_module_learn_by_skin: state.nooReducer.current_module_learn_by_skin,
    contents_data: state.nooReducer.contents_data,
    course_contents: state.nooReducer.course_contents,
});

const mapDispatchToProps = (dispatch) => ({
    getCourseData: bindActionCreators(noo_actions.getCourseData, dispatch),
    getCourseContent: bindActionCreators(noo_actions.getCourseContent, dispatch),
    getModuleLearnDataBySkin: bindActionCreators(noo_actions.getModuleLearnDataBySkin, dispatch),
    getModuleContent: bindActionCreators(noo_actions.getModuleContent, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SectionTextPage);
