import React from "react";
import "./ProgressTransferPage.less";
import {connect} from "react-redux";
import CourseHeader from "Cheops/components/CoursePage/CourseHeader";
import CircularProgress from "Smt/Ui";
import TransferProgressPopup from "Cheops/components/CoursePage/TransferProgressPopup";
import {bindActionCreators} from "redux";
import * as noo_actions from "../actions/noopolis";
import MessagePopup from "Cheops/components/MessagePopup";

class ProgressTransferPage extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            popup_info: null,
            errors: [],
            is_loading: false,
            component_is_loading: true,
        };

    }

    async componentWillMount() {

        await this.props.getCourseLearnData(this.props.match.params.course_id);
        await this.props.getCourseContent(this.props.current_course_learn.hash);

        this.setState({component_is_loading: false});

    }

    componentDidMount() {

        window.addEventListener('resize', this.handleWindowHeight);
        this.handleWindowHeight();

    }

    async componentDidUpdate({match}) {

        let prevProps = match.params;
        let newProps = this.props.match.params;

        if (newProps.course_id !== prevProps.course_id) {

            await this.props.getCourseLearnData(this.props.match.params.course_id);

        }

    }

    componentWillUnmount() {

        window.removeEventListener("resize", this.handleWindowHeight);

    }

    handleWindowHeight = () => {

        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);

    };


    transferProgress = async () => {

        this.setState({is_loading: true});

        let popup_info = null;

        let transfering = await noo_actions.transferProgress(this.props.match.params.course_id);

        if (transfering.error) {

            this.setState({errors: transfering.error, is_loading: false});
            return;

        }


        if (transfering.success) {

            popup_info = transfering.success;

        }

        this.setState({
            popup_info,
            is_loading: false,
        });

    };

    declineProgressTransferring = async () => {

        this.setState({is_loading: true});

        let declining = await noo_actions.declineProgressTransferring(this.props.match.params.course_id);

        if (declining.success) {

            location.hash = `#/course/${this.props.match.params.course_id}`;

        }

        this.setState({is_loading: false});

    };

    closeTransferPopup = () => {

        location.hash = `#/course/${this.props.match.params.course_id}`;

    };


    closeErrorPopup = (errorIndex) => {

        const {errors} = this.state;

        errors.splice(errorIndex, 1);

        this.setState({errors});

    };


    render() {

        if (this.state.component_is_loading) {

            return <CircularProgress centerOfWindow={true} />;

        }


        let error = null;

        if (this.state.errors.length > 0) {

            error = this.state.errors[0];

        }


        return (
            <div className="progress_transfer_page">
                {this.state.is_loading
                && <div className="progress_transfer_page__waiting">
                    <CircularProgress centerOfWindow={true} />
                    <div className="progress_transfer_page__waiting_text">Мы пересчитываем прогресс по курсу...</div>
                </div>}

                {error
                && <MessagePopup
                    rightButtonText="Закрыть"
                    onRightClick={() => this.closeErrorPopup(0)}
                >
                    <h2>{error.message}</h2>
                    {!!error.meta && error.meta.action}
                </MessagePopup>}

                {this.state.popup_info
                && <TransferProgressPopup closeTransferPopup={this.closeTransferPopup}
                                          task_number={this.state.popup_info.tasksCount}
                />}

                <CourseHeader title="Автопрохождение"
                              course_color={this.props.course_contents[this.props.current_course_learn.hash].color}
                              back_path={`/#/course/${this.props.match.params.course_id}`}
                />
                <div className="progress_transfer_page__content">
                    <div className="progress_transfer_page__content_header">
                        Некоторые задачи этого курса вы решили в других курсах. Не обязательно их решать ещё раз
                    </div>
                    <div>
                        Если хотите, мы автоматически засчитаем вам такие задачи. А вы сможете быстрее продвигаться к
                        новому и интересному
                    </div>
                    <div className="progress_transfer_page__buttons">
                        {this.props.current_course_learn.canTransfer && !this.props.current_course_learn.transferDeclined
                        && <div className="progress_transfer_page__abort_button"
                                onClick={this.declineProgressTransferring}
                        >
                            Больше не предлагать
                        </div>}
                        {this.props.current_course_learn.canTransfer
                        && <div className="progress_transfer_page__transfer_button" onClick={this.transferProgress}>
                            Засчитать задачи
                        </div>}
                    </div>
                </div>
            </div>);

    }

}


const mapStateToProps = (state) => ({
    course_contents: state.nooReducer.course_contents,
    current_course_learn: state.nooReducer.current_course_learn,
});

const mapDispatchToProps = (dispatch) => ({
    getCourseLearnData: bindActionCreators(noo_actions.getCourseLearnData, dispatch),
    getCourseContent: bindActionCreators(noo_actions.getCourseContent, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProgressTransferPage);
