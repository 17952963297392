import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as noo_actions from "Cheops/actions/noopolis";
import CircularProgress from "Smt/Ui";
import AutoGradeHeader from "Cheops/components/AutoGrade/AutoGradeHeader";
import * as autograde_actions from "Cheops/actions/autograde";
import AutoGradeTimeout from "Cheops/components/AutoGrade/AutoGradeTimeout";
import AutoGradeStatus from "Cheops/components/AutoGrade/AutoGradeStatus";
import AutoGradeTask from "Cheops/components/AutoGrade/AutoGradeTask";
import AutoGradeFinished from "Cheops/components/AutoGrade/AutoGradeFinished";
import AutoGradePopup from "Cheops/components/AutoGrade/AutoGradePopup";
import AutogradeStartPage from "Cheops/components/AutoGrade/AutogradeStartPage";
import SolveResult from "Cheops/components/ModulePassing/SolveResult";
import AutogradeStartConfirmPage from "Cheops/components/AutoGrade/AutogradeStartConfirmPage";
import Helpers from "Cheops/Helpers";
import "./CourseAutogradePage.less";
import AutoGradeNothingToDo from "Cheops/components/AutoGrade/AutoGradeNothingToDo";

class CourseAutogradePage extends React.Component {


    // eslint-disable-next-line react/sort-comp
    STATUS_CAN_START = 'canStart';
    STATUS_ONGOING = 'ongoing';

    SCREEN_TIMEOUT = 2;

    SCREEN_START = 'canStart';
    SCREEN_START_CONFIRM = 'canStartConfirm';
    SCREEN_STATUS = 'status';
    SCREEN_ONGOING = "ongoing";
    SCREEN_FINISHED = "finished";
    SCREEN_DISCARDED = "discarded";
    SCREEN_COMMITTED = "committed";
    SCREEN_NOTHING_TO_DO = "nothingToDo";


    task_interval = 0;

    constructor(props) {

        super(props);

        this.state = {
            status: {
                solved: null,
                total: null,
                approximate: null,
                currentTime: null,
            },
            time_offset: 0,
            visible_screen: null,
            is_loading: true,
            answer_sending_disabled: false,
            show_skip_popup: false,
            task_time_till: null,
            currentTry: null,
            task_remain_time: "",
            task_obj: null,
        };

    }

    componentDidUpdate(prevProps, prevState) {

        if (prevState.status.currentTime !== this.state.status.currentTime && this.state.status.currentTime) {

            let time_offset = new Date(this.state.status.currentTime) - (new Date());
            this.setState({time_offset});

        }

    }

    componentWillMount() {

        this.getCourseData(parseInt(this.props.match.params.course_id));

    }

    async componentWillReceiveProps({match, history}) {

        if (
            (this.props.match.params.course_id !== match.params.course_id)
            || history.location.force_refresh_data
        ) {

            delete history.location.force_refresh_data;
            this.getCourseData(parseInt(match.params.course_id));

        }

    }


    async getCourseData(course_id) {

        this.setState({is_loading: true});

        await this.props.getCourseLearnData(course_id);

        if (!this.props.current_course_learn.isAttended) {

            Helpers.renderErrorPage(404);

        }

        if (!this.props.current_course_learn.autograde) {

            Helpers.renderErrorPage(404);

        }

        await this.props.getCourseContent(this.props.current_course_learn.hash);


        let response = await autograde_actions.getAutogradeStatus(course_id, this.props.current_course_learn.autograde);

        this.processStatusResponse(response);

    }

    processStatusResponse(response, is_next_task = false) {

        switch (response.status) {
            case this.STATUS_CAN_START:
            case this.SCREEN_FINISHED:
            case this.SCREEN_DISCARDED:
            case this.SCREEN_COMMITTED:
            case this.SCREEN_NOTHING_TO_DO:
                this.setState({
                    status: response.value,
                    visible_screen: response.status,
                    is_loading: false,
                    show_skip_popup: false,
                });
                break;

            case this.STATUS_ONGOING:
                this.setState({status: response.value});
                if (response.value.currentTaskExpired === true) {

                    this.setState({
                        status: response.value,
                        visible_screen: this.SCREEN_TIMEOUT,
                        is_loading: false,
                        show_skip_popup: false,
                    });
                    break;

                }

                if (response.value.currentTaskExpired === false) {

                    this.showTask(is_next_task);
                    break;

                }

                this.setState({
                    status: response.value,
                    visible_screen: this.SCREEN_STATUS,
                    is_loading: false,
                    show_skip_popup: false,
                });
                break;
        }

    }

    async showTask(is_next_task = false) {

        let response;

        let currentTry = this.state.currentTry;

        if (is_next_task) {

            currentTry = null;
            response = await autograde_actions.getAutogradeNextTask(this.props.match.params.course_id, this.props.current_course_learn.autograde);

        } else {

            response = await autograde_actions.getAutogradeCurrentTask(this.props.match.params.course_id, this.props.current_course_learn.autograde);

        }

        if (response.success) {

            await this.props.getElementContent(response.success.hash);

            let content = this.props.contents_data[response.success.hash];

            clearInterval(this.task_interval);
            this.task_interval = setInterval(this.calculateRemainTime, 1000);

            this.setState({
                currentTry,
                visible_screen: this.SCREEN_ONGOING,
                task_time_till: new Date(response.success.timeout),
                task_obj: content.task,
                is_loading: false,
            }, this.calculateRemainTime);

        }

    }


    calculateRemainTime = () => {

        let server_now = new Date((new Date()).getTime() + this.state.time_offset);

        let remain_total_seconds = (this.state.task_time_till - server_now) / 1000;

        if (remain_total_seconds <= 0) {

            clearInterval(this.task_interval);
            this.setState({visible_screen: this.SCREEN_TIMEOUT});
            return;

        }

        let remain_minutes = `${Math.floor(remain_total_seconds / 60)}`;
        let remain_seconds = `${Math.floor(remain_total_seconds % 60)}`;

        if (remain_minutes.length === 1) {

            remain_minutes = `0${remain_minutes}`;

        }

        if (remain_seconds.length === 1) {

            remain_seconds = `0${remain_seconds}`;

        }

        this.setState({task_remain_time: `${remain_minutes} : ${remain_seconds}`});

    };


    doAction = async (action_name) => {

        this.setState({is_loading: true});

        clearInterval(this.task_interval);

        let response = await autograde_actions.doAutogradeAction(this.props.match.params.course_id, this.props.current_course_learn.autograde, action_name);

        this.processStatusResponse(response, true);

        return response;

    }

    sendAnswer = async (answer) => {

        this.setState({answer_sending_disabled: true});


        let response = await autograde_actions.solveAutogradeTask(this.props.match.params.course_id, this.props.current_course_learn.autograde, answer);

        let target_screen = response.status;

        let currentTry = response.currentTry;

        if (target_screen === this.SCREEN_ONGOING) {

            if (!response.value.currentTaskCurrentTry) {

                target_screen = this.SCREEN_STATUS;

            } else if (response.verdict === SolveResult.VERDICT_WRONG) {

                response.verdict = SolveResult.VERDICT_WRONG_CAN_AGAIN;

            } else if (response.verdict === SolveResult.VERDICT_PARTLY) {

                response.verdict = SolveResult.VERDICT_PARTLY_CAN_AGAIN;

            }

        }


        if (response.verdict !== SolveResult.VERDICT_WRONG_CAN_AGAIN) {

            clearInterval(this.task_interval);

        }

        this.setState({
            currentTry,
            status: response.value,
            verdict: response.verdict,
        }, () => setTimeout(() => this.setStateAndResetOtherToDefault({visible_screen: target_screen}), 2000));

    };

    setStateAndResetOtherToDefault(new_state) {

        let default_states = {
            answer_sending_disabled: false,
            show_skip_popup: false,
            verdict: null,
        };

        this.setState({...default_states, ...new_state});

    }

    commitResults = async () => {

        let response = await this.doAction('commit');
        this.props.setAppliedAutogradeCount(response.value.modulesDone.length);
        location.hash = `#/course/${this.props.match.params.course_id}`;

    };

    startAutoGrade = async () => {

        let response = await autograde_actions.doAutogradeAction(this.props.match.params.course_id, this.props.current_course_learn.autograde, 'start');

        if (response.status === this.SCREEN_NOTHING_TO_DO) {

            this.setState({visible_screen: response.status});
            return;

        }

        this.showTask(true);

    };

    render() {

        if (this.state.is_loading) {

            return <CircularProgress centerOfWindow={true} />;

        }

        let isParentMod = Helpers.isParentMode();

        let onPauseClick = null;

        if (this.state.visible_screen === this.SCREEN_ONGOING && !isParentMod) {

            onPauseClick = () => this.setState({show_skip_popup: true});

        }

        let back_path = `/#/course/${this.props.match.params.course_id}`;

        if (this.state.visible_screen === this.SCREEN_ONGOING) {

            back_path = null;

        }


        return <div className="course_autograde_page">

            <AutoGradeHeader
                darkTheme={[this.SCREEN_DISCARDED, this.SCREEN_COMMITTED, this.SCREEN_ONGOING].includes(this.state.visible_screen)}
                transparentTheme={[this.SCREEN_FINISHED].includes(this.state.visible_screen)}
                back_path={back_path}
                courseColor={this.props.course_contents[this.props.current_course_learn.hash].color}
                show_notifications={!this.state.user_is_anon}
                showNotifications={() => this.setShowNotifications(true)}
                onPauseClick={onPauseClick}
            >
                {this.state.visible_screen !== this.SCREEN_ONGOING && "Проверка знаний"}
                {this.state.visible_screen === this.SCREEN_ONGOING && <strong>{this.state.task_remain_time}</strong>}
            </AutoGradeHeader>

            <div className="course_autograde_page__wrapper">
                {this.state.show_skip_popup
                && <AutoGradePopup
                    leftButtonText="Пропустить"
                    rightButtonText="Решать"
                    onLeftClick={() => this.doAction('pass')}
                    onRightClick={() => this.setState({show_skip_popup: false})}
                >
                    <strong>
                        Пропустите или решите задачу,
                        <br />
                        чтобы продолжить позже
                    </strong>
                </AutoGradePopup>}

                {this.state.visible_screen === this.SCREEN_TIMEOUT
                && <AutoGradeTimeout toNext={() => this.doAction('pass')} />}

                {this.state.visible_screen === this.SCREEN_START
                && <AutogradeStartPage startAutograde={() => {

                    this.setState({visible_screen: this.SCREEN_START_CONFIRM});
                    document.body.scrollTop = document.documentElement.scrollTop = 0;

                }}
                />}

                {this.state.visible_screen === this.SCREEN_START_CONFIRM
                && <AutogradeStartConfirmPage startAutograde={this.startAutoGrade} status={this.state.status} />}

                {this.state.visible_screen === this.SCREEN_STATUS
                && <AutoGradeStatus status={this.state.status}
                                    backLink={`/#/course/${this.props.match.params.course_id}`}
                                    onFinish={() => this.doAction('finish')}
                                    toNext={() => this.showTask(true)}
                />}


                {this.state.visible_screen === this.SCREEN_NOTHING_TO_DO
                && <AutoGradeNothingToDo backLink={`/#/course/${this.props.match.params.course_id}`} />}

                {this.state.visible_screen === this.SCREEN_ONGOING
                && <AutoGradeTask
                    sendDisabled={this.state.answer_sending_disabled || isParentMod}
                    verdict={this.state.verdict}
                    status={this.state.status}
                    currentTry={this.state.currentTry}
                    task_obj={this.state.task_obj}
                    sendAnswer={this.sendAnswer}
                />}

                {[this.SCREEN_FINISHED, this.SCREEN_DISCARDED, this.SCREEN_COMMITTED].includes(this.state.visible_screen)
                && <AutoGradeFinished status={this.state.status}
                                      courseColor={this.props.course_contents[this.props.current_course_learn.hash].color}
                                      modulesProgress={this.props.current_course_learn.modules}
                                      showActionButtons={this.state.visible_screen !== this.SCREEN_COMMITTED}
                                      courseHref={`/#/course/${this.props.match.params.course_id}`}
                    // onDiscard={() => this.doAction('discard')}
                                      onCommit={this.commitResults}
                />}
            </div>
        </div>;

    }

}

const mapStateToProps = (state) => ({
    confirmed_courses: state.autogradeReducer.confirmed_courses,
    current_course_learn: state.nooReducer.current_course_learn,
    course_contents: state.nooReducer.course_contents,
    contents_data: state.nooReducer.contents_data,
});

const mapDispatchToProps = (dispatch) => ({
    setAppliedAutogradeCount: (count) => dispatch(autograde_actions.setAppliedAutogradeCount(count)),
    getElementContent: bindActionCreators(noo_actions.getElementContent, dispatch),
    getCourseData: bindActionCreators(noo_actions.getCourseData, dispatch),
    getCourseLearnData: bindActionCreators(noo_actions.getCourseLearnData, dispatch),
    getModuleLearnData: bindActionCreators(noo_actions.getModuleLearnData, dispatch),
    getCourseContent: bindActionCreators(noo_actions.getCourseContent, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CourseAutogradePage);
