import cytoscape from 'cytoscape';

const VERSION = CONFIG.Version ? `/${CONFIG.Version}` : "";

export default function () {

    return cytoscape.stylesheet()
        .selector('core')
        .css({'active-bg-size': 0})
        .selector('*')
        .css({
            'overlay-opacity': 0,
            // 'font-family': 'LabGrotesque, HelveticaNeue, sans-serif'
        })
        .selector('#course')
        .css({
            content: 'data(name)',
            'text-valign': 'bottom',
            'text-halign': 'center',
            color: '#fff',
            'background-opacity': 0,
            'background-clip': 'none',
            'background-image': `url("${VERSION}/assets/img/graph_course_bg.svg")`,
            padding: "10px",
            'text-max-width': 230,
            'text-margin-y': 0,
            'text-margin-x': 0,
            'text-wrap': 'wrap',
            'line-height': 22,
            'font-size': 18,
            'font-weight': 700,
            'text-transform': 'uppercase',
            width: 60,
            height: 60,
        })
        .selector('.force_refresh')
        .css({
            // opacity: 0,
        })
        .selector('edge')
        .css({
            'curve-style': 'unbundled-bezier',
            'target-arrow-shape': 'triangle',
            'target-arrow-color': '#fff',
            'line-color': '#fff',
            width: 2,
            'border-width': 12,
        })
        .selector('.module')
        .css({
            content: 'data(name)',
            width: 38,
            height: 52,
            'background-width': '38px',
            'background-height': '52px',
            'line-height': 18,
            'font-size': 16,
            'text-max-width': 180,
            'text-wrap': 'wrap',
            'text-valign': 'center',
            'text-halign': 'left',
            padding: "10px",
            'background-opacity': 0,
            'background-clip': 'none',
            'background-position-y': '10px',
            'background-position-x': '50%',
            color: "#ffffff",
        })
        .selector('.hidden')
        .css({
            display: "none",
        })
        .selector('.module.is_ext_child')
        .css({label: ''})
        .selector('.module:selected')
        .css({
            color: "#47A0F4",
        });

}
