import React                                    from "react";
import {connect}                                from "react-redux";
import {HBox}                                   from "@sirius/ui-lib/src/blocks/Layout";
import {DEPLOY_NAME}                            from "Cheops/constants";
import NotificationsList                        from "Cheops/components/Notifications/NotificationsList";
import {PlatformLandingTitle}                   from "Cheops/components/Landings/PlatformLandingTitle";
import {cluster}                                from "Cheops/../clusters/current";
import LandingsCourseCard                       from "../components/Landings/LandingsCourseCard";
import {Footer}                                 from "../components/Footer";
import LandingsHeader                           from "../components/Landings/LandingsHeader";
import {LandingBlockChains}                     from "Cheops/components/Landings/ChainsCourse";
import {InformationLinkCard}                    from "Cheops/containers/InformationLinkCard/InformationLinkCard";

class PlatformLandingAuthorizedWithMyCoursesPage extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            show_notifications: false,
        };

    }


    setShowNotifications(state) {

        this.setState({show_notifications: state});

    }


    courseCardClick = (course_id) => {

        location.href = `/#/course/${course_id}`;

    };

    continueCourseClick = (event) => {

        location.href = "#";
        event.stopPropagation();

    };

    render() {

        const { soon: fullCourseListSoon} = this.props.full_course_list;

        const pl_fap = `platform_landing_for_authorized_page platform_landing_for_authorized_page-cluster-${cluster.key}`;

        return (
            <div className={pl_fap}>
                <LandingsHeader
                    land_header_with_bg={true}
                    showNotifications={() => this.setShowNotifications(true)}
                />
                {
                    this.state.show_notifications &&
                    <NotificationsList hideNotifications={() => this.setShowNotifications(false)} />
                }
                <div className="platform_landing_for_authorized_page__content">

                    <div className="platform_landing_page__courses">
                        <div
                            className="platform_landing_page__courses_title platform_landing_page__courses_title_my"
                        >
                                <PlatformLandingTitle>
                                    {CONFIG.DeployName === DEPLOY_NAME.TEXTBOOK ? 'Мои предметы' : 'Мои курсы'}
                                </PlatformLandingTitle>
                        </div>
                        <div
                            className="platform_landing_page__courses_list_wrapper platform_landing_page__courses_my_courses_wrapper"
                        >
                            <div
                                className="platform_landing_page__courses_list_content platform_landing_page__courses_list_content_my_courses"
                            >
                                {!!this.props.courses.invites
                                && this.props.courses.invites.map((course) => <LandingsCourseCard key={course.id}
                                                                                                  course={course}
                                                                                                  course_status={LandingsCourseCard.COURSE_STATUS.INVITE}
                                                                                                  is_authorized={true}
                                                                                                  courseCardClick={() => {

                                                                                                      this.courseCardClick(course.id);

                                                                                                  }}
                                                                                                  continueCourseClick={this.continueCourseClick}
                                />)}


                                {!!this.props.courses.my
                                && this.props.courses.my.map((course) => <LandingsCourseCard key={course.id}
                                                                                             course={course}
                                                                                             course_status={LandingsCourseCard.COURSE_STATUS.MY_COURSE}
                                                                                             is_authorized={true}
                                                                                             courseCardClick={() => {

                                                                                                 this.courseCardClick(course.id);

                                                                                             }}
                                                                                             continueCourseClick={this.continueCourseClick}
                                />)}
                            </div>
                        </div>
                    </div>

                    {(this.props.courses.opened
                        && this.props.courses.opened.length > 0)
                    && <div>
                        <div className="platform_landing_page__courses">
                            <div
                                className="platform_landing_page__courses_title platform_landing_page__courses_title_open"
                            >
                                Сейчас
                            </div>
                            <div
                                className="platform_landing_page__courses_content_wrapper platform_landing_page__courses_content_wrapper_open"
                                data-id="open"
                            >
                                <div
                                    className="platform_landing_page__courses_list_wrapper platform_landing_page__courses_available_wrapper"
                                >
                                    <HBox
                                        className="platform_landing_page__courses_list_content platform_landing_page__courses_list_content_available"
                                    >
                                        {this.props.courses.opened.map((course) => <LandingsCourseCard key={course.id}
                                                                                                       course={course}
                                                                                                       course_status={LandingsCourseCard.COURSE_STATUS.AVAILABLE}
                                                                                                       is_authorized={true}
                                                                                                       courseCardClick={() => {

                                                                                                           this.courseCardClick(course.id);

                                                                                                       }}
                                        />)}
                                        <br clear="all"/>
                                    </HBox>
                                </div>
                            </div>
                        </div>

                    </div>}

                    {
                        CONFIG.DeployName === DEPLOY_NAME.CPM || CONFIG.DeployName === DEPLOY_NAME.SIRIUS &&
                        <div className="platform_landing_page__courses">

                            <div className="platform_landing_page__courses_title platform_landing_page__courses_title_open">
                                Обратите внимание
                            </div>
                            <InformationLinkCard variant = "vos"        />
                            <InformationLinkCard variant = "schedule"   />
                            <InformationLinkCard variant = "ai"         />
                        </div>
                    }

                    {
                        (fullCourseListSoon && fullCourseListSoon.length > 0) && cluster.type === "courses"
                        && <div className="platform_landing_page__courses">
                                <LandingBlockChains
                                    soonList={fullCourseListSoon}
                                />
                            </div>
                    }
                </div>
                {
                    cluster.type === "courses" &&
                    <Footer />
                }
            </div>
        );

    }

}

const mapStateToProps = (state) => ({
    full_course_list: state.nooReducer.full_course_list,
    lecturer_list: state.nooReducer.lecturer_list,
    main_content: state.nooReducer.main_content,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PlatformLandingAuthorizedWithMyCoursesPage);
