import React from "react";
import BemClassName from "Cheops/BemClassName";
import "./CourseProgressLine.less";

export default class CourseProgressLine extends React.Component {

    // eslint-disable-next-line react/sort-comp
    LINE_TYPE = {
        NORMAL: 'normal',
        DONE: 'done',
        FAIL: 'fail',
    };

    static defaultProps = {
        wrapperStyle: '',
        pointClick: () => {
        },
        onClick: () => {
        },
        selectedPoint: null,
        checkPoints: [],
        totalValue: 0,
        doneValue: 0,
        failValue: 0,
        type: '',
    };

    returnPercent = (static_value, value) => value / static_value * 100;

    renderCheckpoint(done_pos, fail_pos) {

        return this.props.checkPoints.map((item, key) => {

            let state = this.LINE_TYPE.NORMAL;
            let position = item.value;

            if (this.props.type === 'module') {

                position = this.returnPercent(this.props.totalValue, parseFloat(position));

            }

            let percent_done = this.returnPercent(this.props.totalValue, parseFloat(done_pos));
            let percent_fail = this.returnPercent(this.props.totalValue, parseFloat(fail_pos));

            if (percent_done > 0 && percent_done >= position) {

                state = this.LINE_TYPE.DONE;

            }

            if (percent_fail > 0 && (100 - percent_fail) <= position) {

                state = this.LINE_TYPE.FAIL;

            }

            const className = new BemClassName("course_progress_line__point");
            className.appendStatus(state);
            className.appendStatusIf(key === this.props.selectedPoint, 'selected');

            return (
                <div key={key}
                     style={{left: `${position}%`}}
                     className={className}
                     onClick={() => this.props.pointClick(key)}
                />
            );

        });

    }

    render() {

        return (<div className={`course_progress_line__level ${this.props.wrapperStyle}`} onClick={this.props.onClick}>
            {this.renderCheckpoint(this.props.doneValue, this.props.failValue)}
            <div className='course_progress_line__progress'>
                <div className='course_progress_line__lines'>
                    <div style={{width: `${this.returnPercent(this.props.totalValue, this.props.doneValue)}%`}} />
                    <div style={{width: `${this.returnPercent(this.props.totalValue, this.props.failValue)}%`}} />
                </div>
            </div>
        </div>);

    }

}
