import { LandingsChainsCourseCard }                 from "./LandingsChainsCourseCard";
import type {
    ChainsCourseCardProps
}                                               from "./@types";
import                                          "./index.less";


export {
    LandingsChainsCourseCard
};
export type {
    ChainsCourseCardProps
};
