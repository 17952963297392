import {connect} from "react-redux";
import React from "react";
import Marked from "@sirius/ui-shared/src/components/DisplayEngine/Marked";
import CourseHeader from "Cheops/components/CoursePage/CourseHeader";
import {CourseBackButton} from "Cheops/components/CoursePage/CourseButton";
import CircularProgress from "Smt/Ui";
import {bindActionCreators} from "redux";
import * as noo_actions from "Cheops/actions/noopolis";
import {META_THEME_COLOR_DEFAULT} from "Cheops/constants";
import Helpers from "Cheops/Helpers";


class Page extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            is_loading: true,
        };

        document.title = `${Helpers.getInstanceTitleForCurrentDeploy()} — Открытая онлайн-школа развития таланта`;

    }

    async componentDidMount() {

        await this.updateData(this.props.match.params.news_id);


        let course_contents = this.props.course_contents[this.props.current_course.hash];
        document.querySelector("meta[name=theme-color]").setAttribute("content", course_contents.color);

    }

    async componentWillReceiveProps({match}) {

        if ((this.props.match.params.news_id !== match.params.news_id)) {

            this.updateData(match.params.news_id);

        }

    }

    componentWillUnmount() {

        document.querySelector("meta[name=theme-color]").setAttribute("content", META_THEME_COLOR_DEFAULT);

    }


    async updateData(news_id) {


        this.setState({is_loading: true});

        await this.props.getNewsById(news_id);

        await this.props.getCourseData(this.props.news_data.value.courseId);

        if (!this.props.course_contents[this.props.current_course.hash]) {

            await this.props.getCourseContent(this.props.current_course.hash);

        }

        document.title = `${this.props.news_data.value.headline} — ${Helpers.getInstanceTitleForCurrentDeploy()}`;

        this.setState({is_loading: false});

    }

    render() {

        if (this.state.is_loading) {

            return (<CircularProgress centerOfWindow={true} />);

        }

        let date_str = (new Date(this.props.news_data.value.modificationTM)).toLocaleString("ru", {
            day: "numeric",
            month: "long",
            year: "numeric",
        });

        let course_contents = this.props.course_contents[this.props.current_course.hash];

        return (
            <div className="notification_page">
                <CourseHeader
                    background="white"
                    pinned="pinned"
                    back_path={`#/course/${this.props.news_data.value.courseId}`}
                    course_color={course_contents.color}
                />
                <div className="notification_page__wrapper">
                    <div className="notification_page__title">{this.props.news_data.value.headline}</div>
                    <div className="notification_page__time">{date_str}</div>
                    <div className="notification_page__content">
                        <Marked>{this.props.news_data.value.body}</Marked>
                    </div>
                </div>
                <CourseBackButton
                    courseId={this.props.news_data.value.courseId}
                    icon
                >
                    Вернуться в курс
                </CourseBackButton>
            </div>
        );

    }

}


const mapStateToProps = (state) => {

    return {
        news_data: state.nooReducer.news_data,
        current_course: state.nooReducer.current_course,
        course_contents: state.nooReducer.course_contents,
    };

};

const mapDispatchToProps = (dispatch) => {

    return {
        getNewsById: bindActionCreators(noo_actions.getNewsById, dispatch),
        getCourseData: bindActionCreators(noo_actions.getCourseData, dispatch),
        getCourseContent: bindActionCreators(noo_actions.getCourseContent, dispatch),
    };

};

export default connect(mapStateToProps, mapDispatchToProps)(Page);
