/* eslint-disable */
import AjaxWrapper from "Cheops/AjaxWrapper";

export const getRegions = () => {
    return AjaxWrapper.post('/bgapi/sforms?&fid=199910181396&__act=q&lng=ru',
        {
            "query": "query ($cond: [Condition]!) { meta { getSrcObs(typeName: t_501, first: 100, conditions: $cond, formId: 199910181396, formMapping: \"sforms\") {edges { node { ... on t_501 { id formalname fullname regioncode} } } } } }",
            "variables": {
                "cond": [{"attr": "aolevel", "ct": "EQ", "val": "1"}]
            }
        }
    );
};

export const getSchools = (region_id, search_string) => {
    return AjaxWrapper.post('/bgapi/sforms?&fid=199910162551&__act=q&lng=ru',
        {
            "query": "query ($cond:[Condition]!) { meta { getSrcObs(first:100, typeName:t_994, conditions:$cond ) { edges { node { ... on t_994 { id inn title_rus region { id formalname fullname } address } } } pageInfo { hasNextPage endCursor } } } } ",
            "variables": {
                "cond": [
                    {
                        "attr": "search_string",
                        "ct": "LIKE",
                        "val": search_string
                    },
                    {
                        "attr": "region",
                        "ct": "EQ",
                        "val": region_id
                    }
                ]
            }
        }, {
            dataHandler: data => {
                let nodes = [];

                for (let i in data.data.meta.getSrcObs.edges) {
                    nodes.push(data.data.meta.getSrcObs.edges[i].node);
                }

                return nodes
            }
        }
    );
};
