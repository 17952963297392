import React from "react";
import "./AutoGradeStatus.less";
import AutoGradePopup from "Cheops/components/AutoGrade/AutoGradePopup";
import Helpers from "Cheops/Helpers";

export default class AutoGradeStatus extends React.Component {


    static defaultProps = {
        toNext: null,
        backLink: null,
        onFinish: null,
        status: null,
    };

    constructor(props) {

        super(props);

        this.state = {
            show_finish_popup: false,
        };

    }


    render() {

        let isParentMod = Helpers.isParentMode();

        let approximate = `около ${this.props.status.approximate} мин.`;

        if (this.props.status.approximate < 1) {

            approximate = "менее одной минуты";

        }

        return (<div className="autograde_status">

            {this.state.show_finish_popup
            && <AutoGradePopup
                leftButtonText="Нет"
                rightButtonText="Завершить"
                onLeftClick={() => this.setState({show_finish_popup: false})}
                onRightClick={this.props.onFinish}
            >
                <strong>Завершить тест?</strong>
                <div>Вы не сможете пройти тест ещё раз, если завершите его</div>
            </AutoGradePopup>}

            <div className="autograde_status__center">
                <div>

                    <div className="autograde_status__stats">
                        <div>
                            <div className="autograde_status__stats_titles">
                                <div>Выдано задач</div>
                                <div>решено верно</div>
                            </div>
                            <div className="autograde_status__stats_data">
                                <div>{this.props.status.total}</div>
                                <div>{this.props.status.solved}</div>
                            </div>
                        </div>
                    </div>

                    <div className="autograde_status__bottom_buttons">
                        <div className="autograde_status__remain">
                            {`До конца тестирования ${approximate}`}
                        </div>
                        {!isParentMod
                        && <div className="autograde_status__bottom_links">
                            <div><a href={this.props.backLink}>Продолжить позже</a></div>
                            <div>
                                    <span onClick={() => this.setState({show_finish_popup: true})}>
                                        Завершить досрочно
                                    </span>
                            </div>
                        </div>}
                    </div>

                    <div className="course_autograde_page__bottom_button_wrapper">
                        <button disabled={isParentMod} className="course_autograde_page__bottom_button"
                                onClick={this.props.toNext}
                        >
                            Дальше
                        </button>
                    </div>
                </div>
            </div>
        </div>);

    }

}
