import React from "react";
import "./ModuleTransferProgressCard.less";

export default class ModuleTransferProgressCard extends React.Component {

    static defaultProps = {
        disabled: false,
        transfer_page_link: "/#/",
        transferClick: () => {
        },
    };

    render() {

        return (
            <div className="module_action_card">
                <div className="module_action_card__text">
                    Некоторые задачи вы уже решали в других курсах. Их можно засчитать автоматически!
                </div>
                <div className="module_action_card__cheops" />
                <div className="module_action_card__footer">
                    <button disabled={this.props.disabled}
                            className="module_action_card__button"
                            onClick={() => location.href = this.props.transfer_page_link}
                    >
                        Подробнее
                    </button>
                    <button disabled={this.props.disabled}
                            className="module_action_card__button"
                            onClick={this.props.transferClick}
                    >
                        Засчитать
                    </button>
                </div>
            </div>
        );

    }

}
