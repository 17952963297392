import React from "react";


export default class WidgetsMenuList extends React.Component {

    static defaultProps = {
        current_course_learn: {},
        course_contents: {},
        short_menu: false,
    };

    constructor(props) {

        super(props);

        this.state = {
            menu_expanded: false,
        };

    }

    renderItemMenu = () => {

        const old_link = [{caption: 'Тестирование', link: 'final_test', field: 'finalTest'}, {
            caption: 'О курсе',
            link: 'about',
            field: 'about',
        }, {caption: 'Правила', link: 'rules', field: 'rules'}];

        const array = [];
        const course_contents = this.props.course_contents;
        let widgets = [];

        if (course_contents.widgets && course_contents.widgets.length) {

            widgets = course_contents.widgets;

            old_link.forEach((wgt, index) => {

                if (course_contents[wgt.field] && widgets.findIndex((f_item) => f_item.link === wgt.link) < 0) {

                    widgets.push(old_link[index]);

                }

            });

        } else {

            old_link.forEach((wgt, index) => {

                if (course_contents[wgt.field]) {

                    widgets.push(old_link[index]);

                }

            });

        }

        for (let entry of widgets) {

            array.push({title: entry.caption, link: `/#/course/${this.props.course_id}/${entry.link}`});

        }

        return array;

    };

    render() {

        let {current_course_learn} = this.props;
        let course_contents = this.props.course_contents;

        let main_menu_items = this.renderItemMenu();

        if (!!course_contents.section && (course_contents.section.length > 0)) {

            for (let section of course_contents.section) {

                let link = `/#/course/${this.props.course_id}/section_${section.id}`;

                if (this.props.course_contents.courseContentProgress && this.props.course_contents.courseContentProgress.state === "autograde") {

                    link = null;

                }

                main_menu_items.push({
                    link,
                    title: section.title,
                });

            }

        }

        if (current_course_learn.autograde) {

            if (current_course_learn.autogradeState && current_course_learn.autogradeState !== "canStart") {

                main_menu_items.push({
                    title: 'Проверка знаний',
                    link: `/#/course/${this.props.course_id}/autograde`,
                });

            } else {

                main_menu_items.unshift({
                    title: 'Проверка знаний',
                    className: "primary",
                    link: `/#/course/${this.props.course_id}/autograde`,
                });

            }

        }

        return (
            <div>
                {(main_menu_items.length > 0)
                && <div className="course-sidebar-header__main-menu">
                    {main_menu_items.map((menu_item, i) => {

                        if ((i > 4 && !this.state.menu_expanded)) {

                            return null;

                        }

                        let classes = "course-sidebar-header__main-menu-item";

                        if (menu_item.className) {

                            classes += ` ${menu_item.className}`;

                        }

                        return <a key={i} onClick={(e) => {

                            e.stopPropagation();

                        }}
                                  href={menu_item.link}
                                  className={classes}
                        >
                            {menu_item.title}
                        </a>;

                    })}

                    {main_menu_items.length > 5 && !this.state.menu_expanded && !this.props.short_menu
                    && <div onClick={() => this.setState({menu_expanded: true})}
                            className="course-sidebar-header__expand-menu-button"
                    />}
                </div>}
            </div>
        );

    }

}
