import React from "react";
import {bindActionCreators} from "redux";
import * as noo_actions from "Cheops/actions/noopolis";
import connect from "react-redux/es/connect/connect";
import CourseHeader from "Cheops/components/CoursePage/CourseHeader";
import ResultTable from "Cheops/components/ResultTable/ResultTable";
import CircularProgress from "Smt/Ui";
import NotificationsList from "Cheops/components/Notifications/NotificationsList";
import {META_THEME_COLOR_DEFAULT} from "Cheops/constants";
import Helpers from "Cheops/Helpers";


class RatingTablePage extends React.Component {


    constructor(props) {

        super(props);

        document.title = `Таблица результатов — ${Helpers.getInstanceTitleForCurrentDeploy()}`;

        this.state = {
            is_loading: true,
            show_notifications: false,
        };

    }


    async componentWillMount() {

        await this.props.getModuleProgress(this.props.match.params.course_id, this.props.match.params.module_id, this.props.match.params.table_index);

        await this.props.getCourseLearnData(this.props.match.params.course_id);

        if (!this.props.course_contents[this.props.current_course_learn.hash]) {

            await this.props.getCourseContent(this.props.current_course_learn.hash);

        }

        this.setState({is_loading: false});

        let course_contents = this.props.course_contents[this.props.current_course_learn.hash];
        document.querySelector("meta[name=theme-color]").setAttribute("content", course_contents.color);

    }

    componentWillUnmount() {

        document.querySelector("meta[name=theme-color]").setAttribute("content", META_THEME_COLOR_DEFAULT);

    }


    render() {

        if (this.state.is_loading) {

            return (<CircularProgress centerOfWindow={true} />);

        }

        let course_contents = this.props.course_contents[this.props.current_course_learn.hash];

        return (<>
            <CourseHeader
                back_path={`#/course/${this.props.match.params.course_id}/section_${this.props.match.params.module_id}`}
                show_notifications={true}
                showNotifications={() => this.setState({show_notifications: true})}
                title_align="left"
                title="Таблица результатов"
                course_color={course_contents.color}
            />

            <ResultTable
                mode={ResultTable.MODE.NOO}
                report={this.props.module_progress}
                course_id={this.props.match.params.course_id}
                module_id={this.props.match.params.module_id}
                course_color={course_contents.color}
            />

            {this.state.show_notifications
            && <NotificationsList hideNotifications={() => this.setState({show_notifications: false})} />}

        </>);

    }

}


const mapStateToProps = (state) => ({
    module_progress: state.nooReducer.module_progress,
    current_course_learn: state.nooReducer.current_course_learn,
    course_contents: state.nooReducer.course_contents,
});

const mapDispatchToProps = (dispatch) => ({
    getModuleProgress: bindActionCreators(noo_actions.getModuleProgress, dispatch),
    getCourseLearnData: bindActionCreators(noo_actions.getCourseLearnData, dispatch),
    getCourseContent: bindActionCreators(noo_actions.getCourseContent, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RatingTablePage);
