import "../less/style.less";

import 'url-search-params-polyfill';

import 'raf-polyfill';
import 'whatwg-fetch';
import 'promise-polyfill/src/polyfill';

import React from "react";
import {render} from "react-dom";
import {Provider} from 'react-redux';
import {HashRouter, Route, Switch} from 'react-router-dom';
import {YMInitializer} from 'react-yandex-metrika';
import {NooScreen} from "Cheops/NooScreen";
import {ContestsTaskPage} from "Cheops/containers/ContestsTaskPage/ContestsTaskPage";
import {TeacherRoomPage} from "Cheops/containers/TeacherRoomPage";
import NewsPage from "Cheops/containers/NewsPage";
import Helpers from "Cheops/Helpers";
import PlatformPage from "Cheops/containers/PlatformPage";
import * as auth_actions from "Cheops/actions/auth";
import smoothscroll from 'smoothscroll-polyfill'; // scrollTo polyfill for Edge
import {YANDEX_METRIKA_CHEOPS} from './constants';


import AuthPage from "./containers/AuthPage";
import AuthConfirmationPage from "./containers/AuthConfirmationPage";
import AuthCallbackPage from "./containers/AuthCallbackPage";
import ParentCodePage from "./containers/ParentCodePage";
import QuestionsAnswersPage from "./containers/QuestionsAnswersPage";
import SendQuestionPage from "./containers/SendQuestionPage";
import CertificatesPage from "./containers/CertificatesPage";
import CertificatesFormPage from "./containers/CertificatesFormPage";
import CertificatePage from "./containers/CertificatePage";
import ProgressDemoPage from "./containers/ProgressDemoPage";
import ElementNavItemDemoPage from "./containers/ElementNavItemDemoPage";
import CourseColorDemoPage from "./containers/CourseColorDemoPage";
import FeedbackPage from "./containers/FeedbackPage";
import store from "./Store";
import {metricsLoadWrapper} from "./metrics";
import ErrorBoundary from "Smt/ErrorBoundary";
import {ClassPage} from "Cheops/containers/TeacherRoomPage/ClassPage";
import {TeacherCoursePage} from "Cheops/containers/TeacherRoomPage/CoursePage";
import {TeacherCourseProgressPageV2} from "Cheops/containers/TeacherRoomPage/ProgressPage/v2";
import {InviteToClassPage} from "Cheops/containers/TeacherRoomPage/InviteToClassPage";
import CoursePage from "Cheops/containers/CoursePage";
import ProgressTransferPage from "Cheops/containers/ProgressTransferPage";
import CourseAutogradePage from "Cheops/containers/CourseAutogradePage";
import CourseLandingPage from "Cheops/containers/CourseLandingPage";
import ModulePage from "Cheops/containers/ModulePage";
import SectionPage from "Cheops/containers/SectionPage";
import SectionTextPage from "Cheops/containers/SectionTextPage";
import RatingTablePage from "Cheops/containers/RatingTablePage";
import CourseWidgetContentPage from "Cheops/containers/CourseWidgetContentPage";
import {TeacherMaterialsPage} from "Cheops/containers/TeacherRoomPage/MaterialsPage";


function main () {
    smoothscroll.polyfill();

    window.addEventListener('hashchange', () => {

        if (/#\/course\/\d+\/\d+\/(video|text|task)_\d+/.test(location.hash)) { // TODO make wrapper and cancel this from other event

            return;

        }

        document.body.scrollTop = document.documentElement.scrollTop = 0;

    }, false);

    window.addEventListener('storage', (e) => {

        if (e.key === CONFIG.Auth.localstorage_key) {

            if (e.oldValue !== e.newValue) {

                store.dispatch(auth_actions.setAuthToken(e.newValue));

            }

            setTimeout(Helpers.renderParentModeBar, 1000);

        }

    });

    Helpers.renderParentModeBar();
    Helpers.runCheckVersionInterval();

    render(
        <ErrorBoundary>
            <Provider store={store}>
                <NooScreen>
                    <HashRouter>
                        <Switch>
                            <Route exact path="/" component={PlatformPage} />
                            <Route exact path="/auth" component={AuthPage} />
                            <Route exact path="/auth_parent" component={AuthPage} />
                            <Route exact path="/auth_profile" component={AuthConfirmationPage} />
                            <Route exact path="/auth_confirmation" component={AuthConfirmationPage} />
                            <Route exact path="/auth_callback" component={AuthCallbackPage} />
                            <Route exact path="/parent_code" component={ParentCodePage} />
                            <Route exact path="/qa/:course_id(\d+)_:module_id(\d+)_:qa_ident(\d+)"
                                   component={QuestionsAnswersPage}
                            />
                            <Route exact path="/qa_ask/:course_id(\d+)_:module_id(\d+)_:qa_ident(\d+)"
                                   component={SendQuestionPage}
                            />
                            <Route exact path='/course/:course_id(\d+)' component={CoursePage} />
                            <Route exact path='/course/:course_id(\d+)/chapter/:chapter_num(\d+)' component={CoursePage} />
                            <Route exact path='/course/:course_id(\d+)/progress_transfer' component={ProgressTransferPage} />
                            <Route exact path='/course/:course_id(\d+)/autograde' component={CourseAutogradePage} />
                            <Route exact path='/course/:course_id(\d+)/about' component={CourseLandingPage} />
                            <Route exact path='/course/:course_id(\d+)/:module_id(\d+)' component={ModulePage} />
                            <Route exact path='/course/:course_id(\d+)/:module_id(\d+)/:element_type(.+)_:element_id(\d+)'
                                   component={ModulePage}
                            />
                            <Route exact path='/course/:course_id(\d+)/section_:module_id(\d+)' component={SectionPage} />
                            <Route exact path='/course/:course_id(\d+)/section_:module_id(\d+)/text_:text_index(\d+)'
                                   component={SectionTextPage}
                            />
                            <Route exact path='/course/:course_id(\d+)/section_:module_id(\d+)/results_:table_index(\d+)'
                                   component={RatingTablePage}
                            />
                            <Route exact path='/course/:course_id(\d+)/:page(.*)' component={CourseWidgetContentPage} />
                            <Route exact path="/news/:news_id" component={NewsPage} />
                            <Route exact path="/certificate" component={CertificatesPage} />
                            <Route exact path="/certificate_form" component={CertificatesFormPage} />
                            <Route exact path="/certificate_:hash(.+)" component={CertificatePage} />
                            <Route exact path="/feedback" component={FeedbackPage} />
                            {/* <Route exact path="/ComponentTestPage" component={ComponentTestPage}/> */}
                            <Route exact path="/progress_demo" component={ProgressDemoPage} />
                            <Route exact path="/element_nav_demo" component={ElementNavItemDemoPage} />
                            <Route exact path="/course_color_demo" component={CourseColorDemoPage} />
                            <Route exact path="/contests_page/:event_name" component={ContestsTaskPage} />
                            <Route exact path="/teacher-room" component={TeacherRoomPage} />
                            <Route exact path="/teacher-room/class/:class_id" component={ClassPage} />
                            <Route exact path="/teacher-room/course/:course_id" component={TeacherCoursePage} />
                            <Route exact path="/teacher-room/course/:course_id/module/:module_id/progress" component={TeacherCourseProgressPageV2} />
                            <Route exact path="/teacher-room/course/:course_id/module/:module_id/materials"
                                   component={TeacherMaterialsPage}
                            />
                            <Route exact path="/invite/:hash" component={InviteToClassPage} />

                            <Route render={() => {

                                Helpers.renderErrorPage(404);
                                return '';

                            }}
                            />

                        </Switch>
                    </HashRouter>
                </NooScreen>
            </Provider>
        </ErrorBoundary>,
        document.getElementById("index"),
    );

    if (CONFIG.YandexMetrikaId) {
        render(
            <YMInitializer
                accounts={[CONFIG.YandexMetrikaId]}
                options={YANDEX_METRIKA_CHEOPS.options}
                attrs={{crossOrigin:'anonymous'}}
            />,
            document.getElementById("metrika_cheops"),
        );
    }
}

if (MOCK) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { worker } = require('./mock/browser');
    worker.start().then( main );
} else {
    metricsLoadWrapper( main );
}
