import bnc from "bnc";

const block = new bnc("landings-wrapper");
const pageBlock = new bnc("course_landing_page");
const pagePlatformBlock = new bnc("platform_landing_page");


export {
    block,
    pageBlock,
    pagePlatformBlock,
}
