import {useEffect} from "react";

export const useClass4Element = (element: Element, className: string) => {
    useEffect(
        ()=>{
            const hasClass = element.classList.contains(className);
            if (!hasClass) {
                element.classList.add(className);
                return () => {
                    element.classList.remove(className);
                }
            }
        },
        []
    );
}
