import React, {useEffect, useState, FC}                  from 'react';
import {Link, Redirect, useParams}                       from 'react-router-dom';
import {CircularProgress}                                from '@sirius/ui-lib/src/blocks/CircularProgress';
import {Icon}                                            from '@sirius/ui-lib/src/blocks/Controls';
import {LinkButton, Button}                              from '@sirius/ui-lib/src/blocks/Controls/Button';
import {GrowGap, HBox, VBox, LGap}                       from '@sirius/ui-lib/src/blocks/Layout';
import {windowResize$}                                   from '@sirius/ui-lib/src/subjects/WindowResize';
import {
    deleteClassFromCourse,
    getClassesInCourse,
    getTeacherCourseInfo,
    addClassesToCourse,
    addTeacherModuleAccess,
    cancelTeacherModuleAccess,
    getTeacherModuleAccess,
    getTeacherContestAccess,
    addTeacherContestAccess,
    getTeacherClasses,
    getContestReport
}                                                        from 'Cheops/actions/noopolis-ts';
import {AccessMethodsType, SubjectInfoType}              from 'Cheops/containers/TeacherRoomPage/GrantClassesAccessModal/@types';
import {TextBookHeader}                                  from '../TextBookHeader';
import {coursePageBlock, block}                          from '../blocks';
import {passResponse, isMaxMobileWidth}                  from '../helpers';
import {ClassCard}                                       from '../ClassCard';
import {TeacherModal}                                    from '../TeacherModal';
import {TeacherModuleCard}                               from '../ModuleCard';
import {DropdownSelect, Option}                          from '../Dropdown';
import {InviteToCourseModal}                             from '../InviteToCourseModal';
import {GrantClassesAccessModal}                         from '../GrantClassesAccessModal';
import {TeacherContestCard}                              from '../ContestCard';
import {TeacherCoursePageTOC}                            from './TeacherCoursePageTOC';
import {getContestCardMenuItems, getModuleCardMenuItems} from './CardMenuItems';
import {TeacherCoursePageSection}                        from './PageSection';
import                                                        './style.less';

const BackButton =
    <Link className = {coursePageBlock.el('back-button')}
          to        = {'/teacher-room'}
    >
        <Icon className = {coursePageBlock.el('back-button').mod('icon')}
              icon = {'arrow_back'}
              size = {'xs'}
        />
        Учительская
    </Link>
;

const getClassesIdList = (classes: NooBack.Teacher.Methods.ClassesList = []) => classes.map(({id}) => id);

export const TeacherCoursePage:FC = () => {
    const {course_id}                               = useParams<{course_id?: string}>();
    const [loading, setLoading]                     = useState<boolean>(true);
    const [reload, setReload]                       = useState<boolean>(false);
    const [showBackButton, setShowBackButton]       = useState<boolean>(!isMaxMobileWidth());
    const [deleteModalOpen, setDeleteModalOpen]     = useState<boolean>(false);
    const [classSelected, setClassSelected]         = useState<string | null>(null);
    const [courseInfo, setCourseInfo]               = useState<NooBack.Teacher.Methods.CourseInfo>(null);
    const [toc, setTOC]                             = useState<NooBack.Teacher.Methods.TableOfContents>(null);
    const [classes, setClasses]                     = useState<NooBack.Teacher.Methods.ClassesList>(null);
    const [modules, setModules]                     = useState<NooBack.Teacher.Methods.CourseModulesList>([]);
    const [contests, setContests]                   = useState<Array<NooBack.Teacher.Methods.ContestInfo>>([]);
    const [classToDelete, setClassToDelete]         = useState<NooBack.Teacher.Methods.ClassId>(null);
    const [redirect, setRedirect]                   = useState<string>(null);
    const [modalCourseIsOpen, setModalCourseIsOpen] = useState<boolean>(false);
    const [selectedClasses, setSelectedClasses]     = useState<string[]>([]);
    const [selectedSubject, setSelectedSubject]     = useState<SubjectInfoType>(null);
    const [showAccessModal, setShowAccessModal]     = useState<AccessMethodsType | false>(false);
    const [teacherClasses, setTeacherClasses]       = useState<NooBack.Teacher.Methods.ClassInfo[]>(null);

    const classSelectHandler = ([{id}]: { id: string }[]) => {
        setClassSelected(id);
    }

    const deleteClass = () => {
        deleteClassFromCourse(course_id, classToDelete)
            .then(() => {
                closeConfirmDelete();
                getClassesInCourse(course_id)
                    .then(passResponse)
                    .then((classList) => {
                        setClasses(classList);
                    })
                ;
            })
        ;
    };

    const closeConfirmDelete = () => {
        setDeleteModalOpen(false);
        setClassToDelete(null);
    };

    const openConfirmDelete = (id: NooBack.Teacher.Methods.ClassId) => {
        setDeleteModalOpen(true);
        setClassToDelete(id);
    };

    const resizeHandler = () => {
        if (isMaxMobileWidth()) {
            setShowBackButton(true);
        } else {
            setShowBackButton(false);
        }
    };

    const update = () =>
        getTeacherCourseInfo(course_id, classSelected)
            .then(passResponse)
            .then(
                ({classes, modules, title, id, toc, contests}) => {
                    setTOC(toc);
                    setClasses(classes);
                    setModules(modules);
                    setCourseInfo({title, id});
                    setContests(contests);
                    setReload(false);
                    setLoading(false);
                }
            )
    ;

    const setTeacherClassList = () => {
        getTeacherClasses()
            .then(passResponse)
            .then(setTeacherClasses)
        ;
    };


    useEffect(
        () => {
            resizeHandler();
            setTeacherClassList();
            const widthSub$ = windowResize$.subscribe(resizeHandler);
            return () => {
                widthSub$.unsubscribe();
            }
        },
        []
    );

    useEffect(
        () => {
            if (!courseInfo) {
                setLoading(true);
                update();
            }
        },
        [course_id]
    );

    useEffect(
        () => {
            !!classSelected && update();
        },
        [classSelected]
    );

    useEffect(
        () => {
            reload && update();
        },
        [reload]
    );

    useEffect(
        () => {
            if (classSelected === null && classes && classes.length > 0) {
                const [{id}] = classes;
                setClassSelected(id);
            }
        },
        [classes]
    );

    const modulesDict:Record<string, NooBack.Teacher.Methods.CourseModuleInfo> = modules.reduce(
        (dict, module)=>({...dict, [module.id]:module }),
        {}
    )

    const inviteToCourse = () => {
        addClassesToCourse({ids: selectedClasses}, course_id)
            .then(passResponse)
            .then(() => {
                clearInviteToCourseValues();
                setReload(true);
            })
        ;
    };

    const clearInviteToCourseValues = () => {
        setModalCourseIsOpen(false);
        setSelectedClasses([]);
    };

    const selectClasses = (id: NooBack.Teacher.ClassId) => {
        setSelectedClasses(
            selectedClasses.includes(id)
                ? [...selectedClasses].filter(v => v !== id)
                : [...selectedClasses, id]
        );
    };

    const closeAccessHandler = (needUpdate = false) => {
        setShowAccessModal(false );
        setSelectedSubject(null);
        needUpdate && setReload(true);
    };

    const showModuleAccessHandler = ({id, title}:  Partial<NooBack.Teacher.CourseModuleInfo>) => () => {
        setSelectedSubject({
            id: `${id}`,
            title: `Доступ к уроку «${title}»`
        });
        setShowAccessModal({
            onAddAccess:    addTeacherModuleAccess,
            onCancelAccess: cancelTeacherModuleAccess,
            onGetAccesses:  getTeacherModuleAccess,
        });
    };

    const showContestAccessHandler = ({id, title}:  Partial<NooBack.Teacher.ContestInfo>) => () => {
        setSelectedSubject({
            id,
            title: `Доступ к контрольной «${title}»`
        });
        setShowAccessModal({
            onAddAccess:    addTeacherContestAccess,
            onGetAccesses:  getTeacherContestAccess,
        });
    };

    const getClassesToInvite = () =>
        teacherClasses?.length
            ? teacherClasses.filter(
                ({id}) => !(getClassesIdList(classes ?? []).includes(id))
            )
            : []
    ;

    const downloadContestReport = ({id}: Partial<NooBack.Teacher.ContestInfo>) => {
        getContestReport(course_id, id);
    };

    const isShowAccessModal = showAccessModal && (selectedSubject !== null);
    const menuActions = {setRedirect, showModuleAccessHandler, showContestAccessHandler, downloadContestReport};

    return (
        redirect
        ? <Redirect push to={redirect}/>
        : loading
            ? <CircularProgress centerOfWindow/>
            : <VBox className={coursePageBlock}>
                <TextBookHeader/>
                <VBox className={coursePageBlock.el('content')}>
                    { showBackButton ? BackButton : null }
                    <h1 className={coursePageBlock.el('title')}>
                        {courseInfo.title}
                    </h1>
                    <HBox className={coursePageBlock.el('submenu')}>
                        <LinkButton
                            to        = {`/course/${courseInfo.id}`}
                            className = {coursePageBlock.el('submenu-button')}
                            mode      = {'outlined'}
                            size      = {'l'}
                        >О курсе</LinkButton>
                        <LGap/>
                        <Button className = {block.el('submenu-button')}
                                mode      = {'outlined'}
                                size      = {'l'}
                                icon      = {'notifications_active'}
                                onClick   = {() => setModalCourseIsOpen(true)}
                        >
                            Пригласить на курс
                        </Button>
                        <GrowGap/>
                        {
                            classes?.length > 0 &&
                            <HBox className = {coursePageBlock.el('class-select-container')}>
                                <DropdownSelect
                                    className   = {coursePageBlock.el('modal-dropdown')}
                                    onChange    = {classSelectHandler}
                                    disabled    = {classes.length === 0}
                                    cleanable   = {false}
                                    placeholder = {'Выберите класс'}
                                >
                                    {
                                        classes.map(
                                            ({title, id}, ci) =>
                                                <Option
                                                    key      = {ci}
                                                    value    = {{id,name:title}}
                                                    selected = {classSelected == id}
                                                    label    = {title}
                                                />
                                        )
                                    }
                                </DropdownSelect>
                            </HBox>
                        }
                    </HBox>

                    {
                        toc && toc.length > 0 &&
                        <TeacherCoursePageSection title={'Обучение и результаты'}>
                            <TeacherCoursePageTOC
                                toc         = {toc}
                                modules     = {modulesDict}
                                courseId    = {course_id}
                                menuActions = {menuActions}
                            />
                        </TeacherCoursePageSection>
                    }

                    {
                        (!toc || toc.length === 0) &&
                        modules.length > 0 &&
                        <TeacherCoursePageSection title={'Результаты учеников по модулям'}>
                            <div className={coursePageBlock.el('modules')}>
                            {
                                modules.map((module) =>
                                    <TeacherModuleCard     {...module}
                                                           key              = {module.id}
                                                           courseId         = {course_id}
                                                           popoverMenuItems = {
                                                               getModuleCardMenuItems(
                                                                   {course_id, ...module},
                                                                   {...menuActions}
                                                               )
                                                           }
                                    />
                                )
                            }
                            </div>
                        </TeacherCoursePageSection>
                    }

                    {
                        contests?.length > 0 &&
                        <TeacherCoursePageSection title={'Контрольные работы'}>
                            <div className={coursePageBlock.el('contests')}>
                                {
                                    contests.map((contest) =>
                                        <TeacherContestCard     {...contest}
                                                                key              = {contest.id}
                                                                courseId         = {course_id}
                                                                popoverMenuItems = {
                                                                    getContestCardMenuItems(
                                                                        {...contest},
                                                                        {...menuActions}
                                                                    )
                                                                }
                                        />
                                    )
                                }
                            </div>
                        </TeacherCoursePageSection>
                    }

                    {
                        classes?.length > 0 &&
                        <TeacherCoursePageSection title={'Классы на курсе'}>
                            <div className={coursePageBlock.el('modules')}>
                            {
                                classes.map(
                                    ({title, id}) =>
                                        <ClassCard key={id}
                                                   showMenu={true}
                                                   onDelete={() => openConfirmDelete(id)}
                                                   onEdit={() => setRedirect(`/teacher-room/class/${id}`)}
                                                   {...{title, id}}
                                        />
                                )
                            }
                            </div>
                        </TeacherCoursePageSection>
                    }
                </VBox>
                {
                    deleteModalOpen &&
                    <TeacherModal onClose          = {closeConfirmDelete}
                                  onAction         = {deleteClass}
                                  disabled         = {false}
                                  headerTitle      = {'Удалить класс из курса?'}
                                  actionButtonText = {'Удалить'}
                    />
                }
                {
                    modalCourseIsOpen &&
                    <InviteToCourseModal onClose        = {clearInviteToCourseValues}
                                         onCourseChange = {() => void(0)}
                                         onClassChange  = {selectClasses}
                                         onAction       = {inviteToCourse}
                                         disabled       = {selectedClasses.length === 0}
                                         {...{
                                             courses: [{...courseInfo, id: parseInt(course_id,10), classes}],
                                             classes: getClassesToInvite(),
                                             selectedClasses,
                                             selectedCourse: course_id
                                         }}

                    />
                }
                {   isShowAccessModal &&
                    <GrantClassesAccessModal
                        info             = {selectedSubject}
                        classesList      = {classes}
                        courseId         = {course_id}
                        onClose          = {closeAccessHandler}
                        {...showAccessModal}
                    />
                }

            </VBox>
    )
};
