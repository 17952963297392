import React from "react";
import ProgressCircle from "../ProgressCircle/ProgressCircle";
import "./RequiredModuleItem.less";


export default class RequiredModuleItem extends React.Component {

    static defaultProps = {
        module_obj: null,
        module_progress: null,
        onClick: null,
    };

    render() {

        return (
            <div className="required_modules_list_item" onClick={this.props.onClick}>
                <div className="required_modules_list_item__title">{this.props.module_obj.title}</div>
                <ProgressCircle
                    progress={this.props.module_progress}
                    starred={this.props.module_obj.isAdvanced || false}
                />
            </div>
        );

    }

}
