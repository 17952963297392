import React from "react";
import {CourseContinueButton} from "Cheops/components/CoursePage/CourseButton";
import GraphStyles from "Cheops/components/Graph/GraphStyles";
import Graph from "../Graph/Graph";


export default class CourseGraph extends React.Component {


    render() {

        return (
            <>
                <Graph
                    modules={this.props.course_contents.module}
                    modules_progress={this.props.current_course_learn.modules}
                    dependencies={this.props.course_contents.graph.dependencies}
                    module_positions={this.props.course_contents.graph.layout.layout.module_positions}
                    course_name={this.props.course_contents.name}
                    course_status={this.props.current_course_learn.availability}
                    expandMap={this.props.handleExpandingMap}
                    selected_module_id={this.props.selected_module_id}
                    setSelectedModuleId={this.props.setSelectedModuleId}
                    styles={GraphStyles()}
                />

                {!this.props.selected_module_id
                    && (!this.props.current_course_learn.availability.locked
                                && this.props.current_course_learn.lastVisitedModule >= 0
                                && <CourseContinueButton courseId={this.props.course_id}
                                                         currentCourseLearn={this.props.current_course_learn}
                                    />
                    )
                }
            </>
        );

    }

}
