import hex2rgb from "color-functions/dist/hex2rgb";
import rgb2hsv from "color-functions/dist/rgb2hsv";
import hsv2hex from "color-functions/dist/hsv2hex";

export default class ColorUtils {


    static hex2hsv = (hex) => {

        let rgb = hex2rgb(hex);
        return rgb2hsv(rgb.r, rgb.g, rgb.b);

    };

    static normalizeHSV = (hsv) => {

        if (hsv.h < 0 || !isFinite(hsv.h)) {

            hsv.h = 0;

        }

        if (hsv.h > 359) {

            hsv.h = 359;

        }

        if (hsv.s < 0 || !isFinite(hsv.s)) {

            hsv.s = 0;

        }

        if (hsv.s > 100) {

            hsv.s = 100;

        }


        if (hsv.v < 0 || !isFinite(hsv.v)) {

            hsv.v = 0;

        }

        if (hsv.v > 100) {

            hsv.v = 100;

        }

        return hsv;

    };


    static button = () => {

        return "rgba(255,255,255,.08)";

    };


    static darken = (hex) => {

        if (!hex) {

            return null;

        }

        let rgb = hex2rgb(hex);
        let hsv = rgb2hsv(rgb.r, rgb.g, rgb.b);

        hsv.v -= 15;

        hsv = ColorUtils.normalizeHSV(hsv);

        return `#${hsv2hex(hsv.h, hsv.s, hsv.v)}`;

    };


    static veryDarken = (hex) => {

        if (!hex) {

            return null;

        }

        let rgb = hex2rgb(hex);
        let hsv = rgb2hsv(rgb.r, rgb.g, rgb.b);

        hsv.v -= 30;

        hsv = ColorUtils.normalizeHSV(hsv);

        return `#${hsv2hex(hsv.h, hsv.s, hsv.v)}`;

    };

}
