import React, { FC }                from "react";
import bnc                          from "bnc";
import { HBox, VBox }               from "@sirius/ui-lib/src/blocks/Layout";
import {Chips, ChipsList}           from "@sirius/ui-lib/src/blocks/Controls/Chips";
import {HasStyles, HasSwitchStyle}  from "Cheops/containers/ModulePage__Types";
import                                   "./ModulePage__StylesControl.less";

const block = new bnc("module-page-styles-control");

export const StylesControl:FC<HasStyles & HasSwitchStyle> = ({styles, switchStyle}) =>
    <VBox className={block.el("wrapper")}>
        <HBox className={block.el("label") + "text-s"}>Стиль изложения</HBox>
        <ChipsList
            wrapperClassName = {block.el("chips")}
            onChange         = {switchStyle}
            selected         = {styles.filter(({selected})=>selected).map(({style})=>style)}
        >
            {
                styles.map(
                    ({style, selected}, si)=>
                        <Chips
                            key      = {si}
                            value    = {style}
                            size     = {'s'}
                            selected = {selected}
                        >{style}</Chips>
                )
            }
        </ChipsList>
    </VBox>
;
