import {connect}                  from "react-redux";
import React                      from "react";
import * as noo_actions           from "Cheops/actions/noopolis";
import * as auth_actions          from "Cheops/actions/auth";
import CircularProgress           from "Smt/Ui";
import {bindActionCreators}       from "redux";
import {META_THEME_COLOR_DEFAULT} from "Cheops/constants";
import CourseHeader               from "../components/CoursePage/CourseHeader";
import QuestionsAnswersItem       from "../components/QuestionsAnswers/QuestionsAnswersItem";
import Helpers                    from "Cheops/Helpers";
import {ProfCheopsGreeting}       from "Cheops/components/ProfCheopsGreeting";
import {HBox}                     from "@sirius/ui-lib/src/blocks/Layout";

class QuestionsAnswersPage extends React.Component {

    constructor(props) {

        super(props);

        let student_first_name = auth_actions.getUserInfo('firstName');

        let qa_ident = parseInt(this.props.match.params.qa_ident);
        let course_id = parseInt(this.props.match.params.course_id);
        let module_id = parseInt(this.props.match.params.module_id);

        if (!qa_ident) {

            throw Error("no qa ident");

        }

        if (!course_id) {

            throw Error("no course_id");

        }

        if (!module_id) {

            throw Error("no course_id ");

        }

        // TODO check if course_id and module_id belong to qa_ident

        document.title = `Вопросы-ответы — ${Helpers.getInstanceTitleForCurrentDeploy()}`;

        this.state = {
            qa_ident,
            course_id,
            module_id,
            student_first_name,
            isLoading: true,
            questions: [],
            ask_enabled: true,
            lock_bar_expanded: true,
            lock_bar_text: null,
            show_ask_popup: false,
        };

    }

    async componentWillMount() {

        await this.loadData(this.state.qa_ident);

        let course_contents = this.props.course_contents[this.props.current_course_learn.hash];
        document.querySelector("meta[name=theme-color]").setAttribute("content", course_contents.color);

    }

    componentWillReceiveProps({match}) {

        let qa_ident = parseInt(match.params.qa_ident);
        let course_id = parseInt(match.params.course_id);
        let module_id = parseInt(match.params.module_id);

        if (!qa_ident) {

            throw Error("no qa ident");

        }

        if (!course_id) {

            throw Error("no course_id");

        }

        if (!module_id) {

            throw Error("no course_id ");

        }


        if (this.props.match.params.qa_ident !== match.params.qa_ident) {

            this.setState({
                isLoading: true,
                qa_ident,
                course_id,
                module_id,
            });

            this.loadData(qa_ident);

        }

    }

    componentWillUnmount() {

        document.querySelector("meta[name=theme-color]").setAttribute("content", META_THEME_COLOR_DEFAULT);

    }


    async loadData(qa_ident) {

        let {availability, elements, source} = await noo_actions.getQaQuestions(qa_ident);

        let show_ask_popup = false;

        if (elements.length === 0) {

            show_ask_popup = true;

        }

        let roles = auth_actions.getUserInfo('roles');

        let is_parent = roles && roles.indexOf('nooRO') !== -1;
        let lock_bar_text = null;
        let ask_enabled = !(availability.locked || is_parent);

        if (availability.locked) {

            lock_bar_text = availability.locked.message;

        }

        await this.props.getCourseLearnData(this.state.course_id);

        if (!this.props.course_contents[this.props.current_course_learn.hash]) {

            await this.props.getCourseContent(this.props.current_course_learn.hash);

        }


        if (elements) {

            this.setState({
                isLoading: false,
                questions: elements,
                source,
                lock_bar_text,
                ask_enabled,
                show_ask_popup,
            });

        }

    }

    notFoundClick = () => {

        this.setState({show_ask_popup: true});

    };


    render() {


        if (this.state.isLoading) {

            return (<CircularProgress centerOfWindow={true} />);

        }

        let advice_text = `${this.state.student_first_name ? `${this.state.student_first_name}, х` : "Х"}отите `
            + ` задать вопрос? Сделайте перерыв. Попробуйте разобраться ещё раз. Возможно, всё не так сложно.`;

        if (this.state.questions.length === 0) {

            advice_text = `${this.state.student_first_name ? `${this.state.student_first_name}, п` : "П"}о этой `
                + `теме ещё не задавали вопросов. Возможно, всё не так сложно. Сделайте перерыв. Попробуйте `
                + `разобраться ещё раз.`;

        }

        let back_link = `/#/course/${this.state.course_id}/${this.state.module_id}`;

        if (this.state.source) {

            back_link += `/${this.state.source.type}_${this.state.source.id}`;

        }

        let course_contents = this.props.course_contents[this.props.current_course_learn.hash];

        return (
            <div className="qa_page">
                <CourseHeader
                    back_path={back_link}
                    title="Вопросы-ответы по теме"
                    background="white"
                    title_align="center"
                    expandable={false}
                    course_color={course_contents.color}
                    pinned={true}
                />
                <div className="qa_page__content">
                    {this.state.questions.map((answered_item) => {

                        let student_name = '';

                        if (answered_item.answeredBy) {

                            student_name = `${answered_item.answeredBy.name} ${answered_item.answeredBy.surname}`;

                        }

                        // let who_answered_name = '';

                        // if (answered_item.askedBy ) {
                        // who_answered_name = answered_item.askedBy.name + " " + answered_item.askedBy.surname;
                        // }

                        return <QuestionsAnswersItem
                            key={answered_item.id}
                            question_text={answered_item.question}
                            // who_answered_name={who_answered_name}
                            student_name={student_name}
                            answer_text={answered_item.answer}
                        />;

                    })}

                    {!this.state.show_ask_popup
                    && <div className="qa_page__not_found_wrapper">
                        <a className="qa_page__to_contest_button" href={back_link}> Вернуться</a>
                        <div className="qa_page__not_found_button" onClick={this.notFoundClick.bind(this)}>
                            Не нашёл ответ
                        </div>
                    </div>}

                    {this.state.show_ask_popup
                    && <div className="qa_page__not_found_block">
                        <ProfCheopsGreeting>
                            {advice_text}
                        </ProfCheopsGreeting>
                        <HBox center className="qa_page__wrapper">
                            <a className="qa_page__to_contest_button" href={back_link}>Вернуться</a>
                            {!this.state.ask_enabled
                            && <span className="qa_page__ask_button qa_page__ask_button--disabled">
                                Задать вопрос
                            </span>}
                            {this.state.ask_enabled
                            && <a className="qa_page__ask_button"
                                  href={`/#/qa_ask/${this.state.course_id}_${this.state.module_id}_${this.state.qa_ident}`}
                            >
                                Задать вопрос
                            </a>}
                        </HBox>
                    </div>}
                </div>


                {!!this.state.lock_bar_text
                && !!this.state.lock_bar_text
                && <div className="lock_bar">
                    {this.state.lock_bar_expanded
                        ? <>
                            <div className="lock_bar__prof_advice_text">
                                <div className="lock_bar__prof_img" />
                                <div className="lock_bar__prof_text">
                                    <h3>Проф. Хеопс</h3>
                                    <p>{this.state.lock_bar_text}</p>
                                </div>
                            </div>
                            <div className="lock_bar__close" onClick={() => this.setState({lock_bar_expanded: false})}>
                                <svg width="14" height="14"
                                     viewBox="0 0 14 14"
                                     fill="none"
                                     xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M13.3 0.709971C12.91 0.319971 12.28 0.319971 11.89 0.709971L6.99997 5.58997L2.10997 0.699971C1.71997 0.309971 1.08997 0.309971 0.699971 0.699971C0.309971 1.08997 0.309971 1.71997 0.699971 2.10997L5.58997 6.99997L0.699971 11.89C0.309971 12.28 0.309971 12.91 0.699971 13.3C1.08997 13.69 1.71997 13.69 2.10997 13.3L6.99997 8.40997L11.89 13.3C12.28 13.69 12.91 13.69 13.3 13.3C13.69 12.91 13.69 12.28 13.3 11.89L8.40997 6.99997L13.3 2.10997C13.68 1.72997 13.68 1.08997 13.3 0.709971Z"
                                        fill="#B0B4B9"
                                    />
                                </svg>
                            </div>
                        </>
                        : this.state.lock_bar_text}

                </div>}

            </div>
        );

    }

}


const mapStateToProps = (state) => ({
    current_course_learn: state.nooReducer.current_course_learn,
    course_contents: state.nooReducer.course_contents,
});

const mapDispatchToProps = (dispatch) => ({
    getCourseLearnData: bindActionCreators(noo_actions.getCourseLearnData, dispatch),
    getCourseContent: bindActionCreators(noo_actions.getCourseContent, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(QuestionsAnswersPage);
