import  React, { FC }         from "react";
import {cluster}              from "CheopsRoot/clusters/current";
import {block}                from "./block";
import type {IconSocialProps} from "./@types";
import                             "./index.less";


export const IconSocial: FC<IconSocialProps> = ({ social }) =>

    <a className={
            block +
            block.mod( `media`, social )
        }
        href   = {cluster.social[`${social}_url`]}
        target = {'_blank'}
    />
;




