import React                    from "react";
import {bindActionCreators}     from "redux";
import {connect}                from "react-redux";
import {CircularProgress}       from "@sirius/ui-lib";
import {Screen}                 from "@sirius/ui-lib/src/blocks/Layout";
import {cluster}                from "Cheops/../clusters/current";
import * as noo_actions         from "Cheops/actions/noopolis";
import Helpers                  from "Cheops/Helpers";
import NotificationsList        from "Cheops/components/Notifications/NotificationsList";

import {
    ViberShareButton,
    ViberIcon,
    VKShareButton,
    VKIcon,
    OKShareButton,
    OKIcon,
    TelegramShareButton,
    TelegramIcon,
    EmailShareButton,
    EmailIcon,
} from 'react-share';

import {Footer} from "../components/Footer";
import LandingsHeader from "../components/Landings/LandingsHeader";
import {DEPLOY_NAME} from "Cheops/constants";


class CertificatesPage extends React.Component {

    constructor(props) {

        super(props);


        document.title = `Сертификат — ${Helpers.getInstanceTitleForCurrentDeploy()}`;

        this.state = {
            show_notifications: false,
            is_loading: true,
        };

    }


    componentDidUpdate(prevProps) {

        if (this.props.match.params.hash !== prevProps.match.params.hash) {

            this.loadData();

        }

    }

    componentWillMount() {

        this.loadData();

    }


    async loadData() {

        this.setState({is_loading: true});

        if (!this.props.certificate_contents[this.props.match.params.hash]) {

            await this.props.getCertificate(this.props.match.params.hash);

        }

        let certificate_content = this.props.certificate_contents[this.props.match.params.hash];

        document.title = `${certificate_content.certificateTitle} "${certificate_content.courseTitle}" — ${Helpers.getInstanceTitleForCurrentDeploy()}`;

        this.setState({is_loading: false});

    }

    renderUserPage() {

        let share_url = location.origin + location.hash.substr(1);
        let share_messangers_text = "Мой сертификат на Сириус.Курсах ";

        if (CONFIG.DeployName === DEPLOY_NAME.CPM) {

            share_messangers_text = "Мой сертификат в ЦПМ";

        }

        let certificate_content = this.props.certificate_contents[this.props.match.params.hash];

        return (
            <div className="certificate_page">
                {
                    this.state.show_notifications
                    && <NotificationsList hideNotifications={() => this.setState({show_notifications: false})} />
                }

                <LandingsHeader showNotifications={() => this.setState({show_notifications: true})}
                                back_path="/#/certificate"
                                land_header_with_bg={true}
                />
                <div className="certificate_page__content">

                    <div className="certificate_page__block_wrapper">

                        <div className="certificate_page__title">
                            {`${certificate_content.certificateTitle} `}
                            <a href={`/#/course/${certificate_content.courseId}`}>
                                {certificate_content.courseTitle}
                            </a>
                        </div>
                        <div className="certificate_page__course_date">
                            {Helpers.getStartEndDateStr(certificate_content.courseOpenDate, certificate_content.courseCloseDate)}
                        </div>

                        <div className="certificate_page__social">
                            <VKShareButton url={share_url}>
                                <VKIcon size={32} round={true} />
                            </VKShareButton>
                            <TelegramShareButton url={share_url}>
                                <TelegramIcon size={32} round={true} />
                            </TelegramShareButton>
                            <ViberShareButton url={share_url} title={share_messangers_text}>
                                <ViberIcon size={32} round={true} />
                            </ViberShareButton>
                            <OKShareButton url={share_url}>
                                <OKIcon size={32} round={true} />
                            </OKShareButton>
                            <EmailShareButton url={share_url}>
                                <EmailIcon size={32} round={true} />
                            </EmailShareButton>
                        </div>

                        <div className="certificate_page__links">
                            <span>
                                <a href={`${CONFIG.Api.noopolis.url}/certificate/${this.props.match.params.hash}/load/certificate`}
                                    target="_blank"
                                    download="certificate.pdf"
                                    rel="noreferrer"
                                >
                                    Скачать файл
                                </a>
                                {'  (pdf)'}
                            </span>
                            <span>
                                <a href="#"
                                onClick={(e) => {

                                    e.preventDefault();
                                    Helpers.copyToClipboard(share_url);

                                }}
                                >
                                    Копировать ссылку
                                </a>
                            </span>
                        </div>
                        <img className="certificate_page__img"
                            src={`${CONFIG.Api.noopolis.url}/certificate/${this.props.match.params.hash}/load/preview`}
                        />
                        <div className="certificate_page__link">
                            Постоянная ссылка:
                            <div>
                                <input id="url_input" defaultValue={share_url} />
                            </div>
                        </div>
                        <img className="certificate_page__qr"
                            src={`${CONFIG.Api.noopolis.url}/certificate/${this.props.match.params.hash}/load/qr`}
                        />

                    </div>
                </div>
                <Footer />
            </div>
        );

    }

    renderPublicPage() {

        let share_url = location.origin + location.hash.substr(1);
        let certificate_content = this.props.certificate_contents[this.props.match.params.hash];

        return (
            <div className={`certificate_page certificate_page-cluster-${cluster.key}`}>
                <div className="certificate_page__content certificate_page__content--no_header">
                    <div className="certificate_page__row">
                        <div className="certificate_page__col">
                            <a href="/" className="certificate_page__logo" />
                            <div className="certificate_page__bubble">
                                Документ&nbsp;сформирован<br/>
                                и&nbsp;загружен&nbsp;администрацией<br/>
                                проекта
                            </div>
                            <div className="certificate_page__title2">
                                {`${certificate_content.certificateTitle} "${certificate_content.courseTitle}"`}
                            </div>
                            <div className="certificate_page__course_date">
                                {Helpers.getStartEndDateStr(certificate_content.courseOpenDate, certificate_content.courseCloseDate)}
                            </div>

                            <div className="certificate_page__links">
                                <span>
                                    <a
                                        href={`${CONFIG.Api.noopolis.url}/certificate/${this.props.match.params.hash}/load/certificate`}
                                        target="_blank"
                                        download="certificate.pdf"
                                        rel="noreferrer"
                                    >
                                        Скачать файл
                                    </a>
                                    {" (pdf)"}
                                </span>
                                <span>
                                    <a href="#"
                                       onClick={(e) => {

                                           e.preventDefault();
                                           Helpers.copyToClipboard(share_url);

                                       }}
                                    >
                                        Копировать ссылку
                                    </a>
                                </span>
                            </div>
                        </div>
                        <div className="certificate_page__col">
                            <img className="certificate_page__img2"
                                 src={`${CONFIG.Api.noopolis.url}/certificate/${this.props.match.params.hash}/load/preview`}
                            />
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );

    }

    renderPage = () => {
        if (this.state.is_loading || !this.props.certificate_contents[this.props.match.params.hash]) {
            return <CircularProgress centerOfWindow={true} />;
        }
        if (this.props.certificate_contents[this.props.match.params.hash].accessLevel === 'private') {
            return this.renderUserPage();
        }
        return this.renderPublicPage();
    };

    render() {

        return <Screen>
            {this.renderPage()}
        </Screen>;
    }
}


const mapStateToProps = (state) => ({
    certificates: state.nooReducer.certificates,
    certificate_contents: state.nooReducer.certificate_contents,
});

const mapDispatchToProps = (dispatch) => ({
    getCertificates: bindActionCreators(noo_actions.getCertificates, dispatch),
    getCertificate: bindActionCreators(noo_actions.getCertificate, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CertificatesPage);
