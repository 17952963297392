import React from "react";
import NotificationsListItem from "Cheops/components/Notifications/NotificationsListItem";
import Helpers from "Cheops/Helpers";
import * as auth_actions from "Cheops/actions/auth";
import "./ProfCheopsMessage.less";

export default class ProfCheopsMessage extends React.Component {

    static defaultProps = {
        notifications: [],
        unpinNotification: null,
    };


    constructor(props) {

        super(props);

        this.state = {
            last_pinned_notification: null,
        };

    }

    async UNSAFE_componentWillMount() {

        this.getLastPinnedNotification();

    }


    componentDidUpdate(prevProps) {

        if (prevProps.notifications !== this.props.notifications) {

            this.getLastPinnedNotification();

        }

    }


    getLastPinnedNotification() {

        if (!this.props.notifications || this.props.notifications.length < 1) {

            return;

        }

        let last_pinned_notification = null;


        for (let notification of this.props.notifications) {

            if (notification.isPinned) {

                last_pinned_notification = notification;

                break;

            }

        }

        if (last_pinned_notification !== null
            && last_pinned_notification.status !== NotificationsListItem.STATUS.READ
            && !Helpers.isParentMode()) {

            if (last_pinned_notification.sourceType !== NotificationsListItem.TYPES.NEWS) {

                this.markAsReadNotificationRequest(last_pinned_notification.notificationId);

            }

        }

        this.setState({last_pinned_notification});

    }


    markAsReadNotificationRequest(notification_id) {

        let roles = auth_actions.getUserInfo('roles');

        if (roles.indexOf('nooRO') !== -1) {

            return;

        }

        this.props.markAsReadNotification(notification_id);

    }

    closeCheopsMessage = (notification_id) => {

        if (Helpers.isParentMode()) {

            return;

        }

        this.props.unpinNotification(notification_id);

    };

    openLink() {

        if (this.state.last_pinned_notification.status !== NotificationsListItem.STATUS.READ && !Helpers.isParentMode()) {

            if (this.state.last_pinned_notification.sourceType === NotificationsListItem.TYPES.NEWS) {

                this.markAsReadNotificationRequest(this.state.last_pinned_notification.notificationId);

            }

        }

        if (this.state.last_pinned_notification.sourceType === NotificationsListItem.TYPES.NEWS) {

            location.hash = `#/news/${this.state.last_pinned_notification.link}`;

        }

        if (this.state.last_pinned_notification.sourceType === NotificationsListItem.TYPES.QA) {

            location.hash = `#${this.state.last_pinned_notification.link}`;

        }

        if (this.state.last_pinned_notification.sourceType === NotificationsListItem.TYPES.MODULE) {

            location.hash = `#${this.state.last_pinned_notification.link}`;

        }

        if (this.state.last_pinned_notification.sourceType === NotificationsListItem.TYPES.CERTIFICATE) {

            location.hash = '#/certificate';

        }

        if (this.state.last_pinned_notification.sourceType === NotificationsListItem.TYPES.INVITATION) {

            location.hash = `#/course${this.state.last_pinned_notification.link}`;

        }

    }


    render() {

        let {last_pinned_notification} = this.state;

        if (!last_pinned_notification) {

            return "";

        }

        let show_news_link = false;

        if (this.state.last_pinned_notification !== null && this.state.last_pinned_notification.sourceType) {

            if (this.state.last_pinned_notification.sourceType === NotificationsListItem.TYPES.NEWS
                || last_pinned_notification.sourceType === NotificationsListItem.TYPES.MODULE
                || last_pinned_notification.sourceType === NotificationsListItem.TYPES.QA
                || last_pinned_notification.sourceType === NotificationsListItem.TYPES.CERTIFICATE
                || last_pinned_notification.sourceType === NotificationsListItem.TYPES.INVITATION) {

                show_news_link = true;

            }

        }


        let link_text = 'Перейти';

        if (last_pinned_notification !== null && (last_pinned_notification.sourceType === NotificationsListItem.TYPES.NEWS || last_pinned_notification.sourceType === NotificationsListItem.TYPES.QA)) {

            link_text = "Читать";

        }

        if (last_pinned_notification.sourceType === NotificationsListItem.TYPES.INVITATION) {

            link_text = "Присоединяйтесь!";

        }


        return (
            <div className="prof_cheops_message">
                <div className="prof_cheops_message__block">
                    <div className="prof_cheops_message__title">Проф. Хеопс</div>
                    <div className="prof_cheops_message__text" onClick={() => this.openLink()}>
                        {last_pinned_notification.body}
                        &nbsp;
                        {show_news_link
                        && <span className="prof_cheops_message__link">{link_text}</span>}
                    </div>
                </div>
                <div className="prof_cheops_message__cheops" />
                <div className="prof_cheops_message__close"
                     onClick={() => this.closeCheopsMessage(last_pinned_notification.notificationId)}
                />
            </div>
        );

    }

}
