import React, {FC, useEffect, useState} from 'react';
import {Link, Redirect, useParams}      from 'react-router-dom';
import isEqual                          from 'lodash/isEqual';
import {HBox, VBox}                     from '@sirius/ui-lib/src/blocks/Layout';
import {Table}                          from '@sirius/ui-lib/src/blocks/Layout/UITable';
import {
    Button,
    Icon,
    InputWrapper,
    Textarea,
    TextInput
}                                       from '@sirius/ui-lib/src/blocks/Controls';
import {CircularProgress}               from '@sirius/ui-lib/src/blocks/CircularProgress';
import {defaultUiSchema}                from '@sirius/ui-lib/src/blocks/Layout/UITable/uischema';
import {windowResize$}                  from '@sirius/ui-lib/src/subjects/WindowResize';
import {IconButton}                     from '@sirius/ui-lib/src/blocks/Controls/Button';
import store                            from 'Cheops/Store';
import {setAuthToken}                   from 'Cheops/actions/auth';
import {
    deleteStudentFromClass,
    editClassName,
    editStudentNameInClass,
    getClassInfo,
    getClassInviteLink,
    getClassStudents,
    getCoursesListForClass,
    getTokenToSeeAsStudent,
    addClassGrantToTeachers
}                                       from 'Cheops/actions/noopolis-ts';
import {
    getRowEditMenu,
    rowMapper
}                                       from 'Cheops/containers/TeacherRoomPage/ClassPage/helpers';
import {PermissionsClass}               from 'Cheops/containers/TeacherRoomPage/ClassPage/PermissionsClass'
import {TextBookHeader}                 from 'Cheops/containers/TeacherRoomPage/TextBookHeader';
import {
    getClassModalDisabled,
    passResponse,
    isMaxMobileWidth
}                                       from 'Cheops/containers/TeacherRoomPage/helpers';
import {
    classPageBlock,
    EditNameModalProps,
    ErrorNotificationCardProps,
    ErrorNotificationProps,
    GrantsModalProps,
    HasModalTypes,
    InviteStudentsModalProps,
    StudentRowType,
    SubmenuButtonProps,
}                                       from '../blocks';
import {TeacherModal}                   from '../TeacherModal';
import {EditStudentNameModal}           from '../EditStudentNameModal';
import                                      './style.less';

const tableCols = [
        {name: 'num',  title: '№'},
        {name: 'name', title: 'ФИО или ID'}
    ]
;

const GrantsModal = (props: GrantsModalProps) =>
    <TeacherModal {...props}
                  headerTitle      = {`Дать доступ к «${props.classTitle}»`}
                  actionButtonText = {'Дать доступ'}
    >
        <Textarea onChange    = {props.onChange}
                  size        = {'s'}
                  placeholder = {'Вставьте эл.почты учителей. Каждую на новой строке'}
                  className   = {classPageBlock.el('modal-textarea')}
                  value       = {''}
        />
    </TeacherModal>
;

const DeleteStudentModal = (props: HasModalTypes) =>
    <TeacherModal {...props}
                  headerTitle      = {'Удалить ученика из класса?'}
                  actionButtonText = {'Удалить'}
    />
;

const InviteStudentsModal = ({link, onClose, onAction, disabled, classTitle, onCopy}: InviteStudentsModalProps) =>
    <TeacherModal onClose          = {onClose}
                  onAction         = {onAction}
                  disabled         = {disabled}
                  headerTitle      = {'Пригласить учеников в класс'}
                  actionButtonText = {'Скопировать ссылку'}
    >
        <VBox>
            <p className={classPageBlock.el('modal-text')}>
                {`Скопируйте ссылку для приглашения в «${classTitle}» и разошлите её ученикам.`}
            </p>
            <InputWrapper className = {classPageBlock.el('modal-input')}>
                <TextInput value     = {link}
                           onChange  = {void(0)}
                />
                <IconButton icon    = {'copy'}
                            onClick = {onCopy}
                            size    = {'s'}
                />
            </InputWrapper>
        </VBox>
    </TeacherModal>
;

const EditClassNameModal = ({onClose, onAction, disabled, onChange, initValue}: EditNameModalProps) =>
    <TeacherModal onClose          = {onClose}
                  onAction         = {onAction}
                  disabled         = {disabled}
                  headerTitle      = {'Переименовать класс'}
                  actionButtonText = {'Переименовать'}
    >
        <VBox grow>
            <p className={classPageBlock.el('modal-text')}>
                Название не более 30 символов
            </p>
            <Textarea onChange    = {onChange}
                      size        = {'s'}
                      placeholder = {'Введите новое название класса'}
                      className   = {classPageBlock.el('modal-textarea')}
                      value       = {initValue}
            />
        </VBox>
    </TeacherModal>
;

const ErrorNotificationCard = ({message, onClick, index}: ErrorNotificationCardProps) =>
    <VBox className = {classPageBlock.el('notification') + 'text-s'}
          onClick   = {() => onClick(index)}
    >
        {message}
    </VBox>
;

const ErrorNotification = ({errors, onClick}: ErrorNotificationProps) =>
    <>
        {
            errors.map(
                ({message}, i) =>
                    <ErrorNotificationCard message = {message}
                                           onClick = {onClick}
                                           index   = {i}
                                           key     = {`error-notification-${i}`}
                    />
            )
        }
    </>
;

const SubmenuButton = ({label, ...buttonProps}: SubmenuButtonProps) => (
    <Button className = {classPageBlock.el('submenu-button')}
            mode      = {'outlined'}
            size      = {'l'}
            {...buttonProps}
    >
        {label}
    </Button>
);


export const ClassPage:FC = () => {
    const [students, setStudents]               = useState<NooBack.Teacher.Methods.StudentsList>(null);
    const [classInfo, setClassInfo]             = useState<NooBack.Teacher.Methods.ClassInfo>(null);
    const [classCourses, setClassCourses]       = useState<NooBack.Teacher.Methods.ClassList>(null);
    const [studentToEdit, setStudentToEdit]     = useState<StudentRowType>(null);
    const [inviteLink, setInviteLink]           = useState<NooBack.Teacher.Methods.Link>(null);
    const [showBackButton, setShowBackButton]   = useState<boolean>(!(window.innerWidth > 720));
    const [loading, setLoading]                 = useState<boolean>(true);
    const [loadingRequest, setLoadingRequest]   = useState<boolean>(false);
    const [dataToPatch, setDataToPatch]         = useState<string>(null);
    const [redirect, setRedirect]               = useState<string>(null);
    const [errors, setErrors]                   = useState<NooBack.Teacher.Methods.ErrorObjectsList>([]);

    const [deleteModalOpen, setDeleteModalOpen]           = useState<boolean>(false);
    const [inviteModalOpen, setInviteModalOpen]           = useState<boolean>(false);
    const [editStudentModalOpen, setEditStudentModalOpen] = useState<boolean>(false);
    const [editClassModalOpen, setEditClassModalOpen]     = useState<boolean>(false);
    const [grantsModalOpen, setGrantsModalOpen]           = useState<boolean>(false);

    const {class_id}: {class_id: string} = useParams();

    const resizeHandler = () => {
        if (isMaxMobileWidth()) {
            setShowBackButton(true);
        } else {
            setShowBackButton(false);
        }
    };

    const deleteStudent = () => {
        deleteStudentFromClass(class_id, studentToEdit.id)
            .then(() => {
                clearEditingInfo();
                getClassStudents(class_id)
                    .then(passResponse)
                    .then((students) => {
                        setStudents(students);
                    })
                ;
            })
        ;
    };

    const openConfirmModal = (studentRow: StudentRowType, modal: 'delete' | 'edit') => {
        if (studentRow) {
            setStudentToEdit(studentRow);
            modal === 'delete'
                ? setDeleteModalOpen(true)
                : setEditStudentModalOpen(true)
            ;
        }
    };

    const seeAsStudent = (studentRow: StudentRowType, course: NooBack.Teacher.Methods.CourseInfo) => {
        getTokenToSeeAsStudent(studentRow.id)
            .then(passResponse)
            .then(({token}) => {
                store.dispatch(setAuthToken(token));
                setRedirect(`/course/${course.id}`);
            })
        ;
    };

    const closeModals = () => {
        setDeleteModalOpen(false);
        setEditStudentModalOpen(false);
        setEditClassModalOpen(false);
        setGrantsModalOpen(false);
    };

    const clearEditingInfo = () => {
        closeModals();
        setStudentToEdit(null);
        setDataToPatch(null);
    };

    const copyLink = (closeModal = true) => {
        navigator.clipboard.writeText(inviteLink);
        closeModal && setInviteModalOpen(false);
    };

    const editStudentNameHandler = () => {
        const newName = dataToPatch?.trim().length > 0
            ? dataToPatch
            : null
        ;
        editStudentNameInClass(class_id, studentToEdit.id, {name: newName})
            .then(passResponse)
            .then((studentInfo) => {
                if (studentInfo) {
                    const updatedList = students.map((student) =>
                        student.id === studentToEdit.id
                            ? studentInfo
                            : student
                    );
                    setStudents(updatedList);
                }
            })
            .finally(clearEditingInfo)
        ;
    };

    const editClassNameHandler = () => {
        editClassName(class_id, {title: dataToPatch})
            .then(passResponse)
            .then(({title}) => {
                title && setClassInfo({...classInfo, title});
            })
            .finally(clearEditingInfo)
        ;
    };

    const grantsHandler = () => {
        if (dataToPatch) {
            const usersEmails = dataToPatch.split('\n')
            setLoadingRequest(true);
            setGrantsModalOpen(false);
            addClassGrantToTeachers(class_id, {users: usersEmails})
                .then((res) => {
                    'response' in res
                        ? res.response && !isEqual(res.response, classInfo) && setClassInfo(res.response)
                        : 'errors' in res
                            ? setErrors(res.errors)
                            : null
                })
                .finally(() => {
                    setLoadingRequest(false);
                    clearEditingInfo();
                })
            ;
        }
    };

    const deleteError = (index: number) => {
        const nextErrors = errors.filter((item, i) => i !== index);
        setErrors(nextErrors);
    };

    useEffect(() => {
        if (students === null && class_id) {
            setLoading(true);
            Promise.all([
                getClassStudents(class_id).then(passResponse),
                getClassInfo(class_id).then(passResponse),
                getClassInviteLink(class_id).then(passResponse),
                getCoursesListForClass(class_id).then(passResponse)
            ])
                .then(([students, classInfo, inviteLink, courses]) => {
                    setStudents(students);
                    setClassInfo(classInfo);
                    inviteLink.uri && setInviteLink(inviteLink.uri);
                    setClassCourses(courses);
                    setLoading(false);
                })
            ;
        }
        resizeHandler();
        const widthSub$ = windowResize$.subscribe(resizeHandler);
        return () => {
            widthSub$.unsubscribe();
        }
    }, []);

    const openGrantAccessModal  = () => setGrantsModalOpen(true);
    const openEditClassModal    = () => setEditClassModalOpen(true);
    const openInviteModal       = () => setInviteModalOpen(true);

    const isEditStudentDisabled = isEqual(studentToEdit?.name, dataToPatch);
    const isEditClassDisabled   = getClassModalDisabled(dataToPatch) || isEqual(classInfo?.title, dataToPatch);
    const isGrantsAccessDisabled = !classInfo?.owner ;

    return (
        redirect
        ? <Redirect push to={redirect}/>
        : loading
            ? <CircularProgress centerOfWindow />
            : <div className={classPageBlock}>
                <TextBookHeader/>
                <div className={classPageBlock.el('content')}>
                    { showBackButton &&
                        <Link className = {classPageBlock.el('back-button')}
                              to        = {'/teacher-room'}
                        >
                            <Icon className = {classPageBlock.el('back-button').mod('icon')}
                                  icon = {'arrow_back'}
                                  size = {'xs'}
                            />
                            Учительская
                        </Link>
                    }
                    <h1 className={classPageBlock.el('title')}>
                        {classInfo?.title}
                    </h1>
                    <HBox className={classPageBlock.el('submenu')}>
                        <SubmenuButton label    = {'Добавить учеников'}
                                       icon     = {'person_add_alt'}
                                       onAction = {openInviteModal}
                        />
                        <SubmenuButton label    = {'Переименовать класс'}
                                       icon     = {'edit'}
                                       onAction = {openEditClassModal}
                        />
                        <SubmenuButton label    = {'Дать доступ'}
                                       icon     = {'security'}
                                       onAction = {openGrantAccessModal}
                                       disabled = {isGrantsAccessDisabled}
                        />
                    </HBox>
                    <Table cols           = {tableCols}
                           rows           = {students.map(rowMapper)}
                           checkedRows    = {[]}
                           uischema       = {defaultUiSchema}
                           onRowSelect    = {void(0)}
                           RowEditElement = {getRowEditMenu({onActionModal: openConfirmModal, courses: classCourses, onActionAsStudent: seeAsStudent})}
                           className      = {classPageBlock.el('table')}
                    />
                    { classInfo?.permissions?.length > 0 &&
                        <PermissionsClass grantAccessAction = {openGrantAccessModal}
                                          block             = {classPageBlock}
                                          {...classInfo}
                        />
                    }
                </div>
                {
                    deleteModalOpen &&
                    <DeleteStudentModal onAction = {deleteStudent}
                                        onClose  = {clearEditingInfo}
                                        disabled = {false}
                    />
                }
                {
                    inviteModalOpen &&
                    <InviteStudentsModal link       = {inviteLink}
                                         onAction   = {copyLink}
                                         onClose    = {() => setInviteModalOpen(false)}
                                         disabled   = {false}
                                         classTitle = {classInfo.title}
                                         onCopy     = {() => copyLink(false)}
                    />
                }
                {
                    editStudentModalOpen &&
                    <EditStudentNameModal onClose   = {clearEditingInfo}
                                          onAction  = {editStudentNameHandler}
                                          disabled  = {isEditStudentDisabled}
                                          onChange  = {setDataToPatch}
                                          id        = {studentToEdit.id}
                                          initValue = {studentToEdit.name}
                    />
                }
                {
                    editClassModalOpen &&
                    <EditClassNameModal onClose   = {clearEditingInfo}
                                        onAction  = {editClassNameHandler}
                                        disabled  = {isEditClassDisabled}
                                        onChange  = {setDataToPatch}
                                        initValue = {classInfo.title}
                    />
                }
                {
                    grantsModalOpen &&
                        <GrantsModal classTitle = {classInfo.title}
                                     onClose    = {clearEditingInfo}
                                     onChange   = {setDataToPatch}
                                     onAction   = {grantsHandler}
                                     disabled   = {!dataToPatch}
                        />
                }
                {
                    errors.length > 0 &&
                    <ErrorNotification errors  = {errors}
                                       onClick = {deleteError}
                    />
                }
                {
                    loadingRequest && <CircularProgress centerOfWindow={true}/>
                }
            </div>
    );
};
