import React from "react";
import {bindActionCreators} from "redux";
import * as noo_actions from "Cheops/actions/noopolis";
import {connect} from "react-redux";
import NotificationsIcon from "./NotificationsIcon";
import NotificationsListItem from "./NotificationsListItem";

class NotificationsList extends React.Component {

    static defaultProps = {
        hideNotifications: () => {
        },
    };


    constructor(props) {

        super(props);

        let {unpinned_notifications, pinned_notifications} = this.divideNotifications(this.props.notifications);

        this.props.setHasNewNotifications(false);

        this.state = {
            unpinned_notifications,
            pinned_notifications,
            list_padding_top: 0,
        };

    }

    componentDidMount() {

        let list_padding_top = this.getContentHeight();
        this.setState({list_padding_top}, () => {

            let wrapper = document.querySelector('.notifications_list__list');
            wrapper.scrollTo(0, wrapper.scrollHeight);

        });

    }

    componentWillReceiveProps({notifications}) {

        let {unpinned_notifications, pinned_notifications} = this.divideNotifications(notifications);

        this.setState({
            unpinned_notifications,
            pinned_notifications,
        });

    }

    componentDidUpdate(prevProps, {pinned_notifications}) {

        if (pinned_notifications.length !== this.state.pinned_notifications.length) {

            let list_padding_top = this.getContentHeight();
            this.setState({list_padding_top});

        }

    }


    divideNotifications(notifications) {

        let unpinned_notifications = [];
        let pinned_notifications = [];

        for (let i in notifications) {

            if (notifications[i].isPinned) {

                pinned_notifications.push(notifications[i]);
                continue;

            }

            unpinned_notifications.push(notifications[i]);

        }

        return {
            unpinned_notifications,
            pinned_notifications,
        };

    }

    getContentHeight() {

        let pinned_list_el = document.querySelector('.notifications_list__pinned_list');
        let header_el = document.querySelector('.notifications_list__header');

        return pinned_list_el.offsetHeight + header_el.offsetHeight;

    }

    render() {

        return (
            <div className="notifications_list">
                <div className="notifications_list__header">
                    <div className="notifications_list__back_button" onClick={this.props.hideNotifications} />
                    <div className="notifications_list__title">Уведомления</div>
                    <div className="notifications_list__header_widgets">
                        <NotificationsIcon notifications_icon_background="transparent" do_not_auto_update={true}
                                           show_last_pinned={false}
                        />
                    </div>
                </div>
                <div className="notifications_list__content">
                    <div className="notifications_list__pinned_list">
                        {this.state.pinned_notifications.map((notification, i) => {

                            return <NotificationsListItem
                                key={i}
                                notification_obj={notification}
                                unpinNotificationRequest={this.props.unpinNotification}
                                markAsReadNotification={this.props.markAsReadNotification}
                            />;

                        })}
                    </div>
                    <div className="notifications_list__list"
                         style={{height: `calc(100% - ${this.state.list_padding_top}px`}}
                    >
                        {this.state.unpinned_notifications.map((notification, i) => {

                            return <NotificationsListItem
                                key={i}
                                notification_obj={notification}
                                unpinNotificationRequest={this.props.unpinNotification}
                                markAsReadNotification={this.props.markAsReadNotification}
                            />;

                        })}
                    </div>
                </div>
            </div>
        );

    }

}


const mapStateToProps = (state) => ({
    notifications: state.nooReducer.notifications,
});

const mapDispatchToProps = (dispatch) => ({
    setHasNewNotifications: (new_state) => dispatch(noo_actions.setHasNewNotifications(new_state)),
    markAsReadNotification: bindActionCreators(noo_actions.markAsReadNotification, dispatch),
    unpinNotification: bindActionCreators(noo_actions.unpinNotification, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsList);
