import React                      from 'react';
import { Link }                   from 'react-router-dom';
import {BoxAlignment, HBox}       from '@sirius/ui-lib/src/blocks/Layout/index';
import { courseBlock }            from '../blocks';
import                                 './style.less';

const ClassLabel = ({title}: NooBack.Teacher.Methods.ClassInfo) =>
    <HBox className = {courseBlock.el('class-label')}
          align     = {BoxAlignment.center}
    >
        {title}
    </HBox>
;

export const CourseCard = ({id, title, classes}: NooBack.Teacher.Methods.CourseListItem) =>
    <Link className = {courseBlock}
          to        = {`/teacher-room/course/${id}`}
    >
        <p className = {courseBlock.el('title')}>
            {title}
        </p>
        <HBox  className = {courseBlock.el('class-label-wrapper')}>
            {
                classes?.length > 0 &&
                classes.map(
                    ({title, id}) => <ClassLabel key = {id} {...{title, id}}/>
                )
            }
        </HBox>
    </Link>
;
