import React                                from 'react';
import {LGap, MGap, VBox}                   from '@sirius/ui-lib/src/blocks/Layout';
import {CheckboxControl}                    from '@sirius/ui-lib/src/blocks/Controls';
import {TeacherModal}                       from 'Cheops/containers/TeacherRoomPage/TeacherModal';
import {DropdownSelect, Option}             from './Dropdown';
import {block, InviteToCourseModalProps}    from './blocks';

export const InviteToCourseModal = (
    {
        courses,
        classes,
        onClose,
        onAction,
        onCourseChange,
        onClassChange,
        selectedCourse,
        selectedClasses,
        disabled
    }: InviteToCourseModalProps) => {
        const courseHasSelected =
            courses.length === 1 &&
            `${courses[0].id}` === `${selectedCourse}`
        ;

        return <TeacherModal onClose={onClose}
                             onAction={onAction}
                             disabled={disabled}
                             headerTitle={'Пригласить на курс'}
                             actionButtonText={'Пригласить'}
        >
            <VBox>
                {
                    courseHasSelected
                        ? <p className={block.el('modal-text') + 'text-s'}>
                            Выбран курс
                          </p>
                        : <p className={block.el('modal-text') + 'text-s'}>
                            Выберите курс и классы для приглашения на учёбу
                          </p>
                }
                <DropdownSelect inline={true}
                                className={block.el('modal-dropdown')}
                                disableFilter={true}
                                onChange={onCourseChange}
                                inlineWidth={328}
                                disabled={courseHasSelected}
                                readOnly={courseHasSelected}
                >
                    {
                        courses.length > 0 &&
                        courses.map(
                            ({title, id}) =>
                                <Option value={{value: id}}
                                        label={title}
                                        selected={`${selectedCourse}` === `${id}`}
                                        key={id}
                                />
                        )
                    }
                </DropdownSelect>
                <p className={block.el('modal-text') + 'text-s'}>
                    Выберите классы, которые хотите пригласить
                </p>
                <VBox>
                    <MGap/>
                    {
                        classes.length > 0
                            ? classes.map(({title, id}) =>
                                <React.Fragment key={id}>
                                    <CheckboxControl key={id}
                                                     checked={selectedClasses.includes(id)}
                                                     onChange={() => onClassChange(id)}
                                    >
                                        {title}
                                    </CheckboxControl>
                                    <LGap/>
                                </React.Fragment>
                            )
                            : <p className={block.el('modal-text') + 'text-s'}>
                                У вас нет доступных для приглашения классов
                            </p>
                    }
                    <MGap/>
                </VBox>
            </VBox>
        </TeacherModal>
    }
;
