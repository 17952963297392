import React from "react";
import Task from "Cheops/components/ModulePassing/Task";
import {connect} from "react-redux";
import ElementInfoTogglers from "Cheops/components/ModulePassing/ElementInfoTogglers";
import SolutionToShowPopup from "Cheops/components/ModulePassing/SolutionToShowPopup";
import {bindActionCreators} from "redux";
import * as noo_actions from "Cheops/actions/noopolis";
import {AnalogicTaskSolution} from "Cheops/components/ModulePassing/AnalogicTaskSolution/AnalogicTaskSolution";

class ModuleTaskContent extends React.Component {

    static defaultProps = {
        element_progress: {},
    };

    constructor(props) {

        super(props);

        this.state = {
            show_solution_to_show: false,
            hintsExpanded: false,
            newHintsAllowed: false,
            taskHints: [],
            showAnalogicTask: false
        };

    }

    UNSAFE_componentWillMount() {

        this.getTaskHints();

    }

    getTaskHints = async () => {

        let current_element_learn;

        if (this.props.current_element_learn[this.props.module_id]) {

            if (this.props.current_element_learn[this.props.module_id][this.props.element_progress.type + this.props.element_progress.id]) {

                current_element_learn = this.props.current_element_learn[this.props.module_id][this.props.element_progress.type + this.props.element_progress.id];

            }

        }

        let newHintsAllowed = false;

        if (current_element_learn && current_element_learn.taskInfo && current_element_learn.taskInfo.hasHints) {

            newHintsAllowed = true;

        }

        let taskHints = [];

        if (current_element_learn && current_element_learn.taskInfo && current_element_learn.taskInfo.hintsUsed) {

            await this.props.getTaskHints(this.props.course_id, this.props.module_id, this.props.element_progress.id);

            if (this.props.taskHints.length) {

                taskHints = this.props.taskHints;

            }

        }

        this.setState({
            newHintsAllowed,
            taskHints,
        });

    };

    showHint = async () => {

        let next_hint_number = 1;

        if (this.state.taskHints.length) {

            next_hint_number = this.state.taskHints.length + 1;

        }

        await noo_actions.getTaskHint(this.props.course_id, this.props.module_id, this.props.element_progress.id, next_hint_number);
        await this.props.getElementLearnData(this.props.course_id, this.props.module_id, this.props.element_progress.id, this.props.element_progress.type);
        await this.getTaskHints();

        this.setState({hintsExpanded: true});

    };

    render() {

        let hintsNumber = 0;

        if (this.state.taskHints.length) {

            hintsNumber = this.state.taskHints.length;

        }

        return (
            <div className="module_passing_content" data-el_num={this.props.selected_element_num}>

                {this.state.show_solution_to_show
                && <SolutionToShowPopup
                    renderInDescription={!!this.props.element_content?.layout?.inline}
                    elementProgress={this.props.element_progress}
                    elementContent={this.props.element_content}
                    courseId={this.props.course_id}
                    moduleId={this.props.module_id}
                    onClose={() => this.setState({show_solution_to_show: false})}
                />}

                <div className="module_passing_content__wrapper">
                    <div className="module_passing_content_inner">
                        <Task
                            selected_element_num={this.props.selected_element_num}
                            element_progress={this.props.element_progress}
                            element_content={this.props.element_content}
                            goToNextElement={() => this.props.goToNextElement(this.props.selected_element_num)}
                            updateStoreDataFromDiff={this.props.updateStoreDataFromDiff}
                            module_id={this.props.module_id}
                            course_id={this.props.course_id}
                            module_availability={this.props.module_availability}
                            module_content={this.props.module_content}
                            task_number={this.props.task_number}
                            module_element_learn={this.props.module_element_learn}
                            hints={this.state.taskHints}
                            hintsExpanded={this.state.hintsExpanded}
                            showHint={this.showHint}
                            newHintsAllowed={this.state.newHintsAllowed}
                            reduceHints={() => this.setState({hintsExpanded: false})}
                        />
                    </div>
                    {(!this.state.hintsExpanded && !this.state.showAnalogicTask)
                    && <div className="text-center">
                        <ElementInfoTogglers
                            module_id={this.props.module_id}
                            course_id={this.props.course_id}
                            element_content={this.props.element_content}
                            element_type='task'
                            element_progress={this.props.element_progress}
                            hintsNumber={hintsNumber}
                            showHintPopup={() => this.setState({hintsExpanded: true})}
                            showSolutionToShow={() => this.setState({show_solution_to_show: true})}
                            showAnalogicTask={() => this.setState({showAnalogicTask: true})}
                        />
                    </div>}

                    {
                        this.state.showAnalogicTask
                        && <AnalogicTaskSolution courseId  = {this.props.course_id}
                                                 moduleId  = {this.props.module_id}
                                                 elementId = {this.props.element_progress.id}
                                                 onClose   = {() => this.setState({showAnalogicTask: false})}
                        />
                    }
                </div>
            </div>
        );

    }

}


const mapStateToProps = (state) => {

    return {
        current_element_learn: state.nooReducer.current_element_learn,
        taskHints: state.nooReducer.taskHints,
    };

};

const mapDispatchToProps = (dispatch) => {

    return {
        getTaskHints: bindActionCreators(noo_actions.getTaskHints, dispatch),
        getElementLearnData: bindActionCreators(noo_actions.getElementLearnData, dispatch),
    };

};

export default connect(mapStateToProps, mapDispatchToProps)(ModuleTaskContent);
