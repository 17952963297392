import React, {FC}                from "react";
import {LandingsChainsCourseCard} from "./Card";
import {block}                    from "./block";
import type {
    ChainsCourseProps
}                                 from "./@types";
import                                 "./index.less";

export const ChainsCourse: FC<ChainsCourseProps> = (props) => {
     const {
        soonList
     } = props;

    return  (
                <div className={block}>
                    {
                        soonList?.map(
                            (soon) =>
                                <LandingsChainsCourseCard
                                    key={soon.id}
                                    soon={soon}
                                />
                         )
                    }
                </div>
    )
}



