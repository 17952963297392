import React, { FC } from 'react';
import bnc           from 'bnc';
import {noop}        from '@sirius/ui-lib/src/utils/noop';
import                    './style.less';

type Props = {
    checkPoints:    NooBack.Content.Checkpoint[];
    type:           NooBack.Content.ProgressItem['type'];
    pointClick?:    (point: number) => void;
    onClick?:       () => void;
    selectedPoint?: number;
    totalValue:     number;
    doneValue:      number;
    failValue:      number;
    className?:     string;
};

const block = new bnc('course-progress-line');

const returnPercent = (staticValue: number, value: number) => value / staticValue * 100;

export const CourseProgressLine:FC<Props> = (
    {
        checkPoints,
        type,
        pointClick = noop,
        selectedPoint,
        totalValue,
        doneValue,
        failValue,
        onClick = noop,
        className = ''
    }) => {

    const renderCheckpoint = (done: number, fail: number) =>
        checkPoints.map((checkpoint, idx) => {
            const position = type === 'module'
                ? returnPercent(totalValue, checkpoint.value)
                : checkpoint.value
            ;
            const donePercent = returnPercent(totalValue, done);
            const failPercent = returnPercent(totalValue, fail);
            const isDone      = donePercent > 0 && donePercent >= position;
            const isFailed    = failPercent > 0 && (100 - failPercent) <= position;

            return (
                <div key       = {idx}
                     style     = {{left: `${position}%`}}
                     className = {
                            block.el('point') +
                            block.el('point').mod('type',
                                isDone
                                    ? 'done'
                                    : isFailed
                                        ? 'failed'
                                        : 'normal'
                            ) +
                            block.bod('selected', idx === selectedPoint)
                     }
                     onClick   = {() => pointClick(idx)}
                />
            );
        })
    ;

    return(
        <div className = {`${block.el('level')} ${className}`}
             onClick   = {onClick}
        >
            { renderCheckpoint(doneValue, failValue) }
            <div className={block.el('progress')}>
                <div className = {
                        block.el('part') +
                        block.el('part').mod('done')
                     }
                     style     = {{width: `${returnPercent(totalValue, doneValue)}%`}}
                />
                <div className = {
                        block.el('part') +
                        block.el('part').mod('fail')
                     }
                     style     = {{width: `${returnPercent(totalValue, failValue)}%`}}
                />
            </div>
        </div>
    );
};
