import React, { useEffect, useState }        from 'react';
import { Redirect }                          from 'react-router-dom';
import { Button, Textarea }                  from '@sirius/ui-lib/src/blocks/Controls';
import { CircularProgress }                  from '@sirius/ui-lib/src/blocks/CircularProgress';
import { HBox, LGap, MGap, VBox }            from '@sirius/ui-lib/src/blocks/Layout';
import {
    addClassesToCourse,
    createTeacherClass,
    getTeacherClasses,
    getTeacherCourses
}                                            from "Cheops/actions/noopolis-ts";
import { TeacherModal }                      from "Cheops/containers/TeacherRoomPage/TeacherModal";
import {getClassModalDisabled, passResponse} from "Cheops/containers/TeacherRoomPage/helpers";
import {
    block,
    classBlock,
    courseBlock,
    CreateClassModalProps
}                                            from "./blocks";
import { InviteToCourseModal }               from "./InviteToCourseModal";
import { ClassCard }                         from "./ClassCard";
import { CourseCard }                        from "./CourseCard";
import { TextBookHeader }                    from "./TextBookHeader";
import                                            "./style.less";


const CreateClassModal = ({onClose, onChange, onAction, disabled}: CreateClassModalProps) =>
    <TeacherModal onClose          = {onClose}
                  onAction         = {onAction}
                  disabled         = {disabled}
                  headerTitle      = {'Создать класс'}
                  actionButtonText = {'Создать'}
    >
        <VBox grow>
            <p className={block.el('modal-text')}>
                Название не более 30 символов
            </p>
            <Textarea onChange    = {onChange}
                      size        = {'s'}
                      placeholder = {'Введите название класса'}
                      className   = {block.el('modal-textarea') + block.el('modal-text')}
            />
        </VBox>
    </TeacherModal>
;

export const TeacherRoomPage = () => {

    const [courses, setCourses]                     = useState<NooBack.Teacher.Methods.CoursesList>(null);
    const [classes, setClasses]                     = useState<NooBack.Teacher.Methods.ClassesList>(null);
    const [loading, setLoading]                     = useState<boolean>(true);
    const [createClassV, setCreateClassV]           = useState<string>('');
    const [modalClassIsOpen, setModalClassIsOpen]   = useState<boolean>(false);
    const [modalCourseIsOpen, setModalCourseIsOpen] = useState<boolean>(false);
    const [selectedCourse, setSelectedCourse]       = useState<string>(null);
    const [selectedClasses, setSelectedClasses]     = useState<string[]>([]);
    const [redirect, setRedirect]                   = useState<string>(null);

    const createClass = () => {
        if (createClassV.trim().length > 0) {
            createTeacherClass(createClassV)
                .then(passResponse)
                .then((classInfo) => {
                    if (classInfo) {
                        setRedirect( `/teacher-room/class/${classInfo.id}`);
                    }
                })
            ;
        }
    };

    const inviteToCourse = () => {
        addClassesToCourse({ids: selectedClasses}, selectedCourse)
            .then(passResponse)
            .then(() => {
                clearInviteToCourseValues();
                getTeacherCourses()
                    .then(({ response: courses }) => courses && setCourses(courses))
                ;
            })
        ;
    };

    const clearInviteToCourseValues = () => {
        setModalCourseIsOpen(false);
        setSelectedCourse(null);
        setSelectedClasses([]);
    };

    const clearCreateClassValues = () => {
        setModalClassIsOpen(false);
        setCreateClassV('');
    };

    const selectClasses = (id: NooBack.Teacher.ClassId) => {
        setSelectedClasses(
            selectedClasses.includes(id)
                ? [...selectedClasses].filter(v => v !== id)
                : [...selectedClasses, id]
        );
    };

    useEffect(() => {
        if (courses === null) {
            setLoading(true);
            Promise.all([
                getTeacherCourses().then(passResponse),
                getTeacherClasses().then(passResponse)
            ])
                .then(([courses, classes]) => {
                    setCourses(courses);
                    setClasses(classes);
                    setLoading(false);
                })
            ;
        }

    }, []);


    return (
        redirect
        ? <Redirect push to={redirect}/>
        : !loading
            ? <div className = {block}>
                <TextBookHeader/>
                <VBox className = {block.el('content')}>

                    <section className = {block.el('section')}>
                        <h2 className = {block.el('title')}>
                            Мои классы
                        </h2>
                        <HBox className = {block.el('submenu')}>
                            <Button className = {block.el('submenu-button')}
                                    mode      = {'outlined'}
                                    size      = {'l'}
                                    icon      = {'group_add'}
                                    onClick   = {() => setModalClassIsOpen(true)}
                            >
                                Добавить класс
                            </Button>
                        </HBox>
                        <LGap/>
                        <MGap/>
                        <div className = {classBlock.el('wrapper')}>
                            {
                                classes?.length > 0 &&
                                classes.map(
                                    ({title, id}) =>
                                        <ClassCard key = {id}
                                                   {...{title, id}}
                                        />
                                )
                            }
                        </div>
                    </section>

                    <section className = {block.el('section')}>
                        <h2 className = {block.el('title')}>
                            Мои курсы
                        </h2>
                        <HBox className = {block.el('submenu')}>
                            <Button className = {block.el('submenu-button')}
                                    mode      = {'outlined'}
                                    size      = {'l'}
                                    icon      = {'notifications_active'}
                                    onClick   = {() => setModalCourseIsOpen(true)}
                            >
                                Пригласить на курс
                            </Button>
                        </HBox>
                        <LGap/>
                        <MGap/>
                        <div className = {courseBlock.el('wrapper')}>
                            {
                                courses?.length > 0 &&
                                courses.map(
                                    ({id, title, classes}) =>
                                        <CourseCard title   = {title}
                                                    id      = {id}
                                                    classes = {classes}
                                                    key     = {id}
                                        />
                                )
                            }
                        </div>
                    </section>

                    {
                        modalClassIsOpen &&
                        <CreateClassModal disabled = {getClassModalDisabled(createClassV)}
                                          onClose  = {clearCreateClassValues}
                                          onAction = {createClass}
                                          onChange = {setCreateClassV}
                        />
                    }

                    {
                        modalCourseIsOpen &&
                        <InviteToCourseModal onClose        = {clearInviteToCourseValues}
                                             onCourseChange = {(v) => setSelectedCourse(v[0]?.value || null)}
                                             onClassChange  = {selectClasses}
                                             onAction       = {inviteToCourse}
                                             disabled       =  {!(selectedClasses.length > 0 && selectedCourse)}
                                             {...{
                                                 courses,
                                                 classes,
                                                 selectedClasses,
                                                 selectedCourse
                                             }}

                        />
                    }
                </VBox>
            </div>
            : <CircularProgress centerOfWindow/>
    );
};
