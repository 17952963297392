import React from "react";
import Helpers from "Cheops/Helpers";
import BemClassName from "Cheops/BemClassName";
import ProgressCircle from "../ProgressCircle/ProgressCircle";
import RequiredModulesList from "./RequiredModulesList";
import "./ModuleCard.less";

export default class ModuleCard extends React.Component {

    static defaultProps = {
        showRequired: false,
        course_id: null,
        course_stats: null,
        selected_module_id: null,
        course_learn: null,
        module_list: null,
        setSelectedModuleId: () => {
        },
    };


    render() {


        let module_obj = this.props.module_list[this.props.selected_module_id];

        let extDependencies     = Helpers.getAllExtDependencies(module_obj.id, this.props.module_list);
        let reverseDependencies = Helpers.getAllExtDependencies(module_obj.id, this.props.module_list, true);

        let all_dependencies = [...extDependencies, this.props.selected_module_id, ...reverseDependencies];


        let learn_module_obj = this.props.course_learn.modules[module_obj.id];
        let module_info_texts = [];
        let users_info_texts = [];
        let module_link = null;
        let time_text = "";
        let today = new Date();
        let closed_by_time = false;


        if (!learn_module_obj.availability.locked) {

            if (learn_module_obj.availability.closeDate) {

                let date = new Date(learn_module_obj.availability.closeDate);

                if (today < date) {

                    time_text = `До ${date.toLocaleString("ru", {
                        day: "numeric",
                        month: "short",
                        hour: "2-digit",
                        minute: "2-digit",
                    })}`;

                }

            }

            if (learn_module_obj.availability.openDate) {

                let date = new Date(learn_module_obj.availability.openDate);

                if (today < date) {

                    time_text = `Откроется ${date.toLocaleString("ru", {
                        day: "numeric",
                        month: "short",
                        hour: "2-digit",
                        minute: "2-digit",
                    })}`;

                    closed_by_time = true;

                }

            }

        }

        if (learn_module_obj.isClosed) {

            time_text = "Завершён";

        }

        if (learn_module_obj.availability.locked) {

            time_text = "Закрыт";

            if (learn_module_obj.availability.closeDate) {

                let date = new Date(learn_module_obj.availability.closeDate);

                if (today >= date) {

                    time_text = `Закрыт ${date.toLocaleString("ru", {
                        day: "numeric",
                        month: "short",
                    })}`;

                }

            }

        }


        if (module_obj.workload.lectures > 0) {

            module_info_texts.push(`${module_obj.workload.lectures} ${Helpers.formatPlural(module_obj.workload.lectures, ["лекция", "лекции", "лекций"])}`);

        }

        if (module_obj.workload.tasks > 0) {

            module_info_texts.push(`${module_obj.workload.tasks} ${Helpers.formatPlural(module_obj.workload.tasks, ["упражнение", "упражнения", "упражнений"])}`);

        }

        if (module_obj.workload.advancedTasks > 0) {

            module_info_texts.push(`${module_obj.workload.advancedTasks} ${Helpers.formatPlural(module_obj.workload.advancedTasks, ["задача", "задачи", "задач"])}`);

        }


        let module_card_class = new BemClassName("module_card");
        module_card_class.appendStatusIf(this.props.course_learn.availability.locked, 'locked_course');
        module_card_class.appendStatusIf(this.props.showRequired, 'show_required');
        module_card_class.appendStatusIf(!learn_module_obj.hash, 'not_available');


        let module_card__lecturer_class = "module_card__lecturer";

        if (!learn_module_obj.hash) {

            module_card__lecturer_class += " module_card__lecturer--not_available";

        }


        if (learn_module_obj.hash) {

            module_link = `/#/course/${this.props.course_id}/${module_obj.id}`;

            if (learn_module_obj.stepsDone>0 && learn_module_obj?.lastVisitedElement > 0) {
                module_link += `/${learn_module_obj.lastVisitedElementType}_${learn_module_obj.lastVisitedElement}`;
            }

        }

        let achieved_number = "";

        if (this.props.course_stats !== null && this.props.course_stats.achieved) {

            achieved_number = this.props.course_stats.achieved;

        }

        let in_progress_number = "";


        if (this.props.course_stats !== null) {

            in_progress_number = this.props.course_stats.inProgress;

        }

        let module_card__continue_button_class = new BemClassName('module_card__continue_button');
        let module_button_text = "Приступить";

        if (this.props.course_learn.lastVisitedModule && (this.props.course_learn.lastVisitedModule === this.props.selected_module_id)) {

            module_card__continue_button_class.appendStatus(" module_card__continue_button--continue");
            module_button_text = "Продолжить";

        }

        if (learn_module_obj.stepsDone > 0 || learn_module_obj.progressCurrent > 0 || learn_module_obj.progressFailed > 0) {

            module_button_text = "Продолжить";

        }

        if (learn_module_obj.isClosed || learn_module_obj.availability.locked) {

            module_button_text = "Смотреть";

        }

        if (achieved_number) {

            users_info_texts.push(`Завершили: ${achieved_number.toLocaleString()}`);

        }


        if (in_progress_number > 0) {

            if (learn_module_obj.availability.locked) {

                users_info_texts.push(`Изучали: ${in_progress_number.toLocaleString()}`);

            } else {

                users_info_texts.push(`Изучают: ${in_progress_number.toLocaleString()}`);

            }

        }

        let card_wrapper_styles = {};

        if (learn_module_obj.hash && this.props.course_color) {

            card_wrapper_styles.background = this.props.course_color;

        }

        let hideProgress = false;

        if (module_obj.displaySettings) {

            hideProgress = module_obj.displaySettings.hideProgress;

        }

        return (
            <div className={module_card_class}>
                <a href={module_link}>
                    <div className={module_card__lecturer_class}>
                        <div className="module_card__lecturer_photo">
                            {!!module_obj.lecturers && module_obj.lecturers.length > 0 && !!module_obj.lecturers[0].avatar
                                ? <img src={module_obj.lecturers[0].avatar} />
                                : <img src={require("CheopsRoot/img/module_card__no_lecturer.svg")} />}
                        </div>
                        {(!!module_obj.lecturers && module_obj.lecturers.length > 0)
                        && <div className="module_card__lecturer_text">
                            {module_obj.lecturers[0].lastName}
                            {' '}
                            {module_obj.lecturers[0].firstName}
                            {' '}
                            {module_obj.lecturers[0].middleName}
                        </div>}
                    </div>

                    <div className="module_card__wrapper" style={card_wrapper_styles}>
                        <div className="module_card__header">

                            {all_dependencies.length > 1
                            && <div className="module_card__switcher">
                                {all_dependencies.map((dep_id) => <div
                                    key={dep_id}
                                    className={dep_id === this.props.selected_module_id ? 'module_card__switcher_dot module_card__switcher_dot--active' : 'module_card__switcher_dot'}
                                    onClick={(e) => {

                                        e.preventDefault();
                                        this.props.setSelectedModuleId(dep_id);

                                    }}
                                />)}
                            </div>}

                            <div className="module_card__header_text">
                                <div className="module_card__title">{module_obj.title}</div>
                                <div className="module_card__content">
                                    {!closed_by_time
                                    && <div className="module_card__content_consist">
                                        <div>{module_info_texts.join(", ")}</div>
                                        <div>{users_info_texts.join(", ")}</div>
                                    </div>}
                                </div>
                            </div>

                            <div>
                                <ProgressCircle
                                    progress={learn_module_obj}
                                    show_border={true}
                                    starred={module_obj.isAdvanced || false}
                                    hideProgress={hideProgress}
                                />
                            </div>
                        </div>

                        {time_text
                        && <div className="module_card__time_text">{time_text}</div>}

                        {learn_module_obj.hash
                        && <div className={module_card__continue_button_class}>
                            <div className="module_card__continue_button_text">{module_button_text}</div>
                        </div>}

                    </div>
                </a>

                {this.props.showRequired && !learn_module_obj.hash && module_obj.dependencies.length > 0
                && <RequiredModulesList module_obj={module_obj}
                                        module_list={this.props.module_list}
                                        course_learn={this.props.course_learn}
                                        course_color={this.props.course_color}
                                        setSelectedModuleId={this.props.setSelectedModuleId}
                />}
            </div>
        );

    }

}
