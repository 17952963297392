import React, {FC, useState, useEffect} from "react";
import bnc                              from "bnc";
import { noop }                         from '@sirius/ui-lib/src/utils/noop';
import MarkedWithVideo                  from "Smt/MarkedWithVideo";
import {CourseProgressLine}             from "Cheops/components/CoursePage/V2/CourseProgressLine";
import type {
    ContentProgress,
    RenderDescriptionParams,
    RenderGraphParams,
    CourseProgressFormProps,
    GetStatsLevelParams,
    RenderItemDescriptionParams,
    RenderSelectLevelParams,
    RenderTabsParams,
    GetCourseCurrentProgressParams,
    RenderGraphAdvancedParams,
    RenderLineProgressParams,
}                                       from "./@types";
import                                       "./CourseProgressForm.less";

const block = new bnc('course_progress_form');

const INIT_STATE = {
    point: 0,
    level: 1,
    level_content: {} as ContentProgress,
    level_count: 1,
    tab: '',
}

const TAB_TYPE = {
    MAIN: 'main',
    ADVANCED: 'advanced',
};


const returnPercent = (static_value: number, value: number): number => value / static_value * 100;

const getTabType = ({modulesMax, scoreTotal, advancedModulesMax}: ContentProgress): string => {

    const isMainType = modulesMax > 0 || scoreTotal > 0;
    const isAdvancedType = advancedModulesMax > 0;

    return isMainType
        ? TAB_TYPE.MAIN
        : isAdvancedType
            ? TAB_TYPE.ADVANCED
            : ''
        ;
};

const getCourseCurrentProgress = ({courseProgress, level}: GetCourseCurrentProgressParams): Partial<NooBack.Progress>  => {

    const index = courseProgress.findIndex((item) => item.level === level);

    if (index < 0) {

        return {};
    }

    return courseProgress[index];
};

const getHeightColumn = (buckets: Array<NooBack.Bucket>, amount: number): number => {

    const max_height = buckets.reduce((acc, item) => (item.amount > acc ? item.amount : acc), 0);

    return returnPercent(max_height, amount);
};

const getStatsLevel = ({level, levelStatistics}: GetStatsLevelParams): Partial<NooBack.Course.Stats.Statistics> => {

    const index = levelStatistics.findIndex((item) => item.level === level);

    if (index < 0) {

        return {achieved: 0, inProgress: 0};
    }

    return levelStatistics[index];
};

const renderGraph = ({level, levelContent, tab, point, courseBuckets, courseProgress, selectPoint}: RenderGraphParams) => {

    const index_buckets = courseBuckets.findIndex((bucket) => bucket.level === level);
    const points = [];

    for (let i = 0; i < levelContent.modulesMax; i++) {
        const point = {
            amount: 0,
            height: 0,
        };

        if (index_buckets >= 0 && courseBuckets[index_buckets].buckets) {

            if (courseBuckets[index_buckets].buckets[i]) {

                point.amount = courseBuckets[index_buckets].buckets[i].amount;
                point.height = getHeightColumn(courseBuckets[index_buckets].buckets, point.amount);

            }

        }

        points.push({...point});
    }

    const {checkpoints} = levelContent ;
    const {modulesAchieved, failedModules} = getCourseCurrentProgress({courseProgress, level}) ;

    const getPointIndex = (key: number) => {

        if (!checkpoints) {

            return false;
        }

        const checkpointValue = checkpoints.findIndex(({value}) => value === key);

        if (checkpointValue < 0) {

            return null;
        }

        return checkpointValue;
    };

    return points.map((item, key) => {

        const is_point = getPointIndex(key + 1) as number;

        const isPoint = is_point !== null;
        const isEmpty = !item.amount;

        let icon_type = 'circle';
        let icon = null;

        if (tab === TAB_TYPE.ADVANCED) {

            icon_type = 'star';

        }

        const is_ok = modulesAchieved >= key + 1;
        const is_fail = points.length - failedModules < (key + 1);

        if (is_ok) {

            icon = icon_type;

        } else {

            icon = `${icon_type}_o`;

        }

        if (is_fail) {

            icon = `${icon_type}_red`;

        }

        if (is_point !== null) {

            icon = `cap`;

            if (is_ok) {

                icon = `cap_green`;

            }

            if (is_fail) {

                icon = `cap_red`;

            }

            if (point === is_point) {

                icon = `cap_blue`;

            }

        }

        const clickHandler = isPoint
            ? () => selectPoint(is_point)
            : noop
        ;

        return (
            <div onClick={clickHandler}
                 key={key}
                 className={
                     block.el('graph__item') +
                     block.el('graph__item').bod('empty', isEmpty ) +
                     block.el('graph__item').bod('point', isPoint )
                 }
                 style={{height: `${item.height}px`}}
            >
                <div className={block.el('icon')}>
                    <img className={
                                block.el('graph__item_image') +
                                block.el('graph__item').bod('point_image', isPoint )
                         }
                         alt='ico'
                         src={require(`CheopsRoot/img/${icon}.svg`)}
                    />
                </div>
            </div>
        );

    });
};

const renderGraphAdvanced = ({level, levelContent, courseProgress}: RenderGraphAdvancedParams) => {

    const {advancedModulesMax} = levelContent;
    const {advancedModulesAchieved, failedAdvancedModules} = getCourseCurrentProgress({courseProgress, level});
    let icon = null;

    const arr = [...Array(advancedModulesMax)].map((_, i) => i+1 );

    return arr.map((item, index) => {

        if (advancedModulesAchieved >= item) {

            icon = 'star';

        } else {

            icon = 'star_o';

        }

        if ((arr.length - failedAdvancedModules) < item) {

            icon = 'star_red';

        }

        return (
            <div key={index}
                 className={block.el('graph__item') + block.el('graph__item').mod('advanced')}
            >
                <div className={block.el('icon__star')}>
                    <img className={block.el('graph__item_image') + block.el('icon__star_image')} alt='ico' src={require(`CheopsRoot/img/${icon}.svg`)} />
                </div>
            </div>
        );

    });
};

const renderTabs = ({tab, levelContent, courseProgress, level, changeTab}: RenderTabsParams)  => {

    const {type, advancedModulesMax, modulesMax, scoreTotal} = levelContent;
    const {modulesAchieved, advancedModulesAchieved, currentProgress} = getCourseCurrentProgress({courseProgress, level});

    let icon = 'circle_o';

    if ((type === 'module' && modulesAchieved > 0) || (type === 'score' && currentProgress > 0)) {

        icon = 'circle';

    }

    if ((type === 'module' && modulesAchieved === 0) || (type === 'score' && currentProgress === 0)) {

        icon = 'circle_o';

    }

    return (
        <div>
            {((type === 'module' && modulesMax > 0) || (type === 'score' && scoreTotal > 0))
                &&  <button
                        className={
                            block.el('button') +
                            block.el('tabs_item') +
                            block.el('tabs_item').bod('selected', (tab === TAB_TYPE.MAIN) )
                        }
                        onClick={() => changeTab(TAB_TYPE.MAIN)}
                    >
                        <div className={block.el('icon')}>
                            <img className={block.el('tabs_item_image')} alt='ico' src={require(`CheopsRoot/img/${icon}.svg`)} />
                        </div>
                        {type === 'module'
                            && `${modulesAchieved || 0} / ${modulesMax}`
                        }
                        {type === 'score'
                            && `${Math.round(returnPercent(scoreTotal, currentProgress))}%`
                        }
                    </button>
            }
            {advancedModulesMax > 0
                &&  <button
                        className={
                            block.el('button') +
                            block.el('tabs_item') +
                            block.el('tabs_item').bod('selected', (tab ===  TAB_TYPE.ADVANCED) )
                        }
                        onClick={() => changeTab(TAB_TYPE.ADVANCED)}
                    >
                        {
                            advancedModulesAchieved > 0 &&
                            <div className={block.el('icon')}>
                                <img  className={block.el('tabs_item_image')} alt='ico' src={require(`CheopsRoot/img/star.svg`)} />
                            </div>
                        }
                        {
                            advancedModulesAchieved === 0 &&
                            <div className={block.el('icon')}>
                                <img  className={block.el('tabs_item_image')} alt='ico' src={require(`CheopsRoot/img/star_o.svg`)} />
                            </div>
                        }
                        {`${advancedModulesAchieved || 0} / ${advancedModulesMax}`}
                    </button>
            }
        </div>
    );
};

const renderLineProgress = ({courseProgress, level, levelContent, point, selectPoint}: RenderLineProgressParams) => {

    const {scoreTotal, checkpoints, type} = levelContent;
    const {currentProgress, failedProgress} = getCourseCurrentProgress({courseProgress, level});

    return (
        <CourseProgressLine checkPoints   = {checkpoints}
                            totalValue    = {scoreTotal}
                            doneValue     = {currentProgress}
                            failValue     = {failedProgress}
                            className     = {block.el('line_progress')}
                            pointClick    = {selectPoint}
                            selectedPoint = {point}
                            type          = {type}
        />
    );
};

const renderSelectLevel = ({courseContentProgress, levelCount, level, loadLevel}: RenderSelectLevelParams) => {

    const arr = [...Array(levelCount)].map((_, i) => i+1 );

    const clickHandler = (levelIndex: number) => loadLevel(levelIndex, courseContentProgress);

    return arr.map((levelItem, index) =>

        <div
            key={index}
            className={
                block.el('select_level_item') +
                block.el('select_level_item').bod('selected', levelItem === level)
            }
            onClick={() => clickHandler(levelItem)}
        />

    );
};

const renderItemDescription = ({title, description, point_index = 0, point, tab, selectPoint = noop}: RenderItemDescriptionParams) => {

    const isSelected = point_index === point;
    const isMainTab = tab === TAB_TYPE.MAIN;

    const iconName = isMainTab
        ? isSelected ? 'cap_blue' : 'cap'
        : 'star_o'
    ;

    const  handler = isMainTab
        ? () => selectPoint(point_index)
        : noop;

    return (
        <div
            key={point_index}
            className={
                block.el('description__item') +
                block.el('description__item').bod('selected', isSelected )
            }
            onClick={handler}
            onTouchStart={handler}
        >
            <h3 className={
                    block.el('description__item_title') +
                    block.el('description__item_title').bod('selected', isSelected)
                }
            >
                <div className={block.el('icon') + block.el('description__item_wrapper')}>
                    <img className={block.el('description__item_title_image')} alt='ico' src={require(`CheopsRoot/img/${iconName}.svg`)} />
                </div>
                {title || 'Без названия'}
            </h3>

            <MarkedWithVideo className={block.el('description__item_details')}>
                {description}
            </MarkedWithVideo>
        </div>
    );
};

const renderDescription = ({checkpoints, point, tab, selectPoint}: RenderDescriptionParams) =>
    checkpoints
        .map(
            ({title, description}, point_index) => renderItemDescription({title, description, point_index, point, tab, selectPoint})
        )
        .filter((i) => !!i)
;


const CourseProgressForm: FC<CourseProgressFormProps> = (
    {
        courseContentProgress = [],
        courseBuckets= [],
        courseProgress=[],
        courseData= null,
        levelStatistics= [],
        showLevel= 1,
    }
) => {

    const [point, setPoint]                 = useState(INIT_STATE.point);
    const [level, setLevel]                 = useState(INIT_STATE.level);
    const [levelContent, setLevelContent]   = useState<ContentProgress>(INIT_STATE.level_content);
    const [levelCount, setLevelCount]       = useState<number>(INIT_STATE.level_count);
    const [tab, setTab]                     = useState(INIT_STATE.tab);

    useEffect(() => {
        const level_count = courseContentProgress.reduce((acc: number, item) => (acc < item.level ? item.level : acc), 0);
        setLevelCount(level_count);
    }, [showLevel, courseContentProgress]);

    useEffect(() => {
        loadLevel(showLevel, courseContentProgress);
    }, [levelCount]);

    const loadLevel = (level: number, courseContentProgress: Array<ContentProgress>): void => {

        const index = courseContentProgress.findIndex((item) => item.level === level);

        const isFindLevel = index >= 0 ;

        const level_content = (isFindLevel)
            ?   courseContentProgress[index]
            :   levelContent
        ;

        const tab = getTabType(level_content);

        setLevel(level);
        setLevelContent(level_content);
        setTab(tab)

    };

    const selectPoint = (point: number): void => setPoint(point);

    const changeTab = (tab: string): void => setTab(tab);

    const {type, checkpoints} = levelContent;
    const {availability} = courseData;
    const is_closed = !!availability.locked;
    const {achieved, inProgress} = getStatsLevel({level, levelStatistics});

    const description = checkpoints ? renderDescription({checkpoints, point, tab, selectPoint}) : [];
    const show_description = description?.length > 0 || tab === TAB_TYPE.ADVANCED;

    return (
        <div className={block}>
            <div className={block.el('body')}>
                <div className={block.el('head')}>

                    {
                        levelCount > 1 &&
                        <h2 className={block.el('head_title')}>
                            {`Уровень ${level}`}
                        </h2>
                    }

                    {
                        levelCount === 1 &&
                        <h2 className={block.el('head_title')}>
                            Прогресс по курсу
                        </h2>
                    }
                    {
                        !!levelCount && levelCount > 1 &&
                        <div className={block.el('select_level')}>
                            {renderSelectLevel({courseContentProgress, levelCount, level, loadLevel})}
                        </div>
                    }

                    <div className={block.el('statistic')}>
                        {is_closed
                            ? `Завершили: ${achieved.toLocaleString()}, изучали: ${inProgress.toLocaleString()}`
                            : `Завершили: ${achieved.toLocaleString()}, изучают: ${inProgress.toLocaleString()}`
                        }
                    </div>
                </div>

                {
                    !!levelContent &&
                    <div className={block.el('tabs')}>
                        {renderTabs({tab, levelContent, courseProgress, level, changeTab})}
                    </div>
                }


                {(type === 'module' && tab === TAB_TYPE.MAIN) && (levelContent?.modulesMax > 0)
                    &&  <div className={block.el('graph')}>
                            <div className={block.el('graph__body')}>
                                {renderGraph({level, levelContent, tab, point, courseBuckets, courseProgress, selectPoint})}
                            </div>
                        </div>
                }

                {(tab === TAB_TYPE.ADVANCED) && !!levelContent
                    &&  <div className={
                                block.el('graph') +
                                block.el('graph__advanced')
                            }
                        >
                            <div className={
                                    block.el('graph__body') +
                                    block.el('graph__body__advanced')
                                }
                            >
                                {renderGraphAdvanced({level, levelContent, courseProgress})}
                            </div>
                        </div>
                }

                {type === 'score' && tab !== TAB_TYPE.ADVANCED && !!levelContent
                    &&  <div className={block.el('graph')}>
                            <div className={block.el('graph__line')}>
                                {renderLineProgress({courseProgress, level, levelContent, point, selectPoint})}
                            </div>
                        </div>
                }

                {
                    show_description &&
                    <div>
                        <div className={block.el('separator')} />
                        <div className={block.el('description')}>
                            {(tab === TAB_TYPE.MAIN)
                                && description
                            }
                            {(tab === TAB_TYPE.ADVANCED)
                                && renderItemDescription(
                                    {
                                        title: 'Лекционные модули',
                                        description: 'Это модули дополнительной программы. Они не учитываются в прогрессе по курсу и при выдаче сертификата, но позволяют расширить кругозор',
                                        point,
                                        tab
                                    }
                                )
                            }
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

export default CourseProgressForm;
