import {Complexity, TCourseContents} from "Cheops/containers/ModulePage__Types";

export const getComplexityByCourseContents = (
    groups: NooBack.Content.ModuleGroups,
    _elNum: number,
    current_module_learn: NooBack.ModuleProgress,
    moduleId: string
): Complexity => {
    const elNum = _elNum + 1;
    const result: Complexity = groups
                    .find(
                        ({from, to, complexitySwitch}) => (
                            complexitySwitch &&
                            elNum >= from &&
                            elNum <= to
                        )
                    )
                    ?.complexitySwitch
                    .map(
                        ({name, level}, ci) => ({
                            selected: ci + 1 === current_module_learn.summary?.currentComplexity,
                            title: name,
                            moduleId,
                            level
                        })
                    )
    ;
    return result;
}
