import React                                from "react";
import bnc                                  from "bnc";
import {HBox, LGap, VBox, BoxAlignment}     from "@sirius/ui-lib/src/blocks/Layout";
import {cluster}                            from "Cheops/../clusters/current";
import {DEPLOY_NAME}                        from "Cheops/constants";
import {CheopsLocaleString}                 from "Cheops/CheopsLocaleBlock";
import {IconSocial,EIconSocial}             from "Cheops/components/Footer/IconSocial";

import                                           "./index.less";



const currentYear = new Date().getFullYear();
const block       = new bnc("landings-footer");

type FooterProps = {
    has_navigation?: boolean
}

const Footer: React.FC<FooterProps> = ({has_navigation}) => {

    const isSiriusCluster =  cluster.key === DEPLOY_NAME.SIRIUS;
    const hasIconsSocial = !!cluster.social;

    return (
        <VBox
            className = {
                block +
                block.mod( cluster.key )
            }
        >
            {
                hasIconsSocial
                && <HBox  align={BoxAlignment.center} className={block.el("social")}>
                        <IconSocial
                            social={EIconSocial.vk}
                        />
                        <LGap
                            className={block.el("social_gap")}
                        />
                        <IconSocial
                            social={EIconSocial.youtube}
                        />
                        <LGap
                            className={block.el("social_gap")}
                        />
                        <IconSocial
                            social={EIconSocial.telegram}
                        />
                    </HBox>
            }
            <HBox className={block.el("links")}>
                <a className={block.el("link")} href="/">{ cluster.title }</a>
                <LGap
                    className={block.el("links_gap")}
                />
                <a className={block.el("link")} href="/#/feedback">Обратная связь</a>
                {
                    isSiriusCluster &&
                    <>
                        <LGap
                            className={block.el("links_gap")}
                        />
                        <a className={block.el("link")} href="/jobs">Вакансии</a>
                    </>
                }
            </HBox>
            <HBox
                className={
                    block.el("bottom") +
                    block.el("bottom").mod( has_navigation ? "big" : "small" )
                }
            >
                <VBox
                    className={
                        block.el("bottom").mod('text')
                    }
                >
                    <CheopsLocaleString
                        k      = {`cheops.copyright.${cluster.key}`}
                        id     = 'value'
                        values = {{currentYear, br: <br />}}
                    />
                </VBox>
            </HBox>
        </VBox>
    );
}

export {Footer};
