import React from "react";
import "./Hints.less";

export default class HintPopup extends React.Component {

    static defaultProps = {
        cancelHintRequest: null,
        sendHintRequest: null,
    };


    render() {

        return (
            <div className="hint_popup">
                <div className="hint_popup__text">
                    Не спешите тратить подсказки.
                    <br />
                    Лучше разобраться самостоятельно
                </div>
                <div className="hint_popup__buttons">
                    <div className="hint_popup__button" onClick={this.props.cancelHintRequest}>Отмена</div>
                    <div className="hint_popup__button" onClick={this.props.sendHintRequest}>Показать</div>
                </div>
            </div>
        );

    }

}
