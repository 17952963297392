import {connect} from "react-redux";
import React from "react";
import store from "Cheops/Store";
import Helpers from "Cheops/Helpers";
import * as auth_actions from "../actions/auth";


class AuthCallbackPage extends React.Component {

    constructor(props) {

        super(props);

        document.title = `Вход — ${Helpers.getInstanceTitleForCurrentDeploy()}`;

    }

    async componentWillMount() {

        let search_params = location.hash.split("?")[1];

        if (!search_params) {

            location.href = location.origin;

        }

        search_params = new URLSearchParams(search_params);

        let code = search_params.get('code');
        let state = search_params.get('state');
        let redirect_to = search_params.get('redirect_to');


        if (!redirect_to || /^.*\/#\/auth.*/.test(redirect_to)) {

            redirect_to = `${location.origin}/#/`;

        }

        if (!code || !state) {

            location.href = location.origin;
            return;

        }


        let auth_token = await auth_actions.getSessionToken(code, state);

        store.dispatch(auth_actions.setAuthToken(auth_token));

        location.href = redirect_to;

    }

    render() {

        return <div />;

    }

}


const mapStateToProps = () => ({});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AuthCallbackPage);
