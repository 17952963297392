import {ChainsCourse}             from "./ChainsCourse";
import {LandingsChainsCourseCard} from "./Card";
import {LandingBlockChains}             from "./LandingBlockChains";
import type {
    ChainsCourseProps,
    LandingBlockChainsProps
}                                 from "./@types";
import "./index.less";


export {
    ChainsCourse,
    LandingsChainsCourseCard,
    LandingBlockChains
};
export type {
    ChainsCourseProps,
    LandingBlockChainsProps
}

