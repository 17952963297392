import React, { FC }          from 'react';
import bnc                    from 'bnc';
import { noop }               from '@sirius/ui-lib/src/utils/noop';
import { BoxAlignment, HBox } from '@sirius/ui-lib/src/blocks/Layout';
import {CourseProgressLine}   from 'Cheops/components/CoursePage/V2/CourseProgressLine';
import                             './style.less';

type Props = {
    className?:            string;
    courseProgress:        NooBack.Progress[];
    courseContentProgress: NooBack.Content.ProgressItem[];
    onClick?:              (idx: number) => void;
};

const block = new bnc('course-progress');

export const CourseProgress:FC<Props> = (
    {
        className = '',
        onClick = noop,
        courseProgress,
        courseContentProgress
    }) => {

    const getValueLevel = ({level, type}: NooBack.Content.ProgressItem) => {
        const index = courseProgress.findIndex((item) => item.level === level);
        const {
            currentProgress,
            failedProgress,
            modulesAchieved,
            failedModules
        } = courseProgress[index];
        return(
            index < 0
                ? {}
                : type === 'score'
                    ? {
                        done: currentProgress,
                        fail: failedProgress,
                    }
                    : {
                        done: modulesAchieved,
                        fail: failedModules,
                    }
        );
    };

    const getTotal = ({type, scoreTotal, modulesMax}: NooBack.Content.ProgressItem) =>
        type === 'module'
            ? modulesMax
            : scoreTotal
    ;

    const renderSmallProgress = () =>
        courseContentProgress.map((progress, idx) => {
            const {done, fail} = getValueLevel(progress);

            return <CourseProgressLine key         = {idx}
                                       checkPoints = {progress.checkpoints}
                                       totalValue  = {getTotal(progress)}
                                       doneValue   = {done}
                                       failValue   = {fail}
                                       onClick     = {() => onClick(idx + 1)}
                                       type        = {progress.type}
            />;
        })
    ;

    return (
        <div className={`${block} ${className}`}>
            <HBox align = {BoxAlignment.between}>
                { renderSmallProgress() }
            </HBox>
        </div>
    );
};
