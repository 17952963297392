import React from "react";
import Helpers from "Cheops/Helpers";

export default class AutogradeStartConfirmPage extends React.Component {


    static defaultProps = {
        status: {
            courseModuleId: 501,
            maxTimeForTask: 10,
            minTimeForTask: 1,
            totalTime: 103,
        },
    };

    getFormattedTotalTime() {


        let remain_hours = Math.floor(this.props.status.totalTime / 60);
        let remain_minutes = Math.floor(this.props.status.totalTime % 60);

        if (!remain_hours) {

            return `${remain_minutes} ${Helpers.formatPlural(remain_minutes, ["минута", "минуты", "минут"])}`;

        }

        return `${remain_hours} ${Helpers.formatPlural(remain_hours, ["час", "часа", "часов"])} ${remain_minutes} ${Helpers.formatPlural(remain_minutes, ["минута", "минуты", "минут"])}`;

    }


    render() {


        let task_times = `от ${this.props.status.minTimeForTask} до ${this.props.status.maxTimeForTask} минут`;

        if (this.props.status.minTimeForTask === this.props.status.maxTimeForTask) {

            task_times = `по ${this.props.status.minTimeForTask} ${Helpers.formatPlural(this.props.status.minTimeForTask, ["минута", "минуты", "минут"])}`;


        }


        let isParentMod = Helpers.isParentMode();

        return <div>
            <h1>
                Как только вы нажмёте &quot;начать&quot;, вам предстоит пройти тестирование до конца, прежде чем вы
                снова сможете учиться в курсе
            </h1>
            <img src={require("CheopsRoot/img/cheops_autograde.svg")} className="course_autograde_page__img" />

            <h2>Как проходить</h2>

            <p>Чтобы сделать перерыв — используйте паузы между задачами.</p>
            <p>Можно завершить тестирование досрочно при желании.</p>

            <h2>Сколько времени займёт</h2>

            <p>{`На каждое задание у вас будет ${task_times}.`}</p>
            <p>{`Ориентировочное время на тестирование — ${this.getFormattedTotalTime()}.`}</p>
            <p>Если решать задания быстрее — время сократится.</p>
            <p>Если решать задания неверно — время сократится.</p>
            <p>Удачи!</p>
            <div className="course_autograde_page__bottom_button_wrapper">
                <button className="course_autograde_page__bottom_button"
                        disabled={isParentMod}
                        onClick={this.props.startAutograde}
                >
                    Начать
                </button>
            </div>
        </div>;

    }

}
