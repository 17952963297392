import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {getContestsPageData, getContestsPageStatuses} from "Cheops/actions/noopolis-ts";

export const useData = ():[NooBack.ContestsPage.Static.ContestsPage , NooBack.ContestsPage.Status.ContestsPageStatus]  => {

    const [data, setData]             = useState<NooBack.ContestsPage.Static.ContestsPage>(null);
    const [statuses, setStatuses]     = useState<NooBack.ContestsPage.Status.ContestsPageStatus>();

    const [authorized] = useState(!!localStorage.getItem(CONFIG.Auth.localstorage_key));
    const {event_name} = useParams<{event_name: string}>();

    useEffect(() => {
        getContestsPageData(event_name)
            .then(setData)
            .then(
                () => {
                    authorized &&
                    getContestsPageStatuses(event_name)
                        .then((res) => {
                            setStatuses(res);
                        })
                    ;
                    return;
                }
            )

    }, []);

    return [data, statuses];
};
