import React from "react";

export const ProgressIcon = ({className}:{ className: string }) =>
    <svg className={className} width="24" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd"
              clipRule="evenodd"
              d="M12 2C11.2796 2 10.6957 2.58398 10.6957 3.30435C10.6957 4.02472 11.2796 4.6087 12 4.6087C16.0821 4.6087 19.3913 7.9179 19.3913 12C19.3913 16.0821 16.0821 19.3913 12 19.3913C7.9179 19.3913 4.6087 16.0821 4.6087 12C4.6087 11.2796 4.02472 10.6957 3.30435 10.6957C2.58398 10.6957 2 11.2796 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12Z"
              fill="currentColor"
        />
    </svg>
;
