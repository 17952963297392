/* eslint-disable */
import React from "react";
import {connect} from "react-redux";
import ColorUtils from "Cheops/Utils/ColorUtils";
import * as noo_actions from "Cheops/actions/noopolis";
import hex2rgb from "color-functions/dist/hex2rgb";
import rgb2hsv from "color-functions/dist/rgb2hsv";
import hsv2hex from "color-functions/dist/hsv2hex";


class ProgressDemoPage extends React.Component {

    constructor() {

        super();

        this.state = {
            icon: null,
            course_color_hsv: {h: 176, s: 40, v: 17},
            bg_course_color_hsv: {h: 176, s: 40, v: 17},
            card_color_hsv: {h: 176, s: 40, v: 17},
            data_window_content: "",
            data_window_styles: {},
        };

    }

    async componentDidMount() {

        this.setState({icon: await noo_actions.getContentImage(2)});

    }

    // setCourseColor(value) {
    //
    //     if (!/^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.test(value)) {
    //         return
    //     }
    //
    //     this.setState({course_color: value});
    // }

    setCourseColorHsv(param, value, field = 'course_color_hsv') {

        let hsv = this.state[field];

        hsv[param] = parseInt(value);
        hsv = ColorUtils.normalizeHSV(hsv);

        this.setState({[field]: hsv});

    }

    setCourseColorKeypress(param, key_code, fast = false, field = 'course_color_hsv') {

        let direction = 0;

        if (key_code === 38) {

            direction = +1;

        }

        if (key_code === 40) {

            direction = -1;

        }

        if (fast) {

            direction *= 10;

        }

        let hsv = this.state[field];

        hsv[param] += direction;
        hsv = ColorUtils.normalizeHSV(hsv);

        this.setState({[field]: hsv});

    }

    cardBgColor = (hex) => {

        if (!hex) {

            return null;

        }

        let rgb = hex2rgb(hex);
        let hsv = rgb2hsv(rgb.r, rgb.g, rgb.b);

        hsv.s -= 10;
        hsv.v += 10;

        hsv = ColorUtils.normalizeHSV(hsv);

        return `#${hsv2hex(hsv.h, hsv.s, hsv.v)}`;

    };

    render() {

        let hex_course_color = `#${hsv2hex(this.state.course_color_hsv.h, this.state.course_color_hsv.s, this.state.course_color_hsv.v)}`;
        let bg_hex_course_color = `#${hsv2hex(this.state.bg_course_color_hsv.h, this.state.bg_course_color_hsv.s, this.state.bg_course_color_hsv.v)}`;
        let card_hex_color_hsv = `#${hsv2hex(this.state.card_color_hsv.h, this.state.card_color_hsv.s, this.state.card_color_hsv.v)}`;


        return (
            <div className="page_wrapper">

                <link rel="stylesheet" type="text/css" href="https://ui-kit.co/figma2html/dist/layout.css?v=2" />
                <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Lab Grotesque:300" />


                <style dangerouslySetInnerHTML={{
                    __html: `
.page_wrapper{   min-height: 100vh;    box-sizing: border-box;}
.course_module_list{position: static !important}
.course_header{margin-bottom: 30px;}
.col{display: inline-block; vertical-align: top;}
.mt-1{ margin-top: 10px;}
.mt-2{ margin-top: 20px;}
.mt-3{ margin-top: 30px;}
.mt-4{ margin-top: 40px;}
.mt-5{ margin-top: 50px;}
.mb-5{ margin-bottom: 50px;}
.mr-5{ margin-right: 50px;}
.text-center{ text-align: center}

.inputs{position: relative}
.inputs input {width: 55px}

/* Frame 8.14 */
#n1951_36 {
	width: 100%;
	height: 100%;
	overflow: visible;
	    max-width: 1680px;
}
 
	/* Rectangle 2 */
	#n1951_37 {
		background: rgb(19, 26, 38);
		width: 1680px;
		height: 1416px;
	}
 
	/* Rectangle */
	#n1951_40 {
		background: rgb(22, 59, 114);
		top: 1305px;
		height: 270px;
	}
 
	/* Rectangle */
	#n1951_41 {
		background: rgb(255, 255, 255);
		border-radius: 16px 16px 0px 0px;
		top: 1361px;
		height: 971px;
	}
 
	/* Frame 2 */
	#n1951_79 {
		width: 369px;
		height: 270px;
		overflow: visible;
		left: 458px;
		top: 378px;
	}
 
		/* Rectangle */
		#n1951_43 {
			background: rgb(38, 48, 63);
			border-radius: 16px;
			height: 270px;
		}
 
		/* Rectangle */
		#n1951_44 {
			background: rgb(43, 113, 217);
			border-radius: 16px;
			top: 64px;
			height: 206px;
		}
 
		/* Преподаватель */
		#n1951_45 {
			font-family: "Lab Grotesque";
			font-size: 18px;
			font-weight: 300;
			letter-spacing: 0px;
			color: rgb(255, 255, 255);
			opacity: 0.6;
			vertical-align: middle;
			justify-content: flex-start;
			width: 125px;
			align-items: center;
			line-height: 24px;
			height: 24px;
			left: 39px;
			top: 21px;
		}

		/* Модуль */
		#n1951_46 {
			font-family: "Lab Grotesque";
			font-size: 24px;
			font-weight: 300;
			letter-spacing: 0px;
			color: rgb(255, 255, 255);
			vertical-align: middle;
			justify-content: flex-start;
			width: 82px;
			align-items: center;
			line-height: 24px;
			height: 24px;
			left: 39px;
			top: 91px;
		}
 
	/* Frame 2 */
	#n1951_78 {
		width: 369px;
		height: 270px;
		overflow: visible;
		left: 857px;
		top: 378px;
	}
 
		/* Rectangle */
		#n1951_68 {
			background: rgb(38, 48, 63);
			border-radius: 16px;
			height: 270px;
		}
 
		/* Rectangle */
		#n1951_69 {
			background: rgb(43, 113, 217);
			border-radius: 16px;
			top: 64px;
			height: 206px;
		}
 
		/* Преподаватель */
		#n1951_70 {
			font-family: "Lab Grotesque";
			font-size: 18px;
			font-weight: 300;
			letter-spacing: 0px;
			color: rgb(255, 255, 255);
			opacity: 0.6;
			vertical-align: middle;
			justify-content: flex-start;
			width: 125px;
			align-items: center;
			line-height: 24px;
			height: 24px;
			left: 39px;
			top: 21px;
		}
 
		/* Модуль */
		#n1951_71 {
			font-family: "Lab Grotesque";
			font-size: 24px;
			font-weight: 300;
			letter-spacing: 0px;
			color: rgb(255, 255, 255);
			vertical-align: middle;
			justify-content: flex-start;
			width: 82px;
			align-items: center;
			line-height: 24px;
			height: 24px;
			left: 39px;
			top: 91px;
		}
 
	/* Frame */
	#n1951_77 {
		width: 369px;
		height: 270px;
		overflow: visible;
		left: 1252px;
		top: 378px;
	}
 
		/* Rectangle */
		#n1951_72 {
			background: rgb(38, 48, 63);
			border-radius: 16px;
			height: 270px;
		}
 
		/* Rectangle */
		#n1951_73 {
			background: rgb(92, 104, 118);
			border-radius: 16px;
			top: 64px;
			height: 206px;
		}
 
		/* Преподаватель */
		#n1951_74 {
			font-family: "Lab Grotesque";
			font-size: 18px;
			font-weight: 300;
			letter-spacing: 0px;
			color: rgb(255, 255, 255);
			opacity: 0.6;
			vertical-align: middle;
			justify-content: flex-start;
			width: 125px;
			align-items: center;
			line-height: 24px;
			height: 24px;
			left: 39px;
			top: 21px;
		}
 
		/* Модуль */
		#n1951_75 {
			font-family: "Lab Grotesque";
			font-size: 24px;
			font-weight: 300;
			letter-spacing: 0px;
			color: rgb(255, 255, 255);
			vertical-align: middle;
			justify-content: flex-start;
			width: 82px;
			align-items: center;
			line-height: 24px;
			height: 24px;
			left: 39px;
			top: 91px;
		}
 
 
 
	/* Frame */
	#n1951_81 {
		width: 369px;
		height: 270px;
		overflow: visible;
		left: 59px;
		top: 678px;
	}
 
		/* Rectangle */
		#n1951_82 {
			background: rgb(38, 48, 63);
			border-radius: 16px;
			height: 270px;
		}
 
		/* Rectangle */
		#n1951_83 {
			background: rgb(92, 104, 118);
			border-radius: 16px;
			top: 64px;
			height: 206px;
		}
 
		/* Преподаватель */
		#n1951_84 {
			font-family: "Lab Grotesque";
			font-size: 18px;
			font-weight: 300;
			letter-spacing: 0px;
			color: rgb(255, 255, 255);
			opacity: 0.6;
			vertical-align: middle;
			justify-content: flex-start;
			width: 125px;
			align-items: center;
			line-height: 24px;
			height: 24px;
			left: 39px;
			top: 21px;
		}
 
		/* Модуль */
		#n1951_85 {
			font-family: "Lab Grotesque";
			font-size: 24px;
			font-weight: 300;
			letter-spacing: 0px;
			color: rgb(255, 255, 255);
			vertical-align: middle;
			justify-content: flex-start;
			width: 82px;
			align-items: center;
			line-height: 24px;
			height: 24px;
			left: 39px;
			top: 91px;
		}
 
	/* Frame */
	#n1951_86 {
		width: 369px;
		height: 270px;
		overflow: visible;
		left: 458px;
		top: 678px;
	}
 
		/* Rectangle */
		#n1951_87 {
			background: rgb(38, 48, 63);
			border-radius: 16px;
			height: 270px;
		}
 
		/* Rectangle */
		#n1951_88 {
			background: rgb(92, 104, 118);
			border-radius: 16px;
			top: 64px;
			height: 206px;
		}
 
		/* Преподаватель */
		#n1951_89 {
			font-family: "Lab Grotesque";
			font-size: 18px;
			font-weight: 300;
			letter-spacing: 0px;
			color: rgb(255, 255, 255);
			opacity: 0.6;
			vertical-align: middle;
			justify-content: flex-start;
			width: 125px;
			align-items: center;
			line-height: 24px;
			height: 24px;
			left: 39px;
			top: 21px;
		}
 
		/* Модуль */
		#n1951_90 {
			font-family: "Lab Grotesque";
			font-size: 24px;
			font-weight: 300;
			letter-spacing: 0px;
			color: rgb(255, 255, 255);
			vertical-align: middle;
			justify-content: flex-start;
			width: 82px;
			align-items: center;
			line-height: 24px;
			height: 24px;
			left: 39px;
			top: 91px;
		}
  
	/* Frame */
	#n1951_91 {
		width: 369px;
		height: 270px;
		overflow: visible;
		left: 857px;
		top: 678px;
	}
 
		/* Rectangle */
		#n1951_92 {
			background: rgb(38, 48, 63);
			border-radius: 16px;
			height: 270px;
		}
 
		/* Rectangle */
		#n1951_93 {
			background: rgb(92, 104, 118);
			border-radius: 16px;
			top: 64px;
			height: 206px;
		}
 
		/* Преподаватель */
		#n1951_94 {
			font-family: "Lab Grotesque";
			font-size: 18px;
			font-weight: 300;
			letter-spacing: 0px;
			color: rgb(255, 255, 255);
			opacity: 0.6;
			vertical-align: middle;
			justify-content: flex-start;
			width: 125px;
			align-items: center;
			line-height: 24px;
			height: 24px;
			left: 39px;
			top: 21px;
		}
 
		/* Модуль */
		#n1951_95 {
			font-family: "Lab Grotesque";
			font-size: 24px;
			font-weight: 300;
			letter-spacing: 0px;
			color: rgb(255, 255, 255);
			vertical-align: middle;
			justify-content: flex-start;
			width: 82px;
			align-items: center;
			line-height: 24px;
			height: 24px;
			left: 39px;
			top: 91px;
		}
 
	/* Frame 2 */
	#n1951_80 {
		width: 369px;
		height: 270px;
		overflow: visible;
		left: 59px;
		top: 378px;
	}
 
		/* Rectangle */
		#n1951_38 {
			background: rgb(27, 73, 140);
			border-radius: 16px;
			height: 270px;
		}
 
		/* Rectangle */
		#n1951_39 {
			background: rgb(255, 255, 255);
			border-radius: 16px;
			top: 64px;
			height: 206px;
		}
 
		/* Модуль */
		#n1951_42 {
			font-family: "Lab Grotesque";
			font-size: 18px;
			font-weight: 300;
			letter-spacing: 0px;
			color: rgb(255, 255, 255);
			vertical-align: middle;
			justify-content: flex-start;
			width: 62px;
			align-items: center;
			line-height: 24px;
			height: 24px;
			left: 39px;
			top: 21px;
		}
 
		/* Модуль */
		#n1951_47 {
			font-family: "Lab Grotesque";
			font-size: 18px;
			font-weight: 300;
			letter-spacing: 0px;
			color: rgb(53, 53, 62);
			vertical-align: middle;
			justify-content: flex-start;
			width: 62px;
			align-items: center;
			line-height: 24px;
			height: 24px;
			left: 39px;
			top: 91px;
		}
 
	/* Rectangle */
	#n1951_48 {
		background: rgb(43, 112, 216);
		border-radius: 0px 0px 16px 16px;
		height: 303px;
	}
  
	/* Табы */
	#n1951_49 {
		width: 1680px;
		height: 56px;
		overflow: hidden;
		top: 225px;
	}
 
		/* Волшебная кнопка */
		#n1951_50 {
			width: 208px;
			height: 36px;
			overflow: visible;
			left: 72px;
			top: 10px;
		}
 
			/* Rectangle */
			#n1951_51 {
				background: rgb(255, 255, 255);
				border-radius: 12px;
				box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 4px 0px;
			}
 
			/* Итоговое тестирование */
			#n1951_52 {
				font-family: "Lab Grotesque";
				font-size: 16px;
				font-weight: 300;
				letter-spacing: 0px;
				color: rgb(255, 255, 255);
				vertical-align: middle;
				text-align: center;
				justify-content: center;
				align-items: center;
				line-height: 18.75px;
				height: 18px;
				left: 9px;
				right: 10px;
				top: calc((50% - 9px) + 0px);
			}
 
		/* Волшебная кнопка */
		#n1951_53 {
			width: 99px;
			height: 36px;
			overflow: visible;
			left: 290px;
			top: 10px;
		}
 
			/* Rectangle */
			#n1951_54 {
				background: rgb(255, 255, 255);
				border-radius: 12px;
				box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 4px 0px;
			}
 
			/* О курсе */
			#n1951_55 {
				font-family: "Lab Grotesque";
				font-size: 16px;
				font-weight: 300;
				letter-spacing: 0px;
				color: rgb(255, 255, 255);
				vertical-align: middle;
				text-align: center;
				justify-content: center;
				align-items: center;
				line-height: 18.75px;
				height: 18px;
				left: 9px;
				right: 10px;
				top: calc((50% - 9px) + 0px);
			}
 
		/* Волшебная кнопка */
		#n1951_56 {
			width: 99px;
			height: 36px;
			overflow: visible;
			left: 399px;
			top: 10px;
		}
 
			/* Rectangle */
			#n1951_57 {
				background: rgb(255, 255, 255);
				border-radius: 12px;
				box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 4px 0px;
			}
 
			/* Правила */
			#n1951_58 {
				font-family: "Lab Grotesque";
				font-size: 16px;
				font-weight: 300;
				letter-spacing: 0px;
				color: rgb(255, 255, 255);
				vertical-align: middle;
				text-align: center;
				justify-content: center;
				align-items: center;
				line-height: 18.75px;
				height: 20px;
				left: 18px;
				right: 18px;
				top: calc((50% - 10px) + 0px);
			}
 
		/* Волшебная кнопка */
		#n1951_59 {
			width: 184px;
			height: 36px;
			overflow: visible;
			left: 1480px;
			top: 10px;
		}
 
			/* Rectangle */
			#n1951_60 {
				background: rgb(255, 255, 255);
				border-radius: 12px;
				box-shadow: rgb(255, 255, 255) 0px 0px 0px 1px inset;
				width: 184px;
			}
 
			/* Rectangle */
			#n1951_61 {
				background: rgb(255, 255, 255);
				border-radius: 12px;
				width: 89px;
			}
 
			/* Карта */
			#n1951_62 {
				font-family: "Lab Grotesque";
				font-size: 16px;
				font-weight: 300;
				letter-spacing: 0px;
				color: rgb(255, 255, 255);
				vertical-align: middle;
				text-align: center;
				justify-content: center;
				width: 43px;
				align-items: center;
				line-height: 18.75px;
				height: 20px;
				left: 22.8538px;
				top: calc((50% - 10px) + 0px);
			}
 
			/* Список */
			#n1951_63 {
				font-family: "Lab Grotesque";
				font-size: 16px;
				font-weight: 300;
				letter-spacing: 0px;
				color: rgb(255, 255, 255);
				opacity: 0.5;
				vertical-align: middle;
				text-align: center;
				justify-content: center;
				width: 53px;
				align-items: center;
				line-height: 18.75px;
				height: 20px;
				left: 109.805px;
				top: calc((50% - 10px) + 0px);
			}
  
		/* 24px (23) */
		#n1951_64 {
			width: 24px;
			height: 24px;
			overflow: hidden;
			left: 524px;
			top: 16px;
		}
 
			/* Vector */
			#n1951_66 {
				left: 16.6667%;
				right: 16.6667%;
				top: 41.67%;
				bottom: 41.67%;
			}
 
	// eslint-disable-next-line no-irregular-whitespace
	/* Дополнительные главы геометрии. 7 класс. 2.0 */
	#n1951_67 {
		font-family: "Lab Grotesque";
		font-size: 48px;
		font-weight: 300;
		letter-spacing: -0.96px;
		color: rgb(255, 255, 255);
		vertical-align: middle;
		justify-content: flex-start;
		width: 968px;
		transform: translate(0px, 0.106666px);
		line-height: 56px;
		height: 56px;
		left: 72px;
		top: 96px;
	}

                    `
                }}
                />

                <div className="frame" id="n1951_36">
                    <div className="left top rectangle" id="n1951_37"
                         style={{background: bg_hex_course_color}}></div>
                    <div className="left_right top rectangle" id="n1951_40"
                         style={{background: ColorUtils.veryDarken(hex_course_color)}}></div>
                    <div className="left_right top rectangle" id="n1951_41"></div>
                    <div className="frame" id="n1951_79">
                        <div className="left_right top rectangle" id="n1951_43"
                             style={{background: this.cardBgColor(bg_hex_course_color)}}></div>
                        <div className="left_right top rectangle" id="n1951_44"
                             style={{background: hex_course_color}}></div>
                        <div className="left top text" id="n1951_45">Преподаватель</div>
                        <div className="left top text" id="n1951_46">Модуль</div>
                    </div>
                    <div className="frame" id="n1951_78">
                        <div className="left_right top rectangle" id="n1951_68"
                             style={{background: this.cardBgColor(bg_hex_course_color)}}></div>
                        <div className="left_right top rectangle" id="n1951_69"
                             style={{background: hex_course_color}}></div>
                        <div className="left top text" id="n1951_70">Преподаватель</div>
                        <div className="left top text" id="n1951_71">Модуль</div>
                    </div>
                    <div className="frame" id="n1951_77">
                        <div className="left_right top rectangle" id="n1951_72"
                             style={{background: this.cardBgColor(bg_hex_course_color)}}></div>
                        <div className="left_right top rectangle" id="n1951_73"
                             style={{background: (card_hex_color_hsv)}}></div>
                        <div className="left top text" id="n1951_74">Преподаватель</div>
                        <div className="left top text" id="n1951_75">Модуль</div>
                    </div>
                    <div className="frame" id="n1951_81">
                        <div className="left_right top rectangle" id="n1951_82"
                             style={{background: this.cardBgColor(bg_hex_course_color)}}></div>
                        <div className="left_right top rectangle" id="n1951_83"
                             style={{background: (card_hex_color_hsv)}}></div>
                        <div className="left top text" id="n1951_84">Преподаватель</div>
                        <div className="left top text" id="n1951_85">Модуль</div>
                    </div>
                    <div className="frame" id="n1951_86">
                        <div className="left_right top rectangle" id="n1951_87"
                             style={{background: this.cardBgColor(bg_hex_course_color)}}></div>
                        <div className="left_right top rectangle" id="n1951_88"
                             style={{background: (card_hex_color_hsv)}}></div>
                        <div className="left top text" id="n1951_89">Преподаватель</div>
                        <div className="left top text" id="n1951_90">Модуль</div>
                    </div>
                    <div className="frame" id="n1951_91">
                        <div className="left_right top rectangle" id="n1951_92"
                             style={{background: this.cardBgColor(bg_hex_course_color)}}></div>
                        <div className="left_right top rectangle" id="n1951_93"
                             style={{background: (card_hex_color_hsv)}}></div>
                        <div className="left top text" id="n1951_94">Преподаватель</div>
                        <div className="left top text" id="n1951_95">Модуль</div>
                    </div>
                    <div className="frame" id="n1951_80">
                        <div className="left_right top rectangle" id="n1951_38"
                             style={{background: ColorUtils.darken(hex_course_color)}}></div>
                        <div className="left_right top rectangle" id="n1951_39"></div>
                        <div className="left top text" id="n1951_42">Модуль</div>
                        <div className="left top text" id="n1951_47">Модуль</div>
                    </div>
                    {/* Шапка */}
                    <div className="left_right top rectangle" id="n1951_48"
                         style={{background: hex_course_color}}
                    />
                    <div className="frame" id="n1951_49">
                        <div className="frame" id="n1951_50">
                            <div className="h-scale top_bottom rectangle" id="n1951_51"></div>
                            <div className="left_right center text" id="n1951_52">Итоговое тестирование</div>
                        </div>
                        <div className="frame" id="n1951_53">
                            <div className="h-scale top_bottom rectangle" id="n1951_54"></div>
                            <div className="left_right center text" id="n1951_55">О курсе</div>
                        </div>
                        <div className="frame" id="n1951_56">
                            <div className="h-scale top_bottom rectangle" id="n1951_57"></div>
                            <div className="left_right center text" id="n1951_58">Правила</div>
                        </div>
                        <div className="frame" id="n1951_59">
                            <div className="left top_bottom rectangle" id="n1951_60"></div>
                            <div className="left top_bottom rectangle" id="n1951_61"></div>
                            <div className="left center text" id="n1951_62">Карта</div>
                            <div className="left center text" id="n1951_63">Список</div>
                        </div>
                    </div>
                    <div className="left top text" id="n1951_67">Дополнительные главы геометрии. 7&nbsp;класс. 2.0</div>
                </div>


                <div className="row text-center inputs">
                    <div className="col mr-5">

                        <div style={{textAlign: "center"}}>
                            <input value={hex_course_color} className="mb-5" readOnly />
                            <input value={this.state.course_color_hsv['h']} className="mb-5"
                                   onKeyDown={event => this.setCourseColorKeypress('h', event.which, event.shiftKey)}
                                   onChange={event => this.setCourseColorHsv('h', event.target.value)} />
                            <input value={this.state.course_color_hsv['s']} className="mb-5"
                                   onKeyDown={event => this.setCourseColorKeypress('s', event.which, event.shiftKey)}
                                   onChange={event => this.setCourseColorHsv('s', event.target.value)} />
                            <input value={this.state.course_color_hsv['v']} className="mb-5"
                                   onKeyDown={event => this.setCourseColorKeypress('v', event.which, event.shiftKey)}
                                   onChange={event => this.setCourseColorHsv('v', event.target.value)} />
                        </div>
                    </div>
                    <div className="col mr-5">
                        <div style={{textAlign: "center"}}>
                            <input value={bg_hex_course_color} className="mb-5" readOnly />
                            <input value={this.state.bg_course_color_hsv['h']} className="mb-5"
                                   onKeyDown={event => this.setCourseColorKeypress('h', event.which, event.shiftKey, 'bg_course_color_hsv')}
                                   onChange={event => this.setCourseColorHsv('h', event.target.value, 'bg_course_color_hsv')} />
                            <input value={this.state.bg_course_color_hsv['s']} className="mb-5"
                                   onKeyDown={event => this.setCourseColorKeypress('s', event.which, event.shiftKey, 'bg_course_color_hsv')}
                                   onChange={event => this.setCourseColorHsv('s', event.target.value, 'bg_course_color_hsv')} />
                            <input value={this.state.bg_course_color_hsv['v']} className="mb-5"
                                   onKeyDown={event => this.setCourseColorKeypress('v', event.which, event.shiftKey, 'bg_course_color_hsv')}
                                   onChange={event => this.setCourseColorHsv('v', event.target.value, 'bg_course_color_hsv')} />
                        </div>
                    </div>
                    <div className="col">

                        <div style={{textAlign: "center"}}>
                            <input value={bg_hex_course_color} className="mb-5" readOnly />
                            <input value={this.state.card_color_hsv['h']} className="mb-5"
                                   onKeyDown={event => this.setCourseColorKeypress('h', event.which, event.shiftKey, 'card_color_hsv')}
                                   onChange={event => this.setCourseColorHsv('h', event.target.value, 'card_color_hsv')} />
                            <input value={this.state.card_color_hsv['s']} className="mb-5"
                                   onKeyDown={event => this.setCourseColorKeypress('s', event.which, event.shiftKey, 'card_color_hsv')}
                                   onChange={event => this.setCourseColorHsv('s', event.target.value, 'card_color_hsv')} />
                            <input value={this.state.card_color_hsv['v']} className="mb-5"
                                   onKeyDown={event => this.setCourseColorKeypress('v', event.which, event.shiftKey, 'card_color_hsv')}
                                   onChange={event => this.setCourseColorHsv('v', event.target.value, 'card_color_hsv')} />
                        </div>
                    </div>
                </div>

            </div>
        );
    }

}


const mapStateToProps = (state) => {
    return {
        auth_token: state.authReducer.auth_token
    }
};

const mapDispatchToProps = () => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProgressDemoPage);
