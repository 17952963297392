import React                    from "react";
import {NavLink}                from "react-router-dom";
import {HBox, VBox}             from "@sirius/ui-lib/src/blocks/Layout";
import {Icon}                   from "@sirius/ui-lib/src/blocks/Controls/Icon";
import {useLocale}              from "@sirius/ui-lib/src/subjects/Locale/useLocale";
import ProgressCircle           from "Cheops/components/ProgressCircle/ProgressCircle";
import {TOCLessonProps}         from "./@types";
import {lessonEl}               from "./block";

const timeOptionsYear:Intl.DateTimeFormatOptions = {
    month: "short",
    year:  "2-digit",
    day:   "numeric"
}

const timeOptions:Intl.DateTimeFormatOptions = {
    month: "short",
    day:   "numeric"
}

const now = new Date();
const nowYear = now.getFullYear();

const formatAvDate = (date: string, locale: string) => {
    const d       = new Date(date);
    const dYear   = d.getFullYear();
    const hasYear = nowYear !== dYear;
    const formatted = d.toLocaleString(locale, hasYear ? timeOptionsYear : timeOptions);
    return formatted.replaceAll('.', '').replace(/(\d\d) г/, '`$1');
}


export const TOCLesson: React.FC<TOCLessonProps> = ({
    node,
    module,
    learnModule,
    courseId
}) => {
    const locale = useLocale();
    const {moduleId} = node;
    const {availability} = learnModule;
    const {closeDate, openDate} = availability;

    return  <NavLink
                to={`/course/${courseId}/${moduleId}`}
                className={`${lessonEl}`}
            >
                <HBox className={lessonEl.mod('progress')}>
                    <ProgressCircle
                        progress     = {learnModule}
                        show_border  = {true}
                        starred      = {false}
                        hideProgress = {false}
                        whiteBg      = {true}
                    />
                </HBox>
                <VBox className={lessonEl.mod('info')}>
                    <HBox className={lessonEl.mod('title')}>
                         {module?.title}
                    </HBox>
                    <HBox className={`${lessonEl.mod('dates')} text-s`}>
                        {
                            openDate  &&
                            <HBox className={lessonEl.mod('dates-start')}>
                                <Icon icon={'rocket_launch'}/>
                                <span>{formatAvDate(openDate, locale)}</span>
                            </HBox>
                        }
                        {   closeDate &&
                            <HBox className={lessonEl.mod('dates-end')}>
                                <Icon icon={'school'}/>
                                <span>{formatAvDate(closeDate, locale)}</span>
                            </HBox>
                        }
                    </HBox>
                </VBox>
            </NavLink>
    ;
}
