import React from "react";

export default class LandingBlockLecturers extends React.Component {

    static defaultProps = {
        block_id: null,
        title: "",
        lecturers: [],
    };


    render() {

        return (<div className="landings_wrapper">
            <div data-target="navigation"
                 className={`course_landing_page__section course_landing_page__${this.props.block_id}`}
                 data-nav={this.props.block_id}
            >
                {this.props.title
                && <div className="landings_wrapper__title">
                    {this.props.title}
                </div>}
                <div className="course_landing_page__lecturers_list">
                    {this.props.lecturers.map((lecturer, index) => <div
                        key={index}
                        className="course_landing_page__lecturers_list_item"
                        onClick={() => this.props.openLecturerCard()}
                    >
                        <div className="course_landing_page__lecturers_list_item_img">
                            <img src={lecturer.avatar || require('CheopsRoot/img/empty.svg')} />
                        </div>
                        <div className="course_landing_page__lecturers_list_item_name">
                            {`${lecturer.lastName} ${lecturer.firstName.charAt(0)}. ${lecturer.middleName.charAt(0)}.`}
                        </div>
                    </div>)}
                </div>
            </div>
        </div>);

    }

}
