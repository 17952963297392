import React, {useEffect, useState} from 'react';
import {Redirect, useParams}        from 'react-router-dom';
import {MGap}                       from '@sirius/ui-lib/src/blocks/Layout';
import store                        from 'Cheops/Store';
import {
    getModuleClassProgressFlat,
    getTeacherModuleInfo,
    getTokenToSeeAsStudent
}                                   from 'Cheops/actions/noopolis-ts';
import {passResponse}               from 'Cheops/containers/TeacherRoomPage/helpers';
import {getSelectedChips}           from 'Cheops/containers/TeacherRoomPage/ProgressPage/helpers';
import {setAuthToken}               from 'Cheops/actions/auth';
import {progressPage}               from 'Cheops/containers/TeacherRoomPage/blocks';
import {ProgressPageHeader}         from 'Cheops/containers/TeacherRoomPage/ProgressPage/ProgressPageHeader';
import {ProgressPageTableV2}        from './ProgressPageTableV2';
import                                    '../style.less';

const passSuccess = ({success}: any) => Promise.resolve(success);

export const TeacherCourseProgressPageV2 = () => {
    const [table, setTable]                     = useState<NooBack.Teacher.Methods.Table>(null);
    const [moduleInfo, setModuleInfo]           = useState<NooBack.Teacher.Methods.ModuleResponseData>(null);
    const [selectedChips, setSelectedChips]     = useState<NooBack.Teacher.Methods.TableChip>(null);
    const [loading, setLoading]                 = useState<boolean>(true);
    const [redirect, setRedirect]               = useState<string>(null);

    const {module_id}: {module_id: string} = useParams();

    const selectChips = (chip: NooBack.Teacher.Methods.ChipName) => {
        const data = chip ? {chips: [chip]} : {};
        getModuleClassProgressFlat(module_id, data)
            .then(passSuccess)
            .then((table) => {
                setTable(table);
                setSelectedChips(getSelectedChips((table as NooBack.Teacher.Methods.Table).chips));
            })
        ;
    };

    const seeAsStudent = (studentId: NooBack.Teacher.StudentId) => {
        getTokenToSeeAsStudent(studentId)
            .then(passResponse)
            .then(({token}) => {
                store.dispatch(setAuthToken(token));
                setRedirect(`/course/${moduleInfo.course.id}/${moduleInfo.id}`);
            })
        ;
    };

    useEffect(() => {
        if (!table) {
            setLoading(true);
            Promise.all([
                getModuleClassProgressFlat(module_id).then(passSuccess),
                getTeacherModuleInfo(module_id).then(passResponse)
            ])
                .then(([table, module]) => {
                    setTable(table);
                    setModuleInfo(module);
                    setSelectedChips(getSelectedChips((table as NooBack.Teacher.Methods.Table).chips));
                })
                .finally(() => setLoading(false))
            ;
        }

    },[]);

    return (
        redirect
            ? <Redirect push to={redirect}/>
            : !loading &&
            <div className={progressPage}>
                <ProgressPageHeader courseInfo={moduleInfo.course}/>
                <MGap/>
                <ProgressPageTableV2 title         = {moduleInfo.title}
                                     table         = {table}
                                     selectedChips = {selectedChips}
                                     onSelectChips = {selectChips}
                                     seeAsStudent  = {seeAsStudent}
                />
            </div>
    );
};
