import React from "react";
import connect from "react-redux/es/connect/connect";
import CourseHeader from "Cheops/components/CoursePage/CourseHeader";
import TaskCard from "Cheops/components/Section/TaskCard";
import {bindActionCreators} from "redux";
import * as noo_actions from "Cheops/actions/noopolis";
import CircularProgress from "Smt/Ui";
import * as passing_actions from "Cheops/actions/passing";
import NotificationsList from "Cheops/components/Notifications/NotificationsList";
import {META_THEME_COLOR_DEFAULT} from "Cheops/constants";


class SectionPage extends React.Component {

    constructor(props) {

        super(props);


        this.state = {
            is_loading: true,
            show_notifications: false,
        };

    }


    async componentWillMount() {

        await this.getAndSortTasks();

        let course_contents = this.props.course_contents[this.props.current_course.hash];
        document.querySelector("meta[name=theme-color]").setAttribute("content", course_contents.color);

    }

    async componentDidUpdate({match}) {

        if (this.props.match.params.module_id !== match.params.module_id) {

            await this.getAndSortTasks();

        }

        if (this.props.match.params.course_id !== match.params.course_id) {

            await this.getAndSortTasks();

        }

    }

    componentWillUnmount() {

        document.querySelector("meta[name=theme-color]").setAttribute("content", META_THEME_COLOR_DEFAULT);

    }

    async getAndSortTasks() {

        this.setState({is_loading: true});

        let course_id = parseInt(this.props.match.params.course_id);
        let module_id = parseInt(this.props.match.params.module_id);

        await this.props.getCourseData(course_id);
        await this.props.getModuleLearnDataBySkin(course_id, module_id, "contest");

        if (!this.props.course_contents[this.props.current_course.hash]) {

            await this.props.getCourseContent(this.props.current_course.hash);

        }


        let module_hash = this.props.current_module_learn_by_skin.summary.hash;

        await this.props.getModuleContent(module_hash);

        let current_tasks = [];
        let closed_tasks = [];

        if (this.props.current_module_learn_by_skin.elements) {

            for (let [i, element] of this.props.current_module_learn_by_skin.elements.entries()) {

                if (element.type !== "task") {

                    continue;

                }

                element.nav_id = i;

                if (element.availability.locked) {

                    closed_tasks.push(element);

                } else {

                    current_tasks.push(element);

                }

            }

        }


        current_tasks.sort((a, b) => this.sortTaskList(a, b, "id"));
        current_tasks.sort((a, b) => this.sortTaskList(a, b, "taskNumber", true));

        closed_tasks.sort((a, b) => this.sortTaskList(a, b, "id"));
        closed_tasks.sort((a, b) => this.sortTaskList(a, b, "taskNumber", true));

        this.setState({
            current_tasks,
            closed_tasks,
            is_loading: false,
        });

    }


    sortByTaskNumber(a, b) {

        if (!a.taskNumber) {

            return 0;

        }

        return a.taskNumber - b.taskNumber;

    }


    sortByOpened(a, b) {

        let a_in_future = false;
        let b_in_future = false;

        let now = new Date();

        if (a.availability.openDate) {

            let a_date = new Date(a.availability.openDate);
            if (a_date.getTime() > now.getTime()) {

                a_in_future = true;

            }

        }

        if (b.availability.openDate) {

            let b_date = new Date(b.availability.openDate);

            if (b_date.getTime() > now.getTime()) {

                b_in_future = true;

            }

        }


        if (a_in_future && b_in_future) {

            return 0;

        }

        if (a_in_future) {

            return 1;

        }

        if (b_in_future) {

            return -1;

        }

        return 0;

    }

    sortByDate(a, b, property) {

        let a_date = null;
        let b_date = null;


        if (a.availability[property]) {

            a_date = new Date(a.availability[property]);

        }

        if (b.availability[property]) {

            b_date = new Date(b.availability[property]);

        }


        if ((a_date !== null) && (b_date !== null)) {

            return a_date - b_date;

        }

        if ((a_date !== null) && (b_date === null)) {

            return 1;

        }

        if ((a_date === null) && (b_date !== null)) {

            return -1;

        }

        return 0;

    }

    sortTaskList(a, b, property, reverse = false) {

        let direction = reverse ? -1 : 1;

        switch (property) {
            case "id":
                return a.id - b.id;
            case "taskNumber":
                return this.sortByTaskNumber(a, b) * direction;
            case "opened":
                return this.sortByOpened(a, b) * direction;
            case "openDate":
            case "closeDate":
                return this.sortByDate(a, b, property) * direction;
            default:
                return 0;
        }

    }


    render() {

        if (this.state.is_loading) {

            return (<CircularProgress centerOfWindow={true} />);

        }

        let module_hash = this.props.current_module_learn_by_skin.summary.hash;

        let main_menu_items = [];

        for (let [index, widget] of this.props.contents_data[module_hash].widgets.entries()) {

            if (widget.text) {

                main_menu_items.push({
                    title: widget.text.caption,
                    link: `/#/course/${this.props.match.params.course_id}/section_${this.props.match.params.module_id}/text_${index}`,
                });

            }

            if (widget.progressTable) {

                main_menu_items.push({
                    title: widget.progressTable.title,
                    link: `/#/course/${this.props.match.params.course_id}/section_${this.props.match.params.module_id}/results_${index}`,
                });

            }

        }

        let course_contents = this.props.course_contents[this.props.current_course.hash];

        return (
            <div className="section_page" style={{background: course_contents.color}}>

                {this.state.show_notifications
                && <NotificationsList hideNotifications={() => this.setState({show_notifications: false})} />}

                <CourseHeader
                    show_notifications={true}
                    expanded={true}
                    main_menu_items={main_menu_items}
                    title_align="left"
                    title={this.props.contents_data[module_hash].info.title}
                    showNotifications={() => this.setState({show_notifications: true})}
                    back_path={`/#/course/${this.props.match.params.course_id}`}
                    course_color={course_contents.color}
                />

                <div className="section_page__content">
                    <div className="section_page__task_list">
                        {this.state.current_tasks.map((task) => {

                            return <TaskCard key={task.id}
                                             course_id={this.props.match.params.course_id}
                                             module_id={this.props.match.params.module_id}
                                             changeSelectedElementNum={() => this.props.changeSelectedElementNum(task.nav_id)}
                                             task_obj={task}
                            />;

                        })}
                    </div>

                    <div className="section_page__task_list">
                        {this.state.closed_tasks.map((task) => {

                            return <TaskCard key={task.id}
                                             course_id={this.props.match.params.course_id}
                                             module_id={this.props.match.params.module_id}
                                             changeSelectedElementNum={() => this.props.changeSelectedElementNum(task.nav_id)}
                                             task_obj={task}
                            />;

                        })}
                    </div>
                </div>
            </div>
        );

    }

}


const mapStateToProps = (state) => {

    return {
        current_course: state.nooReducer.current_course,
        current_module_learn_by_skin: state.nooReducer.current_module_learn_by_skin,
        contents_data: state.nooReducer.contents_data,
        course_contents: state.nooReducer.course_contents,
    };

};

const mapDispatchToProps = (dispatch) => {

    return {
        getCourseData: bindActionCreators(noo_actions.getCourseData, dispatch),
        getCourseContent: bindActionCreators(noo_actions.getCourseContent, dispatch),
        getModuleLearnDataBySkin: bindActionCreators(noo_actions.getModuleLearnDataBySkin, dispatch),
        getModuleContent: bindActionCreators(noo_actions.getModuleContent, dispatch),
        changeSelectedElementNum: (element_num) => dispatch(passing_actions.changeSelectedElementNum(element_num)),
    };

};

export default connect(mapStateToProps, mapDispatchToProps)(SectionPage);
