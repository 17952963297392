
enum EIconSocial  {
    vk       = 'vk',
    youtube  = 'youtube',
    telegram = 'telegram'
}


type IconSocialProps = {
    readonly social: EIconSocial;

}

export type {
    IconSocialProps
};
export  {
    EIconSocial
};
