import React                                from "react";
import {useParams}                          from "react-router-dom";
import {VBox}                               from "@sirius/ui-lib/src/blocks/Layout";
import {useClass4Element}                   from "Cheops/Utils/useClass4Element";
import {CourseToCProps}                     from "./@types";
import {block}                              from "./block";
import {TOCChapter}                         from "./TOCChapter";
import {TOCParagraph}                       from "./TOCParagraph";
import                                           "./index.less";


export const CourseToC: React.FC<CourseToCProps> = ({
    toc,
    courseId,
    learn,
    modules
}) => {
    useClass4Element(
        document.documentElement,
        block.el('page').trim()
    );
    const {chapter_num} = useParams<{chapter_num:string}>();
    const currentChapter = parseInt(chapter_num,10);
    return  <VBox className={block}>
                {
                    toc.map(
                        (node, i) => {
                            const {type} = node;
                            return  type === "chapter" && (i === currentChapter)
                                        ? <TOCChapter
                                            key      = {i}
                                            node     = {node}
                                            courseId = {courseId}
                                            learn    = {learn}
                                            modules  = {modules}
                                        />
                                        :
                                    type === "paragraph"
                                        ? <TOCParagraph
                                            key      = {i}
                                            node     = {node}
                                            courseId = {courseId}
                                            learn    = {learn}
                                            modules  = {modules}
                                        />
                                        :
                                    null
                            ;
                        }
                    )
                }
            </VBox>
    ;
}
