import React from "react";
import "./CourseTrainingCard.less";
import Helpers from "Cheops/Helpers";
import BemClassName from "Cheops/BemClassName";
import * as noo_action from "Cheops/actions/noopolis";

const CONTENT_VERSION = CONFIG.ContentVersion ? `/${CONFIG.ContentVersion}` : "";

export default class CourseTrainingCard extends React.Component {

    static defaultProps = {
        widgetObj: [],
        course_id: null,
        onDone: null,
    };

    state = {
        hidden: false,
        widget_index: 0,
    };

    doneTraining = () => {

        noo_action.doneHelp(this.props.course_id, [this.props.widgetObj.id]);

        if (this.props.onDone) {

            this.props.onDone(this.props.widgetObj.id);

        }

        this.setState({hidden: true});

    };

    renderTextWidget = (content) => <div>
        <div className="course_training_card__body">
            <img src={`${CONFIG.Api.noopolis.url + CONTENT_VERSION}/content/_image/${content.image}`} />
        </div>
        <div className="course_training_card__content_text">
            {content.text}
        </div>
    </div>;

    renderVideoWidget = (content) => <div>
        <div className="course_training_card__body">
            <iframe src={Helpers.getVideoIframeUrl(content.url)} frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
            />
        </div>
        <div className="course_training_card__content_text">
            {content.text}
        </div>
    </div>;

    render() {

        if (this.state.hidden) {

            return "";

        }

        let widget = this.props.widgetObj.widget[this.state.widget_index];

        let isParentMod = Helpers.isParentMode();

        return (<div className="course_training_card">
            <div className="course_training_card__title">{widget.title}</div>
            {widget.type === 'video' && this.renderVideoWidget(widget.content)}
            {widget.type === 'text' && this.renderTextWidget(widget.content)}
            <div className="course_training_card__footer">
                {(this.state.widget_index + 1) === this.props.widgetObj.widget.length
                && <button className="course_training_card__button" onClick={this.doneTraining} disabled={isParentMod}>
                    Спасибо, ясно!
                </button>}
                {(this.state.widget_index + 1) < this.props.widgetObj.widget.length
                && <button className="course_training_card__button"
                           onClick={() => this.setState({widget_index: this.state.widget_index + 1})}
                >
                    Далее
                </button>}
                <div className="course_training_card__slide_links">
                    {this.props.widgetObj.widget.map((v, i) => {

                        let className = new BemClassName("course_training_card__slide_link");
                        className.appendStatusIf(i === this.state.widget_index, 'active');
                        return <div className={className} key={i}
                                    onClick={() => this.setState({widget_index: i})}
                        />;

                    })}
                </div>
            </div>
        </div>);

    }

}
