import React,
 { useEffect, useRef, useState } from 'react';
import { HBox, VBox, LGap }      from '@sirius/ui-lib/src/blocks/Layout';
import {Button, IconButton }     from '@sirius/ui-lib/src/blocks/Controls/Button';
import { Icon }                  from '@sirius/ui-lib/src/blocks/Controls/Icon';
import { windowResize$ }         from '@sirius/ui-lib/src/subjects/WindowResize';
import { ShareSocial }           from '@sirius/ui-lib/src/blocks/ShareSocial/ShareSocial';
import {Marked}                  from "@sirius/ui-shared/src/components/DisplayEngine";
import LandingsHeader            from 'Cheops/components/Landings/LandingsHeader';
import { Footer }                from 'Cheops/components/Footer';
import NotificationsList         from 'Cheops/components/Notifications/NotificationsList';
import { useData }               from './hooks';
import {
    block,
    blockCard,
    CONTENT_URL,
    CONTEST_URL,
    subjectCardList,
    SubjectCardListType,
    SubjectCardType}             from './block';
import                                './style.less';

const getBgColor = ( {r, g, b, a}: NooBack.ContestsPage.Static.ColorRGBA ) =>
    `rgba(${r}, ${g}, ${b}, ${a})`
;

const getImgUrl = ( hash:string ) => `${CONTENT_URL}/${hash}`;

const getChipsSizeAndShowIcon = (): { chips: 'l' | 's', icon: boolean } => {

    if (window.innerWidth >= 1200) {
        return {chips: 'l', icon: true};
    } else {
        return {chips: 's', icon: false};
    }
};

const SubjectCardList = ({contests, statuses, id}: SubjectCardListType) =>

    <HBox className   = {subjectCardList}
          mobOverturn = {true}
    >
        {
            contests.map((data, i) =>
                <SubjectCard contest  = {data}
                             statuses = {statuses}
                             key      = {`${id}-${i}`}
                />
            )
        }
    </HBox>
;



const SubjectCard = ({contest, statuses}: SubjectCardType) =>

    <VBox className = {blockCard}
          style     = {{
              backgroundColor: getBgColor(contest.background.colorRGBA),
              backgroundImage: `url(${getImgUrl(contest.background.image.hash)})`
          }}
    >
        <HBox className={blockCard.el('heading-wrapper')}>
            <img src={getImgUrl(contest.icon)} style={{maxHeight: '40px'}}/>
            <LGap/>
            <p className={blockCard.el('heading')}>
                {contest.name}
            </p>
        </HBox>
        <p className={blockCard.el('text')}>
            Варианты
        </p>
        <HBox className={blockCard.el('button-wrapper')}>
            {
                contest.variants.map(({id}, index) => {

                    const status = statuses ? statuses[id] : '';
                    const icon = status === 'done'
                                        ? 'done'
                                        : status === 'ongoing'
                                            ? 'hourglass_bottom'
                                            : void(0)
                    ;

                    return <a href      = {`${CONTEST_URL}/${id}`}
                              target    = {'_blank'}
                              className = {blockCard.el('button-link')}
                              key       = {id}
                    >
                        <IconButton icon      = {icon}
                                    size      = {'l'}
                                    className = {
                                         blockCard.el('button') +
                                         blockCard.el('button').bod('gray', !icon) +
                                         blockCard.el('button').bod('blue', icon)
                                    }
                                    mode      = {'primary'}
                        >
                            {
                                !icon
                                && index + 1
                            }
                        </IconButton>
                    </a>
                })

            }
        </HBox>
    </VBox>
;


export const ContestsTaskPage = () => {

    const [chipsSize, setChipsSize]   = useState<'l' | 's'>(getChipsSizeAndShowIcon().chips);

    const [showAdIcon, setShowAdIcon]               = useState(getChipsSizeAndShowIcon().icon);
    const [loading, setLoading]                     = useState(true);
    const [showNotifications, setShowNotifications] = useState(false);

    const [data, statuses] = useData();

    const contentRef = useRef(null);

    const scrollToPart = (id: string) => () => {
        contentRef.current.querySelector(`#anchor-${id}`).scrollIntoView({behavior: 'smooth'});
    };

    const checkWidthAndSetState = () => {
        const {chips, icon} = getChipsSizeAndShowIcon();

        setChipsSize(chips);
        setShowAdIcon(icon);
    };


    const renderPageIntro = () =>

        <div className = {block.el('intro-wrapper')}
             style     = {{
                 backgroundColor: getBgColor(data.background.colorRGBA),
                 backgroundImage: `url('${CONTENT_URL}/${data.background.image.hash}')`}
             }
        >
            <LandingsHeader white_widgets                 = {true}
                            show_notifications            = {false}
                            addition_icon                 = {(showAdIcon && data?.logo) ? `url(${CONTENT_URL}/${data.logo})` : null}
                            course_color                  = {getBgColor(data.background.colorRGBA)}
                            notifications_icon_background = {"transparent"}
                            showNotifications             = {() => setShowNotifications(true)}
                            small_logo                    = {false}
                            land_header_with_bg           = {false}
                            back_path                     = {null}
            />

            {showNotifications &&
            <NotificationsList hideNotifications={() => setShowNotifications(false)} />
            }

            <VBox className={block.el('intro-content')}>

                {
                    showAdIcon &&
                    <HBox className={block.el('back-button')}
                          onClick={() => location.hash = "#/"}
                    >

                        <Icon className = {block.el('back-button').mod('icon')}
                              icon      = {'arrow_upward'}
                              size      = {'s'}
                              mode      = {'white'}
                        />
                        <HBox className={block.el('back-button').mod('text')}>
                            Сириус.Курсы <LGap/> /
                        </HBox>

                    </HBox>
                }

                <h1 className={block.el('heading')}>
                    {data.title}
                </h1>
                <Marked className={block.el('description')} hasMathJax={false}>
                    {data.subtitle}
                </Marked>
                <HBox className={block.el('class-list')}>
                    {   data.sections.map(
                            ({name, id}) =>
                                <Button key      = {id}
                                        mode     = 'secondary'
                                        form     = 'rounded'
                                        size     = {chipsSize}
                                        onAction = {scrollToPart(id)}
                                >
                                    {name}
                                </Button>
                        )
                    }
                </HBox>

            </VBox>
        </div>
    ;

    const renderContent = () =>

        <VBox className = {block.el('content')}
              divRef    = {contentRef}
        >
            {
                data.sections.map(
                    ({name, id, contests}) =>

                        <div className = {block.el('content-class')}
                             id        = {`anchor-${id}`}
                             key       = {id}
                        >
                            <h2 className={block.el('content-title')}>
                                {name}
                            </h2>
                            <SubjectCardList contests = {contests}
                                             statuses = {statuses?.contests || void(0)}
                                             id       = {id}
                            />
                        </div>
                )
            }
        </VBox>
    ;

    useEffect(() => {
        const resizeSub$ = windowResize$.subscribe(checkWidthAndSetState);

        return () => {
            resizeSub$.unsubscribe();
        }
    },[]);

    useEffect(() => {
        if (data) {
            if (data.browserTabTitle) {
                document.title = data.browserTabTitle;
            }
            setLoading(false);
        }
    }, [data, statuses]);

    return(
        <>
            {
                !loading &&
                <main className={block}>

                    { renderPageIntro() }

                    { renderContent() }

                    <ShareSocial url   = {window.location.href}
                                 title = {document.title}
                    />
                    <Footer/>
                </main>
            }
        </>
    );
};
