import React                    from "react";
import * as nooActions          from "Cheops/actions/noopolis";
import Marked                   from "@sirius/ui-shared/src/components/DisplayEngine/Marked";
import CircularProgress         from "Smt/Ui";
import VideoPlayerWithResolve   from "Lab/components/UI2/Controls/VideoPlayerWithResolve";
import "./TaskExplanations.less";

export default class TaskExplanations extends React.Component {

    static defaultProps = {
        courseId: null,
        moduleId: null,
        elementContent: {},
        elementLearn: {},
        taskVideos: {},
    };

    // eslint-disable-next-line react/sort-comp
    VISIBLE_DATA_EXPLANATIONS = 1;
    VISIBLE_DATA_VIDEO = 2;

    constructor(props) {

        super(props);

        this.state = {
            visibleData: null,
            taskExplanations: [],
            videoLink: {},
        };

    }


    componentDidMount() {

        const {elementLearn, moduleContent} = this.props;

        if (elementLearn.explanationsOpened) {

            this.loadExplanationsData();

        }

        if (elementLearn.videosOpened && moduleContent.videosShowMode === 'expanded') {

            this.loadVideoData();

        }

    }


    componentDidUpdate(prevProps) {

        const {elementLearn} = this.props;

        if (prevProps.elementLearn.explanationsOpened !== elementLearn.explanationsOpened) {

            if (elementLearn.explanationsOpened) {

                this.loadExplanationsData();

            }

        }

        if (prevProps.elementLearn.videosOpened !== elementLearn.videosOpened) {

            if (elementLearn.videosOpened) {

                this.loadVideoData();

            }

        }

    }

    showVideos = async () => {

        this.setState({visibleData: this.VISIBLE_DATA_VIDEO});
        this.loadVideoData();

    };


    loadExplanationsData = async () => {

        const {
            elementLearn,
            elementContent,
            courseId,
            moduleId,
        } = this.props;

        if (elementContent.explanations) {

            const taskExplanations = [];

            for (let explanation of elementContent.explanations) {

                const taskExplanation = await nooActions.getTaskExplanation(courseId, moduleId, elementLearn.id, explanation);

                taskExplanations.push(taskExplanation.explanation);

            }

            this.setState({taskExplanations});

        }

    };

    loadVideoData = async () => {

        const {videoLink} = this.state;

        const {
            elementLearn,
            elementContent,
            courseId,
            moduleId,
            taskVideos,
            getTaskVideo,
        } = this.props;

        for (let videoId of elementContent.videos) {

            if (!videoLink[videoId]) {

                if (!taskVideos[videoId]) {

                    await getTaskVideo(courseId, moduleId, elementLearn.id, videoId);

                }

                videoLink[videoId] = taskVideos[videoId].link;

            }

        }

        this.setState({videoLink});

    };


    renderCloseLink() {

        return <div className="task_explanations__close_link" onClick={() => this.setState({visibleData: null})}>
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M13.3002 0.709971C12.9102 0.319971 12.2802 0.319971 11.8902 0.709971L7.00022 5.58997L2.11022 0.699971C1.72022 0.309971 1.09021 0.309971 0.700215 0.699971C0.310215 1.08997 0.310215 1.71997 0.700215 2.10997L5.59022 6.99997L0.700215 11.89C0.310215 12.28 0.310215 12.91 0.700215 13.3C1.09021 13.69 1.72022 13.69 2.11022 13.3L7.00022 8.40997L11.8902 13.3C12.2802 13.69 12.9102 13.69 13.3002 13.3C13.6902 12.91 13.6902 12.28 13.3002 11.89L8.41021 6.99997L13.3002 2.10997C13.6802 1.72997 13.6802 1.08997 13.3002 0.709971Z"
                    fill="#9194A1"
                />
            </svg>
        </div>;

    }

    render() {

        const {visibleData, taskExplanations, videoLink} = this.state;
        const {elementLearn, elementContent, moduleContent} = this.props;

        const explanationsAreVisible = moduleContent.explanationsShowMode !== 'hidden' || visibleData === this.VISIBLE_DATA_EXPLANATIONS;
        const videoAreVisible = (moduleContent.videosShowMode === 'expanded' && elementLearn.videosOpened) || visibleData === this.VISIBLE_DATA_VIDEO;


        const showExplanationsButton = !explanationsAreVisible && elementLearn.explanationsOpened && elementContent.explanations.length > 0;
        const showVideosButton = !videoAreVisible && elementLearn.videosOpened && elementContent.videos.length > 0;

        return (
            <div className="task_explanations">

                {explanationsAreVisible
                && taskExplanations.map((explanation, index) => (
                    <div className="task_explanations__explanation" key={explanation.id}>
                        {visibleData === this.VISIBLE_DATA_EXPLANATIONS && index === 0 && this.renderCloseLink()}
                        <div className="task_explanations__explanation_title">
                            Решение
                            {taskExplanations.length > 1 && ` ${index + 1}`}
                        </div>
                        <div className="task_explanations__explanation_text">
                            <Marked>{explanation.solution}</Marked>
                        </div>
                    </div>))}

                {videoAreVisible
                && elementContent.videos.map((videoId, index) => (
                    <div className="task_explanations__explanation" key={videoId}>
                        {visibleData === this.VISIBLE_DATA_VIDEO && index === 0 && this.renderCloseLink()}
                        <div className="task_explanations__explanation_title">
                            Видеоразбор
                            {elementContent.videos.length > 1 && ` ${index + 1}`}
                        </div>

                        {!videoLink[videoId]
                        && <CircularProgress marginCenter={true} />}

                        {
                            videoLink[videoId]
                            && <VideoPlayerWithResolve
                                    url={videoLink[videoId]}
                                    disableDownload
                                />
                        }

                    </div>))}

                {!visibleData
                && <div className="text-center">

                    {(showExplanationsButton || showVideosButton)
                    && <div className="task_explanations__buttons">

                        {showExplanationsButton
                        && <div
                            onClick={() => this.setState({visibleData: this.VISIBLE_DATA_EXPLANATIONS})}
                        >
                            Решение
                        </div>}

                        {showVideosButton
                        && <div onClick={this.showVideos}>
                            Видеоразбор
                        </div>}

                    </div>}

                </div>}
            </div>
        );

    }

}
