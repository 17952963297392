import React from "react";
import "./TaskButtons.less";
import {TASK_ANSWER_TYPES} from "Lab/constants";
import {ELEMENT_VERDICT} from "Cheops/constants";
import Helpers from "Cheops/Helpers";
import {TaskAnswerType, TaskCheckType} from "Cheops/Model/Task";
import {ElementProgress} from "Cheops/Model/Element";

interface Props {
    checkType?: TaskCheckType;
    answerType: TaskAnswerType;
    elementProgress: ElementProgress;
    commentsAllowed: boolean;
    messageType?: string; // TODO
    sendingDisabled?: boolean;
    formVisible: boolean;

    openAnswerForm(): void;

    openThreadForm(): void;

    closeAnswerForm(): void;

    onAnswer(): void;

    goToNextElement(): void;

}

export default class TaskButtons extends React.Component<Props> {

    static defaultProps = {
        checkType: TaskCheckType.AUTOMATIC,
        commentsAllowed: false,
        sendingDisabled: true,
        formVisible: false,
    };

    render(): React.ReactNode {

        const {
            onAnswer,
            checkType,
            answerType,
            formVisible,
            messageType,
            openAnswerForm,
            openThreadForm,
            elementProgress,
            closeAnswerForm,
            commentsAllowed,
            goToNextElement,
        } = this.props;

        let {sendingDisabled} = this.props;

        let showFormToggle = false;
        let showCloseFormButton = false;
        let submitButtonText: React.ReactNode = "Ответить";
        let submitButtonClassName = 'task_buttons__message_form_submit';
        let submitCallback = onAnswer;

        if (checkType === "manual") {

            if (answerType !== TASK_ANSWER_TYPES.NONE) {

                if (commentsAllowed) {

                    if (messageType === "answer") {

                        submitButtonText = <>
                            Отправить
                            <span className="task_buttons__message_form_submit_review"> на проверку</span>
                        </>;

                    }

                    if (!messageType) {

                        showFormToggle = true;

                    }

                } else {

                    submitButtonText = "Отправить на проверку";

                }

            }

        }

        if (messageType) {

            showCloseFormButton = true;

        }

        if (checkType === "automatic") {


            if (answerType === TASK_ANSWER_TYPES.NONE) {

                return <div className="task_buttons" />;

            }

            if (answerType === TASK_ANSWER_TYPES.PROGRAMMING) {

                submitButtonText = "Отправить на проверку";

                if (elementProgress.verdict === ELEMENT_VERDICT.WRONG) {

                    submitButtonText = `Отправить решение ${elementProgress.solution.answers.length + 1}`;
                    submitCallback = openAnswerForm;


                    if (!formVisible) {

                        submitButtonClassName = 'task_buttons__toggle_answer';

                        if (!Helpers.isParentMode()) {

                            sendingDisabled = false;

                        }

                    }

                }


                if (formVisible) {

                    submitButtonText = 'Отправить';
                    showCloseFormButton = true;
                    submitCallback = onAnswer;

                }

            }

        }

        if (elementProgress.isClosed && !formVisible) {

            sendingDisabled = false;
            submitButtonText = "Далее";
            submitCallback = goToNextElement;
            submitButtonClassName = "task_buttons__toggle_answer";

        }

        return (
            <>
                <div className="task_buttons">

                    {showFormToggle
                    && <>
                        {!elementProgress.isClosed
                        && <button className="task_buttons__toggle_answer" onClick={openAnswerForm}>
                            Ответить
                        </button>}
                        <button className="task_buttons__toggle_comments" onClick={openThreadForm}>
                            <img src={require("CheopsRoot/img/clouds.svg")} />
                            <span> Задать вопрос</span>
                        </button>
                    </>}

                    {!showFormToggle
                    && <>

                        {showCloseFormButton
                        && <button className="task_buttons__message_form_cancel"
                                   onClick={closeAnswerForm}
                        >
                            Отмена
                        </button>}

                        {elementProgress.isClosed && elementProgress.extraSolutions && !formVisible && !Helpers.isParentMode()
                        && <button
                            className="task_buttons__toggle_extra_answer"
                            onClick={openAnswerForm}
                        >
                            {`Отправить решение ${elementProgress.solution.answers.length + 1}`}
                        </button>}

                        <button
                            className={submitButtonClassName}
                            disabled={sendingDisabled}
                            onClick={submitCallback}
                        >
                            {submitButtonText}
                        </button>

                    </>}
                </div>
            </>
        );

    }

}
