import React                                from "react";
import {connect}                            from "react-redux";
import {bindActionCreators}                 from "redux";
import clonedeep                            from "lodash/cloneDeep";

import {HBox}                               from "@sirius/ui-lib/src/blocks/Layout";
import {CircularProgress}                   from "@sirius/ui-lib/src/blocks/CircularProgress";

import Marked                               from "@sirius/ui-shared/src/components/DisplayEngine/Marked";

import {META_THEME_COLOR_DEFAULT,
        REPORT_BUG_LVL}                     from "Cheops/constants";
import * as noo_actions                     from "Cheops/actions/noopolis";
import * as passing_actions                 from "Cheops/actions/passing";
import {TextbookStylesComplexityControls}   from "Cheops/containers/ModulePage__TextbookStylesComplexityControls";
import {ModuleAccessInfoPage}               from "Cheops/containers/ModuleAccessInfoPage";
import {ProfCheopsGreeting}                 from "Cheops/components/ProfCheopsGreeting";
import ModuleVideoContent                   from "Cheops/components/ModulePassing/ModuleVideoContent";
import ModulePassingHeader                  from "Cheops/components/ModulePassing/ModulePassingHeader";
import ModuleTaskContent                    from "Cheops/components/ModulePassing/ModuleTaskContent";
import ModuleTextContent                    from "Cheops/components/ModulePassing/ModuleTextContent";
import ModuleGroupNav                       from "Cheops/components/ModulePassing/ModuleGroupNav";
import Helpers                              from "Cheops/Helpers";



class ModulePage extends React.Component {

    constructor(props) {
        super(props);
        document.title = `${Helpers.getInstanceTitleForCurrentDeploy()} — Открытая онлайн-школа развития таланта`;
        this.state = {
            lock_bar_expanded: true,
            is_loading: true,
            module_is_loading: true,
            is_access_error: false,
        };
    }

    async UNSAFE_componentWillMount() {

        await this.updateCourseData();
        window.addEventListener('scroll', this.onScroll);

        let course_contents = this.props.course_contents[this.props.current_course.hash];
        document.querySelector("meta[name=theme-color]").setAttribute("content", course_contents.color);
        this.scrollToActiveElement();

    }

    shouldComponentUpdate({current_module_learn}) {

        if (this.props.current_module_learn?.summary && current_module_learn?.summary) {

            let newModuleHash = this.props.current_module_learn.summary.hash;

            if (current_module_learn.summary.hash !== newModuleHash) {

                this.props.getModuleContent(current_module_learn.summary.hash);
                return false;

            }

        }

        return true;

    }

    async componentDidUpdate({match, history}) {

        let prevProps = match.params;
        let newProps = this.props.match.params;

        if (newProps.course_id !== prevProps.course_id
            || newProps.module_id !== prevProps.module_id
            || newProps.element_id !== prevProps.element_id
            || newProps.element_type !== prevProps.element_type
            || history.location.force_refresh_data
        ) {

            delete history.location.force_refresh_data;
            setTimeout(() => this.updateCourseData(true),50);

        }

    }

    componentWillUnmount() {

        document.querySelector("meta[name=theme-color]").setAttribute("content", META_THEME_COLOR_DEFAULT);
        window.removeEventListener('scroll', this.onScroll);

    }

    user_is_scrolling = false;
    scrolling_timeout = 0;

    updatePageTitle() {

        let module_hash = this.props.current_module_learn.summary.hash;

        document.title = `${this.props.contents_data[module_hash].info.title}. Задание ${this.props.selected_element_num + 1} из ${this.props.contents_data[module_hash].variant.length} — ${Helpers.getInstanceTitleForCurrentDeploy()}`;

    }


    onScroll = () => {

        this.user_is_scrolling = true;

        if (this.scrolling_timeout) {

            clearTimeout(this.scrolling_timeout);

        }

        this.scrolling_timeout = setTimeout(() => {

            this.user_is_scrolling = false;

        }, 200);

    };

    scrollToElementByNum = num => {

        const elToScroll = document.querySelector(num === 0
            ? '.module_page'
            : `.module_page__wrapper_content > [data-el_num="${num}"]`
        );

        elToScroll?.scrollIntoView({block: "start", behavior: 'smooth'});

    }

    scrollToActiveElement = () => {
        if (this.user_is_scrolling) {
            return;
        }
        let el_num = this.getSelectedElemNum();
        el_num >= 0 && this.scrollToElementByNum(el_num);
    };

    checkAccessError = (current_module_learn) => current_module_learn && 'error' in current_module_learn;

    async updateCourseData(scroll = false) {


        let course_id = parseInt(this.props.match.params.course_id);
        let module_id = parseInt(this.props.match.params.module_id);

        let module_hash;


        if (!this.props.current_course || this.props.current_course.id !== course_id) {

            await this.setState({is_loading: true});
            await this.props.getCourseData(this.props.match.params.course_id);

        }

        if (!this.props.course_contents[this.props.current_course.hash]) {

            await this.setState({is_loading: true});
            await this.props.getCourseContent(this.props.current_course.hash);

        }




        if (this.props.current_module_learn?.summary) {

            module_hash = this.props.current_module_learn.summary.hash;

        }

        if (!this.props.current_module_learn || this.props.current_module_learn.summary?.id !== module_id) {

            await this.setState({is_loading: true});
            await this.props.getModuleLearnData(this.props.match.params.course_id, this.props.match.params.module_id);

            if (this.checkAccessError(this.props.current_module_learn)) {
                this.setState({is_access_error: true, is_loading: false});
                return;
            } else {
                this.setState({is_access_error: false})
            }

            module_hash = this.props.current_module_learn.summary.hash;
            await this.props.getModuleContent(module_hash);
        }

        let new_el_num = this.getSelectedElemNum();


        let progress_elements = this.props.current_module_learn.elements;

        for (let item_num in this.props.contents_data[module_hash].variant) {

            if (new_el_num > item_num) {

                continue;

            }

            new_el_num = parseInt(item_num);

            if (this.getAtLeastOneInGroupHasHash(new_el_num)) {

                break;

            }

        }

        let selected_element_nums = this.getSelectedElementsNums(module_hash, new_el_num);

        for (let selected_element_num of selected_element_nums) {

            let element_progress = progress_elements[selected_element_num];

            if (!this.props.contents_data[element_progress.hash]) {

                if (!this.state.module_is_loading) {

                    await this.setState({module_is_loading: true});
                    this.props.setSelectedElementNum(new_el_num);

                }

                await this.props.getElementContent(element_progress.hash);

            }

            if (element_progress.type === 'task') {

                if (!this.props.current_element_learn[module_id] || !this.props.current_element_learn[module_id][element_progress.type + element_progress.id]) {

                    if (!this.state.module_is_loading) {

                        await this.setState({module_is_loading: true});
                        this.props.setSelectedElementNum(new_el_num);

                    }

                    await this.props.getElementLearnData(course_id, module_id, element_progress.id, element_progress.type);

                }

            }

        }

        await this.props.setSelectedElementNum(new_el_num);

        this.updatePageTitle();

        this.setState({
            is_loading: false,
            module_is_loading: false,
        }, () => {
            scroll && this.scrollToActiveElement();
        });

    }

    getSelectedElemNum() {
        const current_module_learn_elements = this.state.is_access_error ? [] : [...this.props.current_module_learn.elements];
        const match_element_id = `${this.props.match.params.element_id}`;
        const match_element_index = current_module_learn_elements.findIndex( ({id}) => `${id}` === match_element_id );
        return match_element_index;
    }

    async updateElementFromDiff(diff, solutionId) {

        let module_id = parseInt(this.props.match.params.module_id);


        let current_module_learn = this.props.current_module_learn;

        let element_learn;

        if (!this.props.current_element_learn[module_id] || !this.props.current_element_learn[module_id][diff.type + diff.id]) {

            for (let element of current_module_learn.elements) {

                if (element.id === diff.id) {

                    let taskNumber = element.taskNumber;
                    element_learn = clonedeep(element);
                    element_learn.taskNumber = taskNumber;
                    break;

                }

            }

        } else {

            let taskNumber = null;

            for (let element of current_module_learn.elements) {

                if (element.id === diff.id) {

                    taskNumber = element.taskNumber;
                    break;

                }

            }

            element_learn = clonedeep(this.props.current_element_learn[module_id][diff.type + diff.id]);
            element_learn.taskNumber = taskNumber; // TODO module element learn and current_element_learn are different!! Backend must send number

        }

        if (diff.analogicTaskOpened) {
            element_learn.analogicTaskOpened = diff.analogicTaskOpened;
        }

        if (diff.solution) {

            let answers = [];

            let review = null;

            if (diff.tutorComment !== null) {

                review = diff.tutorComment;

            }

            if (element_learn.solution) {

                answers = element_learn.solution.answers;

            }

            answers.unshift({
                id: solutionId,
                answer: diff.solution,
                time: diff.time,
                verdict: diff.verdict,
                review,
            });

            element_learn.solution = {answers};

        }

        if (diff.isAchieved !== null) {

            element_learn.isAchieved = diff.isAchieved;

        }

        if (diff.solutionToShow !== null) {

            element_learn.solution.solutionToShow = diff.solutionToShow;

        }


        if (diff.verdict !== null) {

            element_learn.verdict = diff.verdict;

        }

        if (diff.reviewStatus !== null) {

            element_learn.reviewStatus = diff.reviewStatus;

        }


        if (diff.isAvailable !== null) {
            // TODO?
        }

        if (diff.isClosed !== null) {

            element_learn.isClosed = diff.isClosed;

        }


        if (diff.score !== null) {
            // TODO?
        }


        if (diff.scoreFailed !== null) {
            // TODO?
        }


        if (diff.tries !== null) {

            element_learn.taskInfo.currentTry = diff.tries;

        }


        if (diff.newType !== null) {

            if (diff.type !== element_learn.type) {

                noo_actions.reportBug(
                    "old element type and new element type are not equal",
                    REPORT_BUG_LVL.WARNING,
                    {url: location.href},
                );

            }

            element_learn.type = diff.newType;

        }

        let oldId = null;

        if (diff.newId !== null) {

            oldId = diff.id;
            element_learn.id = diff.newId;

        }

        if (diff.newHash !== null) {

            element_learn.hash = diff.newHash;

        }

        if (diff.videosOpened !== null) {

            element_learn.videosOpened = diff.videosOpened;

        }

        if (diff.explanationsOpened !== null) {

            element_learn.explanationsOpened = diff.explanationsOpened;

        }


        if (diff.type !== "task") {
            // TODO?
        }

        await this.props.updateElementLearnData(element_learn.id, module_id, element_learn);

        let module_learn = clonedeep(current_module_learn);

        for (let i in module_learn.elements) {

            if (oldId && module_learn.elements[i].id === oldId) {

                module_learn.elements[i] = element_learn;
                break;

            } else if (module_learn.elements[i].id === element_learn.id) {

                module_learn.elements[i] = element_learn;
                break;

            }

        }

        await this.props.updateModuleLearnData(module_learn);

    }

    async updateModuleFromDiff(diff, solutionId) {

        if (diff.elements !== null) {

            for (let i in diff.elements) {

                await this.updateElementFromDiff(diff.elements[i], solutionId);

            }

        }

        let current_module_learn = this.props.current_module_learn;

        if (diff.isAchieved !== null) {

            current_module_learn.summary.isAchieved = diff.isAchieved;

        }


        if (diff.isAvailable !== null) {
            // TODO?
        }

        if (diff.isClosed !== null) {

            current_module_learn.summary.isClosed = diff.isClosed;

        }


        if (diff.progressCurrent !== null) {

            current_module_learn.summary.progressCurrent = diff.progressCurrent;

        }


        if (diff.progressFailed !== null) {

            current_module_learn.summary.progressFailed = diff.progressFailed;

        }


        if (diff.stepsDone !== null) {

            current_module_learn.summary.stepsDone = diff.stepsDone;

        }

        if (diff.isDone !== null) {

            current_module_learn.summary.isDone = diff.isDone;

        }

        await this.props.updateModuleLearnData(current_module_learn);

    }

    updateCourseFromDiff(diff, solutionId) {

        // diff.course[i].id
        if (diff.modulesAchieved !== null) {
            // TODO
        }

        if (diff.score !== null) {
            // TODO
        }


        if (diff.advancedModulesAchieved !== null) {
            // TODO
        }

        if (diff.modules !== null) {

            for (let i in diff.modules) {

                this.updateModuleFromDiff(diff.modules[i], solutionId);

            }

        }

    }

    updateStoreDataFromDiff(diff) {

        const {changes, id} = diff;

        if (changes.course) {

            for (let i in changes.course) {

                this.updateCourseFromDiff(changes.course[i], id);

            }

        }

    }


    getSelectedElementsNums(module_hash, selected_element_num = this.props.selected_element_num) {

        let groups = this.props.contents_data[module_hash].groups;

        let selected_element_nums = [selected_element_num];

        for (let group of groups) {

            if (group.from <= (selected_element_num + 1) && group.to >= (selected_element_num + 1)) {

                selected_element_nums = [];

                for (let ii = group.from - 1; ii <= group.to - 1; ii++) {

                    selected_element_nums.push(ii);

                }

            }

        }

        return selected_element_nums;

    }

    changeSelectedElement = async (el_num) => {

        if (!this.getAtLeastOneInGroupHasHash(el_num)) {

            return;

        }

        // await this.props.setSelectedElementNum(el_num);

        this.updateUrl(el_num);

    };

    updateUrl = (element_num) => {

        for (let [index, element] of this.props.current_module_learn.elements.entries()) {

            if (parseInt(element_num) === index) {

                if (parseInt(this.props.match.params.element_id) !== element.id || this.props.match.params.element_type !== element.type) {

                    // eslint-disable-next-line prefer-template
                    location.hash = "#/course/" + this.props.match.params.course_id + "/" + this.props.match.params.module_id
                        + "/" + element.type + "_" + element.id;

                }

                return;

            }

        }

    };


    goToNextElement(prev_elem_num) {

        let next_el_num = prev_elem_num + 1;

        let next_el = document.querySelector(`[data-el_num="${next_el_num}"]`);

        if (next_el) {

            window.scrollTo({top: next_el.offsetTop, behavior: 'smooth'});
            return;

        }

        if (this.isLastGroupOrElem()) {

            location.hash = `#/course/${this.props.match.params.course_id}`;

        }

        this.toNextGroup();

    }


    renderModuleContent() {

        let course_id = parseInt(this.props.match.params.course_id);
        let module_id = parseInt(this.props.match.params.module_id);
        let module_hash = this.props.current_module_learn.summary.hash;


        if (this.props.current_module_learn.summary.id !== module_id) {
            return null;
        }

        let selected_element_nums = this.getSelectedElementsNums(module_hash);

        let content = [];
        let selected_element_progress;

        let module_availability = null;

        if (this.props.current_module_learn.summary.availability) {

            module_availability = this.props.current_module_learn.summary.availability;

        }


        for (let i in selected_element_nums) {

            let learn_el_id = this.props.current_module_learn.elements[selected_element_nums[i]].type + this.props.current_module_learn.elements[selected_element_nums[i]].id;

            let task_number = null;

            if (this.props.current_element_learn[module_id] && this.props.current_element_learn[module_id][learn_el_id]) {

                selected_element_progress = this.props.current_element_learn[module_id][learn_el_id];

            } else {

                selected_element_progress = this.props.current_module_learn.elements[selected_element_nums[i]];

            }

            if (this.props.current_module_learn.elements[selected_element_nums[i]].taskNumber) {

                task_number = this.props.current_module_learn.elements[selected_element_nums[i]].taskNumber;

            }


            switch (selected_element_progress.type) {
                case 'text':

                    if (selected_element_progress.isClosed === false) {

                        if (!Helpers.isParentMode()) {

                            noo_actions.markTask(course_id, module_id, 'text', selected_element_progress.id)
                                .then((response_diff) => {

                                    this.updateStoreDataFromDiff(response_diff);

                                });

                        }

                    }

                    content.push(<ModuleTextContent
                        key={module_id + selected_element_nums[i]}
                        selected_element_num={selected_element_nums[i]}
                        module_id={module_id}
                        course_id={course_id}
                        element_progress={selected_element_progress}
                        element_content={this.props.contents_data[selected_element_progress.hash].text}
                    />);

                    break;
                case 'video':

                    if (selected_element_progress.isClosed === false) {

                        if (!Helpers.isParentMode()) {

                            noo_actions.markTask(course_id, module_id, 'video', selected_element_progress.id)
                                .then((response_diff) => {

                                    this.updateStoreDataFromDiff(response_diff);

                                });

                        }

                    }

                    content.push(
                        <ModuleVideoContent
                            key={module_id + selected_element_nums[i]}
                            selected_element_num={selected_element_nums[i]}
                            module_id={module_id}
                            course_id={course_id}
                            element_progress={selected_element_progress}
                            element_content={this.props.contents_data[selected_element_progress.hash].video}
                        />,
                    );
                    break;
                case 'task':
                    content.push(
                        <ModuleTaskContent
                            key={module_id + selected_element_nums[i]}
                            selected_element_num={selected_element_nums[i]}
                            module_id={module_id}
                            course_id={course_id}
                            module_availability={module_availability}
                            goToNextElement={this.goToNextElement.bind(this)}
                            updateStoreDataFromDiff={this.updateStoreDataFromDiff.bind(this)}
                            element_progress={selected_element_progress}
                            module_content={this.props.contents_data[module_hash]}
                            task_number={task_number}
                            module_element_learn={this.props.current_module_learn.elements[selected_element_nums[i]]}
                            element_content={this.props.contents_data[selected_element_progress.hash].task}
                        />,
                    );
                    break;
            }

        }


        if (selected_element_nums.length > 1) {

            content.push(
                <ModuleGroupNav
                    key={`nav_${selected_element_nums[0]}`}
                    toNextGroup={() => this.toNextGroup()}
                    setSelectedElementNum={this.changeSelectedElement}
                    scrollToElementByNum={this.scrollToElementByNum}
                    module_content={this.props.contents_data[module_hash]}
                    module_progress={this.props.current_module_learn}

                />,
            );

        }

        return content;

    }

    toPrevGroup() {

        let current_group = this.getGroupRange(this.props.selected_element_num);

        if (current_group[0] === 0) {

            return;

        }

        let candidate_group = this.getGroupRange(current_group[0] - 1);

        while (candidate_group[0] >= 0) {

            if (this.getAtLeastOneInGroupHasHash(candidate_group[0])) {

                break;

            }

            candidate_group = this.getGroupRange(candidate_group[0] - 1);

        }

        this.changeSelectedElement(candidate_group[0]);

    }

    toNextGroup() {


        if (this.isLastGroupOrElem()) {

            return;

        }

        let module_hash = this.props.current_module_learn.summary.hash;
        let groups = this.props.contents_data[module_hash].groups;

        for (let group of groups) {

            if (group.from - 1 <= this.props.selected_element_num && group.to - 1 >= this.props.selected_element_num) {

                if (this.getAtLeastOneInGroupHasHash(group.to)) {

                    this.changeSelectedElement(group.to);
                    return;

                }

            }

        }

        for (let item_num in this.props.contents_data[module_hash].variant) {

            item_num = parseInt(item_num);

            if (this.props.selected_element_num + 1 > item_num) {

                continue;

            }


            if (this.getAtLeastOneInGroupHasHash(item_num)) {

                this.changeSelectedElement(item_num);
                return;

            }

        }

    }


    getGroupRange(element_num) {

        let module_hash = this.props.current_module_learn.summary.hash;
        let groups = this.props.contents_data[module_hash].groups;

        for (let group of groups) {

            if (group.from - 1 <= element_num && group.to - 1 >= element_num) {

                return [group.from - 1, group.to - 1];

            }


        }

        return [element_num, element_num];

    }

    getAtLeastOneInGroupHasHash(element_num) {

        let range = this.getGroupRange(element_num);

        let elements_progress = this.props.current_module_learn.elements;

        for (let i = range[0]; i <= range[1]; i++) {

            if (elements_progress[i].hash) {

                return true;

            }

        }

        return false;

    }

    isLastGroupOrElem() {

        let module_hash = this.props.current_module_learn.summary.hash;

        let current_group = this.getGroupRange(this.props.selected_element_num);

        if (this.props.contents_data[module_hash].variant.length - 1 === this.props.selected_element_num) {

            return true;

        }

        if (current_group[1] + 1 === this.props.contents_data[module_hash].variant.length) {

            return true;

        }

        for (let item_num in this.props.contents_data[module_hash].variant) {

            item_num = parseInt(item_num);

            if (current_group[1] + 1 > item_num) {

                continue;

            }

            if (this.getAtLeastOneInGroupHasHash(item_num)) {

                return false;

            }

        }


        return true;

    }

    isFirstGroupOrElem() {


        let current_group = this.getGroupRange(this.props.selected_element_num);

        if (current_group[0] === 0) {

            return true;

        }

        let candidate_group = this.getGroupRange(current_group[0] - 1);

        while (candidate_group[0] >= 0) {

            if (this.getAtLeastOneInGroupHasHash(candidate_group[0])) {

                return false;

            }

            candidate_group = this.getGroupRange(candidate_group[0] - 1);

        }

        return true;

    }

    render() {
        const course_id = parseInt(this.props.match.params.course_id);
        const {is_loading, is_access_error} = this.state;
        const {current_module_learn} = this.props;


        if (is_loading) {

            return (<CircularProgress centerOfWindow={true} />);

        }

        if (is_access_error) {

            return (
                <ModuleAccessInfoPage
                    learnModuleError={current_module_learn}
                    backTo={`#/course/${course_id}`}
                />
            );

        }


        let module_hash = this.props.current_module_learn.summary.hash;

        let cheops_message = "";

        let short_message = "";

        if (this.props.current_module_learn.summary.availability.locked) {

            if (this.props.current_module_learn.summary.availability.locked.message) {

                cheops_message = this.props.current_module_learn.summary.availability.locked.message;

            }

            if (this.props.current_module_learn.summary.availability.locked.shortMessage) {

                short_message = this.props.current_module_learn.summary.availability.locked.shortMessage;

            }

        }

        let back_link = `/#/course/${course_id}`;

        if (this.props.contents_data[module_hash].info.type === "section") {

            back_link = `/#/course/${course_id}/section_${this.props.match.params.module_id}`;

        }

        const course_contents = this.props.course_contents[this.props.current_course.hash];
        const groups = this.props.contents_data[module_hash]?.groups;

        return (
            <div className="module_page">
                <ModulePassingHeader
                    fixed={true}
                    changeSelectedElementNum={(el_id) => {

                        location.hash = `#/course/${this.props.match.params.course_id}/${
                            this.props.match.params.module_id}/${this.props.current_module_learn.elements[el_id].type
                        }_${this.props.current_module_learn.elements[el_id].id}`;

                    }}
                    back_link={back_link}
                    hide_module_progress={this.props.contents_data[module_hash].info.displaySettings.hideProgress}
                    selected_element_num={this.props.selected_element_num}
                    module_content={this.props.contents_data[module_hash]}
                    module_progress={this.props.current_module_learn}
                    course_color={course_contents.color}
                />
                {this.state.module_is_loading
                && <div className="module_page__wrapper_content">
                    <div className="module_passing_content module_passing_content--loading">
                        <CircularProgress centerOfWindow={true} />
                    </div>
                </div>}
                {!this.state.module_is_loading
                && <div className="module_page__wrapper_content">


                    <TextbookStylesComplexityControls
                        course_contents      = {course_contents}
                        groups               = {groups}
                        params               = {this.props.match.params}
                        selected_element_num = {this.props.selected_element_num}
                        current_module_learn = {this.props.current_module_learn}
                    />

                    <div className="detailed_answer__content_title">
                        {this.props.contents_data[module_hash].info.title}
                    </div>

                    {this.renderModuleContent()}

                    {!this.isFirstGroupOrElem()
                    && <div className="module_page__to_prev" onClick={this.toPrevGroup.bind(this)} />}

                    {!this.isLastGroupOrElem()
                    && <div className="module_page__to_next" onClick={this.toNextGroup.bind(this)} />}

                </div>}

                {(cheops_message || short_message)
                && <div className="lock_bar">
                    {(this.state.lock_bar_expanded && cheops_message)
                        ? <>
                            <HBox center className='lock_bar__wrapper'>
                                <ProfCheopsGreeting>
                                    <Marked>{cheops_message}</Marked>
                                </ProfCheopsGreeting>
                            </HBox>
                            <div onClick={() => this.setState({lock_bar_expanded: false})} className="lock_bar__close">
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                     xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M13.3 0.709971C12.91 0.319971 12.28 0.319971 11.89 0.709971L6.99997 5.58997L2.10997 0.699971C1.71997 0.309971 1.08997 0.309971 0.699971 0.699971C0.309971 1.08997 0.309971 1.71997 0.699971 2.10997L5.58997 6.99997L0.699971 11.89C0.309971 12.28 0.309971 12.91 0.699971 13.3C1.08997 13.69 1.71997 13.69 2.10997 13.3L6.99997 8.40997L11.89 13.3C12.28 13.69 12.91 13.69 13.3 13.3C13.69 12.91 13.69 12.28 13.3 11.89L8.40997 6.99997L13.3 2.10997C13.68 1.72997 13.68 1.08997 13.3 0.709971Z"
                                        fill="#B0B4B9"
                                    />
                                </svg>
                            </div>
                        </>
                        : <>{short_message}</>}

                </div>}
            </div>);

    }

}

const mapStateToProps = (state) => {

    return {
        current_module_learn: state.nooReducer.current_module_learn,
        my_course_list: state.nooReducer.my_course_list,
        course_contents: state.nooReducer.course_contents,
        current_element_learn: state.nooReducer.current_element_learn,
        selected_element_num: state.passingReducer.selected_element_num,
        contents_data: state.nooReducer.contents_data,
        current_course: state.nooReducer.current_course,
    };

};

const mapDispatchToProps = (dispatch) => {

    return {
        updateElementLearnData: (element_id, module_id, data) => dispatch(noo_actions.updateElementLearnData(element_id, module_id, data)),
        updateModuleLearnData: (element_num) => dispatch(noo_actions.updateModuleLearnData(element_num)),
        setSelectedElementNum: (element_num) => dispatch(passing_actions.changeSelectedElementNum(element_num)),
        getMyCourseList: bindActionCreators(noo_actions.getMyCourseList, dispatch),
        getCourseData: bindActionCreators(noo_actions.getCourseData, dispatch),
        getCourseLearnData: bindActionCreators(noo_actions.getCourseLearnData, dispatch),
        getModuleLearnData: bindActionCreators(noo_actions.getModuleLearnData, dispatch),
        getCourseContent: bindActionCreators(noo_actions.getCourseContent, dispatch),
        getModuleContent: bindActionCreators(noo_actions.getModuleContent, dispatch),
        getElementContent: bindActionCreators(noo_actions.getElementContent, dispatch),
        getElementLearnData: bindActionCreators(noo_actions.getElementLearnData, dispatch),
    };

};

export default connect(mapStateToProps, mapDispatchToProps)(ModulePage);
