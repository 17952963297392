import React, {FC, useEffect} from "react";
import bnc                    from "bnc";
import MarkedWithVideo        from "Smt/MarkedWithVideo";
import {
    useVideoResolve,
    IUseVideoResolveArgs
}                             from "Smt/hooks/useVideoResolve";
import ElementInfoTogglers    from "Cheops/components/ModulePassing/ElementInfoTogglers";
import {HBox, VBox}           from "@sirius/ui-lib/src/blocks/Layout";
import {VideoPlayer}          from "@sirius/ui-shared/src/components/Video/Player";


const block         = new bnc("module-video-content");
const instance: IUseVideoResolveArgs["instance"] = CONFIG?.Instance ? `${CONFIG.Instance as IUseVideoResolveArgs["instance"]}` : null;

type strNumber = string | number;

interface Props {
    selected_element_num: strNumber;
    element_content: any;
    module_id: strNumber;
    course_id: strNumber;
    element_progress: NooBack.CourseProgress;
}
const  ModuleVideoContent: FC<Props> = (
    {
        selected_element_num,
        element_content,
        module_id,
        course_id,
        element_progress,
    }
) => {

    const {
        url,
        title,
        synopsis,
    } = element_content;

    const [videoContainer, getVideoContainer] = useVideoResolve({instance});

    useEffect(() => {
        getVideoContainer(url);
    }, [url]);

    return (
        <HBox className={block} data-el_num={selected_element_num}>
            <VBox className={block.el("wrapper")}>
                <HBox className={block.el("title")}>
                    {title}
                </HBox>
                <div
                    className={
                        block.el("inner").mod("video") +
                        block.el("inner").mod("w-full")
                    }
                >
                    {videoContainer?.url &&
                        <VideoPlayer
                            videoContainer={videoContainer as Common.Video.VideoResolveContainer}
                            disableContextMenu
                            disableDownload
                        />
                    }
                </div>
                <div className={block.el("inner")}>
                    <div className={block.el("inner").mod("description")}>
                        <MarkedWithVideo>
                            {synopsis}
                        </MarkedWithVideo>
                    </div>
                </div>
                <HBox center>
                    <ElementInfoTogglers
                        module_id={module_id}
                        course_id={course_id}
                        element_type='video'
                        element_content={element_content}
                        element_progress={element_progress}
                    />
                </HBox>
            </VBox>
        </HBox>
    );
}

export default ModuleVideoContent;

