import React from "react";
import {connect} from "react-redux";
import NotificationsList from "Cheops/components/Notifications/NotificationsList";
import {DEPLOY_NAME} from "Cheops/constants";
import LandingsHeader from "../components/Landings/LandingsHeader";
import {Footer} from "../components/Footer";
import {cluster} from "CheopsRoot/clusters/current";

class FeedbackPage extends React.Component {

    constructor(props) {

        super(props);


        let auth_token = props.auth_token;

        this.state = {
            user_is_anon: !auth_token,
            show_notifications: false,
        };

    }

    setShowNotifications(state) {

        this.setState({show_notifications: state});

    }

    copyMail() {

        let email = document.querySelector("#feedback_page__mail");
        email.select();
        document.execCommand('copy');

    }

    render() {

        return (
            <div className="certificate_page">
                {this.state.show_notifications
                && <NotificationsList hideNotifications={() => this.setShowNotifications(false)} />}
                <LandingsHeader
                    show_notifications={!this.state.user_is_anon}
                    showNotifications={() => this.setShowNotifications(true)}
                />
                <div className="certificate_page__content">
                    <div className="platform_landing_page__block_wrapper platform_landing_page__block_wrapper--feedback_page">
                        <div className="certificate_page__title feedback_page__title">
                            Возникла проблема? — нам важно это знать
                        </div>
                        <div className="feedback_page__pic" />
                        <div className="certificate_page__text">
                            <div className="feedback_page__text_row">Пишите — разберёмся</div>
                            <div className="feedback_page__text_row">
                                {CONFIG.DeployName !== DEPLOY_NAME.CPM
                                && <>
                                    <a className="feedback_page__email" href="mailto:help@sirius.online">
                                        help@sirius.online
                                    </a>
                                    <input id="feedback_page__mail"
                                           type="text"
                                           value="help@sirius.online"
                                           readOnly={true}
                                    />
                                </>}
                                {CONFIG.DeployName === DEPLOY_NAME.CPM
                                && <>
                                    <a className="feedback_page__email" href="mailto:help@sirius.online">
                                        math@mosolymp.ru
                                    </a>
                                    <input id="feedback_page__mail"
                                           type="text"
                                           value="math@mosolymp.ru"
                                           readOnly={true}
                                    />
                                </>}
                                <div className="feedback_page__text_row_frame_pic"
                                     title="Скопировать в буфер обмена"
                                     onClick={this.copyMail.bind(this)}
                                />
                            </div>
                            <div className="feedback_page__text_row">Информация поможет нам стать лучше</div>
                        </div>
                    </div>
                </div>
                {
                    cluster.type === "courses" &&
                    <Footer />
                }
            </div>
        );

    }

}


const mapStateToProps = (state) => ({
    auth_token: state.authReducer.auth_token,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackPage);
