
type ICurrentCourseLearn = NooBack.ModuleSummary & NooBack.Progress & NooBack.CourseProgress;
type TCourseId = number;

interface IGenLink {
    courseId: TCourseId,
    lastVisitedModule?: NooBack.Id,
    lastVisitedElement?: TLastVisitedElement
}

enum ETitleButton  {
    begin = 'Начать',
    proceed = 'Приступить',
    continue = 'Продолжить'
}

type TLastVisitedElement = {
    lastVisitedElementType: NooBack.ElementType,
    lastVisitedElement: NooBack.Id
}
type TFromPage =  "Course" | "Landing" | "Default";

type CourseContinueButtonProps = {
    readonly courseId: TCourseId;
    readonly currentCourseLearn: ICurrentCourseLearn;
    readonly fromPage? : TFromPage;
    readonly disabled?: boolean;
}

type CourseBackButtonProps = {
    readonly courseId: TCourseId;
    readonly fromPage? : TFromPage;
    readonly icon?: boolean;
    readonly disabled?: boolean;
}

export type {
    ICurrentCourseLearn,
    IGenLink,
    TLastVisitedElement,
    CourseContinueButtonProps,
    CourseBackButtonProps
};
export {
    ETitleButton,
};
