import React                    from "react";
import Helpers                  from "Cheops/Helpers";
import {connect}                from "react-redux";
import BemClassName             from "Cheops/BemClassName";
import CourseProgress           from "Cheops/components/CoursePage/CourseProgress";
import WidgetsMenuList          from "Cheops/components/WidgetsMenuList";
import {CourseContinueButton}     from "Cheops/components/CoursePage/CourseButton";
import {ym}                     from "../../Utils/MetrikaWrapper";
import                               "./LandingCourseCard.less";

class LandingsCourseCard extends React.Component {

    static COURSE_STATUS = {
        MY_COURSE: "my_course",
        INVITE: "invite",
        UPCOMING: "upcoming",
        AVAILABLE: "available",
        CLOSED: "closed",
    };

    static defaultProps = {
        course: {},
        progressMax: null,
        progressCurrent: null,
        advancedModulesTotal: null,
    };


    render() {

        const {
            hide_course_continue_button = false,
            course,
            courseCardClick
        } = this.props;
        const { chainName = 'unknown'} = course;

        let course_is_open = true;
        let now = new Date();
        let open_date = new Date(this.props.course.openDate);
        let close_date = new Date(this.props.course.closeDate);
        let attend_date = new Date(this.props.course.attendUntil);
        let time_options = {
            day: "numeric",
            month: "long",
            year: "numeric",

        };

        if (close_date && (close_date < now) && this.props.course_status !== LandingsCourseCard.COURSE_STATUS.INVITE) {

            course_is_open = false;

        }

        let open_date_out = open_date.toLocaleString("ru", time_options);
        let close_date_out = close_date.toLocaleString("ru", time_options);
        let attend_date_out = attend_date.toLocaleString("ru", time_options);

        let landing_course_card = new BemClassName("landing_course_card");

        landing_course_card.appendStatusIf(!!this.props.course_status, this.props.course_status);
        landing_course_card.appendStatusIf(!this.props.is_authorized, "non_authorized");
        landing_course_card.appendStatusIf(!course_is_open, "closed");
        landing_course_card.appendStatusIf(!!this.props.course.image && course_is_open && (this.props.course_status === LandingsCourseCard.COURSE_STATUS.MY_COURSE || this.props.course_status === LandingsCourseCard.COURSE_STATUS.INVITE), "has_img");

        let stats_fill = "rgba(255, 255, 255, 0.5)";
        let landing_course_card__date_class = new BemClassName("landing_course_card__date");
        landing_course_card__date_class.appendStatusIf(this.props.course_status === LandingsCourseCard.COURSE_STATUS.MY_COURSE, "my");
        landing_course_card__date_class.appendStatusIf(!course_is_open, "closed");

        if (!course_is_open) {

            stats_fill = "#C6C2CC";

        }

        let style = {};

        if ((this.props.course_status === LandingsCourseCard.COURSE_STATUS.AVAILABLE || this.props.course_status === LandingsCourseCard.COURSE_STATUS.MY_COURSE || this.props.course_status === LandingsCourseCard.COURSE_STATUS.INVITE)) {

            if (course_is_open) {

                style.backgroundColor = this.props.course.bgColor;

            }

        }

        let course_date_text = "";


        if (this.props.course.openDate && open_date > now) {

            course_date_text = `Старт ${open_date_out}`;

        }

        if (this.props.course.attendUntil && attend_date > now) {

            course_date_text = `Набор до ${attend_date_out}`;

            if (this.props.course_status === LandingsCourseCard.COURSE_STATUS.MY_COURSE) {

                course_date_text = this.props.course.closeDate
                    ? `До ${close_date_out}`
                    : ''
                ;
            }

        }

        if (!this.props.course.attendUntil && this.props.course.closeDate && close_date >= now) {

            course_date_text = `Набор до ${close_date_out}`;

            if (this.props.course_status === LandingsCourseCard.COURSE_STATUS.MY_COURSE) {

                course_date_text = `До ${close_date_out}`;

            }

        }

        if (this.props.course.attendUntil && (attend_date <= now) && (!this.props.course.closeDate || (this.props.course.closeDate && (close_date > now)))) {

            course_date_text = "Набор завершён";

        }

        if (close_date && (close_date <= now)) {

            course_date_text = `Завершён ${close_date_out}`;

            if (this.props.course_status === LandingsCourseCard.COURSE_STATUS.MY_COURSE) {

                course_date_text = `Закрыт ${close_date_out}`;

            }

        }

        if (this.props.course_status === LandingsCourseCard.COURSE_STATUS.INVITE) {

            course_date_text = "Вы приглашены в курс";

        }

        let course_date = <div className={landing_course_card__date_class}>{course_date_text}</div>;

        let course_title = this.props.course.name;
        if (this.props.course.hasInvitation && this.props.course_status === LandingsCourseCard.COURSE_STATUS.AVAILABLE) {

            course_title = <span>
                <span className="landing_course_card__title_invitation">
                    Приглашение.
                </span>
                {` ${this.props.course.name}`}
            </span>;

        }

        const onClick = () => {
            const goalParams = {};
            const reachGoalTpl = `noo.CoursePage.chain.${chainName}`;

            setTimeout (courseCardClick, 500);
            ym('reachGoal',reachGoalTpl, goalParams, courseCardClick);
        }

        return (
            <div
                className={landing_course_card}
                style={style}
                onClick={onClick}
            >
                {this.props.course.image && course_is_open
                && (
                    this.props.course_status === LandingsCourseCard.COURSE_STATUS.INVITE
                    || this.props.course_status === LandingsCourseCard.COURSE_STATUS.MY_COURSE
                )
                && <img className="landing_course_card__img" src={this.props.course.image} />}

                <div className="landing_course_card__wrapper">

                    {!(this.props.course_status === LandingsCourseCard.COURSE_STATUS.MY_COURSE) && course_date}

                    {(this.props.course_status === LandingsCourseCard.COURSE_STATUS.MY_COURSE)
                    && !!this.props.course.progress
                    && !!this.props.course.courseContentProgress
                    && <div className="landing_course_card__progress">
                        <div className='landing_course_card__progress_lines'>
                            <CourseProgress
                                courseProgress={this.props.course.courseContentProgress.progress}
                                courseContentProgress={this.props.course.progress}
                            />
                        </div>

                        {this.props.course.courseContentProgress
                        && this.props.course.courseContentProgress.advancedModulesTotal > 0
                        && <div className='landing_course_card__progress_starred' title="Зачтённые лекционные модули">
                            <svg width="16"
                                 height="15"
                                 viewBox="0 0 16 15"
                                 fill="none"
                                 xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M8 12.3917L11.4583 14.4834C12.0917 14.8667 12.8667 14.3 12.7 13.5834L11.7833 9.65003L14.8417 7.00003C15.4 6.5167 15.1 5.60003 14.3667 5.5417L10.3417 5.20003L8.76667 1.48336C8.48333 0.808362 7.51667 0.808362 7.23333 1.48336L5.65833 5.1917L1.63333 5.53336C0.9 5.5917 0.599999 6.50836 1.15833 6.9917L4.21667 9.6417L3.3 13.575C3.13333 14.2917 3.90833 14.8584 4.54167 14.475L8 12.3917Z"
                                    fill="#88CC00"
                                />
                            </svg>
                            <div className="landing_course_card__progress_starred_numbers">
                                {this.props.course.courseContentProgress.advancedModulesAchieved}
                                /
                                {this.props.course.courseContentProgress.advancedModulesTotal}
                            </div>
                        </div>}
                    </div>}

                    <div className="landing_course_card__title">{course_title}</div>

                    {(this.props.course_status === LandingsCourseCard.COURSE_STATUS.MY_COURSE)
                    && <>
                        <div className="landing_course_card__menu">
                            <WidgetsMenuList course_contents={this.props.course}
                                             course_id={this.props.course.id}
                                             short_menu={true}
                            />
                        </div>
                    </>}

                    {!(this.props.course_status === LandingsCourseCard.COURSE_STATUS.UPCOMING)
                    && !(this.props.course_status === LandingsCourseCard.COURSE_STATUS.MY_COURSE)
                    && !(this.props.course_status === LandingsCourseCard.COURSE_STATUS.INVITE)
                    && <div className="landing_course_card__content">
                        {`${this.props.course.modulesCount} `}
                        {Helpers.formatPlural(this.props.course.modulesCount, ["модуль", "модуля", "модулей"])}
                        {this.props.course.lecturesCount > 0 && `, ${this.props.course.lecturesCount} `}
                        {this.props.course.lecturesCount > 0 && Helpers.formatPlural(this.props.course.lecturesCount, ["видеолекция", "видеолекции", "видеолекций"])}
                        {this.props.course.tasksCount > 0 && `, ${this.props.course.tasksCount} `}
                        {this.props.course.tasksCount > 0 && Helpers.formatPlural(this.props.course.tasksCount, ["задача", "задачи", "задач"])}
                        {this.props.course.lecturers
                        && <div>
                            {this.props.course.lecturers.map((lecturer, i) => {

                                if (i > 4) {

                                    return null;

                                }

                                let name = lecturer.lastName;

                                if (lecturer.firstName) {

                                    name += ` ${lecturer.firstName.charAt(0).toUpperCase()}.`;

                                }

                                if (lecturer.middleName) {

                                    name += ` ${lecturer.middleName.charAt(0).toUpperCase()}.`;

                                }

                                if (i < this.props.course.lecturers.length - 1) {

                                    name += ", ";

                                }

                                return <span key={i}>{name}</span>;

                            })}
                        </div>}
                    </div>}
                </div>

                { !hide_course_continue_button
                && this.props.course_status === LandingsCourseCard.COURSE_STATUS.MY_COURSE
                && this.props.course.courseContentProgress
                && course_is_open
                && <CourseContinueButton currentCourseLearn={this.props.course.courseContentProgress}
                                         courseId={this.props.course.id}
                                         fromPage={'Landing'}
                />}

                <div className="landing_course_card__stats">
                    {this.props.course.stat
                    && <>
                        {!!this.props.course.stat.inProgress
                        && <div className="landing_course_card__stats_block">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                 xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M7 9.75C6.31 9.75 5.75 10.31 5.75 11C5.75 11.69 6.31 12.25 7 12.25C7.69 12.25 8.25 11.69 8.25 11C8.25 10.31 7.69 9.75 7 9.75ZM13 9.75C12.31 9.75 11.75 10.31 11.75 11C11.75 11.69 12.31 12.25 13 12.25C13.69 12.25 14.25 11.69 14.25 11C14.25 10.31 13.69 9.75 13 9.75ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 9.71 2.02 9.42 2.05 9.14C4.41 8.09 6.28 6.16 7.26 3.77C9.07 6.33 12.05 8 15.42 8C16.2 8 16.95 7.91 17.67 7.74C17.88 8.45 18 9.21 18 10C18 14.41 14.41 18 10 18Z"
                                    fill={stats_fill}
                                />
                            </svg>
                            <div className="landing_course_card__stats_block_text">
                                {this.props.course.stat.inProgress.toLocaleString("ru-ru")}
                            </div>
                        </div>}
                        {!!this.props.course.stat.achieved
                        && <div className="landing_course_card__stats_block">
                            <svg width="21" height="18" viewBox="0 0 21 18" fill="none"
                                 xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M3.00027 10.18V12.99C3.00027 13.72 3.40027 14.4 4.04027 14.75L9.04027 17.48C9.64027 17.81 10.3603 17.81 10.9603 17.48L15.9603 14.75C16.6003 14.4 17.0003 13.72 17.0003 12.99V10.18L10.9603 13.48C10.3603 13.81 9.64027 13.81 9.04027 13.48L3.00027 10.18ZM9.04027 0.520029L0.610273 5.12003C-0.0797266 5.50003 -0.0797266 6.50003 0.610273 6.88003L9.04027 11.48C9.64027 11.81 10.3603 11.81 10.9603 11.48L19.0003 7.09003V13C19.0003 13.55 19.4503 14 20.0003 14C20.5503 14 21.0003 13.55 21.0003 13V6.59003C21.0003 6.22003 20.8003 5.89003 20.4803 5.71003L10.9603 0.520029C10.3603 0.200029 9.64027 0.200029 9.04027 0.520029Z"
                                    fill={stats_fill}
                                />
                            </svg>
                            <div className="landing_course_card__stats_block_text">
                                {this.props.course.stat.achieved.toLocaleString("ru-ru")}
                            </div>
                        </div>}
                    </>}

                    {(this.props.course_status === LandingsCourseCard.COURSE_STATUS.MY_COURSE) && course_date}

                </div>

                {(this.props.course_status === LandingsCourseCard.COURSE_STATUS.AVAILABLE
                    || this.props.course_status === LandingsCourseCard.COURSE_STATUS.UPCOMING
                    || this.props.course_status === LandingsCourseCard.COURSE_STATUS.INVITE)
                && <div className="landing_course_card__button">Подробнее</div>}
            </div>);

    }

}

const mapStateToProps = () => ({});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(LandingsCourseCard);
