import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as noo_actions from "Cheops/actions/noopolis";
import CourseLandingPage from "Cheops/containers/CourseLandingPage";
import UserCoursePage from "Cheops/containers/UserCoursePage";
import CircularProgress from "Smt/Ui";

class CoursePage extends React.Component {

    constructor(props) {

        super(props);


        this.state = {
            is_loading: true,
        };

    }

    componentWillMount() {

        this.updateCourseData(parseInt(this.props.match.params.course_id));

    }

    async componentWillReceiveProps({match, history}) {

        if (
            (this.props.match.params.course_id !== match.params.course_id)
            || history.location.force_refresh_data
        ) {

            delete history.location.force_refresh_data;

            this.updateCourseData(parseInt(match.params.course_id));

        }

    }


    updateCourseData = async (course_id) => {

        this.setState({is_loading: true});

        let is_user_course = false;


        await this.props.getCourseLearnData(course_id);

        if (this.props.current_course_learn.isAttended) {

            is_user_course = true;

        }

        await this.props.getCourseData(this.props.match.params.course_id);

        if (is_user_course) {

            if (this.props.current_course_learn.hash) {

                await this.props.getCourseContent(this.props.current_course_learn.hash);

            }

        }

        this.setState({is_user_course, is_loading: false});

    };


    render() {

        if (this.state.is_loading) {

            return (<CircularProgress centerOfWindow={true} />);

        }

        if (this.state.is_user_course) {

            return <UserCoursePage updateCourseData = {this.updateCourseData}
                                   course_id        = {this.props.match.params.course_id}
                                   chapter_num      = {this.props.match.params.chapter_num}
            />;

        }

        return <CourseLandingPage history={this.props.history} />;

    }

}

const mapStateToProps = (state) => ({
    current_course: state.nooReducer.current_course,
    current_course_learn: state.nooReducer.current_course_learn,
    course_contents: state.nooReducer.course_contents,
    auth_token: state.authReducer.auth_token,
});

const mapDispatchToProps = (dispatch) => ({
    getCourseData: bindActionCreators(noo_actions.getCourseData, dispatch),
    getCourseLearnData: bindActionCreators(noo_actions.getCourseLearnData, dispatch),
    getCourseContent: bindActionCreators(noo_actions.getCourseContent, dispatch),
});


export default connect(mapStateToProps, mapDispatchToProps)(CoursePage);
