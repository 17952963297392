import React from "react";
import bnc   from "bnc";
import            "./ProgressCircle.less";

const block = new bnc("progress");

export default class ProgressCircle extends React.Component {


    static COLOR_GREEN = "#88CC00";
    static COLOR_RED = "#FF5E73";
    static COLOR_WHITE = "rgba(255,255,255,.2)";
    static COLOR_BLACK = "#18181A";
    static COLOR_FOR_WHITE_BG = "rgba(0, 0, 0, 0.12)";


    static ICON_STAR = 1;
    static ICON_DOT = 3;
    static ICON_LOCK = 5;
    static ICON_CHEKMARK = 6;
    static ICON_MINUS = 7;

    static BG_TYPE_BIG = 1;
    static BG_TYPE_BIG_STAR = 2;
    static BG_TYPE_SMALL = 3;
    static BG_TYPE_SMALL_STAR = 4;


    static defaultProps = {
        starred: false,
        progress: null,
        forceShowBorder: false,
        onClick: null,
        hideProgress: false,
        whiteBg: false
    };


    render() {

        const {
            progress,
            whiteBg,
            onClick,
            forceShowBorder,
            starred,
            hideProgress
        } = this.props;

        let progress_obj = progress;


        if (!progress_obj) {

            let type_of = progress === null ? 'null' : typeof progress_obj;
            throw Error(`Expected object but ${type_of} given`);

        }


        let dash_array = 2 * Math.PI * 10; // 10 is radius

        let background_type = false;
        let background_color = whiteBg ? ProgressCircle.COLOR_FOR_WHITE_BG : ProgressCircle.COLOR_WHITE;


        let show_progress = true;

        let fill_percent = 0;
        let fill_need_to_next_percent = 0;
        let fill_failed_percent = 0;

        let icon = starred ? ProgressCircle.ICON_STAR : ProgressCircle.ICON_DOT;
        let icon_color = whiteBg ? ProgressCircle.COLOR_FOR_WHITE_BG : ProgressCircle.COLOR_WHITE;

        let corner_icon = null;

        if (!progress_obj.hash) {

            corner_icon = ProgressCircle.ICON_LOCK;


            if (progress_obj.isAchieved) {

                icon_color = ProgressCircle.COLOR_GREEN;

            } else {

                background_type = starred ? ProgressCircle.BG_TYPE_BIG_STAR : ProgressCircle.BG_TYPE_BIG;
                show_progress = false;
                icon = null;

            }

        } else {


            if (progress_obj.isClosed) {

                icon_color = ProgressCircle.COLOR_RED;
                corner_icon = progress_obj.isAchieved ? ProgressCircle.ICON_CHEKMARK : ProgressCircle.ICON_MINUS;
                // background_type = starred ? ProgressCircle.BG_TYPE_SMALL_STAR : ProgressCircle.BG_TYPE_SMALL;
                // background_color = ProgressCircle.COLOR_RED;
                // icon = null;

                if (!progress_obj.isDone) {

                    background_type = starred ? ProgressCircle.BG_TYPE_SMALL_STAR : ProgressCircle.BG_TYPE_SMALL;
                    background_color = ProgressCircle.COLOR_RED;

                }

            }

            if (progress_obj.isAchieved) {

                icon_color = ProgressCircle.COLOR_GREEN;

            }

            if (progress_obj.isDone) {

                background_type = starred ? ProgressCircle.BG_TYPE_SMALL_STAR : ProgressCircle.BG_TYPE_SMALL;
                background_color = ProgressCircle.COLOR_GREEN;

            }

        }


        if (show_progress) {

            let failed_percent = Math.round(progress_obj.progressFailed / progress_obj.progressMax * 100);
            let percent = Math.round(progress_obj.progressCurrent / progress_obj.progressMax * 100);
            let need_to_next_percent = Math.round(progress_obj.percentToPass);

            if (hideProgress) {

                percent = Math.round(progress_obj.stepsDone / progress_obj.stepsMax * 100);

            }

            fill_percent = (dash_array - (dash_array * (percent / 100))).toFixed(3);

            if (fill_percent < 0) {

                fill_percent = 0;

            }

            fill_need_to_next_percent = (dash_array - (dash_array * (need_to_next_percent / 100))).toFixed(3);

            if (failed_percent > 0 && !hideProgress) {

                fill_failed_percent = (dash_array + (dash_array * (failed_percent / 100))).toFixed(3);

            }

        }

        if (background_color === icon_color && starred) {

            icon_color = whiteBg ? ProgressCircle.COLOR_FOR_WHITE_BG : ProgressCircle.COLOR_WHITE;

        }

        return (
            <svg width     = {36}
                 height    = {36}
                 viewBox   = "0 0 36 36"
                 xmlns     = "http://www.w3.org/2000/svg"
                 onClick   = {onClick}
                 className = {block + block.bod('clickable', !!onClick)}
            >

                {show_progress
                && <>
                    <circle cx="18" cy="18" r="10" fill="transparent"
                            strokeDashoffset={fill_need_to_next_percent}
                            strokeDasharray={dash_array}
                            strokeWidth="4"
                            strokeLinecap="round"
                            stroke={whiteBg ? ProgressCircle.COLOR_FOR_WHITE_BG : ProgressCircle.COLOR_WHITE}
                            transform="rotate(-90 18 18)"
                    />

                    {fill_failed_percent > 0
                    && <circle cx="18" cy="18" r="10" fill="transparent"
                               strokeDashoffset={fill_failed_percent}
                               strokeDasharray={dash_array}
                               strokeWidth="4"
                               strokeLinecap="round"
                               stroke={ProgressCircle.COLOR_RED}
                               transform="rotate(-90 18 18)"
                    />}

                    <circle cx="18" cy="18" r="10" fill="transparent"
                            strokeDashoffset={fill_percent}
                            strokeDasharray={dash_array}
                            strokeWidth="4"
                            strokeLinecap="round"
                            stroke={ProgressCircle.COLOR_GREEN}
                            transform="rotate(-90 18 18)"
                    />
                </>}

                {(show_progress || forceShowBorder)
                && <circle opacity="0.2" cx="18" cy="18" r="15.5" stroke={whiteBg ? "rgb(0, 0, 0)" : "#ffffff"} fill="none" />}


                {background_type === ProgressCircle.BG_TYPE_BIG
                && <circle cx="18" cy="18" r="12" fill={background_color} />}

                {background_type === ProgressCircle.BG_TYPE_BIG_STAR
                && <path fillRule="evenodd" clipRule="evenodd"
                         d="M18 30C24.6274 30 30 24.6274 30 18C30 11.3726 24.6274 6 18 6C11.3726 6 6 11.3726 6 18C6 24.6274 11.3726 30 18 30ZM20.9099 23.3644L18 21.6045L15.0901 23.3574C14.5572 23.68 13.9051 23.2032 14.0453 22.6001L14.8166 19.2906L12.2433 17.0608C11.7735 16.6541 12.0259 15.8828 12.643 15.8337L16.0297 15.5462L17.3549 12.426C17.5933 11.858 18.4067 11.858 18.6451 12.426L19.9703 15.5532L23.357 15.8407C23.9741 15.8898 24.2265 16.6611 23.7567 17.0678L21.1834 19.2976L21.9547 22.6072C22.0949 23.2102 21.4428 23.687 20.9099 23.3644Z"
                         fill={background_color}
                />}

                {background_type === ProgressCircle.BG_TYPE_SMALL
                && <circle cx="18" cy="18" r="8.5" fill={background_color} filter="url(#filter1_d)" />}

                {background_type === ProgressCircle.BG_TYPE_SMALL_STAR
                && <path fillRule="evenodd" clipRule="evenodd"
                         d="M18 26C22.4183 26 26 22.4183 26 18C26 13.5817 22.4183 10 18 10C13.5817 10 10 13.5817 10 18C10 22.4183 13.5817 26 18 26ZM20.4249 22.4704L18 21.0037L15.5751 22.4645C15.131 22.7333 14.5876 22.336 14.7044 21.8335L15.3472 19.0755L13.2027 17.2173C12.8112 16.8784 13.0216 16.2357 13.5358 16.1948L16.3581 15.9552L17.4624 13.355C17.6611 12.8817 18.3389 12.8817 18.5376 13.355L19.6419 15.961L22.4642 16.2006C22.9784 16.2415 23.1888 16.8843 22.7973 17.2232L20.6528 19.0813L21.2956 21.8393C21.4124 22.3418 20.869 22.7391 20.4249 22.4704Z"
                         fill={background_color} filter="url(#filter1_d)"
                />}

                {icon === ProgressCircle.ICON_STAR
                && <path
                    d="M18 21.6045L20.9099 23.3644C21.4428 23.687 22.0949 23.2102 21.9547 22.6072L21.1834 19.2976L23.7567 17.0678C24.2265 16.6611 23.9741 15.8898 23.357 15.8407L19.9703 15.5532L18.6451 12.426C18.4067 11.858 17.5933 11.858 17.3549 12.426L16.0297 15.5462L12.643 15.8337C12.0259 15.8828 11.7735 16.6541 12.2433 17.0608L14.8166 19.2906L14.0453 22.6001C13.9051 23.2032 14.5572 23.68 15.0901 23.3574L18 21.6045Z"
                    fill={icon_color}
                />}

                {icon === ProgressCircle.ICON_DOT
                && <circle cx="18" cy="18" r="5" fill={icon_color} />}


                {corner_icon === ProgressCircle.ICON_LOCK
                && <path fillRule="evenodd" clipRule="evenodd"
                         d="M29.5 6V7H26.5V6C26.5 5.17157 27.1716 4.5 28 4.5C28.8284 4.5 29.5 5.17157 29.5 6ZM32 8C32 7.44772 31.5523 7 31 7V6C31 4.34315 29.6569 3 28 3C26.3431 3 25 4.34315 25 6V7C24.4477 7 24 7.44772 24 8V12C24 12.5523 24.4477 13 25 13H31C31.5523 13 32 12.5523 32 12V8ZM28 9C27.4477 9 27 9.44772 27 10C27 10.5523 27.4477 11 28 11C28.5523 11 29 10.5523 29 10C29 9.44772 28.5523 9 28 9Z"
                         fill="#737480"
                />}

                {corner_icon === ProgressCircle.ICON_CHEKMARK
                && <>
                    <circle cx="28" cy="9" r="7" fill="#88CC00" filter="url(#filter0_d)" />
                    <path d="M25 9L27 11L31 7" stroke="#22232E" strokeWidth="2" strokeLinecap="round" fill="none" />
                </>}

                {corner_icon === ProgressCircle.ICON_MINUS
                && <>
                    <circle cx="28" cy="9" r="7" fill="#FF5E73" filter="url(#filter0_d)" />
                    <path d="M25 9L31 9" stroke="#22232E" strokeWidth="2" strokeLinecap="round" />
                </>}

                <defs>
                    <filter id="filter0_d" x="17" y="0" width="22" height="22" filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                    >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" type="matrix"
                                       values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        />
                        <feOffset dy="2" />
                        <feGaussianBlur stdDeviation="2" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
                    </filter>
                    <filter id="filter1_d" x="6" y="8" width="24" height="24" filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                    >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix in="SourceAlpha" type="matrix"
                                       values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        />
                        <feOffset dy="2" />
                        <feGaussianBlur stdDeviation="2" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0" />
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
                    </filter>
                </defs>

            </svg>);

    }

}
