import React from "react";
import {connect} from "react-redux";
import NotificationsList from "Cheops/components/Notifications/NotificationsList";
import {bindActionCreators} from "redux";
import * as noo_actions from "Cheops/actions/noopolis";
import CircularProgress from "Smt/Ui";
import Helpers from "Cheops/Helpers";
import * as auth_actions from "Cheops/actions/auth";
import TextField, {Input} from '@material/react-text-field';
import {Footer} from "../components/Footer";
import LandingsHeader from "../components/Landings/LandingsHeader";

class CertificatesFormPage extends React.Component {

    constructor(props) {

        super(props);

        document.title = `Проверка личной информации - ${Helpers.getInstanceTitleForCurrentDeploy()}`;

        this.state = {
            show_notifications: false,
            is_loading: true,
            form_data: {
                firstName: "",
                lastName: "",
                middleName: "",
                comment: "",
            },
        };

    }


    async componentWillMount() {

        if (!this.props.certificates.dataStatus) {

            await this.props.getCertificates();

        }

        let status = Object.keys(this.props.certificates.dataStatus)[0];

        if (['pending', 'rejected', 'verified'].includes(status) || Helpers.isParentMode()) {

            location.href = "/#/certificate";
            return;

        }

        let form_data = this.state.form_data;

        let data_status = this.props.certificates.dataStatus[status];


        form_data.firstName = data_status.firstName ? data_status.firstName : auth_actions.getUserInfo('firstName');
        form_data.lastName = data_status.lastName ? data_status.lastName : auth_actions.getUserInfo('lastName');
        form_data.middleName = data_status.middleName ? data_status.middleName : auth_actions.getUserInfo('middleName');

        this.setState({is_loading: false});

    }


    changeFormInput(name, value) {

        let form_data = this.state.form_data;

        form_data[name] = value;

        this.setState({form_data});

    }


    validateForm() {

        return this.state.form_data.firstName && this.state.form_data.lastName;

    }

    async sendForm() {

        if (!this.validateForm()) {

            return;

        }

        let data = {...this.state.form_data};

        for (let i in data) {

            if (data[i] === '') {

                data[i] = null;

            }

        }

        let result = await noo_actions.sendCertificateFormData(data);

        if (result.status === 'error') {

            Helpers.render500();
            return;

        }

        if (result.status === 'success') {

            let certificate_id = sessionStorage.getItem('certificate_form-certificate_id');

            if (certificate_id) {

                await noo_actions.requestCertificate(certificate_id);

            }

            location.href = "/#/certificate";

        }

    }

    render() {

        if (this.state.is_loading) {

            return (<CircularProgress centerOfWindow={true} />);

        }

        let status = Object.keys(this.props.certificates.dataStatus)[0];

        return (<div className="certificates_page">
            {this.state.show_notifications
            && <NotificationsList hideNotifications={() => this.setState({show_notifications: false})} />}

            <LandingsHeader showNotifications={() => this.setState({show_notifications: true})}
                            back_path="/#/certificate"
                            land_header_with_bg={true}
            />

            <div className="certificates_page__content">
                <div className="certificates_page__block_wrapper">
                    <div className="certificates_page_form">
                        <div className="certificates_page_form__title">
                            Проверьте информацию о себе, чтобы мы правильно оформили сертификат
                        </div>
                        <div className="certificates_page_form__subtitle">Мы сохраним изменения в ваш профиль</div>
                        <TextField className="certificates_page_form__input_text"
                                   outlined
                                   label='Фамилия*'
                        >
                            <Input type="text"
                                   value={this.state.form_data.lastName}
                                   onChange={(e) => this.changeFormInput('lastName', e.target.value)}
                            />
                        </TextField>
                        <TextField className="certificates_page_form__input_text"
                                   outlined
                                   label='Имя*'
                        >
                            <Input type="text"
                                   value={this.state.form_data.firstName}
                                   onChange={(e) => this.changeFormInput('firstName', e.target.value)}
                            />
                        </TextField>
                        <TextField className="certificates_page_form__input_text"
                                   outlined
                                   label='Отчество, если есть'
                        >
                            <Input type="text"
                                   value={this.state.form_data.middleName}
                                   onChange={(e) => this.changeFormInput('middleName', e.target.value)}
                            />
                        </TextField>

                        {status === 'declined'
                        && <div>

                            {this.props.certificates.dataStatus[status].reason
                            && <div className="certificates_page_form__answer">
                                <div>Проф. Хеопс</div>
                                {this.props.certificates.dataStatus[status].reason}
                            </div>}

                            <TextField
                                className="certificates_page_form__input_text certificates_page_form__input_text--textarea"
                                outlined
                                textarea
                                label='Ваш ответ'
                            >
                                <Input type="text"
                                       value={this.state.form_data.comment}
                                       rows={10}
                                       onChange={(e) => this.changeFormInput('comment', e.target.value)}
                                />
                            </TextField>
                        </div>}
                        <div
                            className={`certificates_page_form__btn certificates_page_form__btn--${this.validateForm() ? "enabled" : "disabled"}`}
                            onClick={() => this.sendForm()}
                        >
                            <div>Заказать сертификат</div>
                        </div>
                        <div className="certificates_page_form__agreement_text">
                            Я даю согласие на обработку персональных данных в соответствии с требованиями
                            {' '}
                            <nobr>152-ФЗ</nobr>
                            {' и '}
                            <nobr>242-ФЗ</nobr>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>);

    }

}


const mapStateToProps = (state) => ({
    certificates: state.nooReducer.certificates,
});

const mapDispatchToProps = (dispatch) => ({
    getCertificates: bindActionCreators(noo_actions.getCertificates, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CertificatesFormPage);
