import React, {FC}                from "react";
import {ChainsCourse}             from "./ChainsCourse";
import bnc                        from "bnc";
import {LandingBlockChainsProps}        from "Cheops/components/Landings/ChainsCourse/@types";

const block = new bnc('platform_landing_page');

export const LandingBlockChains: FC<LandingBlockChainsProps> = (props) => {

    return  (
        <>
            <div className={block.el('courses_title')}>
                Скоро
            </div>

            <div
                className={block.el('courses_content_wrapper')}
                data-id="chains"
            >
                <ChainsCourse
                    {...props}
                />
            </div>
        </>
    )
}
