import React from "react";
import Axios from "axios";
import BemClassName from "Cheops/BemClassName";
import ColorUtils from "Cheops/Utils/ColorUtils";
import clonedeep from "lodash/cloneDeep";


import "./ElementNavItem.less";
import SolveResult from "Cheops/components/ModulePassing/SolveResult";

export default class ElementNavItem extends React.Component {

    static defaultProps = {
        course_color: null,
        active: false,
        show_group_summary: false,
        forceShowNumbers: false,
        element_index: null,
        module_content: {},
        module_progress: {
            elements: {},
            summary: {},
        },
        onClick: null,
    };


    constructor(props) {

        super(props);

        this.state = {
            module_variants: [],
            is_loading: true,
        };

    }

    async componentDidMount() {

        await this.updateModuleVariants(this.props.module_content);

    }

    async componentDidUpdate(prevProps) {

        if (!Object.compare(prevProps.module_progress, this.props.module_progress)) {

            await this.updateModuleVariants(this.props.module_content);

        }

    }

    async getIcon(element_index) {

        let elements_progress = this.props.module_progress.elements;

        let show_checkmark = true;

        let first_task_index = null;
        let first_video_index = null;
        let first_text_index = null;

        let range = this.getGroupRange(element_index);

        let all_is_advanced = true;

        for (let index = range[0]; index <= range[1]; index++) {

            if (elements_progress[index].type === 'task' && !first_task_index) {

                first_task_index = index;

            }

            if (elements_progress[index].type === 'video' && !first_video_index) {

                first_video_index = index;

            }

            if (elements_progress[index].type === 'text' && !first_text_index) {

                first_text_index = index;

            }

            if (elements_progress[index].isClosed === false) {

                show_checkmark = false;

            }

            if (this.props.module_content.variant[index].value[0].element.isAdvanced === false) {

                all_is_advanced = false;

            }

        }

        if (first_task_index === null || all_is_advanced) {

            show_checkmark = false;

        }


        let {icon} = this.props.module_content.variant[range[0]];


        if (first_video_index !== null) {

            icon = this.props.module_content.variant[first_video_index].icon;

        }

        if (first_text_index !== null) {

            icon = this.props.module_content.variant[first_text_index].icon;

        }

        if (first_task_index !== null) {

            icon = this.props.module_content.variant[first_task_index].icon;

        }

        if (show_checkmark) {

            icon = require("CheopsRoot/img/checkmark.svg");

        }

        if (ElementNavItem.icon_cache[icon]) {

            if (typeof ElementNavItem.icon_cache[icon] === "object") {

                return (await ElementNavItem.icon_cache[icon]).data;

            }

            return ElementNavItem.icon_cache[icon];

        }

        let loading_promise = Axios.get(icon);

        ElementNavItem.icon_cache[icon] = loading_promise;

        let icon_data = (await loading_promise).data;

        ElementNavItem.icon_cache[icon] = icon_data;

        return icon_data;

    }

    getGroupRange(element_index) {

        let groups = this.props.module_content.groups;

        if (!this.props.show_group_summary) {

            return [element_index, element_index];

        }

        for (let group of groups) {

            if (group.from - 1 <= element_index && group.to - 1 >= element_index) {

                return [group.from - 1, group.to - 1];

            }

        }

        return [element_index, element_index];

    }

    getAtLeastOneHasHash(element_index) {

        let range = this.getGroupRange(element_index);
        let elements_progress = this.props.module_progress.elements;

        for (let index = range[0]; index <= range[1]; index++) {

            if (elements_progress[index].hash) {

                return true;

            }

        }

        return false;

    }


    getVerdict(element_index) {

        let range = this.getGroupRange(element_index);

        let verdict = 'none';

        for (let index = range[0]; index <= range[1]; index++) {

            let progress = this.props.module_progress.elements[index];

            let item_verdict = progress.verdict;

            if (progress.isClosed && item_verdict === 'none') {

                item_verdict = 'wrong';

            }

            if (item_verdict === 'partly') {

                return 'partly';

            }

            if (verdict === 'ok' && item_verdict === 'wrong') {

                return 'partly';

            }

            if (verdict === 'wrong' && item_verdict === 'ok') {

                return 'partly';

            }

            if (item_verdict !== 'none') {

                verdict = item_verdict;

            }

        }

        return verdict;

    }


    getGroupIsClosed(element_index) {

        let range = this.getGroupRange(element_index);

        for (let index = range[0]; index <= range[1]; index++) {

            if (!this.props.module_progress.elements[index].isClosed) {

                return false;

            }

        }

        return true;

    }

    // eslint-disable-next-line react/sort-comp
    static icon_cache = {};

    async updateModuleVariants(module_content) {

        await this.setState({is_loading: true});

        let module_variants = clonedeep(module_content.variant);

        for (let index in module_variants) {

            if (module_variants[index].icon) {

                module_variants[index].icon = await this.getIcon(parseInt(index)); // TODO optimize! that calls too many times

            }

        }

        this.setState({module_variants, is_loading: false});

    }

    render() {

        if (this.state.is_loading) {

            return <div className="element_nav_item" />;

        }

        if (this.props.element_index === null) {

            return "";

        }


        let is_closed = this.getGroupIsClosed(this.props.element_index);
        let disabled = !this.getAtLeastOneHasHash(this.props.element_index);

        let bg_color_style;

        let verdict = this.getVerdict(this.props.element_index);

        if (this.props.course_color) {

            if (!is_closed
                || [
                    SolveResult.VERDICT_NONE_UNSCORED,
                    SolveResult.VERDICT_UNSCORED,
                ].includes(verdict)) { // For all other verdicts the color is set in Less

                bg_color_style = ColorUtils.button(this.props.course_color);

            }

        }

        let element_content = this.state.module_variants[this.props.element_index].icon;
        let element_progress = this.props.module_progress.elements[this.props.element_index];

        if (!this.props.module_content.groups.length && element_progress.taskNumber !== null) {

            element_content = element_progress.taskNumber;

        }

        if (this.props.forceShowNumbers && element_progress.taskNumber !== null) {

            element_content = element_progress.taskNumber;

        }

        let classname = new BemClassName("element_nav_item", [`verdict-${verdict}`]);

        classname.appendStatusIf(is_closed, 'closed');

        classname.appendStatusIf(this.props.active, 'active');
        classname.appendStatusIf(disabled, 'disabled');
        classname.appendStatusIf(!!element_progress.reviewStatus, 'has-review-status');
        classname.appendStatusIf(!!element_progress.reviewStatus, `review-status-${element_progress.reviewStatus}`);


        let onClick;

        if (!disabled) {

            onClick = this.props.onClick;

        }

        return (
            <div
                style={{background: bg_color_style}}
                className={classname}
                dangerouslySetInnerHTML={{__html: element_content}}
                onClick={onClick}
            />
        );

    }

}
