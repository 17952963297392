import React, {FC}                from 'react';
import {Button}                   from '@sirius/ui-lib/src/blocks/Controls/Button';
import {CourseSidebarHeaderProps} from './@types';
import {CourseCard}               from './CourseCard';
import {block}                    from './block';
import                                 './style.less';

export const CourseSidebarHeader:FC<CourseSidebarHeaderProps> = (
    {
        currentCourseLearn: {
            progress: learnProgress,
            advancedModulesTotal,
            advancedModulesAchieved,
            autogradeState
        },
        courseContents: {
            hasToc,
            progress: contentProgress,
            name: title
        },
        courseColor = '',
        expanded,
        showNotifications,
        menuItems = [],
        expandHeader,
        onNotificationsClick,
        onProgressClick,
        backPath= '/#/',
        expandToc
    }) =>
    <>
        {
            hasToc &&
            <Button onClick   = {expandToc}
                    className = {block.el('toc-button')}
                    mode      = {'secondary'}
                    size      = {'s'}
                    icon      = {'toc'}
            />
        }
        <CourseCard {...{
                hasToc,
                title,
                courseColor,
                backPath,
                menuItems,
                onProgressClick,
                showNotifications,
                onNotificationsClick,
                autogradeState,
                advancedModulesTotal,
                advancedModulesAchieved,
                contentProgress,
                learnProgress,
                expanded
            }}
        />
        {
            !expanded &&
            <Button onClick   = {expandHeader}
                    className = {block.el('menu-button')}
                    mode      = {'primary'}
                    size      = {'s'}
                    icon      = {'more_vert'}
                    style     = {{backgroundColor: courseColor}}
            />
        }
    </>
;
