import React, {useState}        from "react";
import {HBox,VBox}              from "@sirius/ui-lib/src/blocks/Layout";
import {IconButton}             from "@sirius/ui-lib/src/blocks/Controls";
import {TOCParagraphProps}      from "./@types";
import {paragraphEl}            from "./block";
import {TOCLesson}              from "./TOCLesson";

export const TOCParagraph: React.FC<TOCParagraphProps> = ({
    node,
    courseId,
    modules,
    learn
}) => {

    const {lessons, description, name} = node;

    const [open, setOpen] = useState<boolean>(false);

    const toggleOpen = () => {
        setOpen(!open);
    }

    return  <VBox className={paragraphEl + paragraphEl.bod('open', open)}>
                <HBox className={paragraphEl.mod('title')} onClick={toggleOpen}>
                    <HBox className={paragraphEl.mod('name')}>
                        {name}
                    </HBox>
                    <HBox className={paragraphEl.mod('arrow')}>
                        <IconButton icon={'expand_more'} onAction={toggleOpen} />
                    </HBox>
                </HBox>
                <HBox className={paragraphEl.mod('description')}>
                    {description}
                </HBox>
                <HBox className={paragraphEl.mod('lessons')}>
                    {
                        lessons &&
                        lessons.map(
                            (lesson, i) =>
                                <TOCLesson
                                    key         = {i}
                                    node        = {lesson}
                                    module      = {modules[lesson.moduleId]}
                                    learnModule = {learn.modules[lesson.moduleId]}
                                    courseId    = {courseId}
                                />
                        )
                    }
                </HBox>
          </VBox>
    ;
}
