import React, {FC}                               from 'react';
import {Footer, Header, Table}                   from '@sirius/ui-lib/src/blocks/Layout/UITable';
import {BoxAlignment, HBox, VBox}                from '@sirius/ui-lib/src/blocks/Layout';
import {Chips, ChipsList}                        from '@sirius/ui-lib/src/blocks/Controls/Chips';
import {progressPage, ProgressPageTableProps}    from 'Cheops/containers/TeacherRoomPage/blocks';
import {ColumnMapper, RowMapper, getTableSchema} from './helpers';

export const ProgressPageTableV2:FC<ProgressPageTableProps> = ({table, title, selectedChips, onSelectChips, onEdit, seeAsStudent}) => {

    const columns = table.cols.map(ColumnMapper);
    const rows    = table.rows.map(({cells}) => cells).map(RowMapper);

    const tasks = rows.length > 0
        ? Object
            .keys(rows[0])
            .filter((key) => /moduleTask-\d+/.test(key))
        : []
    ;

    return(
        <Table rows              = {rows}
               checkedRows       = {[]}
               cols              = {columns}
               uischema          = {getTableSchema(tasks, seeAsStudent)}
               className         = {progressPage.el('table')}
               hardWidthDisabled = {true}
        >
            <Header>
                <VBox className={progressPage.el('table-header')}>
                    <h1 className={progressPage.el('table-header-title')}>
                        {title}
                    </h1>
                    {
                        table.chips?.length > 0 &&
                        <HBox align={BoxAlignment.start}
                        >
                            <ChipsList onChange   = {onSelectChips}
                                       align      = {'start'}
                                       selected   = {[selectedChips ? selectedChips.name : '']}
                            >
                                {[
                                    <Chips key={'all'} value={''} size={'xs'}>Все классы</Chips>,
                                    <span key={'separator'} className={progressPage.el('table-header-separator')}/>,
                                    ...table.chips.map(
                                        (chip, ci) =>
                                            <Chips key       = {ci}
                                                   value     = {(chip as Common.Table.V2.TableChip).name}
                                                   size      = {'xs'}
                                            >
                                                {(chip as Common.Table.V2.TextLabel).label}
                                            </Chips>
                                    )
                                ]}
                            </ChipsList>
                        </HBox>
                    }
                </VBox>
            </Header>

            <Footer>
                <div style={{height: '8px'}}/>
            </Footer>
        </Table>
    );
};
