import React, {FC, useRef, useState}                        from "react";
import {BoxAlignment, HBox, PopoverMenu, PopoverMenuItem}   from "@sirius/ui-lib/src/blocks/Layout";
import {Button}                                             from "@sirius/ui-lib/src/blocks/Controls";
import {contestBlock as block, TeacherContestCardProps}     from "Cheops/containers/TeacherRoomPage/blocks";
import {TeacherCardIcon}                                    from "Cheops/containers/TeacherRoomPage/TeacherCardIcon";
import                                                          "./style.less";


export const TeacherContestCard: FC<TeacherContestCardProps> = (
    {
        title,
        hasSelectedClassAccess = false,
        popoverMenuItems = [],
    }
) => {
    const [target, setTarget] = useState(null);
    const buttonRef = useRef(null);

    const showPopoverMenu = (e: React.MouseEvent) => {
        e?.stopPropagation();
        setTarget(buttonRef.current);
    };

    const togglePopoverMenu = (e: React.MouseEvent): void => {
        e?.stopPropagation();
        setTarget(!!target ? null : e.currentTarget);
    };


    return (
        <HBox className = {block}
              align     = {BoxAlignment.between}
        >
            <div className = {block.el('link')}
                 onClick={showPopoverMenu}
            >
                <TeacherCardIcon block={block}
                                 hasAccess = {hasSelectedClassAccess}
                />
                <p className={block.el('title')}>
                    {title}
                </p>
            </div>
            { popoverMenuItems.length > 0 &&
                <div className={block.el('menu')}>
                    <Button onClick = {togglePopoverMenu}
                            icon    = {'more_vert'}
                            size    = {'s'}
                            mode    = {'text'}
                            active  = {!!target}
                            buttonRef = {buttonRef}
                    />
                    <PopoverMenu target    = {target}
                                 close     = {() => setTarget(null)}
                                 placement = {'bottom-end'}
                                 wsize     = {'s'}
                    >
                        {
                            popoverMenuItems.map(
                                (menuItem, i) => (
                                    <PopoverMenuItem {...menuItem} key={i}/>
                                )
                            )
                        }
                    </PopoverMenu>
                </div>
            }

        </HBox>
    )
}

;
