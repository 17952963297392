import React, {FC}        from "react";
import MarkedWithVideo    from "Smt/MarkedWithVideo";
import {block, pageBlock} from "./block";

interface Props {
    block_id: string,
    image?: string,
    title?: string,
    text?: string,
}
const LandingBlockText: FC<Props> = (
    {
        block_id: blockId,
        image = null,
        title,
        text,
    }
) => {

    return (
        <div className={block}>
            <div className={
                    pageBlock.el('section') +
                    pageBlock.el(blockId)
                 }
                 data-target="about"
                 data-nav={blockId}
            >
                {
                    !!title
                    &&  <div className={block.el("title")}>
                            {title}
                        </div>
                }
                <div className={block.el("text").mod("text")}>
                    {
                        !!image
                        &&  <div className={block.el("text").mod("img")}>
                                <img src={image} alt={`about ${title}`} />
                            </div>
                    }
                    <div className={pageBlock.el('about-description') +
                                    pageBlock.el('about-description').mod( 'image', !!image ? 'true' : 'false' )}
                    >
                        <MarkedWithVideo>
                            {text}
                        </MarkedWithVideo>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default LandingBlockText
