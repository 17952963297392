import React from "react";
import "./CourseProgress.less";
import CourseProgressLine from "Cheops/components/CoursePage/CourseProgressLine";

export default class CourseProgress extends React.Component {

    // eslint-disable-next-line react/sort-comp
    LINE_TYPE = {
        NORMAL: 'normal',
        DONE: 'done',
        FAIL: 'fail',
    };

    static defaultProps = {
        wrapperStyle: '',
        courseContentProgress: [],
        courseProgress: [],
        onClick: () => {
        },
    };

    renderSmallProgress() {

        const getValueLevel = ({level, type}) => {

            const data = this.props.courseProgress;
            const index = data.findIndex((item) => item.level === level);

            if (index < 0) {

                return {};

            }

            if (type === 'score') {

                return {
                    done: data[index].currentProgress,
                    fail: data[index].failedProgress,
                };

            }

            if (type === 'module') {

                return {
                    done: data[index].modulesAchieved,
                    fail: data[index].failedModules,
                };

            }

            return {};

        };

        const getTotal = ({type, scoreTotal, modulesMax}) => {

            if (type === 'module') {

                return parseFloat(modulesMax);

            }

            if (type === 'score') {

                return parseFloat(scoreTotal);

            }

            return null;

        };

        return this.props.courseContentProgress.map((item, key) => {

            const {done, fail} = getValueLevel(item);
            return (
                <CourseProgressLine
                    key={key}
                    checkPoints={item.checkpoints}
                    totalValue={getTotal(item)}
                    doneValue={done}
                    failValue={fail}
                    onClick={() => this.props.onClick(key + 1)}
                    type={item.type}
                />
            );

        });

    }

    render() {

        return (
            <div className={`course_progress ${this.props.wrapperStyle}`}>
                <div className='course_progress__head'>
                    {this.renderSmallProgress()}
                </div>
            </div>
        );

    }

}
