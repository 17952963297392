import React from "react";
import BemClassName from "Cheops/BemClassName";
import Marked from "@sirius/ui-shared/src/components/DisplayEngine/Marked";
import * as constants from "Cheops/constants";


export default class TaskCard extends React.Component {

    static defaultProps = {
        task_obj: {},
        className: "",
    };

    render() {

        let task_card_class = new BemClassName("task_card", [], this.props.className);
        let task_card__answer_text_class = new BemClassName("task_card__answer_text");
        let task_obj = this.props.task_obj;

        let task_link = `/#/course/${this.props.course_id}/${this.props.module_id}/${task_obj.type}_${task_obj.id}`;


        if (!task_obj.hash) {

            task_card_class.appendStatus("not_available");
            task_card__answer_text_class.appendStatus("not_available");
            task_link = null;

        }

        let time_text = "";
        let answer_text = "";
        let answer_images = null;
        let scores_text = "";
        let show_button = true;
        let today = new Date();


        if (task_obj.isClosed) {

            task_card_class.appendStatusIf(task_obj.current === task_obj.max, "green");
            task_card_class.appendStatusIf(task_obj.current === 0, "red");
            task_card_class.appendStatusIf(task_obj.current > 0 && task_obj.current < task_obj.max, "yellow");

            task_card__answer_text_class.appendStatusIf(task_obj.current === task_obj.max, "green");
            task_card__answer_text_class.appendStatusIf(task_obj.current === 0, "red");
            task_card__answer_text_class.appendStatusIf(task_obj.current > 0 && task_obj.current < task_obj.max, "yellow");

        }

        if (task_obj.availability.locked) {

            time_text = "Задача закрыта";

        }

        if (task_obj.availability.closeDate) {

            let date = new Date(task_obj.availability.closeDate);

            if (today < date) {

                time_text = `До ${date.toLocaleString("ru", {
                    day: "numeric",
                    month: "long",
                    hour: "2-digit",
                    minute: "2-digit",
                })}`;

            }

        }

        if (task_obj.availability.openDate) {

            let date = new Date(task_obj.availability.openDate);

            if (today < date) {

                time_text = `Откроется ${date.toLocaleString("ru", {
                    day: "numeric",
                    month: "long",
                    hour: "2-digit",
                    minute: "2-digit",
                })}`;

                show_button = false;

            }

        }


        if (task_obj.officialSolution) {

            time_text = "Опубликовано решение";

        }

        if (task_obj.current > 0
            || (task_obj.current === 0 && task_obj.reviewStatus
                && task_obj.reviewStatus !== constants.REVIEW_STATUS.ON_REVIEW)
        ) {

            scores_text = `${task_obj.current} из ${task_obj.max}`;

        }

        if (task_obj.current > 0) {

            scores_text = `${task_obj.current} из ${task_obj.max}`;

        }

        if ((task_obj.current === task_obj.max) || task_obj.isClosed || task_obj.availability.locked || !task_obj.hash) {

            show_button = false;

        }

        let task_card__button_class = new BemClassName("task_card__button");
        let button_text = "Приступить";

        if (task_obj.userSolution) {

            button_text = "Продолжить";
            task_card__button_class.appendStatus("continue");

        }

        let task_card__task_number = task_obj.taskNumber;
        let task_card__head_text_class = new BemClassName("task_card__task_number");

        if ((!task_obj.userSolution && !task_obj.review) || task_obj.isClosed || (task_obj.current === task_obj.max)) {

            task_card__task_number = `Задача ${task_obj.taskNumber}`;
            task_card__head_text_class.appendStatus("big");

        }


        let task_card__answer_text_inner_class = new BemClassName("task_card__answer_text_inner");
        let task_card__images_item_class = new BemClassName("task_card__images_item");
        let task_card__current_score_class = new BemClassName("task_card__current_score");

        if ((task_obj.reviewStatus && task_obj.reviewStatus === constants.REVIEW_STATUS.REVIEWED) || (task_obj.reply && !task_obj.replySeen)) {

            task_card__answer_text_inner_class.appendStatus("bold");
            task_card__images_item_class.appendStatus("bold");
            task_card__current_score_class.appendStatus("bold");

        }

        task_card__current_score_class.appendStatusIf(task_obj.isClosed, "white");

        let inner_text = "";

        if (task_obj.review) {

            inner_text = task_obj.review.message;

        }

        if (task_obj.reply) {

            if (task_obj.replyTime && task_obj.reviewTime) {

                let reply_time = new Date(task_obj.replyTime);
                let review_time = new Date(task_obj.reviewTime);

                if (reply_time > review_time) {

                    inner_text = task_obj.reply.message;

                }

            }

            if (!task_obj.review) {

                inner_text = task_obj.reply.message;

            }

        }

        if (inner_text) { // TODO someday here will be images

            task_card__answer_text_class.appendStatusIf(!!answer_images || !!task_obj.review, "small");

            answer_text = <span className={task_card__answer_text_inner_class}>
                <Marked>{`Ответ: ${inner_text}`}</Marked>
            </span>;

        }

        if (task_obj.officialSolution && !(task_obj.reply && !task_obj.replySeen) && !(task_obj.reviewStatus && task_obj.reviewStatus === constants.REVIEW_STATUS.REVIEWED)) {

            inner_text = task_obj.officialSolution[task_obj.officialSolution.length - 1].solution;

            task_card__answer_text_class.appendStatusIf(!!task_obj.review, "small");

            answer_text = <span className={task_card__answer_text_inner_class}>
                <Marked>{`Решение: ${inner_text}`}</Marked>
            </span>;

        }

        if (!!task_obj.userSolution && task_obj.reviewStatus === constants.REVIEW_STATUS.ON_REVIEW && (!task_obj.reply || task_obj.replySeen)) {

            answer_text = "Решение отправлено";

        }

        return (
            <a className={task_card_class} href={task_link}>
                <div className={task_card__head_text_class}>
                    {task_card__task_number}
                </div>
                <div className="task_card__scores">{scores_text}</div>

                {answer_text
                && <div className={task_card__answer_text_class}>{answer_text}</div>}

                {answer_images
                && <div className="task_card__images">{answer_images}</div>}

                {task_obj.review
                && <div className={task_card__current_score_class}>{task_obj.current}</div>}

                {show_button
                && <div className={task_card__button_class}>{button_text}</div>}

                {time_text
                && <div className="task_card__time_text">{time_text}</div>}

            </a>
        );

    }

}
