import React                from "react";
import bnc                  from "bnc";
import {bindActionCreators} from "redux";
import {connect}            from "react-redux";
import {CircularProgress}   from "@sirius/ui-lib/src/blocks/CircularProgress";
import * as noo_actions     from "Cheops/actions/noopolis";
import * as auth_actions    from "Cheops/actions/auth";
import store                from "Cheops/Store";
import Helpers              from "Cheops/Helpers";
import {cluster}            from "CheopsRoot/clusters/current";
import AuthContent          from "../components/Auth/AuthContent";
import {Footer}             from "../components/Footer";


const block = new bnc('auth_page');

const SUBMIT_DELAY_SEC = 90;

class AuthPage extends React.Component {

    constructor(props) {

        super(props);

        document.title = `Вход — ${Helpers.getInstanceTitleForCurrentDeploy()}`;

        const initState = {
            enter_for_parents: props.match.url === '/auth_parent',
            loading: false,
            timeLeft: SUBMIT_DELAY_SEC,
            submitDisabled: false
        };

        if (!initState.enter_for_parents) {

            const searchParams = location.hash.split("?")[1];

            if (!searchParams) {
                initState.loading = true;
                auth_actions.redirectToAuth(location.origin);
            }

            const urlSearchParams = new URLSearchParams(searchParams);

            const context = urlSearchParams.get('context');

            if (!context) {
                auth_actions.redirectToAuth(location.origin);
            }

            this.props.setAuthContext(context);

        }

        this.state = {
            error_message: null,
            ...initState
        };

    }

    authDelayTimer;

    componentWillUnmount() {
        clearInterval(this.authDelayTimer);
    }

    backClick() {

        store.dispatch(auth_actions.setAuthToken(null));
        location.hash = '#';

    }

    handleParentTokenResponse = (token) => {
        if (token === null) {
            this.setState({error_message: 'Введён неверный код'});
        } else {
            store.dispatch(auth_actions.setAuthToken(token));
            location.href = '/#/';
        }
    };

    countDown() {
        const {timeLeft} = this.state;

        if (timeLeft <= 0) {
            clearInterval(this.authDelayTimer);
            return this.setState({
                error_message:  null,
                submitDisabled: false,
                timeLeft:       SUBMIT_DELAY_SEC
            });
        }
        this.setState({
            timeLeft:      timeLeft - 1,
            error_message: <>На этот email уже отправлен код, <br /> повторная отправка возможна через {timeLeft} секунд</>
        })
    }

    startDelay = () => {
        this.setState({submitDisabled: true});
        this.authDelayTimer = setInterval(() => {
            this.countDown();
        }, 1000);
    };

    async onSubmit(field_value) {
        const {enter_for_parents} = this.state;

        let response;


        if (enter_for_parents) {

            response = await noo_actions.getParentCodeToken(field_value);

        } else {

            let session_token = await this.props.getFormSessionToken(true);
            response = await auth_actions.sendAuthCode(session_token.value, field_value);

        }

        if (response.error) {

            let error_message = null;

            switch (response.error.message) {
                case 'spent attempts':
                    error_message = <>
                        Вы ввели неверный код три раза. <br />
                        Возможность входа временно ограничена.<br />
                        Попробуйте войти снова через 5 минут.
                    </>;
                    break;
                case "invalid session":
                    error_message = <>
                        Время действия кода истекло.
                        <br />
                        Попробуйте войти ещё раз
                    </>;
                    break;
                case 'invalid login':
                    error_message = <>Неправильный адрес эл. почты</>;
                    break;
                case 'invalid code':
                    error_message = <>Неверный код</>;
                    break;
                case `timeout for an email hasn't been reached yet`:
                    this.startDelay();
                    break;
                default:
                    error_message = <>
                        Вход временно невозможен.
                        <br />
                        Повторите попытку позже
                    </>;
            }

            this.setState({error_message});

        } else {

            if (enter_for_parents) {
                return this.handleParentTokenResponse(response.token);
            }

            this.props.setUserEmail(field_value);

            if (response.result.next_step === 'profile') {

                location.hash = "#/auth_profile";
                return;

            }

            location.hash = "#/auth_confirmation";

        }

    }

    render() {
        const {loading, enter_for_parents} = this.state;

        let title = <div>
            Добро пожаловать!
            <br />
            Войдите, чтобы учиться
        </div>;
        let override_footer_text = null;
        let validate_email = true;

        let auth_form_button_title = "Получить код для входа";

        if (enter_for_parents) {

            validate_email = false;
            title = <div>
                Войдите и наблюдайте за учебным процессом глазами ученика.
                <br />
                Код для входа выдаёт ученик
            </div>;
            override_footer_text = 'Вы не сможете вмешиваться в учебный процесс';
            auth_form_button_title = "Войти";

        }


        return (
            loading
                ?  <CircularProgress centerOfWindow={true} />
                :   <div className = { block +
                                       block.bod('textbook', cluster.type === 'textbook')
                        }
                    >
                        <div className="auth_page__back_arrow" onClick={() => this.backClick()} />
                        <AuthContent validate_email={validate_email}
                                     title={title}
                                     override_footer_text={override_footer_text}
                                     user_email={this.props.user_email}
                                     enter_for_parents={this.state.enter_for_parents}
                                     session_token={this.state.session_token}
                                     auth_form_button_title={auth_form_button_title}
                                     error_message={this.state.error_message}
                                     onSubmit={this.onSubmit.bind(this)}
                                     submitDisabled={this.state.submitDisabled}
                        />
                        {
                            cluster.type === "courses" &&
                            <Footer />
                        }
                    </div>
        );

    }

}


const mapStateToProps = (state) => ({
    user_email: state.authReducer.user_email,
});

const mapDispatchToProps = (dispatch) => ({
    setUserEmail: (user_email) => dispatch(auth_actions.setUserEmail(user_email)),
    setAuthContext: (context) => dispatch(auth_actions.setContext(context)),
    getFormSessionToken: bindActionCreators(auth_actions.getFormSessionToken, dispatch),
});


export default connect(mapStateToProps, mapDispatchToProps)(AuthPage);
