import React                    from "react";
import {connect}                from "react-redux";
import Marked                   from "@sirius/ui-shared/src/components/DisplayEngine/Marked";
import {bindActionCreators}     from "redux";
import CircularProgress         from "Smt/Ui";
import {cluster}                from "Cheops/../clusters/current";
import NotificationsList        from "Cheops/components/Notifications/NotificationsList";
import * as noo_actions         from "Cheops/actions/noopolis";
import {Footer}                 from "Cheops/components/Footer";
import LandingsHeader           from "Cheops/components/Landings/LandingsHeader";
import * as auth_actions        from "Cheops/actions/auth";
import LandingBlockText         from "Cheops/components/Landings/LandingBlockText";
import LandingBlockLecturers    from "Cheops/components/Landings/LandingBlockLecturers";
import LandingBlockProgram      from "Cheops/components/Landings/LandingBlockProgram";
import LandingBlockColumns      from "Cheops/components/Landings/LandingBlockColumns";
import LandingBlockVideo        from "Cheops/components/Landings/LandingBlockVideo";
import CourseHeader             from "Cheops/components/CoursePage/CourseHeader";
import {CourseBackButton} from "Cheops/components/CoursePage/CourseButton";
import LandingActionPopup       from "Cheops/components/Landings/LandingActionPopup";
import Helpers                  from "Cheops/Helpers";
import {ym}                     from "../Utils/MetrikaWrapper";

class CourseLandingPage extends React.Component {

    // eslint-disable-next-line react/sort-comp
    PROGRAM_MENU_ITEM_NAMES = {
        THEMES: "themes",
        GRAPH: "graph",
    };

    BUTTON_POSITION = {
        TOP: "top",
        MIDDLE: "middle",
        BOTTOM: "bottom",
    };

    constructor(props) {

        super(props);

        this.state = {
            is_loading: true,
            isAttendLoading: false,
            course_button_position: this.BUTTON_POSITION.TOP,
            navigation_is_fixed: false,
            land_header_with_bg: false,
            small_logo: false,
            show_notifications: false,
            nav_active_item: null,
            refusePopupIsOpen: false,
            user_is_anon: !props.auth_token,
        };

    }

    async componentWillMount() {

        if (!this.props.current_course) {

            await this.props.getCourseData(this.props.match.params.course_id);
            await this.props.getCourseLearnData(this.props.match.params.course_id);

        }

        await this.props.getCourseContent(this.props.current_course.hash);
        document.title = `${this.props.course_contents[this.props.current_course.hash].name} — ${Helpers.getInstanceTitleForCurrentDeploy()}`;
        this.setState({is_loading: false});

    }

    async componentDidMount() {

        window.addEventListener('scroll', this.handleScroll);
        this.progressBar();

    }

    componentWillUnmount() {

        window.removeEventListener('scroll', this.handleScroll);

    }

    handleScroll = () => {

        // animation for button
        let scroll_top = window.pageYOffset;
        let header_buttons_wrapper = document.querySelector('.course_landing_page__header_buttons_wrapper');

        if (!header_buttons_wrapper) {

            return;

        }

        let course_button_position = this.BUTTON_POSITION.TOP;

        if ((header_buttons_wrapper.getBoundingClientRect().top + header_buttons_wrapper.clientHeight) < 0) {

            course_button_position = this.BUTTON_POSITION.MIDDLE;

        }

        this.progressBar();
        this.navigationPositioning();

        // navigation item highlighting

        let sections = document.getElementsByClassName("course_landing_page__section");
        let header = document.querySelector(".course_landing_page__header");
        let window_height = document.documentElement.clientHeight;
        let nav_active_item = this.state.nav_active_item;

        for (let i = 0; i < sections.length; i++) {

            let section = sections[i];
            let min_top = header.getBoundingClientRect().bottom;
            let top = section.getBoundingClientRect().top + scroll_top;
            let bottom = top + section.offsetHeight;
            let id = section.getAttribute('data-nav');

            if (scroll_top > (top - (window_height / 4)) && scroll_top <= bottom) {

                nav_active_item = parseInt(id);

            } else if (scroll_top < min_top) {

                nav_active_item = null;

            }

        }

        this.setState({
            course_button_position,
            nav_active_item,
        });

    };

    openLecturerCard() {

    }

    progressBar() {

        let scroll_top = window.pageYOffset;
        let footer_height = document.documentElement.clientHeight;
        let target = document.body;
        let progress_bar = document.querySelector('.course_landing_page__navigation_progressbar');

        if (progress_bar) {

            target.onscroll = updateProgressBar;
            updateProgressBar();

        }

        function updateProgressBar() {

            let percents = getScrollPercent(target);
            progress_bar.style.width = `${percents}%`;

        }

        function getScrollPercent(el) {

            return (scroll_top / (el.offsetHeight - footer_height)) * 100;

        }

    }

    navigationPositioning() {

        if (window.matchMedia("(max-width: 760px)").matches && window.scrollY > 0) {

            this.setState({land_header_with_bg: true});
            return;
        }

        let scroll_top = window.pageYOffset;
        let navigation = document.querySelector('.course_landing_page__navigation');
        let header_height = document.querySelector('.course_landing_page__header').clientHeight;
        let navigation_height = navigation?.clientHeight || 0;
        let trigger = header_height - navigation_height;
        let navigation_is_fixed = false;
        let small_logo = false;
        let land_header_with_bg = false;

        if (scroll_top >= trigger) {

            navigation_is_fixed = true;

        }

        if (scroll_top >= (trigger - navigation_height)) {

            small_logo = true;

        }

        if ((scroll_top > 0) && (scroll_top <= (trigger - navigation_height))) {

            land_header_with_bg = true;

        }

        this.setState({
            navigation_is_fixed,
            small_logo,
            land_header_with_bg,
        });

    }

    // smooth scrolling

    navigationItemClick = (e) => {

        let scroll_top = window.pageYOffset;
        let navigation = document.querySelector(".course_landing_page__navigation");
        let navigation_height = navigation.offsetHeight;
        let source = e.currentTarget.dataset.scroll;
        let target = document.querySelector(`.course_landing_page__${source}`);
        let target_y = target.getBoundingClientRect().top + scroll_top - navigation_height;
        let distance = target_y > scroll_top ? target_y - scroll_top : scroll_top - target_y;

        if (distance < 100) {

            scrollTo(0, target_y);
            return;

        }

        let speed = Math.round(distance / 100);

        if (speed >= 20) {

            speed = 20;

        }

        let step = Math.round(distance / 25);
        let leap_y = target_y > scroll_top ? scroll_top + step : scroll_top - step;
        let timer = 0;
        if (target_y > scroll_top) {

            for (let i = scroll_top; i < target_y; i += step) {

                setTimeout(`window.scrollTo(0, ${leap_y})`, timer * speed);
                leap_y += step;

                if (leap_y > target_y) {

                    leap_y = target_y;

                }

                timer++;

            }

            return;

        }

        for (let i = scroll_top; i > target_y; i -= step) {

            setTimeout(`window.scrollTo(0, ${leap_y})`, timer * speed);
            leap_y -= step;

            if (leap_y < target_y) {

                leap_y = target_y;

            }

            timer++;

        }

    }

    setShowNotifications(state) {

        this.setState({show_notifications: state});

    }

     attendCourseClick = () => {

        if (this.state.isAttendLoading) {

            return;

        }

        this.setState({isAttendLoading: true});

        noo_actions.attendCourse(this.props.current_course.id)
            .then(() =>
                this.props.history.replace({force_refresh_data: true})
            )
            .finally(()=>
                this.setState({isAttendLoading: false})
            )
        ;
    }

    openRefuseCoursePopup = () => {

        this.setState({refusePopupIsOpen: true});

    };

    closeRefuseCoursePopup = () => {

        this.setState({refusePopupIsOpen: false});

    };

    refuseCourse = async () => {

        let result = await noo_actions.refuseCourse(this.props.current_course.id);

        if (result.success) {

            this.setState({refusePopupIsOpen: false});
            location.hash = "#/";

        }

    };

    render() {

        if (this.state.is_loading) {

            return (<CircularProgress centerOfWindow={true} />);

        }

        let show_compact_landing = !this.state.user_is_anon && !!this.props.current_course_learn.hash;
        let course_content_by_hash = this.props.course_contents[this.props.current_course.hash];
        let course_landing_page__navigation = "course_landing_page__navigation";
        let menu_class = 'course_landing_page__navigation_inner_wrapper';

        if (!this.state.user_is_anon) {

            menu_class += ' course_landing_page__navigation_inner_wrapper--registered';

        }


        if (this.state.navigation_is_fixed) {

            course_landing_page__navigation += " course_landing_page__navigation--fixed";

        }
        const clp_cb = 'course_landing_page__to_course_button';
        const {course_button_position} = this.state;
        let course_landing_page__to_course_button = `${clp_cb} ${clp_cb}--${course_button_position} ${clp_cb}-cluster-${cluster.key}`;
        let roles = auth_actions.getUserInfo('roles');
        let is_parent = false;

        if (roles && (roles.includes('nooRO') || roles.includes('i:nooRO'))) {

            is_parent = true;

        }

        let modules_count = course_content_by_hash.modulesCount - course_content_by_hash.advancedModulesCount;
        let advanced_modules_count = course_content_by_hash.advancedModulesCount;
        let to_course_button_disabled = false;

        let progress_current = this.props.current_course_learn.modulesAchieved - this.props.current_course_learn.advancedModulesAchieved;
        let progress_max = this.props.current_course_learn.modulesTotal - this.props.current_course_learn.advancedModulesTotal;

        if (!this.props.current_course_learn.status.opened) {

            to_course_button_disabled = true;

        }

        if (this.state.isAttendLoading) {

            to_course_button_disabled = true;

        }

        let locked_course_text = "";
        let locked_course_date = null;
        let locked_course_date_options = {day: "numeric", month: "long", year: "numeric"};

        if (this.props.current_course_learn.status.closedAt) {

            locked_course_date = new Date(this.props.current_course_learn.status.closedAt);
            locked_course_text = `Набор закончен ${locked_course_date.toLocaleString('ru', locked_course_date_options)}`;

        }

        if (this.props.current_course_learn.status.finishedAt) {

            locked_course_date = new Date(this.props.current_course_learn.status.finishedAt);
            locked_course_text = `Курс завершён ${locked_course_date.toLocaleString('ru', locked_course_date_options)}`;

        }

        if (this.props.current_course_learn.status.opensAfter) {

            locked_course_date = new Date(this.props.current_course_learn.status.opensAfter);
            locked_course_text = `Курс откроется ${locked_course_date.toLocaleString('ru', locked_course_date_options)}`;

        }

        return (
            <div className="course_landing_page">
                {this.state.show_notifications
                && <NotificationsList hideNotifications={() => this.setShowNotifications(false)} />}
                {!show_compact_landing
                && <div className="course_landing_page__header" style={{background: course_content_by_hash.color}}>
                    <LandingsHeader
                        show_notifications={!this.state.user_is_anon}
                        notifications_icon_background="white"
                        white_widgets={true}
                        small_logo={this.state.small_logo}
                        showNotifications={() => this.setShowNotifications(true)}
                        land_header_with_bg={this.state.land_header_with_bg}
                        course_color={course_content_by_hash.color}
                    />
                    {(course_content_by_hash.landingBlocks && course_content_by_hash.landingBlocks.length > 0)
                    && <div className={course_landing_page__navigation}
                            style={{background: course_content_by_hash.color}}
                    >
                        <div className="course_landing_page__navigation_wrapper">
                            <div className="course_landing_page__navigation_progress_wrapper">
                                <div className="course_landing_page__navigation_progressbar" />
                            </div>

                            <div className={menu_class}>
                                {/* eslint-disable-next-line array-callback-return,consistent-return */}
                                {course_content_by_hash.landingBlocks.map((landing_block, i) => {

                                    const type_block = Object.keys(landing_block)[0];

                                    if (!type_block) {

                                        return null;

                                    }

                                    const content_block = landing_block[type_block];

                                    if (content_block.caption) {

                                        return <div
                                            className="course_landing_page__navigation_item course_landing_page__navigation_item_about"
                                            key={i}
                                        >
                                            <a data-scroll={i}
                                               className={this.state.nav_active_item === i ? "active" : ""}
                                               onClick={this.navigationItemClick.bind(this)}
                                            >
                                                {content_block.caption}
                                            </a>
                                        </div>;

                                    }

                                })}
                            </div>

                        </div>
                    </div>}

                    <div className="course_landing_page__header_bg" />
                    <div className="course_landing_page__header_content">
                        <div className="course_landing_page__block_wrapper">
                            {!course_content_by_hash.isPublic
                            && <div className="course_landing_page__invitation">Вы приглашены в курс</div>}

                            <div className="course_landing_page__course_title">
                                {course_content_by_hash.name}
                            </div>
                            <div className="course_landing_page__course_description">
                                <Marked>{course_content_by_hash.description}</Marked>
                            </div>
                            {!is_parent
                            && <div className="course_landing_page__header_buttons_wrapper">
                                {
                                    this.state.course_button_position !== this.BUTTON_POSITION.BOTTOM &&
                                    <button
                                        onClick={
                                            ()=> {
                                                ym('reachGoal','noo.CoursePage.AttendCourse');
                                                this.attendCourseClick()
                                            }
                                        }
                                               disabled={to_course_button_disabled}
                                               className={course_landing_page__to_course_button}
                                    >
                                        {this.state.isAttendLoading && <CircularProgress white size="small" />}
                                        {
                                            !this.state.isAttendLoading &&
                                            <>
                                                <div className="course_landing_page__to_course_button_arrow">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M12 8.00002V6.41002C12 5.52002 13.08 5.07002 13.71 5.70002L19.3 11.29C19.69 11.68 19.69 12.31 19.3 12.7L13.71 18.29C13.08 18.92 12 18.48 12 17.59V16H5C4.45 16 4 15.55 4 15V9.00002C4 8.45002 4.45 8.00002 5 8.00002H12Z"
                                                            fill="white"
                                                        />
                                                    </svg>
                                                </div>
                                                {
                                                    this.state.course_button_position !== this.BUTTON_POSITION.MIDDLE &&
                                                    <div className="course_landing_page__to_course_button_title">
                                                        Поступить на курс
                                                    </div>
                                                }
                                            </>
                                        }
                                    </button>
                                }
                                {this.props.current_course_learn.hasInvitation && !to_course_button_disabled
                                && <button onClick={this.openRefuseCoursePopup.bind(this)}
                                           className="course_landing_page__refuse_button"
                                >
                                    Отказаться
                                </button>}
                                {locked_course_text
                                && <div className="course_landing_page__locked_text">{locked_course_text}</div>}
                            </div>}
                        </div>
                    </div>
                </div>}

                {this.state.refusePopupIsOpen
                && <LandingActionPopup
                    abortClick={this.closeRefuseCoursePopup}
                    refuseClick={this.refuseCourse}
                />}

                {show_compact_landing && <div>
                    <CourseHeader
                        show_notifications={true}
                        show_progress={true}
                        title_align="left"
                        course_id={this.props.current_course.id}
                        course_hash={this.props.current_course_learn.hash}
                        title='О курсе'
                        progress_current={progress_current}
                        progress_max={progress_max}
                        star_modules_count={this.props.current_course_learn.advancedModulesTotal}
                        achieved_star_modules_count={this.props.current_course_learn.advancedModulesAchieved}
                        showNotifications={() => this.setShowNotifications(true)}
                        course_color={course_content_by_hash.color}
                        back_path={`#/course/${this.props.current_course.id}`}
                    />
                    <CourseBackButton
                        courseId={this.props.current_course.id}
                        icon
                    >
                        Вернуться в курс
                    </CourseBackButton>

                </div>}

                {course_content_by_hash.landingBlocks && course_content_by_hash.landingBlocks.length > 0
                && <div className="course_landing_page__content">
                    {/* eslint-disable-next-line array-callback-return,consistent-return */}
                    {course_content_by_hash.landingBlocks.map((landing_block, i) => {

                        if (landing_block.text) {

                            let text = "";
                            let image = null;

                            if (landing_block.text.text) {

                                text = landing_block.text.text.text || '';
                                image = landing_block.text.text.image || null;

                            }

                            return <LandingBlockText title={landing_block.text.title || ''}
                                                     text={text}
                                                     image={image}
                                                     block_id={i}
                                                     key={i}
                            />;

                        }

                        if (landing_block.lecturers) {

                            return <LandingBlockLecturers lecturers={landing_block.lecturers.list}
                                                          title={landing_block.lecturers.title || ''}
                                                          block_id={i}
                                                          key={i}
                                                          openLecturerCard={() => this.openLecturerCard()}
                            />;

                        }

                        if (landing_block.courseProgram) {

                            return <LandingBlockProgram modules_count={modules_count}
                                                        block_id={i}
                                                        key={i}
                                                        advanced_modules_count={advanced_modules_count}
                                                        course_content_by_hash={course_content_by_hash}
                                                        current_course={this.props.current_course}
                                                        title={landing_block.courseProgram.title || ''}
                            />;

                        }

                        if (landing_block.columns) {

                            return <LandingBlockColumns block_id={i}
                                                        key={i}
                                                        title={landing_block.columns.title || ''}
                                                        blocks={landing_block.columns.blocks}
                            />;

                        }

                        if (landing_block.video) {

                            let text = "";
                            let url = null;

                            if (landing_block.video.video) {

                                text = landing_block.video.video.text || '';
                                url = landing_block.video.video.url || null;

                            }

                            return <LandingBlockVideo title={landing_block.video.title || ''}
                                                      text={text}
                                                      url={url}
                                                      block_id={i}
                                                      key={i}
                            />;

                        }


                    })}
                </div>}
                {
                    !show_compact_landing && cluster.type === "courses" &&
                    <div className="course_landing_page__footer">
                        <Footer
                            has_navigation={course_content_by_hash.landingBlocks && course_content_by_hash.landingBlocks.length > 0}
                        />
                    </div>
                }
            </div>);

    }

}

const mapStateToProps = (state) => ({
    current_course: state.nooReducer.current_course,
    course_contents: state.nooReducer.course_contents,
    auth_token: state.authReducer.auth_token,
    current_course_learn: state.nooReducer.current_course_learn,
});

const mapDispatchToProps = (dispatch) => ({
    getCourseContent: bindActionCreators(noo_actions.getCourseContent, dispatch),
    getCourseData: bindActionCreators(noo_actions.getCourseData, dispatch),
    getCourseLearnData: bindActionCreators(noo_actions.getCourseLearnData, dispatch),
});


export default connect(mapStateToProps, mapDispatchToProps)(CourseLandingPage);
