import React, {FC}                              from "react";
import MarkedWithVideo                          from "Smt/MarkedWithVideo";
import {block, pageBlock, pagePlatformBlock}    from "./block";

type TBlock = {
    image: string;
    caption: string;
    text: string;
}
interface Props {
    block_id?: string,
    title?: string,
    blocks?: Array<TBlock>,
}

const LandingBlockColumns: FC<Props> = (
    {
        block_id: blockId= null,
        title,
        blocks = [],
    }
) => {

        return (
            <div className={block}>
                <div
                    className={pageBlock.el('section') + pageBlock.el(blockId)}
                    data-nav={blockId}
                >
                    {
                        title
                        &&  <div className={block.el('title')}>
                                {title}
                            </div>
                    }
                    <div className={pagePlatformBlock.el('how_to_list')}>
                        {
                            blocks?.map(
                                (
                                    {image, caption, text},
                                    i
                                ) =>
                                    <div className={pagePlatformBlock.el('how_to_list_item')} key={i}>
                                        {
                                            image
                                            &&  <img src={image} alt={caption}/>
                                        }
                                        {
                                            caption
                                            &&  <div className={pagePlatformBlock.el('columns_title')}>{caption}</div>
                                        }
                                        {
                                            text
                                            &&  <div className={pagePlatformBlock.el('columns_text')}>
                                                    <MarkedWithVideo>
                                                        {text}
                                                    </MarkedWithVideo>
                                                </div>
                                        }
                                    </div>
                            )
                        }
                    </div>
                </div>
            </div>
        );
}

export default LandingBlockColumns;
