import React          from 'react';
import {Link}         from 'react-router-dom';
import {HBox, MGap}   from '@sirius/ui-lib/src/blocks/Layout';
import {Icon}         from '@sirius/ui-lib/src/blocks/Controls';
import {progressPage} from 'Cheops/containers/TeacherRoomPage/blocks';

export const ProgressPageHeader = ({courseInfo}: {courseInfo: NooBack.Teacher.CourseInfo}) =>
    <HBox className={progressPage.el('header')}>
        <Link className = {progressPage.el('back-button')}
              to        = {`/teacher-room/course/${courseInfo.id}`}
        >
            <Icon className = {progressPage.el('back-button').mod('icon')}
                  icon = {'arrow_back'}
                  size = {'xs'}
            />
        </Link>
        <Link className = {progressPage.el('back-button')}
              to        = {`/teacher-room`}
        >
            <p className = {progressPage.el('back-button-text')}>
                Учительская
            </p>
        </Link>
        <Link className = {progressPage.el('back-button')}
              to        = {`/teacher-room/course/${courseInfo.id}`}
        >
            <MGap/>/<MGap/>
            <p className = {progressPage.el('back-button-text')}>
                {courseInfo.title}
            </p>
        </Link>
    </HBox>
;
