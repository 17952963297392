import {connect} from "react-redux";
import React from "react";
import {bindActionCreators} from "redux";
import Helpers from "Cheops/Helpers";
import AuthContent from "../components/Auth/AuthContent";
import * as auth_actions from "../actions/auth";
import {Footer} from "../components/Footer";
import {cluster} from "CheopsRoot/clusters/current";


class AuthConfirmationPage extends React.Component {

    constructor(props) {

        super(props);

        document.title = `Вход — ${Helpers.getInstanceTitleForCurrentDeploy()}`;


        if (!this.props.user_email) {

            auth_actions.redirectToAuth(location.origin);
            return;

        }

        let show_profile = false;

        if (props.match.url === '/auth_profile') {

            show_profile = true;

        }


        this.state = {
            show_profile,
            session_token: null,
            error_message: null,
        };

    }

    async componentWillMount() {

        let session_token = await this.props.getFormSessionToken();

        this.setState({
            session_token: session_token.value,
        });

    }

    getErrorMessage(server_message) {

        let error_message = null;

        switch (server_message) {
            case 'spent attempts':
                error_message = <>
                    Вы ввели неверный код три раза. <br />
                    Возможность входа временно ограничена.<br />
                    Попробуйте войти снова через 5 минут.
                </>;
                break;
            case "invalid session":
                error_message = <>
                    Время действия кода истекло.
                    <br />
                    Попробуйте войти ещё раз
                </>;
                break;
            case 'invalid login':
                error_message = <>Неправильный адрес эл. почты</>;
                break;
            case 'invalid code':
                error_message = <>Неверный код</>;
                break;
            default:
                error_message = <>
                    Вход временно невозможен.
                    <br />
                    Повторите попытку позже
                </>;
        }

        return error_message;

    }


    async sendUserCode(user_code) {


        let response = await auth_actions.sendAccessCode(this.state.session_token, user_code, this.props.auth_context);

        if (response.error) {

            let error_message = this.getErrorMessage(response.error.message);

            this.setState({error_message});

        } else if (response.result) {

            location.href = response.result.redirect;

        }

    }


    async sendProfile(first_name, last_name, second_name, role, region_id, school_id, school_class, birth_date) {

        let response = await auth_actions.sendProfile(this.state.session_token, first_name, last_name, second_name, role, region_id, school_id, school_class, birth_date);

        if (response.error) {

            let error_message = this.getErrorMessage(response.error.message);
            this.setState({error_message});

            return false;

        }

        if (response.result) {

            return true;

        }

        return false;

    }


    async resendCode() {

        let response = await auth_actions.resendAuthCode(this.state.session_token);

        if (response.error) {

            let session_token = await this.props.getFormSessionToken(true);
            await auth_actions.sendAuthCode(session_token.value, this.props.user_email);
            this.setState({session_token: session_token.value});

        }

    }


    render() {

        let confirmation_title = <div>
            Дождитесь и введите код,
            <br />
            который мы отправили на
            <br />
            {this.props.user_email}
        </div>;

        return (
            <div className="auth_confirmation">
                <a className="auth_page__back_arrow" href="/#/auth" />
                <AuthContent title={confirmation_title}
                             is_confirmation={true}
                             user_email={this.props.user_email}
                             auth_form_button_title="Войти"
                             error_message={this.state?.error_message}
                             show_profile={this.state?.show_profile}
                             session_token={this.state?.session_token}
                             onSubmit={this.sendUserCode.bind(this)}
                             resendCode={this.resendCode.bind(this)}
                             sendProfile={this.sendProfile.bind(this)}
                />
                {
                    cluster.type === "courses" &&
                    <Footer />
                }
            </div>
        );

    }

}

const mapStateToProps = (state) => ({
    user_email: state.authReducer.user_email,
    auth_context: state.authReducer.auth_context,
});

const mapDispatchToProps = (dispatch) => ({
    getFormSessionToken: bindActionCreators(auth_actions.getFormSessionToken, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthConfirmationPage);
