import React, {FC, useEffect, useState} from "react";
import bnc                              from "bnc";
import {Link}                           from "react-router-dom";
import RequiredModuleItem               from "Cheops/components/ModuleCard/RequiredModuleItem";
import {
    ModuleListType,
    CourseModuleListProps,
    ModuleItemProps,
    SortedListsType,
    SortingModulesParams,
    ModuleListProps
}                                       from "Cheops/components/CoursePage/@types";
import                                      "./CourseModuleList.less";


const FILTER_TYPE = {
    ACTIVE:     'active',
    FINISHED:   'finished',
    CURRENT:    'current',
};

const block = new bnc('course_module_list');

const sortingModules = ({modules, currentCourseModulesContent}: SortingModulesParams) => {

    const sortedLists: SortedListsType = {
        current:           [],
        advice:            [],
        alreadyAvailable:  [],
        completed:         [],
    };

    for (let i in modules) {

        if (!currentCourseModulesContent[modules[i].id]) {
            continue;
        }

        const module = {
            ...currentCourseModulesContent[modules[i].id],
            ...modules[i],
        };
        const {hash, isAchieved, isClosed, progressCurrent, progressFailed, stepsDone} = module;

        if (!hash) {
            continue;
        }

        if (isAchieved && !isClosed) {
            sortedLists.advice.push(module);
            continue;
        }

        if (progressCurrent === 0 && progressFailed === 0 && stepsDone === 0) {
            sortedLists.alreadyAvailable.push(module);
            continue;
        }

        if (isClosed) {
            sortedLists.completed.push(module);
            continue;
        }

        sortedLists.current.push(module);
    }

    return {...sortedLists};
};

const ModuleItem = ({module, courseId, currentCourseLearn}: ModuleItemProps) => {
    const {id, lastVisitedElement, lastVisitedElementType} = module;
    const isLastVisitedElement = !!(lastVisitedElement && lastVisitedElementType);
    const moduleTo = `/course/${courseId}/${id}${isLastVisitedElement ? `/${lastVisitedElementType}_${lastVisitedElement}` : ''}`;

    return currentCourseLearn?.autogradeState === 'started'
        ?   <RequiredModuleItem module_obj              = {module}
                                module_progress         = {module}
                                current_course_learn    = {currentCourseLearn}
            />
        :   <Link className = {block.el('module_link')}
                  to = {moduleTo}
            >
                <RequiredModuleItem module_obj              = {module}
                                    module_progress         = {module}
                                    current_course_learn    = {currentCourseLearn}
                />
            </Link>
    ;
};

const ModuleList = ({title, moduleList, courseId, currentCourseLearn}: ModuleListProps) => (
    <div className = {block.el('block')}>
        { title &&
            <h3 className = {block.el('block_title')}>
                {title}
            </h3>
        }
        { moduleList.map(
            (module, i) =>
                <ModuleItem key = {i} {...{module, courseId, currentCourseLearn}} />
        )}
    </div>
);

const CourseModuleList: FC<CourseModuleListProps> = (
    {
        courseId,
        moduleListExpanded = false,
        openModulesList,
        currentCourseLearn,
        currentCourseModulesContent
    }
) => {
    const {modules} = currentCourseLearn;

    const [filter, setFilter]                               = useState(FILTER_TYPE.CURRENT);
    const [currentList, setCurrentList]                     = useState<ModuleListType>([]);
    const [adviceList, setAdviceList]                       = useState<ModuleListType>([]);
    const [alreadyAvailableList, setAlreadyAvailableList]   = useState<ModuleListType>([]);
    const [completedList, setCompletedList]                 = useState<ModuleListType>([]);

    useEffect(() => {
            const {current, advice, alreadyAvailable, completed} = sortingModules({modules, currentCourseModulesContent});
            setCurrentList(current);
            setAdviceList(advice);
            setAlreadyAvailableList(alreadyAvailable);
            setCompletedList(completed);
        },
        [courseId, modules, currentCourseModulesContent]
    );

    const hasActiveFilter = (val: string) => filter === val;

    const showCurrentModules            = currentList?.length > 0;
    const showAdviceModules             = adviceList?.length > 0;
    const showAlreadyAvailableModules   = alreadyAvailableList?.length > 0;
    const showCompletedModules          = completedList?.length > 0;


    return (
        <div className = {block + block.bod('opened', moduleListExpanded)}>
            <div className = {block.el('title')} onClick={openModulesList}>
                Список модулей
                <div className = {block.el('arrow')} />
                <div className = {block.el('title_rectangle')} />
            </div>
            <div className = {block.el('wrapper')}>
                <div className = {block.el('navigation')}>
                    <div className  = {block.el('navigation_item') + block.el('navigation_item').bod('active', hasActiveFilter(FILTER_TYPE.CURRENT))}
                         onClick    = {() => setFilter(FILTER_TYPE.CURRENT)}
                    >
                        Текущие
                    </div>
                    <div className  = {block.el('navigation_item') + block.el('navigation_item').bod('active', hasActiveFilter(FILTER_TYPE.FINISHED))}
                         onClick    = {() => setFilter(FILTER_TYPE.FINISHED)}
                    >
                        Завершённые
                    </div>
                </div>

                { hasActiveFilter(FILTER_TYPE.CURRENT) &&
                    <div className = {block.el('modules')}>
                        { showCurrentModules &&
                            <ModuleList moduleList = {currentList}
                                        {...{courseId, currentCourseLearn}}
                            />
                        }
                        { showAdviceModules &&
                            <ModuleList moduleList = {adviceList}
                                        title      = {'Советуем завершить модули:'}
                                        {...{courseId, currentCourseLearn}}
                            />
                        }
                        { showAlreadyAvailableModules &&
                            <ModuleList moduleList = {alreadyAvailableList}
                                        title      = {'Уже доступны:'}
                                        {...{courseId, currentCourseLearn}}
                            />
                        }
                        { !showCurrentModules && !showAdviceModules && !showAlreadyAvailableModules &&
                            <div className = {block.el('empty')}>
                                <img src={require('CheopsRoot/img/prof_cheops_happy.svg')} alt={'Вы хорошо потрудились :)'}/>
                                Кажется, здесь ничего
                                <br />
                                не осталось
                                <span className = {block.el('cert')}>
                                    Вы хорошо потрудились :)
                                </span>
                            </div>
                        }
                    </div>
                }
                { hasActiveFilter(FILTER_TYPE.FINISHED) &&
                    <div className = {block.el('modules')}>
                        { showCompletedModules
                            ?   <ModuleList moduleList = {completedList}
                                            {...{courseId, currentCourseLearn}}
                                />
                            :   <div className = {block.el('empty')}>
                                    <img src={require('CheopsRoot/img/prof_cheops_nothing.svg')} alt={'Завершённых модулей ещё нет :('}/>
                                    Завершённых модулей ещё нет :(
                                </div>
                        }
                    </div>
                }
            </div>
        </div>
    );
}

export default CourseModuleList;
