/* eslint-disable */
import React from "react";
import {connect} from "react-redux";
import LandingsHeader from "../components/Landings/LandingsHeader";
import ProgressCircle from "Cheops/components/ProgressCircle/ProgressCircle";

class ProgressDemoPage extends React.Component {


    renderRow(progress_obj, add_marker_class = "") {
        add_marker_class = '';
        return (
            <div className={"row"}>
                <div className={"col col-1"}>
                    <pre>{JSON.stringify(progress_obj, null, 2)}</pre>
                </div>
                <div className={"col col-2 " + add_marker_class}>
                    <span className="marker"><ProgressCircle starred={false} progress={progress_obj}/></span>
                    <span className="marker"><ProgressCircle starred={true} progress={progress_obj}/></span>
                    <ProgressCircle starred={false} progress={progress_obj}/>
                    <ProgressCircle starred={true} progress={progress_obj}/>
                </div>

            </div>
        )

    }

    render() {

        return (
            <div className="page_wrapper">
                <LandingsHeader show_notifications={false}/>
                <style dangerouslySetInnerHTML={{
                    __html: `
                    .page_wrapper{padding-top: 150px; background: #5c6f5b}
                    pre{ color: #ccc; display: block;}
                    .col-1{ padding: 0 25px; width: 200px;}
                    .col-2 .progress{  padding: 1px 1px 15px; margin: 3px;border-radius: 0;}
                    .marker .progress{background: url(/img/graph_marker.svg) 50% 0 no-repeat;}
                    .col-3{ width: 350px;}
                    .col-4 .course_module_list{ position: static;}
                    .col{display: inline-block; margin-right: 40px;  vertical-align: middle;}
                    .row{margin-bottom: 80px;}
                    `
                }}/>

                {this.renderRow({
                    isAchieved: false,
                    isClosed: false,
                    isDone: false,
                    percentToPass: 75,
                    progressCurrent: 0,
                    progressFailed: 0,
                    progressMax: 1,
                    stepsDone: 0,
                    stepsMax: 3
                }, "gray_marker")}

                {this.renderRow({
                    isAchieved: true,
                    isClosed: false,
                    isDone: false,
                    percentToPass: 75,
                    progressCurrent: 0,
                    progressFailed: 0,
                    progressMax: 1,
                    stepsDone: 0,
                    stepsMax: 3
                }, "gray_marker")}

                {this.renderRow({
                    hash: "1",
                    isAchieved: false,
                    isClosed: false,
                    isDone: false,
                    percentToPass: 75,
                    progressCurrent: 0,
                    progressFailed: 0,
                    progressMax: 1,
                    stepsDone: 0,
                    stepsMax: 3
                })}

                {this.renderRow({
                    hash: "1",
                    isAchieved: false,
                    isClosed: false,
                    isDone: false,
                    percentToPass: 75,
                    progressCurrent: 40,
                    progressFailed: 0,
                    progressMax: 80,
                    stepsDone: 0,
                    stepsMax: 3
                })}
                {this.renderRow({
                    hash: "1",
                    isAchieved: false,
                    isClosed: false,
                    isDone: false,
                    percentToPass: 75,
                    progressCurrent: 40,
                    progressFailed: 20,
                    progressMax: 80,
                    stepsDone: 0,
                    stepsMax: 3
                })}

                {this.renderRow({
                    hash: "1",
                    isAchieved: true,
                    isClosed: false,
                    isDone: false,
                    percentToPass: 75,
                    progressCurrent: 60,
                    progressFailed: 0,
                    progressMax: 80,
                    stepsDone: 0,
                    stepsMax: 3
                })}

                {this.renderRow({
                    hash: "1",
                    isAchieved: false,
                    isClosed: true,
                    isDone: false,
                    percentToPass: 75,
                    progressCurrent: 20,
                    progressFailed: 30,
                    progressMax: 80,
                    stepsDone: 0,
                    stepsMax: 3
                }, 'red_marker')}

                {this.renderRow({
                    hash: "1",
                    isAchieved: true,
                    isClosed: false,
                    isDone: true,
                    percentToPass: 75,
                    progressCurrent: 60,
                    progressFailed: 0,
                    progressMax: 80,
                    stepsDone: 0,
                    stepsMax: 3
                })}
                {this.renderRow({
                    hash: "1",
                    isAchieved: false,
                    isClosed: false,
                    isDone: true,
                    percentToPass: 75,
                    progressCurrent: 60,
                    progressFailed: 30,
                    progressMax: 80,
                    stepsDone: 0,
                    stepsMax: 3
                })}
                {this.renderRow({
                    hash: "1",
                    isAchieved: false,
                    isClosed: true,
                    isDone: true,
                    percentToPass: 75,
                    progressCurrent: 60,
                    progressFailed: 30,
                    progressMax: 80,
                    stepsDone: 0,
                    stepsMax: 3
                })}

                {this.renderRow({
                    hash: "1",
                    isAchieved: true,
                    isClosed: true,
                    isDone: false,
                    percentToPass: 75,
                    progressCurrent: 60,
                    progressFailed: 40,
                    progressMax: 100,
                    stepsDone: 0,
                    stepsMax: 3
                }, 'dark_grey_marker')}

                {this.renderRow({
                    hash: "1",
                    isAchieved: true,
                    isClosed: true,
                    isDone: true,
                    percentToPass: 75,
                    progressCurrent: 60,
                    progressFailed: 0,
                    progressMax: 80,
                    stepsDone: 0,
                    stepsMax: 3
                }, 'green_marker')}


            </div>
        );
    }

}


const mapStateToProps = (state) => {
    return {
        auth_token: state.authReducer.auth_token
    }
};

const mapDispatchToProps = () => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProgressDemoPage);
