import React, {ErrorInfo} from "react";
import Axios from "axios";
import {REPORT_BUG_LVL, REPORT_INITIATOR} from "Cheops/constants";

type State = {
    hasErrors: boolean;
};

export default class ErrorBoundary extends React.Component {

    static getDerivedStateFromError(error: Error): State {

        return {hasErrors: true};

    }

    state: Readonly<State> = {
        hasErrors: false,
    };

    componentDidCatch(error: Error, errorInfo: ErrorInfo): void {

        const VERSION = CONFIG.Version ? `/${CONFIG.Version}` : "";

        /*
            в cytoscape.js присутствует баг.
            если на мобильном устройстве выделить объект на карте и сразу масштабировать
            то возникает одна из перечисленных ошибок с 'emit'.
            разработчик cytoscape на момент релиза 3.12.1 не устранил проблему.
            мы не пишем ошибку в /bug
         */
        let excludeErrorStack = [
            "processRandomSelector",
            "cadesplugin",
            "TypeError: Unable to get property 'emit' of undefined",
            "TypeError: Cannot read property 'emit' of undefined",
            "TypeError: undefined is not an object (evaluating 'R.emit')",
            "Unable to set property 'lastThreeTouch' of undefined or null reference",
            "Failed to execute 'drawImage' on 'CanvasRenderingContext2D':",
            "TypeError: null is not an object (evaluating 'z.touchData.startPosition[0]')",
            "TypeError: null is not an object (evaluating 'z.touchData.startPosition[E]')",
            "TypeError: null is not an object (evaluating 's.scale')",
            "TypeError: null is not an object (evaluating 'v.context.setTransform')",
            "NS_ERROR_NOT_AVAILABLE",
            "TypeError: R is undefined",
            "Uncaught TypeError: Cannot read property 'call' of undefined", // TODO Achtung! this is awful! need to find out
            "yandex.ru/metrika/watch.js"
        ];
        let excludeUserAgent = ['YaSearchBrowser', 'YandexSearchBrowser', 'Googlebot'];

        let reportedErrors = [] as string[];

        const sectionIndex = document.querySelector('section#index');

        if (sectionIndex && !sectionIndex.innerHTML) {

            const excludeIndex = excludeErrorStack.findIndex((item) => error.message.includes(item));
            const excludeBrowserIndex = excludeUserAgent.findIndex((item) => window.navigator.userAgent.includes(item));

            if (CONFIG.Mode === "development"
                || excludeIndex !== -1
                || excludeBrowserIndex !== -1
                || error.stack.includes('vendors~main.js')
            ) {

                return;

            }

            let errorText = `${error.name}:${error.stack}`;

            if (reportedErrors.includes(errorText)) {

                return;

            }

            let url = null;

            if (CONFIG.Instance === 'Noo') {

                url = `${CONFIG.Api.noopolis.url + VERSION}/bug`;

            }

            let headers = {
                'content-type': 'application/json',
                Accept: 'application/json',
            };

            let authToken = localStorage.getItem('auth_token');
            let params = {};

            if (CONFIG.Instance === 'lab' && authToken) {

                url = `${CONFIG.Api.cheopsLaboratory.url + VERSION}/bug`;
                // @ts-ignore
                headers.Authorization = `Bearer ${authToken}`;

            }

            if (CONFIG.Instance === 'smt') {

                // @ts-ignore
                if (!window.bugReportToken) {

                    return;

                }

                url = `/smt-portal/bug`;
                // @ts-ignore
                params = {auth_token: window.bugReportToken};

            }

            if (!url) {

                return;

            }

            Axios({
                method: "POST",
                url,
                headers,
                data: {
                    error: errorText,
                    level: REPORT_BUG_LVL.ERROR,
                    meta: {
                        stack: error.stack,
                        url: location.href,
                    },
                    initiator: REPORT_INITIATOR.FRONT,
                },
                params,
            });

        }

    }

    render(): React.ReactNode {

        if (this.state.hasErrors) {

            Axios.get(`/600.html`).then((v) => {

                document.open();
                document.write(v.data);
                document.close();


                window.addEventListener('hashchange', () => {

                    location.reload();

                }, false);

            });

            return null;

        }

        return this.props.children;

    }

}
