import React                            from "react";
import MarkedWithVideo                  from "Smt/MarkedWithVideo";
import ElementInfoTogglers              from "Cheops/components/ModulePassing/ElementInfoTogglers";

export default class ModuleTextContent extends React.Component {


    render() {

        return (<div className="module_passing_content" data-el_num={this.props.selected_element_num}>
            <div className="module_passing_content__wrapper">
                {this.props.element_content.lecturers && this.props.element_content.lecturers.length > 0
                && <div className="module_teacher_info">
                    <div className="module_teacher_info__photo">
                        <img src={this.props.element_content.lecturers[0].avatar} />
                    </div>
                    <div className="module_teacher_info__name">
                        {this.props.element_content.lecturers[0].firstName}
                        {' '}
                        {this.props.element_content.lecturers[0].middleName}
                        {' '}
                        {this.props.element_content.lecturers[0].lastName}
                    </div>
                </div>}
                <div className="module_passing_title">
                    <h1>{this.props.element_content.caption}</h1>
                </div>
                <div className="module_passing_content_inner">
                    <div className="module_passing_content_inner__description">
                        <MarkedWithVideo>
                            {this.props.element_content.content}
                        </MarkedWithVideo>
                    </div>
                </div>

                <div className="text-center">
                    <ElementInfoTogglers
                        module_id={this.props.module_id}
                        course_id={this.props.course_id}
                        element_type='text'
                        element_content={this.props.element_content}
                        element_progress={this.props.element_progress}
                    />
                </div>

            </div>
        </div>);

    }

}
