import React, {FC}                          from "react";
import bnc                                  from "bnc";
import {useParams}                          from "react-router";
import {BoxAlignment, HBox, Screen, VBox}   from "@sirius/ui-lib/src/blocks/Layout";
import {Button}                             from "@sirius/ui-lib/src/blocks/Controls";
import {cluster}                            from "CheopsRoot/clusters/current";
import {
    ModuleAccessInfoProps,
    TypeErrContext,
    IUrlParams,
    HasClusterKey
}                                           from "./@types";
import                                              "./index.less";


const block = new bnc("module-access-info");
const HOME_TO = '#/';

const getFormattedDate = (dateISO : string) => {
    const formatter = new Intl.DateTimeFormat("ru", {
        day:    "2-digit",
        month:  "long",
        year:   "numeric",
    });

    return formatter.format( new Date(dateISO) ).slice(0, -3) ;
};

const getTypeErrContext = (
    {
        course_id,
        lastVisitedModule,
        lastVisitedElement,
        lastVisitedElementType,
        error,
        clusterKey
    }: NooBack.LearnModuleResponseError & IUrlParams & HasClusterKey
) => {
    const typeErrContext = {} as TypeErrContext;
    const isLastVisited = !!lastVisitedModule && !!lastVisitedElement && !!lastVisitedElementType;

    switch (error?.type) {
        case 'ModuleBlocked':
            typeErrContext.infoMsg      = 'Модуль заблокирован, так как не завершено прохождение предыдущего модуля';
            typeErrContext.buttonText   = 'Продолжить обучение';
            typeErrContext.buttonTo     = isLastVisited ? `#/course/${course_id}/${lastVisitedModule}/${lastVisitedElementType}_${lastVisitedElement}` : `#/course/${course_id}`;
            break
        case 'ModuleClosed':
            typeErrContext.infoMsg      = clusterKey === 'textbook'
                ? 'Урок ещё не открыт для прохождения.\nДля получения доступа к уроку обратитесь к учителю'
                : `Модуль открыт, но его прохождение ограничено по датам.\nПриступить к изучению вы сможете \n${error?.openDateISO ? getFormattedDate(error.openDateISO) : ''}`
            ;
            typeErrContext.buttonText   = 'Перейти к курсу';
            typeErrContext.buttonTo     = `#/course/${course_id}`;
            break
        case 'ModuleBlockedDuringAutograde':
            typeErrContext.infoMsg      = 'Модуль недоступен во время прохождения диагностики';
            typeErrContext.buttonText   = 'Перейти к диагностике';
            typeErrContext.buttonTo     = `#/course/${course_id}/autograde`;
            break
    }
    return typeErrContext
};

const redirectTo = (to = HOME_TO) => location.hash = to;


export const ModuleAccessInfoPage: FC<ModuleAccessInfoProps> = (
    {
        learnModuleError,
        backTo = HOME_TO,
    }
) => {
    const {course_id}    = useParams<IUrlParams>();
    const {title, error} = learnModuleError;

    const hasErrorType = ['ModuleBlocked', 'ModuleClosed', 'ModuleBlockedDuringAutograde'].includes(error?.type);

    const {infoMsg, buttonText, buttonTo} = getTypeErrContext({...learnModuleError, course_id, clusterKey: cluster.key});

    return (
        <Screen className={block + block.mod('cluster', cluster.key) }>

            <HBox className={block.el('header')} align={BoxAlignment.center}>
                <a  className   = {block.el('back-to')}
                    href        = {backTo}
                />
                <div className   = {block.el('logo')}
                     onClick     = {()=>redirectTo()}
                />
            </HBox>

            <VBox className={block.el('content')} >
                <div className={block.el('content-pic')} />
                <VBox className = {block.el('wrapper')}>
                    <div className={block.el('content-title')} >
                        { title }
                    </div>
                    <div className={block.el('content-text')} >
                        { infoMsg }
                    </div>
                </VBox>
                <VBox className={block.el('wrapper')}>
                    { hasErrorType &&
                        <Button className={block.el('button')}
                                mode={'primary'}
                                onClick={()=>redirectTo(buttonTo)}
                        >
                            { buttonText }
                        </Button>
                    }
                    <Button className={block.el('button')}
                            mode={'outlined'}
                            onClick={()=>redirectTo()}
                    >
                        { 'На Главную' }
                    </Button>
                </VBox>
            </VBox>

            <HBox className={block.el('footer')} />

        </Screen>
    )
};
