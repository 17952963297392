import React                from "react";
import bnc                  from "bnc";
import {cluster}            from "CheopsRoot/clusters/current";
import {HBox, LGap, VBox}   from "@sirius/ui-lib/src/blocks/Layout";
import                           "./index.less";

const block = new bnc("prof-cheops-greeting");

const ProfCheopsGreeting: React.FC = ({children}) =>
    <HBox className={block}>
        {
            cluster.type === 'courses' &&
            <>
                <img  style={{width: '160px'}} src={require('CheopsRoot/img/prof_cheops_greeting.svg')}/>
                <LGap className={block.el('gap')}/>
            </>
        }
        <VBox className={block.el('content')}>
            {children}
        </VBox>
    </HBox>
;

export {ProfCheopsGreeting};
