import {BehaviorSubject} from "rxjs";

const isStudentMode$  = new BehaviorSubject(null);

const setIsStudentMode = (v: boolean) => isStudentMode$.next(v);

export {
    isStudentMode$,
    setIsStudentMode
};
