import React, {FC, useState}            from "react";
import {createPortal}                   from "react-dom";
import {Button}                         from "@sirius/ui-lib/src/blocks/Controls";
import {PopoverMenu, PopoverMenuType}   from "@sirius/ui-lib/src/blocks/Layout";
import {MaybeHasMode, MaybeHasSize}     from "@sirius/ui-lib/src/blocks/Controls/Button/ButtonType";

type TeacherPopoverMenuProps =
    {
        intoPortal?: boolean
    }                           &
    Partial<PopoverMenuType>    &
    MaybeHasMode                &
    MaybeHasSize
;

export const TeacherPopoverMenu: FC<TeacherPopoverMenuProps> = (
    {
        mode: buttonMode = 'text',
        size: buttonSize = 's',
        placement = 'bottom-end',
        children ,
        intoPortal= false,
    }
) => {

    const [target, setTarget] = useState(null);

    const toggleMenu = (e: React.MouseEvent): void => {
        e && e.stopPropagation();
        setTarget(!!target ? null : e.currentTarget);
    }

    const uiScreenElement = document.querySelector('.ui-screen');

    return(
        <>
            <Button onClick = {toggleMenu}
                    icon    = {'more_vert'}
                    size    = {buttonSize}
                    mode    = {buttonMode}
                    active  = {!!target}
            />
            { intoPortal
                ?   createPortal(
                        <PopoverMenu target    = {target}
                                     close     = {() => setTarget(null)}
                                     placement = {placement}
                                     wsize     = {'s'}
                        >
                            {children}
                        </PopoverMenu>,
                        uiScreenElement
                    )
                :   <PopoverMenu target    = {target}
                                 close     = {() => setTarget(null)}
                                 placement = {placement}
                                 wsize     = {'s'}
                    >
                        {children}
                    </PopoverMenu>
            }
        </>
    );
};
