import { CourseContinueButton }                 from "./CourseContinueButton";
import { CourseBackButton }                     from "./CourseBackButton";
import type {
    CourseContinueButtonProps,
    CourseBackButtonProps
}                                               from "./@types";
import                                          "./index.less";


export {
    CourseContinueButton,
    CourseBackButton
};

export type {
    CourseContinueButtonProps,
    CourseBackButtonProps
};
