import breakpoints from '@sirius/ui-lib/src/styles/values/breakpoints.json';

export const passResponse = <T>({response}: {response:T}) => Promise.resolve(response);

export const passSuccess  = <T>({success}: {success:T})   => Promise.resolve(success);

export const getClassModalDisabled = (v: string) => {
    const vLength = v?.trim().length;
    return !vLength || !(vLength > 0) || vLength > 30;
};

export const isMaxMobileWidth = () => window.innerWidth > parseInt(breakpoints.max_mobile_width);

const HOME_TEACHER_TO = '#/teacher-room';
export const redirectTo = (to = HOME_TEACHER_TO) => location.hash = to;
