import React, { FC, useState, useEffect } from "react";
import {cluster}                          from "CheopsRoot/clusters/current";
import {
    block,
    courseRouteTpl
}                                          from "./block";
import type {
    IGenLink,
    CourseContinueButtonProps
}                                         from "./@types";
import {
    ETitleButton
}                                         from "./@types";
import                                      "./index.less";

const genLink =( { courseId, lastVisitedModule = null, lastVisitedElement = null }: IGenLink ): string =>  {
    const INIT_LINK = courseRouteTpl(courseId);
    const linkArr: Array<string> = [INIT_LINK];

    if ( lastVisitedModule !=null ) {
        linkArr.push(`${lastVisitedModule}`);
        lastVisitedElement && linkArr.push(`${lastVisitedElement.lastVisitedElementType}_${lastVisitedElement.lastVisitedElement}`)
    }

    return linkArr.join('/');
}

export const CourseContinueButton: FC<CourseContinueButtonProps> = (props) => {
    const {
        currentCourseLearn,
        courseId,
        fromPage,
        disabled = false
    } = props;

    const {
        lastVisitedModule = null,
        lastVisitedElementType,
        lastVisitedElement,
        progressCurrent
    } = currentCourseLearn;


    const [title, setTitle]      = useState<ETitleButton>(ETitleButton.continue);
    const [link, setLink]        = useState<string>(genLink({courseId}));

    useEffect(
        () => {

            const title: ETitleButton = (progressCurrent === 0 && lastVisitedModule === null)
                                        ? ETitleButton.proceed
                                        : ETitleButton.continue
            ;
            setTitle( title );

            if (lastVisitedModule >= 0) {
                const elem = (lastVisitedElementType && lastVisitedElement && lastVisitedElement > 0)
                            ? { lastVisitedElementType, lastVisitedElement }
                            : null
                ;
                const link =  genLink(
                            {
                                courseId,
                                lastVisitedModule,
                                lastVisitedElement: elem
                            }
                        );
                setLink ( link );
            }
        },
        [lastVisitedModule, lastVisitedElementType, lastVisitedElement, progressCurrent, courseId]
    );

    const stopE = (e: React.MouseEvent ) =>{ e.stopPropagation() }

    return  <a
                href={link}
                onClick={stopE}
                className={
                    block +
                    block.mod( 'cluster', cluster.key ) +
                    block.mod( 'frompage', fromPage ? fromPage.toLowerCase() : 'default' ) +
                    block.bod('disabled', disabled)
                }
            >
                {title}
            </a>;

}
