import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as noo_actions from "Cheops/actions/noopolis";
import PlatformLandingPage from "Cheops/containers/PlatformLandingPage";
import PlatformLandingAuthorizedWithMyCoursesPage from "Cheops/containers/PlatformLandingAuthorizedWithMyCoursesPage";
import PlatformLandingAuthorizedPage from "Cheops/containers/PlatformLandingAuthorizedPage";
import CircularProgress from "Smt/Ui";
import Helpers from "Cheops/Helpers";


class PlatformPage extends React.Component {

    constructor(props) {

        super(props);

        let auth_token = props.auth_token;

        this.state = {
            is_loading: true,
            user_is_anon: !auth_token,
            courses: {},
        };

        document.title = `${Helpers.getInstanceTitleForCurrentDeploy()} — Открытая онлайн-школа развития таланта`;

    }


    async componentDidMount() {

        await this.props.getFullCourseList();

        let courses = {};

        if (this.props.full_course_list) {

            for (let [course_index, course_type] of Object.entries(this.props.full_course_list)) {

                courses[course_index] = [];

                let promises_arr = [];

                for (let course_type_item of course_type) {

                    if (course_type_item.hash) {


                        let content = this.props.getCourseBlockContent(course_index, course_type_item.hash);

                        promises_arr.push(content);

                    }

                }

                let courses_arr = await Promise.all(promises_arr);

                for (let course_type_item of course_type) {

                    for (let course_item of courses_arr) {


                        if (course_type_item.hash === course_item.value.hash) {

                            const element = {
                                ...course_type_item,
                                ...course_item.value.data,
                                courseContentProgress: {
                                    ...course_type_item.progress
                                }
                            };

                            courses[course_index].push(element);

                        }

                    }

                }

            }

        }

        await this.props.getMainInfo();

        await this.props.getMainContent(this.props.main_info.hash);

        this.setState({
            is_loading: false,
            courses,
        });

    }


    render() {

        if (this.state.is_loading) {

            return (<CircularProgress centerOfWindow={true} />);

        }

        if (this.state.user_is_anon) {

            return <PlatformLandingPage courses={this.state.courses} />;

        }

        if (this.props.full_course_list.my && (this.props.full_course_list.my.length !== 0 || this.props.full_course_list.invites.length !== 0)) {

            return <PlatformLandingAuthorizedWithMyCoursesPage courses={this.state.courses} />;

        }

        return <PlatformLandingAuthorizedPage courses={this.state.courses} />;

    }

}

const mapStateToProps = (state) => ({
    full_course_list_is_loading: state.nooReducer.full_course_list_is_loading,
    my_course_list: state.nooReducer.my_course_list,
    full_course_list: state.nooReducer.full_course_list,
    auth_token: state.authReducer.auth_token,
    main_info: state.nooReducer.main_info,
    main_content: state.nooReducer.main_content,
});

const mapDispatchToProps = (dispatch) => ({
    getFullCourseList: bindActionCreators(noo_actions.getFullCourseList, dispatch),
    getMainInfo: bindActionCreators(noo_actions.getMainInfo, dispatch),
    getMainContent: bindActionCreators(noo_actions.getMainContent, dispatch),
    getCourseBlockContent: bindActionCreators(noo_actions.getCourseBlockContent, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlatformPage);
