import React                  from 'react'
import bnc                    from "bnc";
import { useWindowIsMobile }  from '@sirius/ui-lib/src/subjects/WindowIsMobile';
import { BoxAlignment, HBox } from '@sirius/ui-lib/src/blocks/Layout';
import { Button, IconButton } from '@sirius/ui-lib/src/blocks/Controls';
import                             './style.less';


type Props = {
    title?:      string;
    buttonText?: string;
    onAction:    () => void;
};

const panelBlock = new bnc('teacher-panel');

export const TeacherPanel = ({title = 'Просмотр под учеником', buttonText = 'Закрыть просмотр', onAction}: Props) => {
    const isMobile = useWindowIsMobile();

    return (
        <HBox className = {panelBlock}
              align     = {BoxAlignment.between}
        >
            <p className={panelBlock.el('title')}>
                {title}
            </p>
            {
                isMobile
                    ? <IconButton icon     = {'close'}
                                  size     = {'s'}
                                  onAction = {onAction}
                    />
                    : <Button icon      = {'close'}
                              iconPlace = {'end'}
                              mode      = {'text'}
                              size      = {'s'}
                              onAction  = {onAction}
                    >
                        {buttonText}
                    </Button>
            }
        </HBox>
    );
};
