import {PopoverMenuItemType}        from '@sirius/ui-lib/src/blocks/Layout/Popover/@types';
import {ProgressIcon}               from '../icons';
import {ModuleCardMenuActionsType}  from '../blocks'


export const getModuleCardMenuItems = (
    {id, title, hasTeacherMaterials, course_id}: Partial<NooBack.Teacher.Methods.CourseModuleInfo>,
    {setRedirect, showModuleAccessHandler}: ModuleCardMenuActionsType
): Array<PopoverMenuItemType> => (
    [
        {
            icon:   ProgressIcon,
            label: 'Посмотреть модуль',
            onAction: () => setRedirect(`/course/${course_id}/${id}`),
        },
        {
            icon:  'description',
            label: 'Посмотреть методические материалы',
            onAction: () => setRedirect(`/teacher-room/course/${course_id}/module/${id}/materials`),
            disabled: !hasTeacherMaterials,
        },
        {
            icon:  'visibility',
            label: 'Управление доступом',
            onAction: showModuleAccessHandler({id, title}),
        },

    ]
);

export const getContestCardMenuItems = (
    {id, title}: Partial<NooBack.Teacher.Methods.ContestInfo>,
    {showContestAccessHandler, downloadContestReport}: ModuleCardMenuActionsType
): Array<PopoverMenuItemType> => (
    [
        {
            icon:    'visibility',
            label:   'Управление доступом',
            onAction: showContestAccessHandler({id, title}),
        },
        {
            icon:    'file_download',
            label:   'Скачать результаты',
            onAction: () => downloadContestReport({id}),
        }
    ]
);
