import React from "react";
import connect from "react-redux/es/connect/connect";
import * as auth_actions from "Cheops/actions/auth";
import * as noo_actions from "Cheops/actions/noopolis";
import {bindActionCreators} from "redux";
import CircularProgress from "Smt/Ui";
import {META_THEME_COLOR_DEFAULT} from "Cheops/constants";
import {ProfCheopsGreeting} from "Cheops/components/ProfCheopsGreeting";
import CourseHeader from "../components/CoursePage/CourseHeader";
import Helpers from "Cheops/Helpers";
import Marked from "@sirius/ui-shared/src/components/DisplayEngine/Marked";

class SendQuestionPage extends React.Component {

    constructor(props) {

        super(props);


        let qa_ident = parseInt(this.props.match.params.qa_ident);
        let course_id = parseInt(this.props.match.params.course_id);
        let module_id = parseInt(this.props.match.params.module_id);

        if (!qa_ident) {

            throw Error("no qa ident");

        }

        if (!course_id) {

            throw Error("no course_id");

        }

        if (!module_id) {

            throw Error("no course_id ");

        }

        let student_first_name = auth_actions.getUserInfo('firstName');

        document.title = `Вопросы-ответы — ${Helpers.getInstanceTitleForCurrentDeploy()}`;

        this.state = {
            qa_ident,
            course_id,
            module_id,
            submit_is_allowed: false,
            is_submitted: this.props.is_submitted,
            student_first_name,
            value: "",
        };

        this.textareaChange = this.textareaChange.bind(this);

    }


    async componentWillMount() {

        this.setState({is_loading: true});

        await this.props.getCourseData(this.props.match.params.course_id);

        if (!this.props.course_contents[this.props.current_course.hash]) {

            await this.props.getCourseContent(this.props.current_course.hash);

        }

        this.setState({is_loading: false});


        let course_contents = this.props.course_contents[this.props.current_course.hash];
        document.querySelector("meta[name=theme-color]").setAttribute("content", course_contents.color);

    }

    componentWillUnmount() {

        document.querySelector("meta[name=theme-color]").setAttribute("content", META_THEME_COLOR_DEFAULT);

    }


    textareaChange() {

        let text_value = document.querySelector(".send_question_page__form_textarea").value;

        this.setState({value: text_value});

        if (text_value !== "") {

            this.setState({
                submit_is_allowed: true,
            });

        } else {

            this.setState({
                submit_is_allowed: false,
            });

        }

    }

    async submitClick() {

        if (this.state.submit_is_allowed) {

            await noo_actions.sendQaQuestion(this.state.qa_ident, this.state.value);

            this.setState({is_submitted: true});

        }

    }

    render() {

        if (this.state.is_loading) {

            return (<CircularProgress centerOfWindow={true} />);

        }

        let cheops_notice = "Обычно мы отвечаем на вопросы в течение 36 часов. В сложных случаях — дольше.";


        let back_button_title = "Вернуться";
        if (this.state.is_submitted) {

            cheops_notice = `${this.state.student_first_name ? `${this.state.student_first_name}, м` : "М"}ы изучим ваш вопрос и ответим на него в течение 36 часов. Разместим ответ в задаче и пришлём уведомление.`;
            back_button_title = "Спасибо!";

        }

        let course_contents = this.props.course_contents[this.props.current_course.hash];


        return (<div className="send_question_page">
            <CourseHeader
                back_path={`/#/qa/${this.state.course_id}_${this.state.module_id}_${this.state.qa_ident}`}
                title="Задать вопрос"
                background="white"
                title_align="center"
                expandable={false}
                course_color={course_contents.color}
                pinned={true}
            />
            <div
                className={`send_question_page__content ${this.state.is_submitted ? "send_question_page__content--submitted" : ""}`}
            >
                <ProfCheopsGreeting>
                    <Marked>{cheops_notice}</Marked>
                </ProfCheopsGreeting>
                {!this.state.is_submitted
                && <form className='send_question_page__form'>
                        <textarea className="send_question_page__form_textarea" placeholder="Задайте вопрос"
                                  onChange={this.textareaChange}
                                  value={this.state.value}
                        />
                    <button
                        type="submit"
                        className={`send_question_page__form_submit send_question_page__form_submit${this.state.submit_is_allowed ? "--allowed" : "--not_allowed"}`}
                        onClick={this.submitClick.bind(this)}
                    />
                </form>}
                <a className="send_question_page__back_button"
                   href={`/#/qa/${this.state.course_id}_${this.state.module_id}_${this.state.qa_ident}`}
                >
                    {back_button_title}
                </a>
            </div>
        </div>);

    }

}


const mapStateToProps = (state) => ({
    news_data: state.nooReducer.news_data,
    current_course: state.nooReducer.current_course,
    course_contents: state.nooReducer.course_contents,
});

const mapDispatchToProps = (dispatch) => ({
    getCourseData: bindActionCreators(noo_actions.getCourseData, dispatch),
    getCourseContent: bindActionCreators(noo_actions.getCourseContent, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SendQuestionPage);
