import React, { FC } from "react";
import {cluster}                          from "CheopsRoot/clusters/current";
import {
    block,
    courseRouteTpl
}                                          from "./block";
import type {
    CourseBackButtonProps
}                                         from "./@types";
import                                      "./index.less";


export const CourseBackButton: FC<CourseBackButtonProps> = (props) => {
    const {
        courseId,
        fromPage,
        icon,
        disabled = false,
        children
    } = props;

    const link = courseRouteTpl(courseId);
    const stopE = (e: React.MouseEvent ) =>{ e.stopPropagation() }

    return  <a
                href={link}
                onClick={stopE}
                className={
                    block +
                    block.mod( `cluster`, cluster.key ) +
                    block.mod( 'frompage', fromPage ? fromPage.toLowerCase() : 'default' ) +
                    block.bod('disabled', disabled)
                }
            >
                { icon &&
                    <div className={block.el('button_icon')} />
                }
                {children}
            </a>;

}
