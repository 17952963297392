import React        from "react";
import {Icon}       from "@sirius/ui-lib/src/blocks/Controls";
import BemClassName from "Cheops/BemClassName";
import ResultTable  from "Cheops/components/ResultTable/ResultTable";


export default class ResultTableHeaderTable extends React.Component {

    hasDeadline = (id) => {

        const {users} = this.props.report;
        if (users) {

            const index = users.findIndex((item) => {

                return item.tasks.findIndex((tItem) => tItem.id === id && tItem.personalDeadline) >= 0;

            });

            return index >= 0;

        }

        return false;

    };

    render() {

        const {groups} = this.props;

        let result_table_header_table_class = new BemClassName("result_table_header_table");

        result_table_header_table_class.appendStatusIf(this.props.mode === ResultTable.MODE.NOO, "student");

        return (
            <div className={result_table_header_table_class}>
                <table>
                    <tbody>
                    <tr>
                        {this.props.report.tasks.map((task) => {

                            let styles = {};
                            const hasPersonalDeadline = this.hasDeadline(task.id);

                            if (groups[task.group] && groups[task.group].color) {

                                styles.color = groups[task.group].color;

                            }

                            return <td key={task.position} style={styles} className="result_table_header_table__task">
                                {task.position}
                                {hasPersonalDeadline && <div className="result_table_cell__timer_icon">
                                    <Icon icon={'timer'} />
                                </div>}
                            </td>;

                        })}
                    </tr>
                    </tbody>
                </table>
            </div>
        );

    }

}
