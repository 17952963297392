import React, {Fragment} from "react";
import {connect} from "react-redux";
import NotificationsList from "Cheops/components/Notifications/NotificationsList";
import {bindActionCreators} from "redux";
import * as noo_actions from "Cheops/actions/noopolis";
import CircularProgress from "Smt/Ui";
import Helpers from "Cheops/Helpers";
import {Footer} from "../components/Footer";
import LandingsHeader from "../components/Landings/LandingsHeader";

class CertificatesPage extends React.Component {

    constructor(props) {

        super(props);

        document.title = `Мои сертификаты — ${Helpers.getInstanceTitleForCurrentDeploy()}`;

        this.state = {
            show_notifications: false,
            is_loading: true,
        };

    }


    async componentWillMount() {

        await this.props.getCertificates();

        this.setState({is_loading: false});

    }


    async requestCertificate(id, course_index, certificate_index) {

        let user_info_status = Object.keys(this.props.certificates.dataStatus)[0];

        if (['unverified', 'declined'].includes(user_info_status)) {

            sessionStorage.setItem('certificate_form-certificate_id', id);
            location.href = "/#/certificate_form";
            return;

        }


        let response = await noo_actions.requestCertificate(id);

        if (response.status === 'success') {

            let certificates = {...this.props.certificates};

            let certificate = certificates.courses[course_index].certificates[certificate_index];

            let status = Object.keys(certificate.status)[0];
            certificate.status.pending = certificate.status[status];
            delete certificate.status[status];

            this.props.setCertificates(certificates);

        }

    }

    async declineCertificate(id, course_index, certificate_index) {

        let response = await noo_actions.declineCertificate(id);

        if (response.status === 'success') {

            let certificates = {...this.props.certificates};

            delete certificates.courses[course_index].certificates[certificate_index];

            this.props.setCertificates(certificates);

        }

    }


    render() {

        if (this.state.is_loading) {

            return (<CircularProgress centerOfWindow={true} />);

        }

        let decline_reason_showed = false;

        let user_info_status = Object.keys(this.props.certificates.dataStatus)[0];

        return (<div className="certificates_page">
                {this.state.show_notifications
                && <NotificationsList hideNotifications={() => this.setState({show_notifications: false})} />}
                <LandingsHeader showNotifications={() => this.setState({show_notifications: true})}
                                land_header_with_bg={true}
                />
                <div className="certificates_page__content">
                    <div className="certificates_page__block_wrapper">
                        <div className="certificates_page__title">
                            Мои сертификаты
                        </div>
                        {this.props.certificates.courses.map((data, course_index) => {

                            let remain_days_showed = false;

                            let remain_days = ((new Date(data.course.closeDate)) - (new Date())) / 1000 / 60 / 60 / 24;
                            remain_days = remain_days.toFixed(0);


                            return (<div className="course_certificates" key={`course_${data.course.id}`}>
                                <div className="course_certificates__course">
                                    Курс
                                    {' '}
                                    <a href={`/#/course/${data.course.id}`}>{data.course.name}</a>
                                    <div className="course_certificates__course_date">
                                        {Helpers.getStartEndDateStr(data.course.openDate, data.course.closeDate)}
                                    </div>
                                </div>

                                {data.certificates.map((certificate, certificate_index) => {

                                    let status = Object.keys(certificate.status)[0];
                                    let requirement = [<Fragment key={0}>{certificate.requirement}</Fragment>];
                                    let show_decline_reason = false;
                                    let request_available = true;

                                    if (!['available', 'quizAvailable'].includes(status)) {

                                        request_available = false;

                                    }

                                    if (user_info_status === 'rejected') {

                                        request_available = false;

                                    }

                                    if (Helpers.isParentMode()) {

                                        request_available = false;

                                    }

                                    if (
                                        !decline_reason_showed
                                        && ['available', 'availableAfter', 'quizAvailable'].includes(status)
                                        && (['declined', 'rejected'].includes(user_info_status))
                                        && this.props.certificates.dataStatus[user_info_status].reason
                                    ) {

                                        show_decline_reason = true;

                                    }


                                    let is_white = ['available', 'ready', 'pending', 'availableAfter', 'quizAvailable'].includes(status);

                                    if (!remain_days_showed && !is_white && !isNaN(remain_days)) {

                                        requirement.push(<Fragment key={1}>
                                            . До завершения курса —
                                            {' '}
                                            <span>
                                                {` ${remain_days} `}
                                                {Helpers.formatPlural(remain_days, ['день', 'дня', 'дней'])}
                                            </span>
                                        </Fragment>);
                                        remain_days_showed = true;

                                    }

                                    if (status === "availableAfter") {

                                        let after_date = new Date(certificate.status[status].date);

                                        requirement = `Получите после ${after_date.toLocaleString('ru', {
                                            day: "numeric",
                                            month: "long",
                                        })}`;

                                    }

                                    if (['available', 'quizAvailable'].includes(status)) {

                                        requirement = "Получите сертификат ";

                                    }

                                    if (status === 'pending') {

                                        requirement = "Вы получите сертификат после проверки ";

                                    }

                                    if (status === 'ready') {

                                        requirement = [<div key={0}
                                                            className="course_certificates__certificate_target_link"
                                        >
                                            <a href={`${CONFIG.Api.noopolis.url}/certificate/${certificate.status[status].hash}/load/certificate`}
                                               target="_blank"
                                               download='certificate.pdf'
                                            >
                                                Скачать файл
                                            </a>
                                            {' (pdf)'}
                                        </div>,
                                            <div key={1} className="course_certificates__certificate_target_link">
                                                            <span className="course_certificates__copy_link"
                                                                  onClick={() => Helpers.copyToClipboard(`${location.origin}/certificate_${certificate.status[status].hash}`)}
                                                            >
                                                                Копировать ссылку
                                                            </span>
                                            </div>,
                                            <div key={2} className="course_certificates__certificate_target_link">
                                                {'Всего выдано: '}
                                                <span>{certificate.count}</span>
                                            </div>];

                                    }

                                    let name_classes = ["course_certificates__name"];

                                    if (is_white) {

                                        name_classes.push("course_certificates__name--bold");

                                    }

                                    let stats_icon = certificate.type;

                                    if (certificate.type === 'course' && certificate.rank === 1) {

                                        stats_icon = 'course_yellow';

                                    }


                                    return (<div
                                        className={`course_certificates__certificate course_certificates__certificate--${status}`}
                                        key={certificate_index}
                                    >
                                        <div className={name_classes.join(" ")}>
                                            {certificate.status[status].hash
                                            && <a href={`/#/certificate_${certificate.status[status].hash}`}>
                                                {is_white
                                                && <svg>
                                                    <use
                                                        xlinkHref={`${require("Cheops/../img/certificate_stats_icon.svg")}#${stats_icon}`}
                                                    />
                                                </svg>}
                                                {certificate.info.name}
                                                {!!certificate.payload && !!certificate.payload.percent_score >= 0 && <>
                                                    {` — ${certificate.payload.percent_score.toFixed(0)}%`}
                                                </>}
                                            </a>}
                                            {!certificate.status[status].hash
                                            && <>
                                                {is_white
                                                && <svg>
                                                    <use
                                                        xlinkHref={`${require("Cheops/../img/certificate_stats_icon.svg")}#${stats_icon}`}
                                                    />
                                                </svg>}
                                                {certificate.info.name}
                                                {!!certificate.payload && !!certificate.payload.percent_score >= 0
                                                && <>{` — ${certificate.payload.percent_score.toFixed(0)}%`}</>}
                                            </>}
                                        </div>
                                        <div className="course_certificates__row">
                                            <div className="course_certificates__certificate_target">
                                                {requirement}
                                            </div>
                                            {['available', 'availableAfter'].includes(status)
                                            && <div className="course_certificates__certificate_right">
                                                <button className="course_certificates__btn"
                                                        onClick={() => this.requestCertificate(certificate.status[status].id, course_index, certificate_index)}
                                                        disabled={!request_available}
                                                >
                                                    Заказать
                                                </button>
                                            </div>}
                                            {status === 'quizAvailable'
                                            && <div className="course_certificates__certificate_right">
                                                <button
                                                    className="course_certificates__btn course_certificates__btn--light"
                                                    onClick={() => this.declineCertificate(certificate.status[status].id, course_index, certificate_index)}
                                                    disabled={!request_available}
                                                >
                                                    Отказаться
                                                </button>
                                                <button
                                                    className="course_certificates__btn"
                                                    disabled={!request_available}
                                                    onClick={() => this.requestCertificate(certificate.status[status].id, course_index, certificate_index)}
                                                >
                                                    Заказать
                                                </button>
                                            </div>}
                                            {status === 'pending'
                                            && <div className="course_certificates__certificate_right">
                                                Проверка
                                                <svg>
                                                    <use
                                                        xlinkHref={`${require("Cheops/../img/certificate_stats_icon.svg")}#pending`}
                                                    />
                                                </svg>
                                            </div>}
                                            {status === 'ready'
                                            && <a href={`/#/certificate_${certificate.status[status].hash}`}
                                                  className="course_certificates__certificate_right"
                                            >
                                                <img className="course_certificates__certificate_img"
                                                     src={require(`../../img/certificate_demo_${stats_icon}.png`)}
                                                />
                                            </a>}

                                            {!['available', 'ready', 'pending', 'availableAfter', 'quizAvailable'].includes(status)

                                            && <div className="course_certificates__certificate_right">
                                                {'Всего выдано: '}
                                                <span>{certificate.count}</span>
                                                <svg>
                                                    <use
                                                        xlinkHref={`${require("Cheops/../img/certificate_stats_icon.svg")}#${stats_icon}`}
                                                    />
                                                </svg>
                                            </div>}
                                        </div>

                                        {show_decline_reason
                                        && <div className="course_certificates__reject_reason">
                                            <div>Проф. Хеопс</div>
                                            {this.props.certificates.dataStatus[user_info_status].reason}
                                        </div>}

                                        {!!certificate.status[status].progress
                                        && <div className="course_certificates__certificate_progress">
                                            <div style={{width: `${certificate.status[status].progress}%`}} />
                                        </div>}
                                    </div>);

                                })}
                            </div>);

                        })}

                        <div className="certificates_page__about">
                            <div className="certificates_page__about_title">О сертификатах</div>
                            <div className="certificates_page__about_text">
                                Ваши сертификаты будут храниться на этой странице. Вы сможете скачивать,
                                распечатывать,
                                отправлять ссылки на электронные версии, подтверждать их подлинность.
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );

    }

}


const mapStateToProps = (state) => ({
    certificates: state.nooReducer.certificates,
    certificate_contents: state.nooReducer.certificate_contents,
});

const mapDispatchToProps = (dispatch) => ({
    setCertificates: (data) => dispatch(noo_actions.setCertificates(data)),
    getCertificates: bindActionCreators(noo_actions.getCertificates, dispatch),
    getCertificate: bindActionCreators(noo_actions.getCertificate, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CertificatesPage);
