import React from "react";
import "./AutoGradeTimeout.less";
import Helpers from "Cheops/Helpers";

export default class AutoGradeTimeout extends React.Component {


    static defaultProps = {
        toNext: null,
    };


    render() {

        let isParentMod = Helpers.isParentMode();

        return (<div className="autograde_timeout">
            <div className="autograde_timeout__center">
                <div>
                    <img src={require("CheopsRoot/img/prof_cheops_nothing.svg")} />
                    <div className="title">Время истекло</div>
                    <div className="text">Вы не ввели ответ</div>
                </div>
            </div>
            <div className="course_autograde_page__bottom_button_wrapper">
                <button disabled={isParentMod} className="course_autograde_page__bottom_button"
                        onClick={this.props.toNext}
                >
                    Дальше
                </button>
            </div>
        </div>);

    }

}
