import React from "react";
import Helpers from "Cheops/Helpers";

export default class LandingBlockProgram extends React.Component {

    static defaultProps = {
        block_id: null,
        modules_count: null,
        advanced_modules_count: null,
        course_content_by_hash: {},
        current_course: {},
    };

    render() {

        let modules_number = <span>
            <nobr>
                {`${this.props.modules_count} `}
                {Helpers.formatPlural(this.props.modules_count, ["обязательного", "обязательных", "обязательных"])}
            </nobr>
            {' и '}
            <nobr>
                {`${this.props.advanced_modules_count} `}
                {Helpers.formatPlural(this.props.advanced_modules_count, ["лекционного", "лекционных", "лекционных"])}
            </nobr>
            {" модулей"}
        </span>;

        if (this.props.modules_count === 0) {

            modules_number = `${this.props.advanced_modules_count} ${Helpers.formatPlural(this.props.advanced_modules_count, ["модуля", "модулей", "модулей"])}`;

        }

        if (this.props.advanced_modules_count === 0) {

            modules_number = `${this.props.modules_count} ${Helpers.formatPlural(this.props.modules_count, ["модуля", "модулей", "модулей"])}`;

        }

        return (
            <div className="landings_wrapper">
                <div data-target="program"
                     className={`course_landing_page__section course_landing_page__${this.props.block_id}`}
                     data-nav={this.props.block_id}
                >

                    <div className="landings_wrapper__title">
                        {`Курс состоит из `}
                        {modules_number}
                    </div>

                    <div className="course_landing_page__program_composition">
                        <div
                            className="course_landing_page__program_composition_item course_landing_page__program_composition_summary"
                        >
                            <div
                                className="course_landing_page__program_composition_item_logo course_landing_page__program_composition_item_logo_summary"
                            />
                            <div className="course_landing_page__program_composition_item_counts">
                                {`Модули: ${this.props.course_content_by_hash.modulesCount}`}
                                <br />
                                {`Видеолекции: ${this.props.course_content_by_hash.lecturesCount}`}
                                <br />
                                {`Задачи: ${this.props.course_content_by_hash.tasksCount}`}
                            </div>
                        </div>
                        <div
                            className="course_landing_page__program_composition_item course_landing_page__program_composition_certificate"
                        >
                            <div
                                className="course_landing_page__program_composition_item_logo course_landing_page__program_composition_item_logo_certificate"
                            />

                            <div className="course_landing_page__program_composition_item_counts">
                                Сертификат
                                <br />
                                (100% учебных модулей)
                            </div>
                        </div>
                        <div
                            className="course_landing_page__program_composition_item course_landing_page__program_composition_progresses"
                        >
                            <div
                                className="course_landing_page__program_composition_item_logo course_landing_page__program_composition_item_logo_progresses"
                            />
                            <div className="course_landing_page__program_composition_item_counts">
                                Завершили:
                                {` ${this.props.current_course.finished}`}
                                <br />
                                Изучают:
                                {` ${this.props.current_course.inProgress}`}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

    }

}
