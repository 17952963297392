import React from "react";
import "./AutoGradeNothingToDo.less";

export default class AutoGradeNothingToDo extends React.Component {


    static defaultProps = {
        backLink: null,
    };

    render() {

        return (<div className="autograde_nothing_to_do">
            <div className="autograde_nothing_to_do__center">
                <div>
                    <img src={require("CheopsRoot/img/prof_cheops_nothing_to_do.svg")} />
                    <div className="title">Мы не можем предложить вам задания для тестирования</div>
                    <div className="text">
                        Это значит, что вы уже получили зачёт по всем темам из теста. Поздравляем!
                    </div>
                </div>
            </div>
            <div className="course_autograde_page__bottom_button_wrapper">
                <a href={this.props.backLink} className="course_autograde_page__bottom_button"
                   onClick={this.props.toNext}
                >
                    Вернуться в курс
                </a>
            </div>
        </div>);

    }

}
