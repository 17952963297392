import React                        from "react";
import {bindActionCreators}         from "redux";
import {connect}                    from "react-redux";
import cloneDeep                    from "lodash/cloneDeep";

import {HBox}                       from "@sirius/ui-lib/src/blocks/Layout";
import Marked                       from "@sirius/ui-shared/src/components/DisplayEngine/Marked";
import {CircularProgress}           from "@sirius/ui-lib/src/blocks/CircularProgress";

import * as autograde_actions       from "Cheops/actions/autograde";
import * as passing_actions         from "Cheops/actions/passing";
import BemClassName                 from "Cheops/BemClassName";
import {META_THEME_COLOR_DEFAULT}   from "Cheops/constants";
import Helpers                      from "Cheops/Helpers";

import {CourseSidebarHeader}        from "Cheops/components/CoursePage/V2/CourseSidebarHeader";
import {ProfCheopsGreeting}         from "Cheops/components/ProfCheopsGreeting";
import {CourseToC}                  from "Cheops/components/CourseToC";
import {TOCSidebar}                 from "Cheops/components/CourseToC/TOCSidebar";
import CourseCards                  from "Cheops/components/CoursePage/CourseCards";
import CourseGraph                  from "Cheops/components/CoursePage/CourseGraph";
import {CourseSidebar}              from "Cheops/components/CoursePage/V2/CourseSidebar";
import ModuleCard                   from "Cheops/components/ModuleCard/ModuleCard";
import CourseProgressForm           from "Cheops/components/CoursePage/CourseProgressForm";
import CourseModuleList             from "Cheops/components/CoursePage/CourseModuleList";
import ProfCheopsMessage            from "Cheops/components/ProfCheopsMessage";
import ModuleTransferProgressCard   from "Cheops/components/ModuleCard/ModuleTransferProgressCard";
import TransferProgressPopup        from "Cheops/components/CoursePage/TransferProgressPopup";
import AutoGradeOnGoingCard         from "Cheops/components/CoursePage/AutoGradeOnGoingCard";
import CourseTrainingCard           from "Cheops/components/CoursePage/CourseTrainingCard";
import AutoGradePopup               from "Cheops/components/AutoGrade/AutoGradePopup";
import MessagePopup                 from "Cheops/components/MessagePopup";
import NotificationsList            from "Cheops/components/Notifications/NotificationsList";

import {focusMapStatuses,
        toggleMapFocused,
        setSelectedModuleId}        from "../actions";
import * as noo_actions             from "../actions/noopolis";


class UserCoursePage extends React.Component {
    static SIDEBAR_ELEM_PROGRESS      = 'progress';
    static SIDEBAR_ELEM_NOTIFICATIONS = 'notifications';

    constructor(props) {
        super(props);
        let course_contents = this.props.course_contents[this.props.current_course_learn.hash];
        document.title = `${course_contents.name} — ${Helpers.getInstanceTitleForCurrentDeploy()}`;
        this.state = {
            isLoading:                    true,
            sidebar_elements:             [],
            sidebar_elements_params:      [],
            errors:                       [],
            module_list_expanded:         false,
            lock_bar_expanded:            true,
            popup_info:                   null,
            is_transfer_progress_loading: false,
            show_finish_popup:            false,
            isTocListExpanded:            false,
            autograde_status:            { value: {} }
        };
    }

    async componentWillMount() {
        await this.props.getCourseStats(this.props.course_id);
        await this.props.getCourseBuckets(this.props.course_id);
        let autograde_status = this.state.autograde_status;
        if (this.props.current_course_learn.autogradeState === 'started') {
            autograde_status = await autograde_actions.getAutogradeStatus(
                this.props.course_id,
                this.props.current_course_learn.autograde
            );
        }
        this.props.changeSelectedElementNum(0);
        this.setState({
            autograde_status,
            isLoading: false,
        });
        let course_contents = this.props.course_contents[this.props.current_course_learn.hash];
        document.querySelector("meta[name=theme-color]").setAttribute("content", course_contents.color);
        window.addEventListener("resize", this.rerender);
        window.addEventListener('scroll', this.handleExpandingMap);
    }

    async componentWillReceiveProps({course_id, chapter_num}) {
        if (this.props.course_id !== course_id) {
            await this.props.getCourseStats(
                this.props.course_id
            );
        }
        this.props.chapter_num !== chapter_num && this.props.expandMap();
    }

    componentWillUnmount() {
        document.querySelector("meta[name=theme-color]").setAttribute("content", META_THEME_COLOR_DEFAULT);
        this.props.setSelectedModuleId(null);
        if (this.props.expanded) {
            this.props.expandMap();
        }
        window.removeEventListener("resize", this.rerender);
        window.removeEventListener('scroll', this.handleExpandingMap);
    }

    rerender = () => {
        this.forceUpdate();
    };

    removeHelpFromLearn = (helpId) => {
        let courseLearn = cloneDeep(this.props.current_course_learn);
        let index = courseLearn.help.indexOf(helpId);
        if (index === -1) {
            throw new Error('Help was not found in course learn');
        }
        courseLearn.help.splice(index, 1);
        this.props.setCourseLearnData(courseLearn);
    };

    setShowNotifications() {
        this.setState({
            sidebar_elements: [UserCoursePage.SIDEBAR_ELEM_NOTIFICATIONS],
            sidebar_elements_params: []
        });
        this.props.expandMap();
    }

    handleExpandingHeader = () => {
        const {expandHeader, setSelectedModuleId} = this.props;
        this.setState({
            module_list_expanded:    false,
            sidebar_elements:        [],
            sidebar_elements_params: [],
        });

        expandHeader();
        setSelectedModuleId(null);
    }

    handleExpandingToc = () => {
        const {setSelectedModuleId} = this.props;
        this.setState({
            module_list_expanded:    false,
            sidebar_elements:        [],
            sidebar_elements_params: [],
            isTocListExpanded:       true
        });

        setSelectedModuleId(null);
    }

    closeTocList = () => {
        const {isTocListExpanded} = this.state;

        isTocListExpanded &&
        this.setState({isTocListExpanded: false});
    };

    openModulesList = () =>
        this.setState({
            module_list_expanded:    true,
            sidebar_elements:        [],
            sidebar_elements_params: [],
        })
    ;

    handleExpandingMap = () => {
        this.setState({
            module_list_expanded:    false,
            sidebar_elements:        [],
            sidebar_elements_params: [],
            isTocListExpanded:       false
        });
        this.props.expandMap();
    };


    renderItemMenu = () => {
        const old_link = [
            {
                caption: 'Тестирование',
                link:    'final_test',
                field:   'finalTest'
            }, {
                caption: 'О курсе',
                link:    'about',
                field:   'about',
            }, {
                caption: 'Правила',
                link:    'rules',
                field:   'rules'
        }];

        const array = [];
        const course_contents = this.props.course_contents[this.props.current_course_learn.hash];
        let widgets = [];

        if (
            course_contents.widgets &&
            course_contents.widgets.length
        ) {
            widgets = course_contents.widgets;
            old_link.forEach(
                (wgt, index) => {
                    if (
                        course_contents[wgt.field] &&
                        widgets.findIndex(
                            (f_item) => f_item.link === wgt.link
                        ) < 0
                    ) {
                        widgets.push(old_link[index]);
                    }
                }
            );
        } else {
            old_link.forEach((wgt, index) => {
                if (course_contents[wgt.field]) {
                    widgets.push(old_link[index]);
                }
            });
        }
        for (let entry of widgets) {
            array.push({
                title: entry.caption,
                link: `/#/course/${this.props.course_id}/${entry.link}`
            });
        }
        return array;
    };

    setSelectedModuleId = (module_id) => {
        this.props.setSelectedModuleId(module_id);
        this.setState({
            module_list_expanded:    false,
            sidebar_elements:        [],
            sidebar_elements_params: [],
        });
        this.props.expandMap();
    };

    showSidebarElements(sidebar_elements, sidebar_elements_params) {
        this.setState({
            module_list_expanded: false,
            sidebar_elements,
            sidebar_elements_params,
        });
        this.props.expandMap();
    }


    finishAutograde = async () => {
        await autograde_actions.doAutogradeAction(
            this.props.course_id,
            this.props.current_course_learn.autograde, "finish"
        );
        this.props.updateCourseData(this.props.course_id);

    };

    transferClick = async () => {

        if (this.props.current_course_learn.autogradeState &&
            this.props.current_course_learn.autogradeState === "started"
        ) { return; }

        this.setState({is_transfer_progress_loading: true});

        let popup_info = null;
        let transferring = await noo_actions.transferProgress(this.props.course_id);
        if (transferring.error) {
            this.setState({errors: transferring.error, is_transfer_progress_loading: false});
            return;
        }
        if (transferring.success) {
            popup_info = transferring.success;
        }
        this.setState({
            popup_info,
            is_transfer_progress_loading: false,
        });
    };

    closeTransferPopup = async () => {
        location.reload();
    };


    closeErrorPopup = (errorIndex) => {
        const {errors} = this.state;
        errors.splice(errorIndex, 1);
        this.setState({errors});
    };

    render() {

        if (this.state.isLoading) {
            return (<CircularProgress centerOfWindow={true} />);
        }

        const {current_course_learn} = this.props;
        let course_contents = this.props.course_contents[current_course_learn.hash];
        let course_page_class = new BemClassName("course_page");
        let main_menu_items = this.renderItemMenu();
        course_page_class.appendStatusIf(course_contents.hasCards, 'cards');
        course_page_class.appendStatusIf(current_course_learn.autogradeState === 'started', 'dark-overlay');

        if (!!course_contents.section && (course_contents.section.length > 0)) {
            for (let section of course_contents.section) {
                if (!!section.skin && section.skin === "contest") {
                    main_menu_items.push({
                        title: section.title,
                        link: `/#/course/${this.props.course_id}/section_${section.id}`,
                    });
                }
            }
        }

        if (current_course_learn.autograde) {
            if (current_course_learn.autogradeState && current_course_learn.autogradeState !== "canStart") {
                main_menu_items.push({
                    title: 'Проверка знаний',
                    link: `/#/course/${this.props.course_id}/autograde`,
                });
            } else {
                main_menu_items.unshift({
                    title: 'Проверка знаний',
                    className: this.props.current_course_learn.progressCurrent > 0 ? "" : "primary",
                    link: `/#/course/${this.props.course_id}/autograde`,
                });
            }
        }

        const index_about = main_menu_items.findIndex(
            (item) => item.link === `/#/course/${this.props.course_id}/about`
        );

        if (index_about >= 0) {
            main_menu_items.splice(index_about, 1);
        }

        if (this.props.current_course_learn.canTransfer) {
            main_menu_items.push({
                title: 'Автопрохождение',
                link: `/#/course/${this.props.course_id}/progress_transfer`,
            });
        }

        main_menu_items.push({
            title: 'О курсе',
            link: `/#/course/${this.props.course_id}/about`,
        });

        const isTextbook = course_contents.type === 'textbook';
        let headerExpanded = this.props.expanded;
        if (window.matchMedia("(min-width: 761px)").matches) {
            headerExpanded = true;
            if (this.state.sidebar_elements.length || this.props.selected_module_id) {
                headerExpanded = false;
            }
        }

        if (this.state.module_list_expanded) {
            headerExpanded = false;
        }

        let error = null;

        if (this.state.errors.length > 0) {
            error = this.state.errors[0];
        }

        return (
            <div className={course_page_class}>
                {
                    this.state.show_finish_popup &&
                    <AutoGradePopup
                        leftButtonText  = "Нет"
                        rightButtonText = "Завершить"
                        onLeftClick     = {() => this.setState({show_finish_popup: false})}
                        onRightClick    = {this.finishAutograde}
                    >
                    <strong>Завершить тест?</strong>
                    <div>Вы не сможете пройти тест ещё раз, если завершите его</div>
                </AutoGradePopup>}
                {
                    error &&
                    <MessagePopup
                        rightButtonText = "Закрыть"
                        onRightClick    = {() => this.closeErrorPopup(0)}
                    >
                        <h2>{error.message}</h2>
                        {!!error.meta && error.meta.action}
                    </MessagePopup>
                }
                {
                    this.state.is_transfer_progress_loading &&
                    <div className="course_page__waiting">
                        <CircularProgress centerOfWindow/>
                        <div className="course_page__waiting_text">
                            Мы пересчитываем прогресс по курсу...
                        </div>
                    </div>
                }
                {
                    this.state.popup_info &&
                    <TransferProgressPopup
                        closeTransferPopup = {this.closeTransferPopup}
                        task_number        = {this.state.popup_info.tasksCount}
                    />
                }
                {
                    this.props.applied_autograde_count &&
                    <TransferProgressPopup
                        closeTransferPopup = {() => this.props.setAppliedAutogradeCount(null)}
                        type               = {TransferProgressPopup.PROGRESS_TYPE.THEMES}
                        task_number        = {this.props.applied_autograde_count}
                    />
                }
                {
                    course_contents.hasToc
                        ? <CourseToC
                            learn                = {this.props.current_course_learn}
                            modules              = {course_contents.module}
                            toc                  = {course_contents.toc}
                            courseId             = {this.props.course_id}
                        />
                        :
                    course_contents.hasCards
                        ? <CourseCards
                            current_course_learn = {this.props.current_course_learn}
                            module_list          = {course_contents.module}
                            course_contents      = {this.props.course_contents}
                            course_id            = {this.props.course_id}
                            course_stats         = {this.props.course_stats}
                            course_color         = {course_contents.color}
                            isTextbook           = {isTextbook}
                        />
                        :
                    course_contents.hasMap
                        ? <CourseGraph
                            selected_module_id   = {this.props.selected_module_id}
                            current_course_learn = {this.props.current_course_learn}
                            handleExpandingMap   = {() => this.handleExpandingMap()}
                            setSelectedModuleId  = {this.props.setSelectedModuleId}
                            course_id            = {this.props.course_id}
                            course_stats         = {this.props.course_stats}
                            hide_course_page     = {true}
                            show_notifications   = {this.state.show_notifications}
                            course_contents      = {course_contents}
                        />
                        :
                        null
                }
                <CourseSidebar
                    headerExpanded = {headerExpanded}
                    header = {
                        <CourseSidebarHeader
                            courseColor        = {course_contents.color}
                            currentCourseLearn = {this.props.current_course_learn}
                            courseContents     = {course_contents}
                            expanded           = {headerExpanded}
                            showNotifications  = {true}
                            onProgressClick    = {
                                (num) => this.showSidebarElements(
                                    [UserCoursePage.SIDEBAR_ELEM_PROGRESS],
                                    [num]
                                )
                            }
                            menuItems          = {main_menu_items}
                            expandHeader       = {this.handleExpandingHeader}
                            expandToc          = {this.handleExpandingToc}
                            onNotificationsClick  = {() => this.showSidebarElements([UserCoursePage.SIDEBAR_ELEM_NOTIFICATIONS])}
                        />
                    }
                    showModuleList     = {!this.state.sidebar_elements.length}
                    moduleListExpanded = {this.state.module_list_expanded}
                    moduleList={
                        <>
                            {
                                course_contents.hasToc &&
                                <TOCSidebar
                                    toc      = {course_contents.toc}
                                    courseId = {`${this.props.course_id}`}
                                    expanded = {this.state.isTocListExpanded}
                                    onClose  = {this.closeTocList}
                                />
                            }
                            {!course_contents.hasMap && <div />}
                            { !this.props.selected_module_id && course_contents.hasMap &&
                                <CourseModuleList courseId                    = {this.props.course_id}
                                                  moduleListExpanded          = {this.state.module_list_expanded}
                                                  openModulesList             = {this.openModulesList}
                                                  currentCourseLearn          = {this.props.current_course_learn}
                                                  currentCourseModulesContent = {course_contents.module}
                                />
                            }
                        </>
                    }
                >
                    {
                        !!this.props.selected_module_id &&
                        <ModuleCard
                            showRequired
                            course_color        = {course_contents.color}
                            course_id           = {this.props.course_id}
                            selected_module_id  = {this.props.selected_module_id}
                            module_list         = {course_contents.module}
                            course_learn        = {this.props.current_course_learn}
                            course_stats        = {this.props.course_stats.moduleStatistics[this.props.selected_module_id]}
                            setSelectedModuleId = {this.setSelectedModuleId}
                        />
                    }
                    {
                        this.state.sidebar_elements.includes(
                            UserCoursePage.SIDEBAR_ELEM_NOTIFICATIONS
                        ) &&
                        <NotificationsList
                            hideNotifications={() => this.setState({sidebar_elements: []})}
                        />
                    }
                    {
                        this.state.sidebar_elements.includes(UserCoursePage.SIDEBAR_ELEM_PROGRESS) &&
                        <CourseProgressForm
                            courseContentProgress = {course_contents.progress}
                            courseBuckets         = {this.props.course_buckets}
                            courseProgress        = {this.props.current_course_learn.progress}
                            courseData            = {this.props.current_course_learn}
                            showLevel             = {this.state.sidebar_elements_params[this.state.sidebar_elements.indexOf(UserCoursePage.SIDEBAR_ELEM_PROGRESS)]}
                            levelStatistics       = {this.props.course_stats.levelStatistics}
                        />
                    }
                    {
                        current_course_learn.autogradeState === 'started' &&
                        <AutoGradeOnGoingCard
                            course_id={this.props.course_id}
                            status={this.state.autograde_status}
                            openPopup={() => this.setState({show_finish_popup: true})}
                        />
                    }
                    {
                        this.state.sidebar_elements.length === 0 && !this.props.selected_module_id &&
                        <>
                        {
                            this.props.current_course_learn.help &&
                            this.props.current_course_learn.help.map(
                                (widget_id) =>
                                    <CourseTrainingCard
                                        course_id = {this.props.course_id}
                                        key       = {widget_id}
                                        onDone    = {this.removeHelpFromLearn}
                                        widgetObj = {
                                            course_contents.help.find(
                                                (widget) => widget.id === widget_id
                                            )
                                        }
                                    />
                            )
                        }
                        <ProfCheopsMessage
                            markAsReadNotification = {this.props.markAsReadNotification}
                            notifications          = {this.props.notifications}
                            unpinNotification      = {(id) => this.props.unpinNotification(id)}
                        />
                        {
                            this.props.current_course_learn.canTransfer &&
                           !this.props.current_course_learn.transferDeclined &&
                            <ModuleTransferProgressCard
                                transfer_page_link = {`/#/course/${this.props.course_id}/progress_transfer`}
                                transferClick      = {this.transferClick}
                                disabled           = {
                                    this.props.current_course_learn.autogradeState &&
                                    this.props.current_course_learn.autogradeState === "started"
                                }
                            />
                        }
                        </>
                    }
                </CourseSidebar>

                {
                    this.props.current_course_learn.availability.locked &&
                    <div className="lock_bar">
                        {
                            this.state.lock_bar_expanded
                                ? <>
                                    <HBox center className='lock_bar__wrapper'>
                                        <ProfCheopsGreeting>
                                            <Marked>
                                                {this.props.current_course_learn.availability.locked.message}
                                            </Marked>
                                        </ProfCheopsGreeting>
                                    </HBox>
                                    <div
                                        className="lock_bar__close"
                                        onClick={() => this.setState({lock_bar_expanded: false})}
                                    >
                                        <svg width="11" height="11" viewBox="0 0 15 15" fill="none"
                                             xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M13.3 0.709971C12.91 0.319971 12.28 0.319971 11.89 0.709971L6.99997 5.58997L2.10997 0.699971C1.71997 0.309971 1.08997 0.309971 0.699971 0.699971C0.309971 1.08997 0.309971 1.71997 0.699971 2.10997L5.58997 6.99997L0.699971 11.89C0.309971 12.28 0.309971 12.91 0.699971 13.3C1.08997 13.69 1.71997 13.69 2.10997 13.3L6.99997 8.40997L11.89 13.3C12.28 13.69 12.91 13.69 13.3 13.3C13.69 12.91 13.69 12.28 13.3 11.89L8.40997 6.99997L13.3 2.10997C13.68 1.72997 13.68 1.08997 13.3 0.709971Z"
                                                fill="#B0B4B9"
                                            />
                                        </svg>
                                    </div>
                                </>
                                : this.props.current_course_learn.availability.locked.shortMessage
                        }
                    </div>
                }
            </div>
        );
    }
}


const mapStateToProps = (state) => ({
    expanded:                   state.mapIsFocused === focusMapStatuses.NOT_FOCUSED,
    selected_module_id:         state.selectedModuleId,
    current_course:             state.nooReducer.current_course,
    current_course_learn:       state.nooReducer.current_course_learn,
    course_contents:            state.nooReducer.course_contents,
    course_stats:               state.nooReducer.course_stats,
    course_buckets:             state.nooReducer.course_buckets,
    notifications:              state.nooReducer.notifications,
    applied_autograde_count:    state.autogradeReducer.applied_autograde_count,
});

const mapDispatchToProps = (dispatch) => ({
    setCourseLearnData:       (learn_obj)   => dispatch(noo_actions.setCourseLearnData(learn_obj)),
    changeSelectedElementNum: (element_num) => dispatch(passing_actions.changeSelectedElementNum(element_num)),
    expandMap:                ()            => dispatch(toggleMapFocused(focusMapStatuses.FOCUSED)),
    expandHeader:             ()            => dispatch(toggleMapFocused(focusMapStatuses.NOT_FOCUSED)),
    setAppliedAutogradeCount: (count)       => dispatch(autograde_actions.setAppliedAutogradeCount(count)),
    setSelectedModuleId:      (module_id)   => dispatch(setSelectedModuleId(module_id)),
    getCourseStats:           bindActionCreators(noo_actions.getCourseStats, dispatch),
    getCourseBuckets:         bindActionCreators(noo_actions.getCourseBuckets, dispatch),
    getCourseLearnData:       bindActionCreators(noo_actions.getCourseLearnData, dispatch),
    markAsReadNotification:   bindActionCreators(noo_actions.markAsReadNotification, dispatch),
    unpinNotification:        bindActionCreators(noo_actions.unpinNotification, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserCoursePage);
