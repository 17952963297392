/* eslint-disable */
import React from "react";
import {connect} from "react-redux";
import LandingsHeader from "../components/Landings/LandingsHeader";
import ElementNavItem from "Cheops/components/ModulePassing/ElementNavItem";
import ColorUtils from "Cheops/Utils/ColorUtils";
import * as noo_actions from "Cheops/actions/noopolis";


class ProgressDemoPage extends React.Component {

    taskNumber = 1;

    getProgressObj(isClosed = false, verdict = 'none', reviewStatus = null, hash = 'has') {

        return {
            hash,
            isClosed,
            type: "task",
            verdict,
            reviewStatus,
            taskNumber: ++this.taskNumber
        };

    }

    constructor() {
        super();

        this.state = {
            icon: null,
            course_color: '#1a2b2a',
            data_window_content: "",
            data_window_styles: {},
        };
    }

    async componentDidMount() {

        this.setState({icon: await noo_actions.getContentImage(2)});
    }

    showProgress(index, module_progress) {

        let data = module_progress.elements[index];
        this.setState({data_window_content: JSON.stringify(data, null, 2)});
    }


    renderGroup(elements, group_summary_only = false) {

        let group_items = [];

        let module_progress = {
            elements: []
        };

        let content = {
            groups: [{to: elements.length, from: 1}],
            variant: []
        };

        for (let element of elements) {

            content.variant.push({value: [{element: {isAdvanced: false, id: 0}}], icon: this.state.icon});
            module_progress.elements.push(element);

        }


        if (!group_summary_only) {

            for (let index in module_progress.elements) {

                index = parseInt(index);

                group_items.push(
                    <div
                        key={index}
                        onMouseEnter={() => this.showProgress(index, module_progress)}
                        onMouseLeave={() => this.setState({data_window_content: ""})}
                    >
                        <ElementNavItem
                            element_index={index}
                            module_content={content}
                            module_progress={module_progress}
                            show_group_summary={false}
                            course_color={this.state.course_color}
                            forceShowNumbers
                        />
                    </div>
                )
            }
        }

        return (
            <div className="col">
                <ElementNavItem
                    element_index={0}
                    module_content={content}
                    module_progress={module_progress}
                    show_group_summary={true}
                    course_color={this.state.course_color}
                />
                <div className="mt-2 module_group_nav">
                    {group_items}
                </div>
            </div>
        );
    }


    setCourseColor(value) {

        if (!/^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.test(value)) {
            return
        }

        this.setState({course_color: value});
    }

    render() {

        if (!this.state.icon) {
            return <></>;
        }

        this.taskNumber = 0;

        return (
            <div className="page_wrapper" style={{background: (this.state.course_color)}}>
                {this.state.data_window_content &&
                <div className="data_window"
                     style={this.state.data_window_styles}>{this.state.data_window_content}</div>
                }
                <LandingsHeader show_notifications={false}/>
                <style dangerouslySetInnerHTML={{
                    __html: `
                    .page_wrapper{padding-top: 150px;    min-height: 100vh;    box-sizing: border-box;}
                    .col{display: inline-block; vertical-align: top;}
                    .mt-1{ margin-top: 10px;}
                    .mt-2{ margin-top: 20px;}
                    .mt-3{ margin-top: 30px;}
                    .mt-4{ margin-top: 40px;}
                    .mt-5{ margin-top: 50px;}
                    .mb-5{ margin-bottom: 50px;}
                    .module-group-nav{    position: static;    margin: 0;    display: block;}
                    .course_color_title{ background: rgb(255, 255, 255);padding: 5px;    border: 1px solid #ccc;    border-radius: 7px; width: 150px;     margin: 0 auto 10px;}
                    .course_color_title + input{ background: rgb(255, 255, 255); padding: 5px;    border: 1px solid #ccc;    border-radius: 7px;}
                    .data_window{ background: rgb(255, 255, 255); position: absolute;  white-space: pre;    padding: 5px;    border: 1px solid #ccc;    border-radius: 7px; left: 10px;}
                    `
                }}/>
                <div style={{textAlign: "center"}}>
                    <div>
                        <div className="course_color_title">Цвет курса</div>
                        <input defaultValue={this.state.course_color} className="mb-5"
                               onChange={event => this.setCourseColor(event.target.value)}/>
                    </div>


                    {this.renderGroup([
                        this.getProgressObj(false, 'none', null, null)
                    ])}

                    {this.renderGroup([
                        this.getProgressObj(), // Не отвечал, открыта
                    ])}


                    {this.renderGroup([
                        this.getProgressObj(true, 'ok'), // Ответил верно
                        this.getProgressObj(),
                    ])}


                    {this.renderGroup([
                        this.getProgressObj(true, 'ok'),
                        this.getProgressObj(true, 'ok'),
                    ])}


                    {this.renderGroup([
                        this.getProgressObj(true, 'wrong'),
                        this.getProgressObj(true, 'wrong'),
                    ])}


                    {this.renderGroup([
                        this.getProgressObj(false, 'wrong'),
                    ])}

                    {this.renderGroup([
                        this.getProgressObj(true, 'wrong'),
                        this.getProgressObj(false),
                    ])}


                    {this.renderGroup([
                        this.getProgressObj(false, 'wrong'),
                        this.getProgressObj(true, 'wrong'),
                    ])}


                    {this.renderGroup([
                        this.getProgressObj(false, 'partly'),
                    ])}

                    {this.renderGroup([
                        this.getProgressObj(true, 'partly'),
                        this.getProgressObj(false),
                    ])}

                    {this.renderGroup([
                        this.getProgressObj(false, 'partly'),
                        this.getProgressObj(true, 'partly'),
                    ])}
                    {this.renderGroup([
                        this.getProgressObj(false, 'partly'),
                        this.getProgressObj(true, 'ok'),
                    ])}

                    {this.renderGroup([
                        this.getProgressObj(false, 'partly'),
                        this.getProgressObj(false, 'wrong'),
                    ])}

                    {this.renderGroup([
                        this.getProgressObj(true, 'ok'), // Ответил верно
                        this.getProgressObj(false, 'wrong')
                    ])}

                    {this.renderGroup([
                        this.getProgressObj(true, 'ok'), // Ответил верно
                        this.getProgressObj(false, 'wrong'), // Ответил неверно, закрыта
                        this.getProgressObj(false, 'partly'), // Ответил неверно, закрыта
                    ])}
                    {this.renderGroup([
                        this.getProgressObj(false, 'partly'), // Ответил неверно, закрыта
                        this.getProgressObj(true, 'wrong'), // Ответил неверно, закрыта
                    ])}
                    {this.renderGroup([
                        this.getProgressObj(true, 'partly'), // Ответил неверно, закрыта,
                        this.getProgressObj(false, 'wrong'), // Ответил верно
                    ])}

                    {this.renderGroup([
                        this.getProgressObj(true, 'wrong'), // Ответил верно
                        this.getProgressObj(true, 'partly'), // Ответил верно
                        this.getProgressObj(), // Не отвечал, открыта
                    ])}
                    {this.renderGroup([
                        this.getProgressObj(true, 'ok'), // Ответил верно
                        this.getProgressObj(true, 'wrong'), // Ответил верно
                        this.getProgressObj(), // Не отвечал, открыта
                    ])}

                    {this.renderGroup([
                        this.getProgressObj(true, 'ok'), // Ответил верно
                        this.getProgressObj(true, 'partly'), // Ответил верно
                        this.getProgressObj(), // Не отвечал, открыта
                    ])}


                    {this.renderGroup([
                        this.getProgressObj(true, 'ok'), // Ответил верно
                        this.getProgressObj(true, 'wrong'), // Ответил верно
                    ])}

                    {this.renderGroup([
                        this.getProgressObj(true, 'ok'), // Ответил верно
                        this.getProgressObj(true, 'partly'), // Ответил верно
                    ])}

                    {this.renderGroup([
                        this.getProgressObj(true, 'wrong'), // Ответил верно
                        this.getProgressObj(true, 'partly'), // Ответил верно
                    ])}


                    {this.renderGroup([
                        this.getProgressObj(true, 'partly'), // Ответил верно
                        this.getProgressObj(true, 'partly'), // Ответил верно
                    ])}

                </div>
                <div style={{textAlign: "center"}} className="mt-5">
                    {this.renderGroup([this.getProgressObj()], true)}
                    {this.renderGroup([this.getProgressObj(false, 'none', "onReview")], true)}
                    {this.renderGroup([this.getProgressObj(false, 'ok', "onReview")], true)}
                    {this.renderGroup([this.getProgressObj(false, 'partly', "onReview")], true)}
                    {this.renderGroup([this.getProgressObj(false, 'wrong', "onReview")], true)}
                    {this.renderGroup([this.getProgressObj(false, 'ok', "reviewed")], true)}
                    {this.renderGroup([this.getProgressObj(false, 'partly', "reviewed")], true)}
                    {this.renderGroup([this.getProgressObj(false, 'wrong', "reviewed")], true)}
                    {this.renderGroup([this.getProgressObj(false, 'ok', "read")], true)}
                    {this.renderGroup([this.getProgressObj(false, 'partly', "read")], true)}
                    {this.renderGroup([this.getProgressObj(false, 'wrong', "read")], true)}
                </div>
            </div>
        );
    }

}


const mapStateToProps = (state) => {
    return {
        auth_token: state.authReducer.auth_token
    }
};

const mapDispatchToProps = () => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProgressDemoPage);
