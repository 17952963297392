import React, { useEffect, useState } from 'react';
import bnc                            from 'bnc';
import { BoxAlignment, HBox, VBox }   from '@sirius/ui-lib/src/blocks/Layout';
import { Icon }                       from '@sirius/ui-lib/src/blocks/Controls/Icon';
import {CircularProgress}             from '@sirius/ui-lib/src/blocks/CircularProgress';
import Answers                        from 'Cheops/components/ModulePassing/Answers/Answers';
import { getAnalogicTask }            from 'Cheops/actions/noopolis-ts';
import Marked                         from '@sirius/ui-shared/src/components/DisplayEngine/Marked';
import {TaskAnswersData}              from 'Lab/Model/Task';
import {TaskAnswerType}               from 'Cheops/Model/Task';
import                                     './AnalogicTaskSolution.less';

const block = new bnc('analogic-task');

type Props = {
    courseId:  string;
    moduleId:  string;
    elementId: string;
    onClose:   () => void;
};

export const AnalogicTaskSolution = ({courseId, moduleId, elementId, onClose}: Props) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData]       = useState<SmtTask.AnalogicTask>(null);

    useEffect(() => {
        getAnalogicTask(courseId, moduleId, elementId)
            .then((res) => {
                setData(res);
            })
            .finally(() => setLoading(false))
        ;

    },[]);

    const showAnswers = data?.answersData.length > 0 && Object.keys(data?.answersData[0]).length > 0;

    return(
        <VBox className={block}>
            <>
                <HBox align={BoxAlignment.end}>
                    <Icon icon      = {'close'}
                          className = {block.el('close-button')}
                          onClick   = {onClose}
                    />
                </HBox>
                <VBox className={block.el('content')}>
                    {
                        loading
                            ? <CircularProgress marginCenter/>
                            : <>
                                <h2 className={block.el('title')}>
                                    Аналогичная задача
                                </h2>
                                <Marked>{data.description}</Marked>
                                {
                                    showAnswers
                                    && <Answers answersData={data.answersData as TaskAnswersData[]}
                                                answerType={data.type as TaskAnswerType[]}
                                                readOnly={true}
                                    />
                                }
                                <p className={block.el('subtitle')}>
                                    Решение
                                </p>
                                <Marked>{data.explanations[0]?.solution}</Marked>
                            </>
                    }
                </VBox>
            </>
        </VBox>
    );
};
