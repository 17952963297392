import React, {useEffect, useState} from 'react';
import {useParams, Link, Redirect}  from 'react-router-dom';
import {VBox}                       from '@sirius/ui-lib/src/blocks/Layout';
import {Icon}                       from '@sirius/ui-lib/src/blocks/Controls/Icon'
import {CircularProgress}           from '@sirius/ui-lib/src/blocks/CircularProgress';
import {Button}                     from '@sirius/ui-lib/src/blocks/Controls'
import {invitePage}                 from 'Cheops/containers/TeacherRoomPage/blocks';
import {isMaxMobileWidth}           from 'Cheops/containers/TeacherRoomPage/helpers';
import {
    acceptInvitationToClass,
    declineInvitationToClass,
    getInviteToClassInfo}       from 'Cheops/actions/noopolis-ts';
import                               './style.less';

export const InviteToClassPage = () => {
    const [classTitle, setClassTitle] = useState<NooBack.Student.Methods.ClassTitle>();
    const [loading, setLoading]       = useState<boolean>(true);
    const [redirect, setRedirect]     = useState<string>(null);

    const {hash}: {hash: NooBack.Student.InviteHash} = useParams();

    const accept = () => {
        acceptInvitationToClass(hash)
            .then(() => {
                setRedirect('/');
            })
        ;
    };

    const decline = () => {
        declineInvitationToClass(hash)
            .then(() => {
                setRedirect('/');
            })
        ;
    };

    useEffect(() => {
        if (!classTitle) {
            getInviteToClassInfo(hash)
                .then(({title}) => {
                    setClassTitle(title);
                    setLoading(false);
                })
            ;
        }
    }, []);

    return (
        redirect
        ? <Redirect to={redirect}/>
        : !loading
            ? <VBox center className={invitePage}>
                <Link to        = {'/'}
                      className = {invitePage.el('back-link')}
                >
                    <Icon icon      = {'arrow_back'}
                          size      = {'s'}
                    />
                </Link>
                <VBox className={invitePage.el('content')}>
                    <Icon icon      = {'groups'}
                          size      = {'xl'}
                          className = {invitePage.el('icon')}
                    />
                    <h1 className={invitePage.el('title')}>
                        {`Приглашение в класс «${classTitle}»`}
                    </h1>
                    <p className={invitePage.el('text')}>
                        Учитель сможет приглашать вас на&nbsp;курсы и&nbsp;смотреть ваш прогресс обучения.
                    </p>
                    <Button mode      = {'primary'}
                            size      = {isMaxMobileWidth() ? 's' : 'l'}
                            onAction  = {accept}
                            className = {invitePage.el('button')}
                    >
                        Присоединиться
                    </Button>
                    <Button mode      = {'outlined'}
                            size      = {isMaxMobileWidth() ? 's' : 'l'}
                            onAction  = {decline}
                            className = {invitePage.el('button')}
                    >
                        Отказаться
                    </Button>
                </VBox>
            </VBox>
            : <CircularProgress centerOfWindow/>
    );
};
