import React                from "react";
import {connect}            from "react-redux";
import {bindActionCreators} from "redux";
import BemClassName         from "Cheops/BemClassName";
import * as noo_actions     from "Cheops/actions/noopolis";
import MarkedWithVideo      from "Smt/MarkedWithVideo";
import CircularProgress     from "Smt/Ui";
import Helpers              from "Cheops/Helpers";
import NotificationsList    from "Cheops/components/Notifications/NotificationsList";
import CourseHeader         from "Cheops/components/CoursePage/CourseHeader";
import {CourseBackButton}   from "Cheops/components/CoursePage/CourseButton";
import {META_THEME_COLOR_DEFAULT} from "Cheops/constants";

class CourseWidgetContentPage extends React.Component {

    constructor(props) {

        super(props);

        Helpers.redirectToAuthIfAnon();

        document.title = `${Helpers.getInstanceTitleForCurrentDeploy()} — Открытая онлайн-школа развития таланта`;

        this.state = {
            show_notifications: false,
            is_loading: true,
            content_widget: {},
            type: '',
        };

    }

    async componentWillMount() {

        await this.updateCourseData(this.props.match.params.course_id);
        const course_contents = this.props.course_contents[this.props.current_course_learn.hash];
        document.querySelector("meta[name=theme-color]").setAttribute("content", course_contents.color);

    }

    async componentWillReceiveProps({match, history}) {

        if ((this.props.match.params.course_id !== match.params.course_id) || history.location.force_refresh_data) {

            delete history.location.force_refresh_data;
            this.setState({is_loading: true});
            this.updateCourseData(match.params.course_id);

        }

    }

    componentWillUnmount() {

        document.querySelector("meta[name=theme-color]").setAttribute("content", META_THEME_COLOR_DEFAULT);

    }

    returnOldContent = () => {

        let course_contents = this.props.course_contents[this.props.current_course_learn.hash];

        switch (this.props.match.params.page) {
            case 'final_test':
                if (!course_contents.finalTest) {
                    return null;
                }
                return {
                    caption: 'Тестирование',
                    link: 'final_test',
                    type: 'finalTest',
                    content: {text: course_contents.finalTest, ids: this.props.current_course_learn.contests},
                };

            case 'about':
                if (!course_contents.about) {
                    return null;
                }
                return {
                    caption: 'О курсе',
                    link: 'about',
                    type: 'text',
                    content: {text: course_contents.about},
                };

            case 'rules':
                if (!course_contents.rules) {
                    return null;
                }
                return {
                    caption: 'Правила',
                    link: 'rules',
                    type: 'text',
                    content: {text: course_contents.rules},
                };

            case 'text_page':
                const [widget] = course_contents?.widgets;
                return widget || null;

            default:
                return null;
        }

    };

    async updateCourseData(course_id) {

        await this.props.getCourseLearnData(parseInt(course_id));

        // TODO get hash from getCourseData()
        if (!this.props.current_course_learn.hash) {

            Helpers.renderErrorPage(404);

        }

        if (!this.props.course_contents[this.props.current_course_learn.hash]) {

            await this.props.getCourseContent(this.props.current_course_learn.hash);

        }

        let course_contents = this.props.course_contents[this.props.current_course_learn.hash];
        let content_widget = this.returnOldContent();
        if (course_contents.widgets && Array.isArray(course_contents.widgets) && this.props.match.params.page) {
            const index = course_contents.widgets.findIndex((item) => item.link === this.props.match.params.page);
            if (index >= 0 && course_contents.widgets[index].hash) {
                await this.props.getElementContent(course_contents.widgets[index].hash);
                content_widget = this.props.contents_data[course_contents.widgets[index].hash];
            }
        }

        if (!content_widget || !Object.keys(content_widget).length) {
            Helpers.renderErrorPage(404);
        }

        document.title = `${course_contents.name}. ${content_widget.caption} — ${Helpers.getInstanceTitleForCurrentDeploy()}`;

        this.setState({
            is_loading: false,
            content_widget,
            type: content_widget.type ? content_widget.type : '',
        });

    }

    setShowNotifications(state) {

        this.setState({show_notifications: state});

    }

    returnText = () => {

        const data_course = this.props.course_contents[this.props.current_course_learn.hash];

        if (this.state.content_widget.content && this.state.content_widget.content.text) {

            return this.state.content_widget.content.text;

        }

        if (data_course[this.props.match.params.page]) {

            return data_course[this.props.match.params.page];

        }

    };

    renderItemTest = (id, text) => {

        const enable = this.props.current_course_learn.contests && this.props.current_course_learn.contests.findIndex((item) => item === id) >= 0;
        let location = enable ? `${CONFIG.Smt.url}/#/contest/${this.props.match.params.course_id}/${id}` : '';
        const className = new BemClassName('course_widget_content__contest_buttons');
        className.appendStatusIf(!enable, 'disable');

        return <div key={id} className={className}>
            <div>{text}</div>
            <div>
                <button onClick={() => window.location = location} disabled={!enable} className="">
                    {enable ? 'Перейти в тестирование' : 'Недоступно'}
                </button>
            </div>
        </div>;

    };

    renderTest = () => {

        if (this.state.content_widget.content && !!this.state.content_widget.content.ids && this.state.content_widget.content.ids.length > 0) {

            return this.state.content_widget.content.ids.map((item) => this.renderItemTest(item.id, item.text));

        }

        if (!!this.props.current_course_learn.contests && this.props.current_course_learn.contests.length > 0) {

            return this.props.current_course_learn.contests.map((item) => this.renderItemTest(item, 'Итоговое тестирование'));

        }

    };

    render() {

        if (this.state.is_loading) {

            return (<CircularProgress centerOfWindow={true} />);

        }

        const type = this.state.type;
        const course_id = this.props.match.params.course_id;
        const course_contents = this.props.course_contents[this.props.current_course_learn.hash];
        const achieved_modules_count = this.props.current_course_learn.modulesAchieved - this.props.current_course_learn.advancedModulesAchieved;
        const modules_count = this.props.current_course_learn.modulesTotal - this.props.current_course_learn.advancedModulesTotal;

        return (
            <div className="notification_page">
                <CourseHeader show_notifications={true}
                              show_progress={true}
                              title_align="left"
                              course_id={course_id}
                              course_hash={this.props.current_course_learn.hash}
                              title={this.state.content_widget.caption}
                              progress_current={achieved_modules_count}
                              progress_max={modules_count}
                              star_modules_count={this.props.current_course_learn.advancedModulesTotal}
                              achieved_star_modules_count={this.props.current_course_learn.advancedModulesAchieved}
                              showNotifications={() => this.setShowNotifications(true)}
                              course_color={course_contents.color}
                              back_path={`#/course/${course_id}`}
                />
                <div className="course_widget_content__wrapper">
                    <div className="notification_page__content">
                        <MarkedWithVideo>
                            {this.returnText()}
                        </MarkedWithVideo>
                    </div>
                </div>
                {(type === 'finalTest') && <div
                    className='course_widget_content__wrapper course_widget_content__contest_body'
                >
                    {this.renderTest()}
                </div>}
                {(!type || type === 'text')
                && <CourseBackButton
                        courseId={this.props.match.params.course_id}
                        icon
                    >
                        Вернуться в курс
                    </CourseBackButton>
                }
                {this.state.show_notifications
                && <NotificationsList hideNotifications={() => this.setShowNotifications(false)} />}
            </div>
        );

    }

}

const mapStateToProps = (state) => ({
    current_course: state.nooReducer.current_course,
    current_course_learn: state.nooReducer.current_course_learn,
    my_course_list: state.nooReducer.my_course_list,
    course_contents: state.nooReducer.course_contents,
    auth_token: state.authReducer.auth_token,
    contents_data: state.nooReducer.contents_data,
});

const mapDispatchToProps = (dispatch) => ({
    getCourseData: bindActionCreators(noo_actions.getCourseData, dispatch),
    getCourseLearnData: bindActionCreators(noo_actions.getCourseLearnData, dispatch),
    getCourseContent: bindActionCreators(noo_actions.getCourseContent, dispatch),
    getElementContent: bindActionCreators(noo_actions.getElementContent, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CourseWidgetContentPage);
