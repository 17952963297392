import React, {FC}                            from 'react';
import {parseInt}                             from 'lodash';
import {Column, DataCellType, Row}            from '@sirius/ui-lib/src/blocks/Layout/UITable/@types/index';
import {BoxAlignment, HBox, PopoverMenuItem}  from '@sirius/ui-lib/src/blocks/Layout/index';
import {defaultUiSchema}                      from '@sirius/ui-lib/src/blocks/Layout/UITable/uischema';
import {ColumnNames, ComplexityTitle}         from 'Cheops/containers/TeacherRoomPage/ProgressPage/values';
import {TeacherPopoverMenu}                   from 'Cheops/containers/TeacherRoomPage/TeacherPopoverMenu';
import {progressPage, ProgressPageTableProps} from '../blocks';

const getSelectedChips = (chips: NooBack.Teacher.Methods.TableChipsGroupList) =>
    chips.find(
        (chip) => 'selected' in chip && !!chip.selected
    ) as NooBack.Teacher.Methods.TableChip
;

const getColumnTitle =(id:string) => {
    const separated = id.split('-');
    if (separated.length > 1) {
        const complexity = parseInt(separated[1]);
        return ComplexityTitle[complexity].concat(` ${ColumnNames[separated[0]]}`);
    } else {
        return ColumnNames[id];
    }
};

const ColumnMapper = ({ id }: NooBack.Teacher.Methods.Column, i: number, columns: NooBack.Teacher.Methods.Columns): Column => ({
    title:  getColumnTitle(id),
    name:   id,
    grow:   columns.length - 1 === i
});

const RowMapper = (row: NooBack.Teacher.Methods.Cells & {user: NooBack.Teacher.Methods.StudentInfo}): Row => {
    const userName =
        (row?.user?.name?.alias?.trim().length > 0 && row?.user?.name?.alias) ||
        row?.user?.name?.studentName ||
        row?.user.id
    ;

    return {
        ...row,
        user:     userName,
        userInfo: row?.user,
    }
};

const getTableSchema = (onActionAsStudent: ProgressPageTableProps['seeAsStudent']) =>  {

    const UserDataCell: FC<DataCellType> = ({ row }) =>
        <HBox className = {progressPage.el('table-user-cell')}
              align     = {BoxAlignment.between}
        >
            {row.user}
            <div>
                <TeacherPopoverMenu>
                    <PopoverMenuItem icon     = {'school'}
                                     label    = {'Смотреть как ученик'}
                                     onAction = {() => onActionAsStudent(row.userInfo.id)}
                    />
                    {/*<PopoverMenuItem icon     = {'edit'}*/}
                    {/*                 label    = {'Переименовать'}*/}
                    {/*                 onAction = {() => onEdit(row.userInfo)}*/}
                    {/*                 disabled = {!row.user.class}*/}
                    {/*/>*/}
                </TeacherPopoverMenu>
            </div>
        </HBox>
    ;

    const SumDataCell: FC<DataCellType> = ({ row }) =>
        <div className={progressPage.el('table-sum-cell')}>
            {row.sum}
        </div>
    ;

    return {
        ...defaultUiSchema
        ,
        user: {
            DataCell:   UserDataCell,
            ColumnCell: defaultUiSchema.text.ColumnCell
        },
        sum: {
            DataCell:   SumDataCell,
            ColumnCell: defaultUiSchema.text.ColumnCell
        },
    }
};

export {
    getTableSchema,
    ColumnMapper,
    RowMapper,
    getSelectedChips
};
