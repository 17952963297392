import React        from "react";
import {Icon}       from "@sirius/ui-lib/src/blocks/Controls";
import BemClassName from "Cheops/BemClassName";
import ResultTable  from "./ResultTable";


export default class ResultTableCell extends React.Component {

    static DEFAULT_GROUP_COLOR = "#8C8695";

    static defaultProps = {
        user_task: null,
        task: {}
    };

    render() {

        let result_table_cell_class = new BemClassName("result_table_cell");

        let table_cell_text = "";
        let cell_styles = {}; //  Перенести в CSS
        let result_table_cell__bg_styles = {};
        let hasPersonalDeadline = false;

        let link = null;

        if (this.props.user_task) {

            if (this.props.mode === ResultTable.MODE.LAB) {

                link = `/#/course/${this.props.course_id}/review_${this.props.module_id}_${this.props.user_task.id}_${this.props.user_id}`;

            }

            if (this.props.mode === ResultTable.MODE.NOO && this.props.current_user_id === this.props.user_id && this.props.user_task.id) {

                link = `/#/course/${this.props.course_id}/${this.props.module_id}/task_${this.props.user_task.id}`;

            }

            hasPersonalDeadline = this.props.user_task.personalDeadline;

        }

        let cell_title = null;

        result_table_cell_class.appendStatusIf(!this.props.task.isClosed, "open");

        let svg_fill = ResultTableCell.DEFAULT_GROUP_COLOR;

        if (this.props.current_user_id === this.props.user_id) {

            if (this.props.mode === ResultTable.MODE.NOO) {

                if (this.props.groups[this.props.task.group] && this.props.groups[this.props.task.group].color) {

                    if (this.props.task.isClosed) {

                        cell_styles.color = this.props.groups[this.props.task.group].color;
                        result_table_cell__bg_styles.backgroundColor = this.props.groups[this.props.task.group].color;
                        result_table_cell_class.appendStatus("has_color");

                    }

                    svg_fill = this.props.groups[this.props.task.group].color;

                }

                table_cell_text = "...";

                if (!this.props.task.isClosed) {

                    table_cell_text = <svg width="19" height="19"
                                           viewBox="0 0 19 19" fill="none"
                                           xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M0 15.4596V18.4996C0 18.7796 0.22 18.9996 0.5 18.9996H3.54C3.67 18.9996 3.8 18.9496 3.89 18.8496L14.81 7.93957L11.06 4.18957L0.15 15.0996C0.0500001 15.1996 0 15.3196 0 15.4596ZM17.71 5.03957C18.1 4.64957 18.1 4.01957 17.71 3.62957L15.37 1.28957C14.98 0.89957 14.35 0.89957 13.96 1.28957L12.13 3.11957L15.88 6.86957L17.71 5.03957Z"
                            fill={svg_fill}
                        />
                    </svg>;

                }

            }

            result_table_cell_class.appendStatus("bold");

            if (this.props.mode === ResultTable.MODE.NOO && this.props.task.id) {

                link = `/#/course/${this.props.course_id}/${this.props.module_id}/task_${this.props.task.id}`;

                let group_name = "";

                if (this.props.groups[this.props.task.group] && this.props.groups[this.props.task.group].name) {

                    group_name = this.props.groups[this.props.task.group].name;

                }

                cell_title = `${group_name} ${this.props.task.position}`;

            }

        }

        result_table_cell_class.appendStatusIf(!this.props.user_task, "empty");

        if (this.props.user_task) {

            table_cell_text = this.props.user_task.score;

            if (this.props.user_task.score && this.props.user_task.score % 1 !== 0) {

                table_cell_text = this.props.user_task.score.toFixed(1);

            }

            result_table_cell_class.appendStatusIf((this.props.task.score === this.props.user_task.score), "full");

            let now = new Date();

            if (this.props.user_task.questionTime || this.props.user_task.solutionTime) {

                result_table_cell_class.appendStatus("has_comments");

                if (this.props.groups[this.props.task.group] && this.props.groups[this.props.task.group].color) {

                    cell_styles.background = this.props.groups[this.props.task.group].color;

                }

            }

            if (this.props.user_task.questionTime) {

                let question_time = new Date(this.props.user_task.questionTime);

                let expired_time = Math.round((now - question_time) / (60 * 60 * 1000));

                let expired_time_result = `${expired_time}ч`;

                if (expired_time > 23) {

                    expired_time_result = `${Math.round(expired_time / 24)}д`;

                }

                table_cell_text = <span>
                        <img alt="" src={require('Cheops/../img/icons_question.svg')} />
                    {` ${expired_time_result}`}
                </span>;

            }

            if (this.props.user_task.solutionTime) {

                let solution_time = new Date(this.props.user_task.solutionTime);

                let expired_time = Math.round((now - solution_time) / (60 * 60 * 1000));

                let expired_time_result = `${expired_time}ч`;

                if (expired_time > 23) {

                    expired_time_result = `${Math.round(expired_time / 24)}д`;

                }

                table_cell_text = (
                    <span>
                        <img alt="" src={require('Cheops/../img/icon_file.svg')} />
                        {` ${expired_time_result}`}
                    </span>
                );

            }

        }


        return (
            <td className={result_table_cell_class} style={cell_styles} title={cell_title}>
                {this.props.mode === ResultTable.MODE.NOO
                && this.props.current_user_id === this.props.user_id
                && <div className="result_table_cell__bg" style={result_table_cell__bg_styles} />}
                <a href={link}>
                    <span className="result_table_cell__text">{table_cell_text}</span>
                    {hasPersonalDeadline && <div className="result_table_cell__timer_icon">
                        <Icon icon={'timer'} />
                    </div>}
                </a>
            </td>
        );

    }

}
