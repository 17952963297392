import React from "react";
import ElementNavItem from "Cheops/components/ModulePassing/ElementNavItem";
import "./ElementsNavList.less";
import BemClassName from "Cheops/BemClassName";


export default class ElementsNavList extends React.Component {

    static defaultProps = {
        module_content: null,
        module_progress: null,
        course_color: null,
        selected_element_num: null,
        navElementClick: () => {
        },
    };


    getGrouppedVariants = () => {

        // eslint-disable-next-line array-callback-return,consistent-return
        return this.props.module_content.variant.map((el, i) => {

            let group_range = this.getGroupRange(i);

            if (i === group_range[0]) {

                return group_range;

            }

        }).filter((v) => !!v);

    };

    getGroupRange = (element_id) => {

        let groups = this.props.module_content.groups;

        for (let group of groups) {

            if (group.from - 1 <= element_id && group.to - 1 >= element_id) {

                return [group.from - 1, group.to - 1];

            }

        }

        return [element_id, element_id];

    };

    renderElementsOfGroup = (group) => {

        let elements = [];

        for (let index = group[0]; index <= group[1]; index++) {

            elements.push(<ElementNavItem
                key={index}
                element_index={index}
                course_color={this.props.course_color}
                module_content={this.props.module_content}
                module_progress={this.props.module_progress}
                forceShowNumbers
                active={index === this.props.selected_element_num}
                onClick={() => this.props.navElementClick(index)}
            />);

        }

        if (this.props.module_content.groups.length && elements.length === 1 && this.props.module_content.variant[group[0]].value[0].element.title) {

            elements.push(<div className="element_nav_list__element_title">
                {this.props.module_content.variant[group[0]].value[0].element.title}
            </div>);

        }

        return elements;

    };


    render() {

        let has_groups = !!this.props.module_content.groups.length;

        let wrapper_class = new BemClassName('element_nav_list');
        wrapper_class.appendStatusIf(!has_groups, 'without_groups');

        return (
            <div className={wrapper_class}>
                {this.getGrouppedVariants().map((group, i) => {

                    return <div key={i} className="element_nav_list__group">
                        {this.renderElementsOfGroup(group)}
                    </div>;

                })}
            </div>
        );

    }

}
