import React, {FC}                              from "react";
import bnc                                      from "bnc";
import {HBox, VBox}                             from "@sirius/ui-lib/src/blocks/Layout";
import {Chips, ChipsList}                       from "@sirius/ui-lib/src/blocks/Controls/Chips";
import {HasComplexity, HasSwitchComplexity}     from "Cheops/containers/ModulePage__Types";
import                                               "./ModulePage__ComplexityControl.less";

const block = new bnc("module-page-complexity-control");

export const ComplexityControl:FC<HasComplexity & HasSwitchComplexity> = ({complexity, switchComplexity}) =>
    <VBox className={block.el("wrapper")}>
        <HBox className={block.el("label") + "text-s"}>Уровень сложности</HBox>
        <ChipsList
            wrapperClassName = {block.el("chips")}
            onChange         = {switchComplexity}
            selected         = {complexity.filter( ({selected})=>selected ).map( ({level})=> level)}
        >
            {
                complexity.map(
                    ({title, level, selected})=>
                        <Chips
                            key      = {level}
                            value    = {level}
                            size     = {'s'}
                            selected = {selected}
                        >{title}</Chips>
                )
            }
        </ChipsList>
    </VBox>
;
