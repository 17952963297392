import React                                              from 'react';
import {Button}                                           from '@sirius/ui-lib/src/blocks/Controls';
import {MGap, Modal, ModalBody, ModalFooter, ModalHeader} from '@sirius/ui-lib/src/blocks/Layout';
import {ActionButtonsProps, block, TeacherModalProps}     from 'Cheops/containers/TeacherRoomPage/blocks';
import                                                         './style.less';

const ActionButtons = ({onClose, onAction, disabled, actionButtonText}: ActionButtonsProps) =>
    <>
        <Button onAction = {onClose}
                size     = {'s'}
        >
            Отменить
        </Button>
        <MGap />
        <Button mode     = {'primary'}
                onAction = {onAction}
                size     = {'s'}
                disabled = {disabled}
        >
            {actionButtonText}
        </Button>
    </>
;

export const TeacherModal = ({onClose, onAction, disabled, headerTitle, children, actionButtonText, className}: TeacherModalProps) =>
    <Modal narrowWidth = {true}
           onClose     = {onClose}
           style       = {{zIndex: '999'}}
           className   = {className}
    >
        <ModalHeader title   = {headerTitle}
                     onClose = {onClose}
        />

        {
            children &&
            <ModalBody className={block.el('modal-body')}>
                {children}
            </ModalBody>
        }

        <ModalFooter className = {block.el('modal-footer')}>
            <ActionButtons actionButtonText = {actionButtonText}
                           {...{
                               onClose,
                               onAction,
                               disabled
                           }}
            />
        </ModalFooter>
    </Modal>
;
