import React                from "react";
import * as auth_actions    from "Cheops/actions/auth";
import NotificationsList    from "Cheops/components/Notifications/NotificationsList";
import Helpers              from "Cheops/Helpers";
import LandingsHeader       from "Cheops/components/Landings/LandingsHeader";
import {Footer}             from "Cheops/components/Footer";
import {cluster}            from "Cheops/../clusters/current";
import                           "Cheops/../less/parent_code_page.less";



export default class AuthConfirmation extends React.Component {

    constructor(props) {
        super(props);
        document.title = `${cluster.parent.title} — ${Helpers.getInstanceTitleForCurrentDeploy()}`;
        this.state = {
            parent_code: "",
            show_notifications: false,
        };
    }

    componentWillMount() {
        let parent_code = auth_actions.getUserInfo('parentCode');
        if (!parent_code) {
            location.hash = '#/';
        } else {
            this.setState({parent_code});
        }
    }

    setShowNotifications(show_notifications) {
        this.setState({show_notifications});
    }

    copyCode() {
        let email = document.querySelector("#parent_code_page__code_input");
        email.select();
        document.execCommand('copy');
    }

    render() {
        const textbookClass = cluster.type === 'textbook' ? ' auth_content-textbook' : '';

        return (
            <div className="parent_code_page auth_page">
                {
                    this.state.show_notifications &&
                    <NotificationsList hideNotifications={() => this.setShowNotifications(false)} />
                }
                <LandingsHeader showNotifications={() => this.setShowNotifications(true)} />
                <div className={`auth_content${textbookClass}`}>
                    <div className="parent_code_page__wrapper">
                        <div className="parent_code_page__title">{cluster.parent.title}</div>
                        <div className="parent_code_page__code">
                            {this.state.parent_code}
                            <input
                                id="parent_code_page__code_input"
                                type="text"
                                value={this.state.parent_code}
                                readOnly
                            />
                            <div
                                className="parent_code_page__copy"
                                onClick={this.copyCode.bind(this)}
                            >
                                <div
                                    className="parent_code_page__copy_icon"
                                    title="Скопировать в буфер обмена"
                                />
                            </div>
                        </div>
                        <div className="parent_code_page__descr">
                            Доступ по коду позволяет наблюдать за вашим учебным процессом. Но не позволяет вмешиваться в
                            него.
                        </div>
                    </div>
                </div>
                {
                    cluster.type === "courses" &&
                    <Footer />
                }
            </div>
        );
    }
}
