import React from "react";
import Helpers from "Cheops/Helpers";
import BemClassName from "Cheops/BemClassName";

export default class ResultTableSidebar extends React.Component {


    static defaultProps = {
        sidebar_open: false,
        current_user_id: null,
        toggleSidebar: () => {
        },
    };


    renderHoleRow(text) {

        return <tr key={text}>
            <td className="result_table_sidebar__rating result_table_sidebar__rating--hole">...</td>
            <td className="result_table_sidebar__long_cell result_table_sidebar__long_cell--hole">{text}</td>
            <td className="result_table_sidebar__total" />

            {this.props.report.groups.map((group) => <td className="result_table_header_sidebar__stats" key={group.id}>
                <div className="result_table_header_sidebar__stats_inner" />
            </td>)}

            <td className="result_table_sidebar__stats" />
        </tr>;

    }

    render() {

        let result_table_sidebar_class = new BemClassName('result_table_sidebar');

        result_table_sidebar_class.appendStatusIf(this.props.sidebar_open, 'open');

        return (
            <div className={result_table_sidebar_class} onClick={() => this.props.toggleSidebar()}>
                <table>
                    <tbody>
                    {this.props.report.users.map((user) => {

                        if (user.hole) {

                            return this.renderHoleRow(user.hole);

                        }

                        let user_average_score = 0;

                        if (user.average) {

                            user_average_score = user.average;

                            if (user.average % 1 !== 0) {

                                user_average_score = user.average.toFixed(1);

                            }

                        }

                        let user_total_score = 0;

                        if (user.total) {

                            user_total_score = user.total;

                            if (user.total % 1 !== 0) {

                                user_average_score = user.total.toFixed(1);

                            }

                        }

                        let user_name_short = `${user.rating}.${user.name.surname.substring(0, 3)}. ${user.name.name.substring(0, 1)}.`;

                        if (this.props.sidebar_open) {

                            user_name_short = `${user.rating}.${Helpers.cropString(user.name.surname, 6)} ${user.name.name.substring(0, 1)}.`;

                        }

                        let result_table_sidebar__row_class = new BemClassName("result_table_sidebar__row");
                        let result_table_sidebar__long_cell_class = new BemClassName("result_table_sidebar__long_cell");

                        result_table_sidebar__long_cell_class.appendStatusIf(this.props.current_user_id === user.name.id, "bold");
                        result_table_sidebar__row_class.appendStatusIf(this.props.current_user_id === user.name.id, "current");

                        return <tr className={result_table_sidebar__row_class} key={user.rating}>
                            <td className="result_table_sidebar__rating">{user.rating}</td>
                            <td className={result_table_sidebar__long_cell_class}>
                                <span className="result_table_sidebar__name">
                                    {`${user.name.surname} ${user.name.name}`}
                                </span>
                                <span className="result_table_sidebar__name_short">{user_name_short}</span>
                            </td>
                            <td className="result_table_sidebar__total">{user_total_score}</td>
                            {this.props.report.groups.map((group) => {

                                let score = 0;

                                for (let user_group of user.groups) {

                                    if (user_group.groupId === group.id) {

                                        score = user_group.score;

                                        if (user_group.score % 1 !== 0) {

                                            score = user_group.score.toFixed(1);

                                        }

                                        break;

                                    }

                                }

                                return <td className="result_table_sidebar__stats" key={group.id}>{score}</td>;

                            })}
                            <td className="result_table_sidebar__stats">{user_average_score}</td>
                        </tr>;

                    })}
                    </tbody>
                </table>
            </div>
        );

    }

}
