import React                from "react";
import ReactDOM             from "react-dom";
import {cluster}            from "CheopsRoot/clusters/current";
import * as auth_actions    from "Cheops/actions/auth";
import {hasNotifications}   from "Cheops/constants";
import NotificationsIcon    from "../Notifications/NotificationsIcon";


export default class HeaderMenu extends React.Component {


    static defaultProps = {
        show_notifications: true,
        menu_is_open: false,
    };

    UNSAFE_componentWillMount() {

        document.addEventListener('click', this.handleClick, false);

    }

    componentWillUnmount() {

        document.removeEventListener('click', this.handleClick, false);

    }

    handleClick = (e) => {

        if (!ReactDOM.findDOMNode(this).contains(e.target)) {

            if (this.props.menu_is_open) {

                this.props.closeMenuClick(e);

            }

        }

    };


    logout() {

        localStorage.removeItem(CONFIG.Auth.localstorage_key);
        location.href = CONFIG.Auth.logout_url;

    }

    closeMenuClick = (e) => {

        e.stopPropagation();

        this.props.closeMenuClick(e);

    }

    redirect = (link, e) => {
        location.hash = `#/${link}`;
        this.props.closeMenuClick(e);
    }

    showNotification = (e) => {
        this.props.showNotifications();
        this.props.closeMenuClick(e);
    }

    render() {
        const {menu_is_open, user_is_anon, showNotifications} = this.props;

        let header_menu = "header_menu";

        if (menu_is_open) {

            header_menu += " header_menu--opened";

        }

        if (!user_is_anon) {

            header_menu += " header_menu--authorized";

        }

        const first_name = auth_actions.getUserInfo('firstName');
        const last_name  = auth_actions.getUserInfo('lastName');
        const roles      = auth_actions.getUserInfo('roles');

        const isTeacher = !user_is_anon && roles.includes('teacher');
        const isParent  = !user_is_anon && roles.includes('nooRO');

        const hasProfileLink = !!CONFIG.passportUrl && !isParent;

        return (
            <div className={header_menu}>
                <a className="header_menu__item"
                   onClick={(e) => this.redirect(isTeacher ? 'teacher-room' : '', e)}
                >
                    <div className="header_menu__item_title">
                        {
                            isTeacher
                                ? 'Учительская'
                                : 'Главная'
                        }
                    </div>
                    <div className="header_menu__item_widget header_menu__close"
                         onClick={this.closeMenuClick}
                    />
                </a>

                {
                    user_is_anon
                    && <a className="header_menu__item"
                          onClick={(e) => this.redirect('auth_parent', e)}
                    >
                        <div className="header_menu__item_title">
                            {cluster.parent.header_menu}
                        </div>
                    </a>
                }
                {
                    isTeacher &&
                    <a className="header_menu__item"
                       onClick={(e) => this.redirect('', e)}
                    >
                        <div className="header_menu__item_title">
                            Как ученик
                        </div>
                    </a>
                }
                {
                    !user_is_anon && hasNotifications
                    && <div className="header_menu__item" onClick={this.showNotification}
                    >
                        <div className="header_menu__item_title">
                            Уведомления
                        </div>
                        <div className="header_menu__item_widget header_menu__item_widget_notifications">
                            {
                                showNotifications
                                && <NotificationsIcon
                                    showNotifications={void(0)}
                                    notifications_icon_background={NotificationsIcon.BACKGROUND.TRANSPARENT}
                                    show_last_pinned={false}
                                />
                            }
                        </div>
                    </div>
                }
                {
                    !user_is_anon && roles.indexOf('nooRO') === -1
                    && <a className="header_menu__item"
                          onClick={(e) => this.redirect('parent_code', e)}
                    >
                        <div className="header_menu__item_title">
                            {cluster.parent.header_menu_code}
                        </div>
                    </a>
                }

                {
                    !user_is_anon && cluster.type === "courses"
                    && <a className="header_menu__item"
                          onClick={(e) => this.redirect('certificate', e)}
                    >
                        <div className="header_menu__item_title">
                            Сертификаты
                        </div>
                    </a>
                }
                {
                    user_is_anon
                    && <a className="header_menu__item header_menu__item--auth"
                          onClick={() => auth_actions.redirectToAuth()}
                    >
                        <div className="header_menu__auth_title">
                            Войти
                        </div>
                    </a>
                }
                {
                    !user_is_anon
                    && <div className="header_menu__item header_menu__item--logout">
                        {
                            hasProfileLink
                                ? <a className="header_menu__user_name" href={CONFIG.passportUrl}>
                                    {`${first_name} ${last_name}`}
                                </a>
                                : <span className="header_menu__user_name">
                                    {`${first_name} ${last_name}`}
                                </span>
                        }
                        <div className="header_menu__logout"
                             onClick={this.logout}>
                            Выйти
                        </div>
                    </div>
                }
            </div>);

    }

}
