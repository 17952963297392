import React from "react";
import "./AutoGradeOnGoingCard.less";
import BemClassName from "Cheops/BemClassName";

export default class AutoGradeOnGoingCard extends React.Component {

    static defaultProps = {
        status: {},
        course_id: null,
        courseIsStarted: false,
        openPopup: () => {
        },
    };

    state = {
        time_offset: 0,
        task_remain_time: "",
    };

    componentWillMount() {


        if (this.props.status.value.currentTaskExpiresAt) {

            let time_offset = new Date(this.props.status.value.currentTime) - (new Date());

            this.setState({time_offset}, () => {

                this.updateTaskRemainTime();
                this.remainInterval = setInterval(this.updateTaskRemainTime, 1000);

            });

        }

    }

    componentWillUnmount() {

        clearInterval(this.remainInterval);

    }

    remainInterval = 0;

    updateTaskRemainTime = () => {

        let server_now = new Date((new Date()).getTime() + this.state.time_offset);

        let remain_total_seconds = ((new Date(this.props.status.value.currentTaskExpiresAt)) - server_now) / 1000;

        if (remain_total_seconds <= 0) {

            clearInterval(this.remainInterval);
            this.setState({task_remain_time: "Вы не ввели ответ"});
            return;

        }

        let remain_minutes = `${Math.floor(remain_total_seconds / 60)}`;
        let remain_seconds = `${Math.floor(remain_total_seconds % 60)}`;

        if (remain_minutes.length === 1) {

            remain_minutes = `0${remain_minutes}`;

        }

        if (remain_seconds.length === 1) {

            remain_seconds = `0${remain_seconds}`;

        }

        this.setState({task_remain_time: `${remain_minutes} : ${remain_seconds}`});

    };

    render() {

        // let first_name = auth_actions.getUserInfo('firstName');

        let approximate = `около ${this.props.status.value.approximate} мин.`;

        if (this.props.status.value.approximate < 1) {

            approximate = "менее одной минуты";

        }


        let continue_button_class = new BemClassName('autograde_ongoing_card__button', 'green');

        return (
            <>
                <div className="autograde_ongoing_card">
                    <div className="autograde_ongoing_card__text">
                        <div className="autograde_ongoing_card__title">Проверка знаний</div>
                        {approximate
                        && <div>{`Вам осталось ${approximate}`}</div>}
                        Пройдите тест до конца или завершите досрочно, чтобы учиться в курсе
                    </div>
                    {this.props.status.value.currentTaskExpiresAt
                    && <div className="autograde_ongoing_card__task">
                        {`Задача ${this.props.status.value.total + 1} `}
                        <span>{this.state.task_remain_time}</span>
                    </div>}
                    <div className="autograde_ongoing_card__footer">
                        <div className="autograde_ongoing_card__button"
                             onClick={this.props.openPopup}
                        >
                            Завершить
                        </div>
                        <a className={continue_button_class}
                           href={`/#/course/${this.props.course_id}/autograde`}
                        >
                            Продолжить
                        </a>
                    </div>
                </div>
            </>
        );

    }

}
