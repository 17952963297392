import React, {FC, useEffect}                   from "react";
import MarkedWithVideo                          from "Smt/MarkedWithVideo";
import {IUseVideoResolveArgs, useVideoResolve}  from "Smt/hooks/useVideoResolve";
import {HBox, VBox}                             from "@sirius/ui-lib/src/blocks/Layout";
import {VideoPlayer}                            from "@sirius/ui-shared/src/components/Video/Player";
import {block, pageBlock}                       from "./block";



const instance: IUseVideoResolveArgs["instance"] = CONFIG?.Instance ? `${CONFIG.Instance as IUseVideoResolveArgs["instance"]}` : null;

interface Props {
    block_id: string,
    url?: string,
    title?: string,
    text?: string,
}
const LandingBlockVideo: FC<Props> = (
    {
        block_id: blockId,
        url = null,
        title,
        text,
    }
) => {

    const [videoContainer, getVideoContainer] = useVideoResolve({instance});

    useEffect(() => {
        getVideoContainer(url);
    }, [url]);

    const containOnlyVideo = !text;

    return (
        <HBox className={block}>
            <VBox
                className={
                    pageBlock.el('section') +
                    pageBlock.el(blockId)
                }
                data-target="about" data-nav={blockId}
            >
                {
                    !!title
                    &&  <HBox className={block.el("title")}>
                            {title}
                        </HBox>
                }
                <HBox
                    className={block.el("text-text")}
                    mobOverturn
                    mobWidthB={1200}
                >
                    {
                        !!videoContainer?.url
                        &&  <VBox className={block.el("text-video").boolmod('single', containOnlyVideo)}>
                                <VideoPlayer
                                    videoContainer={videoContainer as Common.Video.VideoResolveContainer}
                                    disableContextMenu
                                    disableDownload
                                />
                            </VBox>
                    }
                    {
                        !!text
                        &&  <VBox className={pageBlock.el("about-description")}>
                                <MarkedWithVideo>
                                    {text}
                                </MarkedWithVideo>
                            </VBox>
                    }
                </HBox>
            </VBox>
        </HBox>
    );
}

export default LandingBlockVideo;


