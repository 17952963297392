import React, {useState}                     from 'react';
import { Link }                              from 'react-router-dom';
import { Button, Icon }                      from '@sirius/ui-lib/src/blocks/Controls/index';
import { HBox, PopoverMenu, PopoverMenuItem} from '@sirius/ui-lib/src/blocks/Layout';
import { classBlock, ClassCardProps }        from '../blocks';
import                                            './style.less';

export const ClassCard = ({title, id, showMenu = false, onDelete, onEdit}: ClassCardProps) => {
    const [target, setTarget] = useState(null);

    const toggleMenu = (e: React.MouseEvent): void => {
        e && e.stopPropagation();
        setTarget(!!target ? null : e.currentTarget);
    }

    return (
        <HBox className = {classBlock}>
            <Link className = {classBlock.el('content')}
                  to        = {`/teacher-room/class/${id}`}
            >
                <p className = {classBlock.el('content-text')}>
                    {title}
                </p>
            </Link>
            {
                showMenu
                ? <>
                    <Button onAction  = {toggleMenu}
                            icon      = {'more_vert'}
                            size      = {'s'}
                            mode      = {'secondary'}
                            active    = {!!target}
                            className = {classBlock.el('icon')}
                    />
                    <PopoverMenu target    = {target}
                                 close     = {() => setTarget(null)}
                                 placement = {'bottom-end'}
                    >
                        <PopoverMenuItem icon     = {'edit'}
                                         label    = {'Редактировать класс'}
                                         onAction = {onEdit}

                        />
                        <PopoverMenuItem icon     = {'delete'}
                                         label    = {'Удалить из курса'}
                                         onAction = {onDelete}

                        />
                    </PopoverMenu>
                </>
                : <Icon icon      = {'groups'}
                        size      = {'xl'}
                        className = {classBlock.el('icon')}
                        mode      = {'white'}
                />
            }
        </HBox>
    )
};
