import React                from "react";
import ColorUtils           from "Cheops/Utils/ColorUtils";
import BemClassName         from "Cheops/BemClassName";
import {hasNotifications}   from "Cheops/constants";
import NotificationsIcon    from "../Notifications/NotificationsIcon";



export default class CourseHeader extends React.Component {

    static defaultProps = {
        background: "purple",
        title: "",
        back_path: "/#/",
        title_align: "center",
        show_notifications: false,
        passed_modules_count: 0,
        modules_count: 0,
        showNotifications: null,
        progress_current: null,
        progress_max: null,
        star_modules_count: null,
        achieved_star_modules_count: null,
        pinned: false,
        expanded: false,
        main_menu_items: [],
        course_color: null,
        course_content_progress: [],
        course_progress: {},
        course_buckets: [],
    };


    render() {

        let wrapper_class = new BemClassName('course_header');

        let course_header__main_menu_class = "course_header__main_menu";

        if (this.props.expanded) {

            if (this.props.main_menu_items.length > 0) {

                course_header__main_menu_class += " course_header__main_menu--expanded";
                wrapper_class.appendStatus('expanded-menu');

            } else {

                wrapper_class.appendStatus('expanded');

            }

        } else {

            wrapper_class.appendStatus('reduced');

        }

        wrapper_class.appendStatusIf(this.props.pinned, 'pinned');
        wrapper_class.appendStatusIf(this.props.rounded, 'rounded');

        return (
            <div className={wrapper_class} style={{background: ColorUtils.veryDarken(this.props.course_color)}}>
                <a href={this.props.back_path} className='course_back_arrow' />
                <h1 className={`course_header__title course_header__title--${this.props.title_align}`}>{this.props.title}</h1>
                <div className='course_header__widgets'>
                    {hasNotifications && this.props.show_notifications
                    && <NotificationsIcon showNotifications={this.props.showNotifications} />}

                    {(this.props.main_menu_items.length > 0)
                    && <div onClick={this.props.expandHeader}
                            className={`course_header__menu_button course_header__menu_button--${this.props.expanded ? "hidden" : ""}`}
                    />}
                </div>

                {((this.props.main_menu_items.length > 0) && this.props.expanded)
                && <div className={course_header__main_menu_class}>
                    {this.props.main_menu_items.map((menu_item, i) => <a key={i}
                                                                         href={menu_item.link}
                                                                         className="course_header__main_menu_item"
                                                                         style={{background: ColorUtils.button(this.props.course_color)}}
                    >
                        {menu_item.title}
                    </a>)}
                </div>}
            </div>
        );

    }

}
