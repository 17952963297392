/* eslint-disable */
import AjaxWrapper from "Cheops/AjaxWrapper";

const VERSION = CONFIG.Version ? "/" + CONFIG.Version : "";

export const confirmAutogradeForCourse = (course_id) => ({
    type: "CONFIRM_AUTOGRADE",
    course_id
});

export const setAppliedAutogradeCount = (count) => ({
    type: "APPLIED_AUTOGRADE_COUNT",
    count
});


export const getAutogradeStatus = (course_id, module_id) => {
    return AjaxWrapper.get(CONFIG.Api.noopolis.url + VERSION + '/course/' + course_id + '/learn/' + module_id + '/auto/status', {}, {send_auth_token: true})
};

export const getAutogradeNextTask = (course_id, module_id) => {
    return AjaxWrapper.post(CONFIG.Api.noopolis.url + VERSION + '/course/' + course_id + '/learn/' + module_id + '/auto/next', {}, {send_auth_token: true})
};
export const getAutogradeCurrentTask = (course_id, module_id) => {
    return AjaxWrapper.get(CONFIG.Api.noopolis.url + VERSION + '/course/' + course_id + '/learn/' + module_id + '/auto/current', {}, {send_auth_token: true})
};

export const doAutogradeAction = (course_id, module_id, action) => {
    return AjaxWrapper.post(CONFIG.Api.noopolis.url + VERSION + '/course/' + course_id + '/learn/' + module_id + '/auto/' + action, {}, {send_auth_token: true})
};


export const solveAutogradeTask = (course_id, module_id, answer) => {
    return AjaxWrapper.post(CONFIG.Api.noopolis.url + VERSION + '/course/' + course_id + '/learn/' + module_id + '/auto/solve', answer, {send_auth_token: true})
};

