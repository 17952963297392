import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import TinyDatePicker from 'tiny-date-picker';
import Suggestion from 'search-suggestion';
import php_date from "locutus/php/datetime/date";
import CacheWrapper from "Smt/CacheWrapper";
import * as auth_actions from "../../actions/auth";
import * as biblio_actions from "../../actions/biblio";
import {cluster} from "CheopsRoot/clusters/current";

class AuthContent extends React.Component {

    static ROLE = {
        STUDENT: "student",
        TEACHER: "tutor",
        OTHER: "other",
    };

    static defaultProps = {
        override_footer_text: null,
        validate_email: false,
        auth_form_button_title: "",
        is_confirmation: false,
        submitDisabled: false,
        resendCode: () => {
        },
    };


    constructor(props) {

        super(props);
        this.state = {
            field_value: '',
            first_name: '',
            last_name: '',
            second_name: '',
            school_class: '',
            place_of_work: '',
            birth_date: '',
            role: AuthContent.ROLE.OTHER,
            nav_clicked: false,
            submit_is_enabled: false,
            resending_is_allowed: false,
            time_left: props.form_token_ttl,
            form_is_showed: false,
            selected_region_id: "150111239493",
            selected_school_id: null,
            schools_search_string: null,
            selected_school_address: null,

            regions: [],
            schools: [],
        };

        this.suggestion_ref = React.createRef();

    }

    async componentDidMount() {


        if (this.props.validate_email) {

            let last_email = CacheWrapper.getItem('auth_last_email');

            if (last_email) {

                this.setState({field_value: last_email}, this.validateForm);

            }

        }


        if (this.props.is_confirmation) {

            if (false && this.state.regions.length === 0 && this.props.show_profile) {

                let b_regions = (await biblio_actions.getRegions()).data.meta.getSrcObs.edges;

                let regions = [];

                for (let i in b_regions) {

                    regions.push(b_regions[i].node);

                }


                regions.sort((a, b) => {

                    if (a.regioncode < b.regioncode) {

                        return -1;

                    }

                    if (a.regioncode > b.regioncode) {

                        return 1;

                    }

                    return 0;

                });

                this.setState({regions});

            }

            this.resendCodeTimer = setInterval(this.tick.bind(this), 1000);

            this.tick();

        }

    }

    componentDidUpdate() {

        if (document.querySelector('#birth_date') !== null && this.tiny_date_picker_obj === null) {

            let today = new Date();
            let year = today.getFullYear();
            let month = today.getMonth() + 1;
            let day = today.getDate();

            this.tiny_date_picker_obj = TinyDatePicker(document.querySelector('#birth_date'), {
                lang: {
                    months: [
                        'Январь',
                        'Февраль',
                        'Март',
                        'Апрель',
                        'Май',
                        'Июнь',
                        'Июль',
                        'Август',
                        'Сентябрь',
                        'Октябрь',
                        'Ноябрь',
                        'Декабрь',
                    ],
                },
                max: `'${month}/${day}/${year}'`,
            });

            let birth_date = "";
            this.tiny_date_picker_obj.on('select', (_, dp) => {

                birth_date = new Date(dp.state.selectedDate);
                let birth_date_result = php_date("Y-m-d", birth_date.getTime() / 1000);

                this.setState({birth_date: birth_date_result}, this.validateForm);

            });


        }

    }

    componentWillUnmount() {

        clearInterval(this.resendCodeTimer);

    }

    tiny_date_picker_obj = null;

    async resendCode() {

        this.props.resendCode();

        this.resendCodeTimer = setInterval(this.tick.bind(this), 1000);

        this.setState({
            time_left: this.props.form_token_ttl,
            resending_is_allowed: false,
        });

    }

    tick() {

        let time_to_send = this.state.time_left;

        if (time_to_send <= 0) {

            clearInterval(this.resendCodeTimer);
            this.setState({
                resending_is_allowed: true,
            });
            return;

        }

        this.setState({time_left: --time_to_send});

    }

    onFieldUpdate(e) {

        let field_value = e.target.value;

        let new_state = {
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            second_name: this.state.second_name,
            field_value: this.state.field_value,
            school_class: this.state.school_class,
            place_of_work: this.state.place_of_work,
            submit_is_enabled: true,
            form_is_showed: this.state.form_is_showed,
        };


        switch (e.target.name) {
            case "auth_input_first_name":
                new_state.first_name = field_value;
                break;
            case "auth_input_last_name":
                new_state.last_name = field_value;
                break;
            case "auth_input_second_name":
                new_state.second_name = field_value;
                break;
            case "auth_input_class":
                // TODO with minimal value doesn't work properly, with min/max properties doesn't work at all

                // if(field_value <= 0){
                //     field_value = 1
                // }
                if (field_value >= 11) {

                    field_value = 11;

                }

                new_state.school_class = field_value;
                break;
            case "auth_input_place_of_work":
                new_state.place_of_work = field_value;
                break;
            default:
                new_state.field_value = field_value;
        }


        if (new_state.field_value.length === 4) {

            new_state.form_is_showed = true;

        }

        this.setState(new_state, this.validateForm);

    }

    validateForm() {

        let submit_is_enabled = true;

        if (this.props.show_profile) {

            if (!/\S+/.test(this.state.first_name)) {

                submit_is_enabled = false;

            }

            if (!/\S+/.test(this.state.last_name)) {

                submit_is_enabled = false;

            }

            if (!/\S+/.test(this.state.birth_date)) {

                submit_is_enabled = false;

            }

            if (!/\d+/.test(this.state.selected_region_id)) {

                submit_is_enabled = false;

            }

            if (this.state.role === AuthContent.ROLE.TEACHER) {

                if (!/\d+/.test(this.state.selected_school_id)) {

                    submit_is_enabled = false;

                }

            }

            if (this.state.role === AuthContent.ROLE.STUDENT) {

                if (!/\d+/.test(this.state.selected_school_id)) {

                    submit_is_enabled = false;

                }

                if (!/\S+/.test(this.state.school_class) || this.state.school_class <= 0) {

                    submit_is_enabled = false;

                }

            }

        }

        let regExp = /^\d+$/;

        if (this.props.validate_email) {

            regExp = /.+@.+/;

        }

        if (this.props.enter_for_parents) {

            regExp = /^[\d\w]+$/;

        }

        if (!regExp.test(this.state.field_value)) {

            submit_is_enabled = false;

        }


        this.setState({submit_is_enabled});

    }

    setRole(e) {

        let role = AuthContent.ROLE.STUDENT;
        switch (e.target.id) {
            case "student":
                role = AuthContent.ROLE.STUDENT;
                break;
            case "tutor":
                role = AuthContent.ROLE.TEACHER;
                break;
            case "other":
                role = AuthContent.ROLE.OTHER;
                break;
            default:
                role = AuthContent.ROLE.STUDENT;
        }

        this.setState({
            role,
            nav_clicked: true,
        }, this.validateForm);

        setTimeout(() => this.setState({nav_clicked: false}), 200);

    }

    async onSubmit() {

        if (this.state.submit_is_enabled && !this.props.submitDisabled) {

            this.setState({submit_is_enabled: false});


            if (this.props.validate_email) {

                CacheWrapper.setItem('auth_last_email', this.state.field_value);

            }

            if (this.props.show_profile) {

                if (!await this.props.sendProfile(this.state.first_name, this.state.last_name, this.state.second_name, this.state.role, this.state.selected_region_id, this.state.selected_school_id, this.state.school_class, this.state.birth_date)) {

                    return;

                }

            }

            this.props.onSubmit(this.state.field_value);

        }

    }

    async changeRegion(event) {

        this.setState({selected_region_id: event.target.value}, this.validateForm);

        this.selectSchool();

    }


    async changeSearchString(event) {

        let schools_search_string = event.target.value;

        let schools = await biblio_actions.getSchools(this.state.selected_region_id, schools_search_string);
        this.setState({schools});

    }

    async selectSchool(school) {

        if (!school) {

            if (this.suggestion_ref.current !== null) {

                this.suggestion_ref.current.clearInputValue();

            }

            this.setState({
                selected_school_id: null,
                schools_search_string: "",
                selected_school_address: "",
                schools: [],
            }, this.validateForm);

            return;

        }

        this.setState({
            selected_school_id: school.id,
            schools_search_string: school.title_rus,
            selected_school_address: school.address,
        }, this.validateForm);

    }


    render() {
        const {
            validate_email,
            enter_for_parents,
            is_confirmation,
            show_profile,
            error_message,
            override_footer_text,
            submitDisabled,
            title,
            auth_form_button_title
        } = this.props;

        const {
            form_is_showed,
            birth_date,
            schools_search_string,
            selected_school_address,
            selected_school_id,
            schools,
            role,
            school_class,
            second_name,
            first_name,
            last_name,
            field_value,
            submit_is_enabled,
            time_left,
            resending_is_allowed,
            nav_clicked
        } = this.state;

        let placeholder = 'Введите email';
        let input_type = 'text';
        let input_name = 'email';

        if (!validate_email) {

            placeholder = 'Введите код для входа';
            input_type = 'number';
            input_name = 'number';

        }

        if (enter_for_parents) {

            placeholder = 'Введите код для входа';
            input_type = 'text';
            input_name = 'text';

        }

        let auth_description = "auth_description";

        if (is_confirmation) {

            auth_description += " auth_description--confirmation";

        }

        let auth_form_class = "auth_form";

        if (show_profile && form_is_showed) {

            auth_form_class += " auth_form--new";

        }

        let input_text_class = 'auth_form_input_text';
        let input_code_class = `${input_text_class} auth_form_input_text--code`;

        if (error_message) {

            input_code_class += ' auth_form_input_text--has-error';

        }

        let auth_form__profile_group_class = "auth_form__profile_group";

        if (nav_clicked) {

            auth_form__profile_group_class += " auth_form__profile_group--active";

        }

        let auth_auxiliary = <div className="auth_auxiliary">
            {/* eslint-disable-next-line no-nested-ternary */}
            {override_footer_text
                ? <p>{override_footer_text}</p>
                : (is_confirmation
                        ? <div>
                            <div className="auth_content__resend_code">
                                {resending_is_allowed
                                    ? <>
                                        Не получили код? —&nbsp;
                                        <div className="auth_content__resend_code_button"
                                             onClick={this.resendCode.bind(this)}
                                        >
                                            Отправить ещё раз
                                        </div>
                                    </>
                                    : <div>
                                        Отправить код ещё раз можно
                                        <nobr>{` через ${time_left} секунд`}</nobr>
                                    </div>}
                            </div>
                            <a className="auth_content__change_email" href="/#/auth">Изменить email</a>
                        </div>
                        : <p>
                            Я даю согласие на обработку персональных данных в соответствии с требованиями
                            {' '}
                            <nobr>152-ФЗ</nobr>
                            {' и '}
                            <nobr>242-ФЗ</nobr>
                        </p>
                )}
        </div>;

        const textbookClass      = cluster.type === 'textbook' ? ' auth_content-textbook' : '';
        const submitButtonStatus =
            submitDisabled
                ? "disabled"
                : submit_is_enabled
                    ? "enabled"
                    : "disabled"
        ;

        return (
            <div className={`auth_content${textbookClass}`}>

                <div className={auth_description}>
                    {title}
                </div>

                <div className={auth_form_class} id="auth_form">
                    <form onSubmit={(e) => {

                        e.preventDefault();
                        this.onSubmit();

                    }}
                    >
                        <div className={input_code_class}>
                            <input type={input_type} name={input_name}
                                   value={field_value}
                                   placeholder={placeholder}
                                   autoComplete={validate_email ? "on" : "off"}
                                   onChange={this.onFieldUpdate.bind(this)}
                                   autoFocus={true}
                            />
                        </div>

                        {show_profile && form_is_showed
                        && auth_auxiliary}

                        {show_profile && form_is_showed
                        && <div className="auth_form__profile">
                            <div className="auth_form__profile_title">
                                Впервые у нас? —
                                <br />
                                давайте познакомимся
                            </div>
                            <div className="auth_form__profile_cheops">
                                <div className="auth_form__profile_cheops_circle_big" />
                                <div className="auth_form__profile_cheops_circle_small" />
                                <div className="auth_form__profile_cheops_introduction">
                                    <div className="auth_form__profile_cheops_introduction_text">
                                        Я — профессор Хеопс, а вы?
                                    </div>
                                </div>
                            </div>
                            <div className={auth_form__profile_group_class}>
                                <div className={input_text_class}>
                                    <input type="text" name="auth_input_last_name"
                                           value={last_name} placeholder="Фамилия*"
                                           onChange={this.onFieldUpdate.bind(this)}
                                    />
                                </div>
                                <div className={input_text_class}>
                                    <input type="text" name="auth_input_first_name"
                                           value={first_name} placeholder="Имя*"
                                           onChange={this.onFieldUpdate.bind(this)}
                                    />
                                </div>
                                <div className={input_text_class}>
                                    <input type="text" name="auth_input_second_name"
                                           value={second_name} placeholder="Отчество, если есть"
                                           onChange={this.onFieldUpdate.bind(this)}
                                    />
                                </div>
                                {role === AuthContent.ROLE.STUDENT
                                && <div>
                                    <div className="auth_form__profile_group_question">
                                        <div className="auth_form__profile_group_question_text">Где учитесь?</div>
                                        <div className="auth_form__profile_group_question_circle_big" />
                                        <div className="auth_form__profile_group_question_circle_small" />
                                    </div>
                                    <div className={input_text_class}>
                                        <Suggestion
                                            ref={this.suggestion_ref}
                                            id="school_select"
                                            getDisplayName={(item) => item.title_rus}
                                            items={schools}
                                            onSelectedItem={(item) => this.selectSchool(item)}
                                        >
                                            {({
                                                  getInputProps,
                                                  getListItemProps,
                                                  getItemProps,
                                                  items,
                                                  isOpen,
                                                  clearInputValue,
                                              }) => (
                                                <>
                                                    {selected_school_id
                                                    && <div className="auth_input_school_selected"
                                                            onClick={() => {

                                                                this.selectSchool();

                                                            }}
                                                    >
                                                        {schools_search_string}
                                                        <span>{selected_school_address}</span>
                                                    </div>}

                                                    <input
                                                        {...getInputProps({
                                                            name: "auth_input_school",
                                                            type: "text",
                                                            value: schools_search_string,
                                                            placeholder: "Школа*",
                                                            onFocus: (e) => {

                                                                e.target.placeholder = "Начните писать и выберите";

                                                            },
                                                            onBlur: (e) => {

                                                                e.target.placeholder = "Школа*";
                                                                clearInputValue();

                                                            },
                                                            onChange: (e) => this.changeSearchString(e),
                                                        })}
                                                    />
                                                    {isOpen && items.length > 0 && (
                                                        <div {...getListItemProps({
                                                            className: "auth_input_school_options",
                                                        })}
                                                        >
                                                            {items.map((item) => {

                                                                return (
                                                                    <div
                                                                        {...getItemProps({
                                                                            item,
                                                                            index,
                                                                        })}
                                                                        key={item.id}
                                                                    >
                                                                        {item.title_rus}
                                                                        {' '}
                                                                        <span>{item.address}</span>
                                                                    </div>

                                                                );

                                                            })}
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        </Suggestion>


                                    </div>
                                    <div className={input_text_class}>
                                        <input type="number" name="auth_input_class"
                                               value={school_class} placeholder="Класс*"
                                               onKeyDown={(e) => {

                                                   if ([8, 37, 38, 39, 40, 46].indexOf(e.keyCode) !== -1) {

                                                       return;

                                                   }

                                                   if (!/^[0-9]$/.test(e.key)) {

                                                       e.preventDefault();

                                                   }

                                               }}
                                               onChange={this.onFieldUpdate.bind(this)}
                                        />
                                    </div>
                                </div>}
                                {role === AuthContent.ROLE.TEACHER
                                && <div>
                                    <div className="auth_form__profile_group_question">
                                        <div className="auth_form__profile_group_question_text">Где работаете?</div>
                                        <div className="auth_form__profile_group_question_circle_big" />
                                        <div className="auth_form__profile_group_question_circle_small" />
                                    </div>
                                    <div className={input_text_class}>
                                        <Suggestion
                                            ref={this.suggestion_ref}
                                            id="school_select"
                                            getDisplayName={(item) => item.title_rus}
                                            items={schools}
                                            onSelectedItem={(item) => this.selectSchool(item)}
                                        >
                                            {({
                                                  getInputProps,
                                                  getListItemProps,
                                                  getItemProps,
                                                  items,
                                                  isOpen,
                                                  clearInputValue,
                                              }) => (
                                                <>
                                                    {selected_school_id
                                                    && <div className="auth_input_school_selected"
                                                            onClick={() => {

                                                                this.selectSchool();

                                                            }}
                                                    >
                                                        {schools_search_string}
                                                        <span>{selected_school_address}</span>
                                                    </div>}
                                                    <input
                                                        {...getInputProps({
                                                            name: "auth_input_school",
                                                            type: "text",
                                                            value: schools_search_string,
                                                            placeholder: "Образовательное учреждение*",
                                                            onFocus: (e) => {

                                                                e.target.placeholder = "Начните писать и выберите";

                                                            },
                                                            onBlur: (e) => {

                                                                e.target.placeholder = "Образовательное учреждение*";
                                                                clearInputValue();

                                                            },
                                                            onChange: (e) => this.changeSearchString(e),
                                                        })}
                                                    />
                                                    {isOpen && items.length > 0 && (
                                                        <div {...getListItemProps({
                                                            className: "auth_input_school_options",
                                                        })}
                                                        >
                                                            {items.map((item) => <div
                                                                {...getItemProps({
                                                                    item,
                                                                    index,
                                                                })}
                                                                key={item.id}
                                                            >
                                                                {item.title_rus}
                                                                {' '}
                                                                <span>{item.address}</span>
                                                            </div>)}
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        </Suggestion>
                                    </div>
                                </div>}
                                <div className="auth_form__profile_group_question">
                                    <div className="auth_form__profile_group_question_text">А ваш день рождения?</div>
                                    <div className="auth_form__profile_group_question_circle_big" />
                                    <div className="auth_form__profile_group_question_circle_small" />
                                </div>
                                <div className={input_text_class}>
                                    <input type="text" name="auth_input_birth_date" id="birth_date"
                                           placeholder="День рождения*"
                                           value={birth_date}
                                    />
                                </div>
                            </div>
                        </div>}
                    </form>
                    <div className="auth_form__error">{error_message}</div>
                    <div
                        className={`auth_form_button auth_form_button--${submitButtonStatus}`}
                        onClick={() => this.onSubmit()}
                    >
                        <h1 className='auth_form_button_title'>{auth_form_button_title}</h1>
                    </div>
                    {(!show_profile || !form_is_showed)
                    && auth_auxiliary}
                </div>


            </div>
        );

    }

}

const mapStateToProps = (state) => ({
    form_token_ttl: state.authReducer.form_token_ttl,
});

const mapDispatchToProps = (dispatch) => ({
    getFormSessionToken: bindActionCreators(auth_actions.getFormSessionToken, dispatch),
    sendAuthCode: bindActionCreators(auth_actions.sendAuthCode, dispatch),
});


export default connect(mapStateToProps, mapDispatchToProps)(AuthContent);
