import React from "react";
import Helpers from "Cheops/Helpers";
import "./TransferProgressPopup.less";
import BemClassName from "Cheops/BemClassName";

export default class TransferProgressPopup extends React.Component {

    static PROGRESS_TYPE = {
        TASKS: "tasks",
        THEMES: "themes",
    };

    static defaultProps = {
        type: TransferProgressPopup.PROGRESS_TYPE.TASKS,
        task_number: null,
        closeTransferPopup: () => {
        },
    };


    render() {

        let task_number_text = "";
        let progress_type = ["задача", "задачи", "задач"];

        if (this.props.type === TransferProgressPopup.PROGRESS_TYPE.THEMES) {

            progress_type = ["тема", "темы", "тем"];

        }

        let transfer_text = "Зачтено";
        let transfer_button = "Хорошо";
        let className = new BemClassName("transfer_progress_popup");

        if (this.props.task_number) {

            task_number_text = `${this.props.task_number} ${Helpers.formatPlural(this.props.task_number, progress_type)}`;
            transfer_text = Helpers.formatPlural(this.props.task_number, ["Зачтена", "Зачтены", "Зачтено"]);

        } else {

            transfer_text = "Вы успели решить все задачи, которые можно было засчитать";
            transfer_button = "Ясно";
            className.appendStatus("null");

        }

        return (<div className="transfer_progress_popup__wrapper">
            <div className={className}>
                <div className="transfer_progress_popup__checkmark" />
                <div className="transfer_progress_popup__text">
                    {transfer_text}
                    <br />
                    <nobr>{task_number_text}</nobr>
                </div>
                <div className="transfer_progress_popup__button"
                     onClick={this.props.closeTransferPopup}
                >
                    {transfer_button}
                </div>
            </div>
        </div>);

    }

}
