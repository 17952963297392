import React, { FC }        from "react";
import {Bnc}                from "bnc";
import {Icon}               from "@sirius/ui-lib/src/blocks/Controls";
import {DoorIcon, LockIcon} from "Cheops/containers/TeacherRoomPage/icons";
import                          "./TeacherCardIcon.less";

type Props = {
    hasAccess: boolean;
    block: Bnc;
}

export const TeacherCardIcon: FC<Props> = ({block, hasAccess,}) => (
    <div className={block.el('icon') + block.el('icon').bod('has-access', hasAccess) + 'teacher-card-icon'}>
        <Icon icon={hasAccess ? DoorIcon : LockIcon} />
    </div>
);
