import {ReactNode}              from "react";
import bnc, {Bnc}               from "bnc";
import { DropdownItem }         from "@sirius/ui-lib/src/blocks/DropdownSelect/@types";
import {PopoverMenuItemType}    from "@sirius/ui-lib/src/blocks/Layout/Popover/@types";
import {ButtonProps}            from "@sirius/ui-lib/src/blocks/Controls";

const block              = new bnc('teacher-room-page');
const classBlock         = new bnc('teacher-class-card');
const courseBlock        = new bnc('teacher-course-card');
const classPageBlock     = new bnc('teacher-class-page');
const headerBlock        = new bnc('teacher-header');
const coursePageBlock    = new bnc('teacher-course-page');
const moduleBlock        = new bnc('teacher-module-card');
const contestBlock       = new bnc('teacher-contest-card');
const progressPage       = new bnc('teacher-progress-page');
const invitePage         = new bnc('teacher-invite-page');
const dropdownBlock      = new bnc('teacher-dropdown');
const coursePageTOCBlock = new bnc('teacher-course-page-toc');

type HasModalTypes = {
    onClose:    () => void;
    onAction:   () => void;
    disabled:   boolean;
    className?: string;
};

type HasOnChange         = { onChange: (v: string) => void          };
type HasInitValue        = { initValue: string                      };
type HasActionButtonText = { actionButtonText: string               };
type HasClassTitle       = { classTitle: NooBack.Teacher.ClassTitle };

type ActionButtonsProps    = HasActionButtonText & HasModalTypes;
type CreateClassModalProps = HasOnChange & HasModalTypes;

type InviteToCourseModalProps = {
    courses:          NooBack.Teacher.Methods.CoursesList;
    classes:          NooBack.Teacher.Methods.ClassesList;
    selectedCourse:   string;
    selectedClasses:  string[];
    onCourseChange:   (v: DropdownItem[]) => void;
    onClassChange:    (id: string) => void;
} & HasModalTypes;

type TeacherModalProps = {
    headerTitle:      string;
    children?:        ReactNode;
} & HasActionButtonText & HasModalTypes;

type InviteStudentsModalProps = {
    link:       string;
    onCopy:     () => void;
} & HasModalTypes & HasClassTitle;

type EditNameModalProps = {
    id?: NooBack.Teacher.StudentId;
} & HasOnChange & HasInitValue & HasModalTypes;

type GrantsModalProps = HasClassTitle & HasOnChange & HasModalTypes;

type TeacherCardProps = {
    popoverMenuItems?: Array<PopoverMenuItemType>;
} & MaybeHasCourseId;

type TeacherModuleCardProps =
    TeacherCardProps &
    NooBack.Teacher.CourseModuleInfo;

type TeacherContestCardProps =
    TeacherCardProps &
    NooBack.Teacher.ContestInfo;

type ProgressPageTableProps = {
    table:         NooBack.Teacher.Methods.Table;
    title:         NooBack.Teacher.Methods.CourseModuleTitle;
    selectedChips: NooBack.Teacher.Methods.TableChip;
    onSelectChips: (v: NooBack.Teacher.Methods.ChipName) => void;
    onEdit?:       (v: NooBack.Teacher.Methods.StudentInfo) => void;
    seeAsStudent?: (v: NooBack.Teacher.StudentId) => void;
};

type StudentRowType = {
    num:  number;
    name: string;
    id:   string;
};

type ClassCardProps = {
     showMenu?: boolean;
     onDelete?: () => void;
     onEdit?:   () => void
} & NooBack.Teacher.Methods.ClassInfo;

type ClassPageRowEditType = {
    courses:           NooBack.Teacher.Methods.ClassList;
    onActionModal:     (row: StudentRowType, mode: 'delete' | 'edit') => void;
    onActionAsStudent: (row: StudentRowType, course: NooBack.Teacher.Methods.CourseInfo) => void;
};

type HasTOC = {
    toc: NooBack.Teacher.Methods.TableOfContents
}

type HasModules = {
    modules: Record<string, NooBack.Teacher.Methods.CourseModuleInfo>
}

type HasCourseId = {
    courseId: string
}
type MaybeHasCourseId = Partial<HasCourseId>;

type HasSetRedirect = {
    setRedirect: (url: string) => void;
}

type HasCurrentChapter = {
    currentChapter: string | null
}

type HasSelectChapter = {
    selectChapter: (chapter: string | null) => void;
}

type ModuleCardMenuActionsType = {
    showModuleAccessHandler?:  ({id, title}: Partial<NooBack.Teacher.CourseModuleInfo>) => () => void,
    showContestAccessHandler?: ({id, title}: Partial<NooBack.Teacher.ContestInfo>) => () => void,
    downloadContestReport?:    ({id}       : Partial<NooBack.Teacher.ContestInfo>) => void,
} & HasSetRedirect;

type HasMenuActions = {
    menuActions: ModuleCardMenuActionsType
}

type TeacherCoursePageTOCProps =
    HasTOC &
    HasModules &
    HasCourseId &
    HasMenuActions
;

type TOCChapterLessonsProps =
    NooBack.Teacher.Methods.ChapterWithLessons &
    HasModules &
    HasCourseId &
    HasMenuActions
;

type TOCChapterParagraphsProps =
    NooBack.Teacher.Methods.ChapterWithParagraphs &
    HasModules &
    HasCourseId &
    HasMenuActions
;

type TOCParagraphLessonsProps =
    NooBack.Teacher.Methods.ParagraphWithLessons &
    HasModules &
    HasCourseId &
    HasMenuActions
;

type TOCChapterSelectorProps =
    HasTOC &
    HasCurrentChapter &
    HasSelectChapter
;

type ErrorNotificationProps = {
    errors: NooBack.Teacher.Methods.ErrorObjectsList;
    onClick: (i: number) => void;
};

type ErrorNotificationCardProps = {
    index:   number;
    onClick: (i: number) => void;
} & Pick<NooBack.Teacher.Methods.ErrorItem, 'message'>;

type PermissionsClassProps = {
    block:              Bnc;
    grantAccessAction:  () => void;
} & Pick<NooBack.Teacher.Methods.ClassInfo, 'permissions'| 'owner'>;

type SubmenuButtonProps = {
    label : string
} & ButtonProps;

export {
    block,
    classBlock,
    courseBlock,
    classPageBlock,
    headerBlock,
    coursePageBlock,
    moduleBlock,
    contestBlock,
    progressPage,
    invitePage,
    dropdownBlock,
    coursePageTOCBlock,
    CreateClassModalProps,
    InviteToCourseModalProps,
    ActionButtonsProps,
    HasModalTypes,
    TeacherModalProps,
    InviteStudentsModalProps,
    EditNameModalProps,
    GrantsModalProps,
    TeacherModuleCardProps,
    TeacherContestCardProps,
    StudentRowType,
    ProgressPageTableProps,
    ClassCardProps,
    ClassPageRowEditType,
    TeacherCoursePageTOCProps,
    TOCChapterLessonsProps,
    TOCChapterParagraphsProps,
    TOCParagraphLessonsProps,
    TOCChapterSelectorProps,
    ModuleCardMenuActionsType,
    ErrorNotificationProps,
    ErrorNotificationCardProps,
    PermissionsClassProps,
    SubmenuButtonProps,
};
