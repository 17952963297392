import React from "react";
import Marked from "@sirius/ui-shared/src/components/DisplayEngine/Marked";

export default class QuestionsAnswersItem extends React.Component {

    static defaultProps = {
        student_name: null,
        who_answered_name: null,
        question_text: null,
        answer_text: null,
    };

    render() {

        let answer_text;

        if (this.props.answer_text) {

            answer_text = this.props.answer_text;

            if (this.props.who_answered_name) {

                answer_text += ` / <nobr>${this.props.who_answered_name}</nobr>`;

            }

        } else {

            answer_text = "<p>Ожидаем ответа преподавателя</p>";

        }

        return (
            <div className="qa_item">
                <div className="qa_item__question_block">
                    {this.props.question_text}
                    {this.props.student_name
                    && <span className="qa_item__student_name">
                        {' / '}
                        <nobr>{this.props.student_name}</nobr>
                    </span>}
                </div>
                {answer_text
                && <div className="qa_item__answer_block">
                    <Marked>{answer_text}</Marked>
                </div>}
            </div>);

    }

}
