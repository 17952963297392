import React from "react";

export const DoorIcon = ({className}:{ className: string }) =>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" className={className} xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd"
              clipRule="evenodd"
              d="M19 3C19 2.44772 18.5523 2 18 2H6C5.44772 2 5 2.44772 5 3V3.47703V4V18.323C5 18.7319 5.24895 19.0996 5.62861 19.2514L13.6286 22.4514C14.2855 22.7142 15 22.2304 15 21.523V19H18C18.5523 19 19 18.5523 19 18V3ZM15 17H17V4H13H10L14.3714 5.74856C14.751 5.90042 15 6.26813 15 6.67703V17ZM7 17.646V4.953L13 7.353V13.477C13 13.0681 12.751 12.7004 12.3714 12.5486C11.7145 12.2858 11 12.7696 11 13.477V13.723C11 14.1319 11.249 14.4996 11.6286 14.6514C12.2855 14.9142 13 14.4304 13 13.723V17V19V20.046L7 17.646Z"
              fill="currentColor"
        />
    </svg>

;
