import React, {FC, useEffect}             from "react";
import {HashRouter, Route, Switch}        from "react-router-dom";
import {Screen}                           from "@sirius/ui-lib/src/blocks/Layout";
import {useBehaviorSubject}               from "@sirius/ui-lib/src/subjects/useBehaviorSubject";
import store                              from "Cheops/Store";
import {TeacherPanel}                     from "Cheops/components/TeacherPanel";
import {getUserInfo, setAuthToken}        from "Cheops/actions/auth";
import {isStudentMode$, setIsStudentMode} from "Cheops/subjects/isStudentMode";
import {deleteTokenToSeeAsStudent}        from "Cheops/actions/noopolis-ts";

const deleteToken = () => {
    deleteTokenToSeeAsStudent()
        .then(({response}) => {
            setIsStudentMode(false);
            store.dispatch(setAuthToken(response.token));
            location.hash = '/teacher-room';
        })
    ;
};

const StudentModeHandler:FC = () => {
    useEffect(() => {
        const roles = getUserInfo('roles') || [];
        const showTeacherPanel = roles.includes('i:nooRO');
        showTeacherPanel && setIsStudentMode(true);

        return () => {
            if (isStudentMode$.value) {
                deleteToken();
            }
        };
    }, []);

    return null;
};

const SwitcherCourseMode:FC = () =>
    <HashRouter>
        <Switch>
            <Route path={'/course'} component={StudentModeHandler}/>
        </Switch>
    </HashRouter>
;

export const NooScreen = ({children}: {children: React.ReactNode}) => {

    const showTeacherPanel = useBehaviorSubject(isStudentMode$);

    return(
        <Screen>
            <SwitcherCourseMode/>
            {
                showTeacherPanel &&
                <TeacherPanel onAction={deleteToken}/>
            }
            {children}
        </Screen>
    );
};
