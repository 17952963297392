import React, {FC, useState}           from 'react';
import {CourseProgress}                from 'Cheops/components/CoursePage/V2/CourseProgress';
import {hasNotifications}              from 'Cheops/constants';
import NotificationsIcon               from 'Cheops/components/Notifications/NotificationsIcon';
import {CourseCardProps, MenuItemType} from './@types';
import {block}                         from './block';

const MenuItem:FC<MenuItemType> = ({link, title, className}) =>
    <a href      = {link}
       className = {`${block.el('main-menu-item')} text-s ${className}`}
    >
        {title}
    </a>
;

const StarIcon = () =>
    <svg className= {`${block.el('progress-icon')}`}
         width='16' height='15' viewBox='0 0 16 15' fill='none'
         xmlns='http://www.w3.org/2000/svg'
    >
        <path d='M8 12.3917L11.4583 14.4834C12.0917 14.8667 12.8667 14.3 12.7 13.5834L11.7833 9.65003L14.8417 7.00003C15.4 6.5167 15.1 5.60003 14.3667 5.5417L10.3417 5.20003L8.76667 1.48336C8.48333 0.808362 7.51667 0.808362 7.23333 1.48336L5.65833 5.1917L1.63333 5.53336C0.9 5.5917 0.599999 6.50836 1.15833 6.9917L4.21667 9.6417L3.3 13.575C3.13333 14.2917 3.90833 14.8584 4.54167 14.475L8 12.3917Z'
              fill='#5BC36C'
        />
    </svg>
;

export const CourseCard:FC<CourseCardProps> = (
    {
        courseColor,
        backPath,
        hasToc,
        contentProgress,
        onProgressClick,
        learnProgress,
        advancedModulesTotal ,
        advancedModulesAchieved,
        showNotifications,
        onNotificationsClick,
        title,
        menuItems,
        autogradeState,
        expanded
    }) => {
    const [menuExpanded, setMenuExpanded] = useState(false);

    const getMenuItems = (list: CourseCardProps['menuItems']) =>
        list.map(
            ({link, title, className}, idx) =>
                <MenuItem className = {className || ''}
                          key       = {idx}
                          title     = {title}
                          link      = {
                              autogradeState &&
                              autogradeState === 'started'
                                  ? null
                                  : link
                          }
                />
        )
    ;

    const showExpandButton      = menuItems.length > 5 && !menuExpanded;
    const showNotificationsIcon = showNotifications && hasNotifications && expanded;

    return(
        <div className = {
            block +
            block.bod('expanded', expanded)
        }
             style     = {{background: courseColor}}
        >
            <a href      = {backPath}
               className = {block.el('back-arrow')}
            />
            {
                expanded &&
                <CourseProgress courseContentProgress = {contentProgress}
                                onClick               = {onProgressClick}
                                courseProgress        = {learnProgress}
                />
            }
            <div className={block.el('widgets')}>
                {
                    advancedModulesTotal > 0 &&
                    <div className = {block.el('progress')}
                         title     = {'Зачтённые лекционные модули'}
                    >
                        <StarIcon/>
                        {`${advancedModulesAchieved}/${advancedModulesTotal}`}
                    </div>
                }
                {
                    showNotificationsIcon &&
                    <NotificationsIcon showNotifications = {onNotificationsClick}
                                       show_last_pinned  = {false}
                    />
                }
            </div>
            <div className={`${block.el('title')}${expanded ? ' text-xl' : ''}`}>
                {title}
            </div>
            {
                menuItems.length > 0 && expanded
                && <div className={block.el('main-menu')}>
                    {
                        getMenuItems(
                            showExpandButton
                                ? menuItems.slice(0, 4)
                                : menuItems
                        )
                    }
                    {
                        showExpandButton &&
                        <div onClick   = {() => setMenuExpanded(true)}
                             className = {block.el('expand-menu-button')}
                        />
                    }
                </div>
            }
        </div>
    );
};
