import React from "react";
import RequiredModuleItem from "./RequiredModuleItem";
import "./RequiredModulesList.less";


export default class RequiredModulesList extends React.Component {

    static defaultProps = {
        module_list: {},
        module_obj: null,
        course_learn: {
            modules: {},
        },
        setSelectedModuleId: () => {
        },
    };


    render() {

        return (
            <div className="required_modules_list list">
                <div>
                    {this.props.module_obj.dependencies.map((module_id) => <RequiredModuleItem
                        key={module_id}
                        module_progress={this.props.course_learn.modules[module_id]}
                        module_obj={this.props.module_list[module_id]}
                        course_color={this.props.course_color}
                        onClick={() => this.props.setSelectedModuleId(module_id)}
                    />)}
                </div>
            </div>
        );

    }

}
