import React                from "react";
import ModuleCard           from "Cheops/components/ModuleCard/ModuleCard";
import {TextbookModuleCard} from "Cheops/components/TextbookModuleCard";
import Helpers              from "Cheops/Helpers";


export default class CourseCards extends React.Component {

    static defaultProps = {
        course_stats: [],
        course_color: null,
        isTextbook: false,
    };

    constructor(props) {

        super(props);

        this.state = {
            overridden_cards: {},
            current_module_list: [],
            advanced_module_list: [],
            completed_module_list: [],
        };

    }

    componentWillMount() {

        this.sortModules();

    }

    componentWillReceiveProps() {

        this.sortModules();

    }


    getAllReverseExtDependencies(module_id) {

        let module_obj = this.props.module_list[module_id];


        if (!module_obj || !module_obj.reverseDependencies) {

            return [];

        }

        let extDependencies = module_obj.reverseDependencies.map((v) => (v.type === 'extension' ? v.id : null)).filter((v) => v);

        let add_dependencies = [];

        for (let dependencyId of extDependencies) {

            dependencyId = parseInt(dependencyId);

            add_dependencies = add_dependencies.concat(this.getAllReverseExtDependencies(dependencyId));

        }

        return extDependencies.concat(add_dependencies);

    }


    sortModules() {

        const {current_course_learn, course_contents, isTextbook} = this.props;

        let current_module_list = [];
        let advanced_module_list = [];
        let completed_module_list = [];

        let ext_dependencies_ids = [];

        for (let [module_id] of Object.entries(current_course_learn.modules)) {

            module_id = parseInt(module_id);

            let all_dependecies = this.getAllReverseExtDependencies(module_id);
            ext_dependencies_ids = ext_dependencies_ids.concat(all_dependecies);
        }

        for (let [module_id, learn_module] of Object.entries(current_course_learn.modules)) {

            const moduleList = course_contents[current_course_learn.hash].module;

            let module = {
                ...moduleList[learn_module.id],
                ...learn_module,
            };


            if (isTextbook && module.currentComplexity) {

                const allDependencies =
                    Helpers.getAllExtDependencies(module_id, moduleList, true)
                           .concat(parseInt(module_id))
                ;

                if (allDependencies.length > 1) {

                    const currentComplexityModule =
                        Object.values(moduleList)
                              .filter(
                                  (item) => allDependencies.includes(item.id) &&
                                                item.level === module.currentComplexity
                              )
                    ;

                    if (currentComplexityModule.length > 0) {
                        const learnModuleItem =
                            Object.values(current_course_learn.modules)
                                  .filter((module) => currentComplexityModule[0].id === module.id)
                        ;
                        module = {
                            ...currentComplexityModule[0],
                            ...learnModuleItem[0],
                        };
                    }
                }
            }


            if (!(learn_module.id in moduleList)) {

                continue;

            }


            if (ext_dependencies_ids.includes(parseInt(module_id))) {

                continue;

            }


            if (module.isClosed || module.availability.locked) {

                completed_module_list.push(module);
                continue;

            }

            if (module.isAdvanced) {

                advanced_module_list.push(module);
                continue;

            }

            current_module_list.push(module);

        }


        current_module_list.sort((a, b) => this.sortModuleList(a, b, "id"));
        current_module_list.sort((a, b) => this.sortModuleList(a, b, "closeDate"));
        current_module_list.sort((a, b) => this.sortModuleList(a, b, "openDate"));

        advanced_module_list.sort((a, b) => this.sortModuleList(a, b, "id"));
        advanced_module_list.sort((a, b) => this.sortModuleList(a, b, "closeDate"));
        advanced_module_list.sort((a, b) => this.sortModuleList(a, b, "openDate"));

        completed_module_list.sort((a, b) => this.sortModuleList(a, b, "id"));
        completed_module_list.sort((a, b) => this.sortModuleList(a, b, "closeDate"));
        completed_module_list.sort((a, b) => this.sortModuleList(a, b, "openDate"));


        this.setState({
            current_module_list,
            advanced_module_list,
            completed_module_list,
        });

    }


    // eslint-disable-next-line consistent-return
    sortModuleList(a, b, property) {

        let a_date = null;
        let b_date = null;

        if (property === "id") {

            return a.id - b.id;

        }

        if ((property === "closeDate") || property === "openDate") {

            if (a.availability[property]) {

                a_date = new Date(a.availability[property]);

            }

            if (b.availability[property]) {

                b_date = new Date(b.availability[property]);

            }

            if ((a_date !== null) && (b_date !== null)) {

                return a_date - b_date;

            }

            if ((a_date !== null) && (b_date === null)) {

                if (property === "closeDate") {

                    return -1;

                }

                if (property === "openDate") {

                    return 1;

                }

            }

            if ((a_date === null) && (b_date !== null)) {

                if (property === "closeDate") {

                    return 1;

                }

                if (property === "openDate") {

                    return -1;

                }

            }

            return 0;

        }

    }

    overrideModuleCard = (module_id, target_module_id) => {

        let overridden_cards = this.state.overridden_cards;

        overridden_cards[module_id] = target_module_id;
        delete overridden_cards[target_module_id];

        this.setState({overridden_cards});

    }

    getCards = (cards) => {

        const {
            course_stats,
            course_id,
            module_list,
            current_course_learn,
            course_color,
            isTextbook
        } = this.props;

        return cards.map((module, i) => {

            const module_stats =
                course_stats.moduleStatistics
                    ? course_stats.moduleStatistics[module.id]
                    : null
            ;

            return  isTextbook
                ? <TextbookModuleCard key      = {i}
                                      module   = {module}
                                      progress = {current_course_learn.modules[module.id]}
                                      link     = {`/#/course/${course_id}/${module.id}`}
                />
                : <ModuleCard key                 = {i}
                              selected_module_id  = {module.id}
                              course_learn        = {current_course_learn}
                              course_stats        = {module_stats}
                              setSelectedModuleId = {(target_id) => this.overrideModuleCard(module.id, target_id)}
                              {...{
                                  course_color,
                                  module_list,
                                  course_id
                              }}
                />
            ;

        })
    };

    getLayout = (moduleList, title, textbookMode = false) =>

        <div className = {
                !textbookMode
                    ? "course_page__plain_module_list"
                    : "course_page__plain_module_list course_page__plain_module_list_textbook"
            }
        >
            {
                !textbookMode &&
                <div className="course_page__plain_module_list_title">
                    {title}
                </div>
            }
            { this.getCards(moduleList) }
        </div>
    ;

    getModuleList = (list) => {
        const {current_course_learn} = this.props;
        const {overridden_cards}     = this.state;

        return list.map((module) => {
            const overridden_id = overridden_cards[module.id];

            return overridden_id
                ? current_course_learn.modules[overridden_id]
                : module
            ;
        });
    };

    render() {

        const {expandMap, isTextbook} = this.props;
        const {current_module_list, advanced_module_list, completed_module_list} = this.state;

        const currentModuleList   = this.getModuleList(current_module_list);
        const advancedModuleList  = this.getModuleList(advanced_module_list);
        const completedModuleList = this.getModuleList(completed_module_list);

        return (
            <div className = "course_cards"
                 onClick   = {expandMap}
            >
                {
                    currentModuleList.length > 0 &&
                    this.getLayout(currentModuleList, "Основная программа", isTextbook)
                }

                {
                    advancedModuleList.length > 0 &&
                    this.getLayout(advancedModuleList, "Дополнительные материалы", isTextbook)
                }

                {
                    completedModuleList.length > 0 &&
                    this.getLayout(completedModuleList, "Завершённые", isTextbook)
                }
            </div>
        );
    }
}
