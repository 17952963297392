import {IconSocial} from "./IconSocial";
import {
    EIconSocial
}                    from "./@types";
import type {
    IconSocialProps
}                    from "./@types";
import "./index.less";

export {
    IconSocial,
    EIconSocial
};
export type {
    IconSocialProps
};
