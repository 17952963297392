import React, {FC}             from "react";
import {SubjectIcon}           from "@sirius/ui-lib/src/blocks/Controls/SubjectIcon/SubjectIcon";
import {Subject}               from "@sirius/ui-lib/src/blocks/Controls/SubjectIcon/iconComponents/types";
import {ym}                    from "Cheops/Utils/MetrikaWrapper";
import {block}                 from "./block";
import {ChainsCourseCardProps} from "./@types";
import                              "./index.less";


export const LandingsChainsCourseCard: FC<ChainsCourseCardProps> = (props) => {
    const {
        soon
    } = props;

    const {
        name = 'unknown',
        title,
        bgColor,
        icon,
        comment
    } = soon;

    const chainsCourseCardClick = () => {
        const goalParams = {};
        const reachGoalTpl = `noo.CoursePage.chain.${name}`;
        const locationHref = (param= name) => {
            location.href = `/course/${param}`;
        }
        setTimeout (locationHref, 500, name);
        ym('reachGoal',reachGoalTpl, goalParams, locationHref);
    };

    return  (
        <div  className = {block.el('wrapper-out')}>
            <div
                className = {block}
                style     = {{backgroundColor: bgColor}}
                onClick   = {chainsCourseCardClick}
            >
                <SubjectIcon className = {block.el('icon')}
                             subject   = {icon as Subject}
                             mode      = {'white'}
                             size      = {'l'}
                />
                <div className = {block.el('wrapper')}>
                    <div className = {block.el('title')}>
                        {title}
                    </div>
                    {
                        comment
                         && <div className = {block.el('comment')}>
                                {comment}
                            </div>
                    }
                </div>
            </div>
            <div className = {block.el('margin-block')} />
        </div>
    )
}



export default LandingsChainsCourseCard;
