import React from "react";
import BemClassName from "Cheops/BemClassName";
import * as auth_actions from "Cheops/actions/auth";
import cloneDeep from "lodash/cloneDeep";
import Helpers from "Cheops/Helpers";
import {RESULTS_TABLE_MAX_USER_COUNT, RESULTS_TABLE_UNCUTTABLE_PERCENT} from "Cheops/constants";
import ResultTableSidebar from "./ResultTableSidebar";
import ResultTableHeaderSidebar from "./ResultTableHeaderSidebar";
import ResultTableHeaderTable from "./ResultTableHeaderTable";
import ResultTableCell from "./ResultTableCell";
import "./ResultTable.less";

export default class ResultTable extends React.Component {

    static MODE = {
        NOO: "noo",
        LAB: "lab",
        EMPTY: "EMPTY",
    };

    static defaultProps = {
        report: {},
        mode: ResultTable.MODE.NOO,
    };

    constructor(props) {

        super(props);


        this.state = {
            groups: {},
            sidebar_is_open: false,
        };

        this.header_table_el = null;
        this.table_el = null;

    }

    componentWillMount() {

        let groups = this.state.groups;

        for (let group of this.props.report.groups) {

            groups[group.id] = group;

        }

        this.setState({groups});

    }


    componentDidMount() {

        this.header_table_el = document.querySelector(".result_table_header_table");
        this.table_el = document.querySelector(".result_table__table");


        this.header_table_el.scrollLeft = this.header_table_el.scrollWidth;
        this.header_table_el.scrollTop = 0;
        this.table_el.scrollLeft = this.header_table_el.scrollWidth;
        this.table_el.scrollTop = 0;

        // this.header_table_el.addEventListener('scroll', this.headerScroll);
        this.table_el.addEventListener('scroll', this.tableScroll);

    }


    componentWillUnmount() {

        this.table_el.removeEventListener('scroll', this.tableScroll);

    }


    tableScroll = () => {

        this.header_table_el.scrollLeft = this.table_el.scrollLeft;
        this.header_table_el.scrollTop = 0;

    };


    getReport() {

        let current_user_id = auth_actions.getUserInfo('id');

        let report = cloneDeep(this.props.report);


        if (report.users.length > RESULTS_TABLE_MAX_USER_COUNT && this.props.mode === ResultTable.MODE.NOO) {

            const maxTotalScore = report.users[0].total;

            let lastIndex = report.users.findIndex((user) => user.total !== maxTotalScore);
            let currentUserReportIndex = report.users.findIndex((user) => user.name.id === current_user_id);

            const appendCount = (report.users.length - lastIndex) * RESULTS_TABLE_UNCUTTABLE_PERCENT / 100;

            Helpers.assert(appendCount >= 0, "appendCount is negative number");

            lastIndex += Math.floor(appendCount);

            Helpers.assert(lastIndex + 1 <= report.users.length, "lastIndex is bigger than array of users length");

            if (currentUserReportIndex > lastIndex) {

                const beforeRemainingQuantity = currentUserReportIndex - lastIndex - 1;

                const afterRemainingQuantity = report.users.length - (currentUserReportIndex + 1);

                if (beforeRemainingQuantity > 0) {

                    report.users.splice(lastIndex + 1, beforeRemainingQuantity);

                    report.users.splice(lastIndex + 1, 0, {hole: `${beforeRemainingQuantity} ${Helpers.formatPlural(beforeRemainingQuantity, ['ученик', 'ученика', 'учеников'])}`});

                } else {

                    lastIndex = currentUserReportIndex;

                }


                if (afterRemainingQuantity > 0) {

                    if (beforeRemainingQuantity > 0) {

                        report.users.splice(lastIndex + 3);

                    } else {

                        report.users.splice(lastIndex + 1);

                    }

                    report.users.push({hole: `${afterRemainingQuantity} ${Helpers.formatPlural(afterRemainingQuantity, ['ученик', 'ученика', 'учеников'])}`});

                }

            } else {

                const remainingQuantity = report.users.length - (lastIndex + 1);

                report.users.splice(lastIndex + 1);

                if (remainingQuantity > 0) {

                    report.users.push({hole: `${remainingQuantity} ${Helpers.formatPlural(remainingQuantity, ['ученик', 'ученика', 'учеников'])}`});

                }

            }

        }

        return report;

    }


    render() {


        let result_table__content_class = new BemClassName("result_table__content");
        let result_table__header_lower_class = new BemClassName("result_table__header_lower");

        if (this.props.report.groups) {

            result_table__content_class.appendStatus(`groups_${this.props.report.groups.length}`);
            result_table__header_lower_class.appendStatus(`groups_${this.props.report.groups.length}`);

            if (this.state.sidebar_is_open) {

                result_table__content_class.appendStatus(`groups_open_${this.props.report.groups.length}`);
                result_table__header_lower_class.appendStatus(`groups_open_${this.props.report.groups.length}`);

            }

        }

        result_table__content_class.appendStatusIf(this.props.mode === ResultTable.MODE.NOO, "student");

        let result_table__header_class = new BemClassName("result_table__header");

        result_table__header_class.appendStatusIf(this.props.mode === ResultTable.MODE.NOO, "student");

        let current_user_id = auth_actions.getUserInfo('id');

        const report = this.getReport();

        return (
            <div className="result_table">
                <div className={result_table__header_class} style={{background: this.props.course_color}}>
                    {this.props.mode === ResultTable.MODE.LAB
                    && <div className="result_table__header_upper">
                        <div className="result_table__header_upper_title">Таблица результатов</div>
                    </div>}

                    <div className={result_table__header_lower_class}>
                        <ResultTableHeaderSidebar report={this.props.report}
                                                  groups={this.state.groups}
                                                  mode={this.props.mode}
                                                  toggleSidebar={() => this.setState({sidebar_is_open: false})}
                                                  sidebar_open={this.state.sidebar_is_open}
                        />
                        <ResultTableHeaderTable report={this.props.report}
                                                groups={this.state.groups}
                                                mode={this.props.mode}
                                                sidebar_open={this.state.sidebar_is_open}
                        />
                    </div>
                </div>
                <div className={result_table__content_class}>

                    <ResultTableSidebar report={report}
                                        groups={this.state.groups}
                                        toggleSidebar={() => this.setState({sidebar_is_open: true})}
                                        current_user_id={current_user_id}
                                        sidebar_open={this.state.sidebar_is_open}
                    />

                    <div className="result_table__table">
                        <table>
                            <tbody>
                            {report.users.map((user) => {


                                if (user.hole) {

                                    return <ResultTableCell
                                        mode={ResultTable.MODE.EMPTY}
                                        key={user.hole}
                                    />;

                                }

                                return <tr key={user.rating}>
                                    {this.props.report.tasks.map((task) => {

                                        let user_task = null;

                                        for (let current_task of user.tasks) {

                                            if (current_task.number === task.position) {

                                                user_task = current_task;

                                                break;

                                            }

                                        }

                                        if (!user_task) {

                                            user_task = {score: '', id: task.id, number: task.position};

                                        }

                                        return <ResultTableCell
                                            task={task}
                                            course_id={this.props.course_id}
                                            module_id={this.props.module_id}
                                            user_id={user.name.id}
                                            user_task={user_task}
                                            groups={this.state.groups}
                                            current_user_id={current_user_id}
                                            mode={this.props.mode}
                                            key={task.position}
                                        />;

                                    })}

                                </tr>;

                            })}

                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        );

    }

}
