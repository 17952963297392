import React from "react";
import {connect} from "react-redux";
import bnc from "bnc";
import {Subscription} from "rxjs";
import {windowScroll$} from "@sirius/ui-lib/src/subjects/WindowScroll";
import ElementNavItem from "../ElementNavItem";
import "./index.less";

const block = new bnc('module-group-nav');

type Range = [number, number];

type Props = {
    module_content?: any;
    module_progress?: any;
    selected_element_num?: number;
    setSelectedElementNum?(num: number): void;
    scrollToElementByNum?(num: number): void;
};

class ModuleGroupNav extends React.Component<Props> {

    componentDidMount(): void {

        this.subscriptions = [
            windowScroll$.subscribe(this.handleScroll),
        ];
        this.handleScroll();

    }

    componentWillUnmount(): void {

        this.subscriptions.forEach((s) => s.unsubscribe());

    }

    public subscriptions: Subscription[];

    timeout:ReturnType<typeof setTimeout> = null;


    handleScroll = (): void => {

        this.timeout && clearTimeout(this.timeout);

        this.timeout = setTimeout(() => {
            this.updateActiveElementState();
        }, 100);

    };

    updateActiveElementState = (): void => {

        const {scrollY, innerHeight} = window;
        const {selected_element_num} = this.props;
        const [from, to] = this.getGroupRange(selected_element_num);
        let active = null;
        for (let num = from; num <= to; num++) {

            let wrapper = document.querySelector(`.module_page__wrapper_content > [data-el_num="${ num }"]`);

            if (wrapper === null) {

                return;

            }
            if ((wrapper as HTMLElement).offsetTop < scrollY + (innerHeight / 2)) {

                active = num;

            }

        }
        if (selected_element_num !== active) {

            this.setActive(active);

        }
    };

    setActive = (num: number): void => {

        this.props.setSelectedElementNum(num);

    };

    scrollToItem = (num: number): void => {

        this.props.scrollToElementByNum(num);

    };

    getGroupRange(id: number): Range {

        const {module_content: {groups}} = this.props;
        for (let i in groups) {

            let from = groups[i].from - 1;
            let to = groups[i].to - 1;
            if (from <= id && to >= id) {

                return [from, to];

            }

        }
        return [id, id];

    }

    renderItem = (index: number, { module_content, module_progress, selected_element_num }: Props): JSX.Element => <ElementNavItem
            key={index}
            element_index={index}
            forceShowNumbers
            module_content={module_content}
            module_progress={module_progress}
            active={index === selected_element_num}
            onClick={() => this.scrollToItem(index)}
    />
    ;

    renderItems = ({selected_element_num}: Props): JSX.Element[] => {

        const [from, to] = this.getGroupRange(selected_element_num);
        let elements = [];
        for (let index = from; index <= to; index++) {

            elements.push(
                this.renderItem(index, this.props),
            );

        }
        return elements;

    };

    render = (): JSX.Element => <div className={block}>
            { this.renderItems(this.props) }
        </div>
    ;

}


const mapStateToProps = ({nooReducer: {contents_data}, passingReducer: {selected_element_num}}: any): any => ({
    contents_data,
    selected_element_num,
});

const mapDispatchToProps = (): {} => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ModuleGroupNav);
