import React from "react";
import Helpers from "Cheops/Helpers";

export default class AutogradeStartPage extends React.Component {

    render() {

        let isParentMod = Helpers.isParentMode();

        return (<div>
            <h1>Пройдите тестирование, чтобы узнать свой уровень, или даже засчитать часть курса автоматически</h1>
            <img src={require("CheopsRoot/img/autograde_start_img1.svg")} className="course_autograde_page__img" />
            <h2>Тестирование позволит</h2>
            <p>Увидеть &quot;карту&quot; своих знаний.</p>
            <p>Перейти к изучению материала, который соответствует вашему текущему уровню знаний.</p>
            <p>Вы можете пройти тестирование позже — через меню курса.</p>
            <div className="course_autograde_page__bottom_button_wrapper">
                <button disabled={isParentMod} className="course_autograde_page__bottom_button"
                        onClick={this.props.startAutograde}
                >
                    Пройти тест
                </button>
            </div>
        </div>);

    }

}
