import React, {FC}                    from "react";
import {SwipeEventData, useSwipeable} from "react-swipeable";

interface Props {
    onSwipedUp:     (eventData: SwipeEventData) => void;
    onSwipedDown:   (eventData: SwipeEventData) => void;
}

export const Swipeable:FC<Props> = (
    {
        children,
        onSwipedUp,
        onSwipedDown,
    }
) => {
    const handlers = useSwipeable({
        onSwipedUp:     onSwipedUp,
        onSwipedDown:   onSwipedDown,
    });

    return (
        <div {...handlers}>
            {children}
        </div>
    )
};
