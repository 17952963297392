import React                                 from "react";
import {connect}                             from "react-redux";
import {bindActionCreators}                  from "redux";
import Marked                                from "@sirius/ui-shared/src/components/DisplayEngine/Marked";
import {HBox}                                from "@sirius/ui-lib/src/blocks/Layout";
import * as noo_actions                      from "Cheops/actions/noopolis";
import {ProfCheopsGreeting}                  from "Cheops/components/ProfCheopsGreeting";
import NotificationsList                     from "Cheops/components/Notifications/NotificationsList";
import LandingsCourseCard                    from "Cheops/components/Landings/LandingsCourseCard";
import {Footer}                              from "Cheops/components/Footer";
import LandingsHeader                        from "Cheops/components/Landings/LandingsHeader";
import {PlatformLandingTitle}                from "Cheops/components/Landings/PlatformLandingTitle";
import {DEPLOY_NAME}                         from "Cheops/constants";
import BemClassName                          from "Cheops/BemClassName";
import {cluster}                             from "Cheops/../clusters/current";
import {LandingBlockChains}                  from "Cheops/components/Landings/ChainsCourse";
import {InformationLinkCard}                 from "Cheops/containers/InformationLinkCard/InformationLinkCard";


class PlatformLandingAuthorizedPage extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            show_notifications: false,
        };

    }


    setShowNotifications(state) {

        this.setState({
            show_notifications: state,
        });

    }

    courseCardClick = (course_id) => {

        location.href = `/#/course/${course_id}`;

    };


    render() {
        const { soon: fullCourseListSoon} = this.props.full_course_list;
        const pl_fap                      = `platform_landing_for_authorized_page platform_landing_for_authorized_page-cluster-${cluster.key}`
        const greetingMessage             = this.props.main_content.page.greeting;

        return (
            <div className={pl_fap}>
                <LandingsHeader
                    land_header_with_bg={true}
                    showNotifications={() => this.setShowNotifications(true)}
                />
                {
                    this.state.show_notifications &&
                    <NotificationsList hideNotifications={() => this.setShowNotifications(false)} />
                }
                <div className="platform_landing_for_authorized_page__content">
                    <div className="platform_landing_page__courses">
                        <PlatformLandingTitle>{cluster.platform_landing_for_authorized_page__header}</PlatformLandingTitle>
                    </div>

                    {greetingMessage
                    && <div
                        className={new BemClassName("platform_landing_page__greeting", `deploy-${CONFIG.DeployName}`)}
                    >
                        <ProfCheopsGreeting>
                            <Marked inline>{greetingMessage}</Marked>
                        </ProfCheopsGreeting>
                    </div>}

                    {(this.props.courses.opened && this.props.courses.opened.length > 0)
                    && <div className="platform_landing_page__courses">
                        <div className="platform_landing_page__courses_title">Сейчас</div>
                        <HBox className="platform_landing_page__courses_list_wrapper">
                            {
                                this.props.courses.opened.map(
                                    (course) =>
                                        <LandingsCourseCard key={course.id}
                                                            course={course}
                                                            course_status={LandingsCourseCard.COURSE_STATUS.AVAILABLE}
                                                            is_authorized={true}
                                                            courseCardClick={() => this.courseCardClick(course.id)}
                                        />
                                )
                            }
                        </HBox>
                        <br clear="all"/>
                    </div>}

                    {
                        CONFIG.DeployName === DEPLOY_NAME.CPM || CONFIG.DeployName === DEPLOY_NAME.SIRIUS &&
                        <div className="platform_landing_page__courses">
                            <div className="platform_landing_page__courses_title">
                                Обратите внимание
                            </div>
                            <InformationLinkCard variant = "vos"        />
                            <InformationLinkCard variant = "schedule"   />
                            <InformationLinkCard variant = "ai"         />
                        </div>
                    }

                    {
                        (fullCourseListSoon && fullCourseListSoon.length > 0) && cluster.type === "courses"
                        && <div className="platform_landing_page__courses">
                                <LandingBlockChains
                                    soonList={fullCourseListSoon}
                                />
                            </div>
                    }

                </div>

                {
                    cluster.type === "courses" &&
                    <Footer />
                }

            </div>
        );

    }

}

const mapStateToProps = (state) => ({
    full_course_list: state.nooReducer.full_course_list,
    lecturer_list: state.nooReducer.lecturer_list,
    main_content: state.nooReducer.main_content,
});

const mapDispatchToProps = (dispatch) => ({
    getFullCourseList: bindActionCreators(noo_actions.getFullCourseList, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlatformLandingAuthorizedPage);
