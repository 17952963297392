import React from "react";
import            "./ElementInfoTogglers.less";

const ElementInfoTogglers = (props) => {
    const {
        course_id = null,
        module_id = null,
        showSolutionToShow = null,
        element_type = null,
        element_content = {},
        element_progress = {},
        showHintPopup = null,
        hintsNumber = 0,
        showAnalogicTask
    } = props;

    const solution = element_progress.solution || {};
    const verdict  = element_progress.verdict;

    const showHint = ((element_progress.taskInfo && element_progress.taskInfo.hasHints) || hintsNumber > 0);
    const hasAnalogicTask = element_content.hasAnalogicTask && element_progress.analogicTaskOpened;

    return (
        <div className="element_info_togglers">
            {
                element_content.qaId
                && <a href={`/#/qa/${course_id}_${module_id}_${element_content.qaId}`}>
                    Вопросы-ответы
                    {element_type === 'task' && " по задаче"}
                    {element_type === 'video' && " по видеолекции"}
                    {element_type === 'text' && " по тексту"}
                </a>
            }
            {
                hasAnalogicTask &&
                <div onClick={showAnalogicTask}>
                    Решение аналогичной задачи
                </div>
            }
            {
                showHint
                && <div onClick={showHintPopup}>
                    Подсказки
                    {
                        hintsNumber > 0
                        && <>
                            <div className="element_info_togglers__hints_eye" />
                            {hintsNumber}
                        </>
                    }
                </div>
            }
            {
                solution.solutionToShow
                && verdict !== 'ok'
                && <div onClick={showSolutionToShow}>
                    Верный ответ
                </div>
            }
        </div>
    );
};

export default ElementInfoTogglers;
