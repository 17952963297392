import React, {FC}                           from 'react';
import {Link}                                from 'react-router-dom';
import {BoxAlignment, HBox, PopoverMenuItem} from '@sirius/ui-lib/src/blocks/Layout';
import {moduleBlock, TeacherModuleCardProps} from 'Cheops/containers/TeacherRoomPage/blocks';
import {TeacherPopoverMenu}                  from 'Cheops/containers/TeacherRoomPage/TeacherPopoverMenu';
import {TeacherCardIcon}                     from 'Cheops/containers/TeacherRoomPage/TeacherCardIcon';
import                                            './style.less';



export const TeacherModuleCard: FC<TeacherModuleCardProps> = (
    {
        title,
        courseId,
        id: moduleId,
        hasSelectedClassAccess = false,
        popoverMenuItems = [],
    }
) => (
    <HBox className = {moduleBlock}
          align     = {BoxAlignment.between}
    >
        <Link to        = {`${courseId}/module/${moduleId}/progress`}
              className = {moduleBlock.el('link')}
        >
            <TeacherCardIcon block     = {moduleBlock}
                             hasAccess = {hasSelectedClassAccess}
            />
            <p className={moduleBlock.el('title')}>
                {title}
            </p>
        </Link>
        { popoverMenuItems.length > 0 &&
            <div className={moduleBlock.el('menu')}>
                <TeacherPopoverMenu intoPortal>
                    {
                        popoverMenuItems.map(
                            (menuItem, i) => (
                                <PopoverMenuItem {...menuItem}
                                                 key={i}
                                                 className={moduleBlock.el('menu-item')}
                                />
                            )
                        )
                    }
                </TeacherPopoverMenu>
            </div>
        }
    </HBox>
);
