import {StyleChips} from "Cheops/containers/ModulePage__Types";


export const getStyleStructByCourseContents = (
    groups: NooBack.Content.ModuleGroups,
    _elNum: number,
    current_module_learn: NooBack.ModuleProgress
): StyleChips[] => {
    const elNum = _elNum + 1;
    const result: StyleChips[] = groups
                    .find(
                        ({from, to, styleSwitch}) => (
                            styleSwitch &&
                            elNum >= from &&
                            elNum <= to
                        )
                    )
                    ?.styleSwitch
                    .map(
                        style => ({style, selected: style === current_module_learn.summary?.currentStyle})
                    )
    ;
    return result;
}
