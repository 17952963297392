import React, {FC, useEffect}                  from "react";
import {useParams}                             from "react-router-dom";
import {IUseVideoResolveArgs, useVideoResolve} from "Smt/hooks/useVideoResolve";
import {VideoPlayer, IVideoPlayerConfig}       from "@sirius/ui-shared/src/components/Video/Player";


const instance: IUseVideoResolveArgs["instance"] = CONFIG?.Instance ? `${CONFIG.Instance as IUseVideoResolveArgs["instance"]}` : null;

interface IVideoPlayerWithResolve extends IVideoPlayerConfig {
    url: string;
}
const VideoPlayerWithResolve: FC<IVideoPlayerWithResolve> = (
    {
        url,
        ...props
    }
) => {
    const {contestId} = useParams<{ contestId: string }>();
    const isSmt = !!contestId;

    const [videoContainer, getVideoContainer] = useVideoResolve({instance, isSmt});

    useEffect(() => {
        getVideoContainer(url);
    }, [url]);

    return (
        <>
            {
                videoContainer?.type
                && <VideoPlayer
                        {...props}
                        videoContainer={videoContainer as Common.Video.VideoResolveContainer}
                        disableContextMenu
                        disableDownload
                    />
            }
        </>

    )

}

export default VideoPlayerWithResolve;
