import React                                from 'react';
import {VBox}                               from '@sirius/ui-lib/src/blocks/Layout/index';
import {Textarea}                           from '@sirius/ui-lib/src/blocks/Controls/index';
import {classPageBlock, EditNameModalProps} from 'Cheops/containers/TeacherRoomPage/blocks';
import {TeacherModal}                       from 'Cheops/containers/TeacherRoomPage/TeacherModal';
import                                           './ClassPage/style.less';

export const EditStudentNameModal = ({onClose, onAction, disabled, onChange, id, initValue}: EditNameModalProps) =>
    <TeacherModal onClose          = {onClose}
                  onAction         = {onAction}
                  disabled         = {disabled}
                  headerTitle      = {'Переименовать ученика'}
                  actionButtonText = {'Переименовать'}
    >
        <VBox style={{width: '100%'}}>
            <p className={classPageBlock.el('modal-text')}>
                {`ID: ${id}`}
            </p>
            <Textarea onChange    = {onChange}
                      size        = {'s'}
                      placeholder = {'Введите новое имя'}
                      className   = {classPageBlock.el('modal-textarea')}
                      value       = {initValue}
            />
        </VBox>
    </TeacherModal>
;
