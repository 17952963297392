import React from "react";
import {bindActionCreators} from "redux";
import * as noo_actions from "Cheops/actions/noopolis";
import {connect} from "react-redux";
import ReactDOM from "react-dom";
import BemClassName from "Cheops/BemClassName";
import ProfCheopsMessage from "../ProfCheopsMessage";
import {focusMapStatuses} from "../../actions";

class NotificationsIcon extends React.Component {

    static BACKGROUND = {
        TRANSPARENT: "transparent",
    };

    static defaultProps = {
        do_not_auto_update: false,
        show_last_pinned: true,
    };

    constructor(props) {

        super(props);

        this.expand_timer = 0;
        this.expand_timeout = 0;
        this.update_interval = 0;

    }


    async UNSAFE_componentWillMount() {

        if (this.props.notifications.length === 0) {

            await this.props.getAllNotification();

        }

        if (!this.props.do_not_auto_update) {

            if (this.update_interval) {

                clearInterval(this.update_interval);

            }

            this.update_interval = setInterval(() => this.getNewNotifications(), 1000 * 60);

        }

    }


    componentDidMount() {

        window.addEventListener("resize", this.placeCheopsWindow);

        this.placeCheopsWindow();

    }

    UNSAFE_componentWillReceiveProps(props) {


        if (props.expanded !== this.props.expanded) {

            if (this.expand_timer !== 0) {

                clearInterval(this.expand_timer);

            }

            if (this.expand_timeout !== 0) {

                clearTimeout(this.expand_timeout);

            }

            this.expand_timer = setInterval(() => this.placeCheopsWindow(), 30);
            this.expand_timeout = setTimeout(() => clearInterval(this.expand_timer), 400);

        }

    }

    componentDidUpdate() {

        this.placeCheopsWindow();

    }

    componentWillUnmount() {

        clearInterval(this.update_interval);
        clearInterval(this.expand_timer);

        window.removeEventListener("resize", this.placeCheopsWindow);

    }

    placeCheopsWindow = () => {

        let this_node = ReactDOM.findDOMNode(this);
        let cheops = this_node.querySelector(".prof_cheops_message");

        if (cheops) {

            if (window.matchMedia("(max-width: 760px)").matches) {

                cheops.style.left = "";
                return;

            }

            cheops.style.left = `${this_node.getBoundingClientRect().left - cheops.offsetWidth - 16}px`;

        }

    };

    async getNewNotifications() {

        let new_noitifications = {};

        if (this.props.notifications.length === 0) {

            new_noitifications = await this.props.getAllNotification();

            if (new_noitifications.value) {

                this.props.setHasNewNotifications(true);

            }

        } else {

            let last_notification = this.props.notifications[this.props.notifications.length - 1];

            new_noitifications = await this.props.getNotificationByTM(last_notification.publishedTime);

            if (new_noitifications.value) {

                let new_notifications_ids = Object.keys(new_noitifications.value);

                for (let i in new_notifications_ids) {

                    new_notifications_ids[i] = parseInt(new_notifications_ids[i]);

                }

                for (let i in this.props.notifications) {

                    if (new_notifications_ids.indexOf(this.props.notifications[i].notificationId) !== -1) {

                        this.props.setHasNewNotifications(true);
                        break;

                    }

                }

            }

        }

    }

    render() {

        let unread_count = 0;

        if (!this.props.notifications) {

            return <div className="notifications_icon__wrapper" />;

        }

        for (let notification of this.props.notifications) {

            if (notification.status !== 'read') {

                unread_count++;

            }

        }

        let notifications_icon_background = this.props.notifications_icon_background || 'white';

        let block_class = new BemClassName('notifications_icon');
        let block_numbers_class = new BemClassName('notifications_icon__numbers');

        block_class.appendStatusIf(!!this.props.notifications_icon_background, notifications_icon_background);
        block_numbers_class.appendStatusIf(this.props.has_new_notifications, "has_new");

        return (
            <div className="notifications_icon__wrapper">
                <div className={block_class} onClick={this.props.showNotifications}>
                    {unread_count > 0
                    && <div className={block_numbers_class}>
                        {unread_count}
                    </div>}
                </div>
                {this.props.show_last_pinned
                && <ProfCheopsMessage
                    markAsReadNotification={this.props.markAsReadNotification}
                    notifications={this.props.notifications}
                    unpinNotification={(id) => this.props.unpinNotification(id)}
                />}
            </div>
        );

    }

}


const mapStateToProps = (state) => ({
    expanded: state.mapIsFocused === focusMapStatuses.NOT_FOCUSED,
    notifications: state.nooReducer.notifications,
    has_new_notifications: state.nooReducer.has_new_notifications,
});

const mapDispatchToProps = (dispatch) => ({
    setHasNewNotifications: (new_state) => dispatch(noo_actions.setHasNewNotifications(new_state)),
    getAllNotification: bindActionCreators(noo_actions.getAllNotification, dispatch),
    getNotificationByTM: bindActionCreators(noo_actions.getNotificationByTM, dispatch),
    unpinNotification: bindActionCreators(noo_actions.unpinNotification, dispatch),
    markAsReadNotification: bindActionCreators(noo_actions.markAsReadNotification, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsIcon);
