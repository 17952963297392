import React, {FC, useMemo, useState}   from 'react';
import {HBox, MGap, VBox}               from '@sirius/ui-lib/src/blocks/Layout';
import {IconButtonColored}              from '@sirius/ui-lib/src/blocks/Controls/Button';
import {Marked}                         from '@sirius/ui-shared/src/components/DisplayEngine';
import {useWindowIsMobile}              from '@sirius/ui-lib/src/subjects/WindowIsMobile';
import {TOCChapterProps}                from './@types';
import {chapterEl}                      from './block';
import {TOCParagraph}                   from './TOCParagraph';
import {TOCLesson}                      from './TOCLesson';

const MAX_LENGTH = 300;

const DescriptionExpand:FC<Pick<NooBack.Content.TocNode,'description'>> = ({description}) => {
    const [opened, setOpened] = useState(false);

    const isMobile = useWindowIsMobile();

    const toggleOpened = () => {
        setOpened((prev) => !prev);
    };

    const visiblePart = useMemo(() =>
        description
            .split(' ')
            .reduce(
                (acc, word, i, arr) => {
                    const nextAcc = [acc, word].join(' ');
                    if (nextAcc.length < MAX_LENGTH) {
                        return nextAcc;
                    } else {
                        arr.splice(i);
                        return acc;
                    }
                }, ''
        )
    ,[]);

    return(
        <VBox className={chapterEl.mod('description-expand') + chapterEl.mod('description')}>
            <Marked>{opened ? description : visiblePart}</Marked>
            <MGap/>
            <MGap/>
            <IconButtonColored icon    = {opened ? 'expand_less' : 'more_horiz'}
                               palette = {'colors-action'}
                               onClick = {toggleOpened}
                               size    = {isMobile ? 'l' : 'xxl'}
            />
        </VBox>
    );
};

const getDescription = (description: string) =>
    description?.length > MAX_LENGTH
        ? <DescriptionExpand description={description}/>
        : <HBox className={chapterEl.mod('description')}>
            <Marked>{description}</Marked>
        </HBox>
;

export const TOCChapter: FC<TOCChapterProps> = ({
    node,
    modules,
    learn,
    courseId
}) => {
    const paragraphs = 'paragraphs' in node ? node.paragraphs : null;
    const lessons    = 'lessons'    in node ? node.lessons    : null;

    const {name, description} = node;

    return  <VBox className={chapterEl}>
                <HBox className={chapterEl.mod('name')}>{name}</HBox>
                { getDescription(description) }
                <VBox className={chapterEl.mod(paragraphs ? 'paragraphs' : lessons ? 'lessons' : 'unknown')}>
                    {
                        paragraphs &&
                        paragraphs.map(
                            (para, i) =>
                                <TOCParagraph
                                    key      = {i}
                                    node     = {para}
                                    modules  = {modules}
                                    learn    = {learn}
                                    courseId = {courseId}
                                />
                        )
                    }
                    {
                        lessons &&
                        lessons.map(
                            (lesson, i) =>
                                <TOCLesson
                                    key         = {i}
                                    node        = {lesson}
                                    module      = {modules[lesson.moduleId]}
                                    learnModule = {learn.modules[lesson.moduleId]}
                                    courseId    = {courseId}
                                />
                        )
                    }
                </VBox>
            </VBox>
    ;
}
