import React, {FC, useEffect, useLayoutEffect, useState} from "react";
import bnc                                               from "bnc";
import {CircularProgress}                                from "@sirius/ui-lib/src/blocks/CircularProgress";
import {Checkbox}                                        from "@sirius/ui-lib/src/blocks/Controls";
import {HBox, VBox}                                      from "@sirius/ui-lib/src/blocks/Layout";
import {passSuccess}                                     from "Cheops/containers/TeacherRoomPage/helpers";
import {TeacherModal}                                    from "Cheops/containers/TeacherRoomPage/TeacherModal";
import {
    AccessesType, ClassesDictType,
    GrantClassesAccessModalProps
}                                                        from "./@types";
import './style.less';

const block = new bnc('teacher-grant-access');

const findChangeAccessClasses = (accesses: AccessesType, filterState: boolean) =>
    Object.entries(accesses).filter(([id, value]) => value === filterState).map(([id]) => id)
;

export const GrantClassesAccessModal: FC<GrantClassesAccessModalProps> = (
    {
        courseId,
        info: {id, title},
        classesList,
        onClose,
        onAddAccess,
        onCancelAccess,
        onGetAccesses,
    }
) => {
    const [loading, setLoading]             = useState<boolean>(false);
    const [changed, setChanged]             = useState<boolean>(false);
    const [initAccesses, setInitAccesses]   = useState<AccessesType>({});
    const [accesses, setAccesses]           = useState<AccessesType>({});
    const [classesDict, setClassesDict]     = useState<ClassesDictType>({});
    const [classesAccess, setClassesAccess] = useState<NooBack.Teacher.Methods.ClassesWithAccessInfo>([]);

    useLayoutEffect(
        () => {
            setLoading(true);

            onGetAccesses({id, courseId})
                .then(passSuccess)
                .then((classesAccess) => {
                    const initClassesAccess = Object.fromEntries( classesAccess?.classes.map(({id, hasAccess}) => [id, hasAccess]) );
                    setClassesAccess(classesAccess?.classes)
                    setInitAccesses(initClassesAccess);
                })
                .finally(() => setLoading(false))
            ;
        },
        [id]
    );

    useEffect(
        () => {
            const isChanged = Object.entries(accesses).length > 0;
            setChanged(isChanged);
        },
        [accesses]
    );

    useEffect(
        () => {
            const classesDict = Object.fromEntries(classesList?.map(({id, title}) => [id, title])) ?? {};
            setClassesDict(classesDict);
        },
        [classesList]
    );

    const setChange = (id: string , nextAccesses: AccessesType) => {
        nextAccesses[id] === initAccesses[id] && delete nextAccesses[id];
        setAccesses(nextAccesses);
    };

    const onClickHandler = (id: string) => (e: React.MouseEvent) => {
        e?.stopPropagation();
        const next = id in accesses
            ? !accesses[id]
            : !initAccesses[id]
        ;
        setChange(id, {...accesses, [id]: next});
    };

    const confirmAccessHandler = () => {
        const confirmAccesses = [];

        const addAccess = findChangeAccessClasses(accesses, true);
        addAccess.length > 0 &&
            confirmAccesses.push( onAddAccess({courseId, id, classes: addAccess}) )
        ;

        const cancelAccess = onCancelAccess ? findChangeAccessClasses(accesses, false) : [];
        cancelAccess.length > 0 &&
            confirmAccesses.push( onCancelAccess({id, classes: cancelAccess}) )
        ;

        Promise.all(confirmAccesses)
            .finally(() => onClose(true))
        ;
    };

    const classesListWOAccess = classesAccess.filter(({hasAccess}) => !hasAccess);
    const classesListWAccess = classesAccess.filter(({hasAccess}) => hasAccess);

    return (
        <TeacherModal onClose          = {onClose}
                      onAction         = {confirmAccessHandler}
                      disabled         = {!changed}
                      headerTitle      = {title}
                      actionButtonText = {'Открыть доступ'}
                      className        = {block.el('modal')}
        >
            {loading
                ? <CircularProgress marginCenter size={'small'}/>
                : <VBox className={block.el('form')}>
                    {   classesListWOAccess.length > 0 &&
                        <HBox className={block.el('section-row')} >
                            {'Выберите классы, которым будет открыт доступ:'}
                        </HBox>
                    }
                    {   classesListWOAccess.map(
                            ({id}) => (
                                <HBox className={block.el('checkbox-row')}
                                      onClick={onClickHandler(id)}
                                      key={id}
                                >
                                    <Checkbox checked={accesses[id] ?? initAccesses[id]}>
                                        {classesDict[id]}
                                    </Checkbox>
                                </HBox>
                            )
                        )
                    }

                    {   classesListWAccess.length > 0 &&
                        <HBox className={block.el('section-row')} >
                            {'Доступ уже открыт для классов:'}
                        </HBox>
                    }
                    {   classesListWAccess.map(
                            ({id}) => (
                                <HBox className={block.el('checkbox-row') + block.el('checkbox-row').bod('disabled', !onCancelAccess)}
                                      onClick={onClickHandler(id)}
                                      key={id}
                                >
                                    <Checkbox checked={accesses[id] ?? !!initAccesses[id]}
                                              disabled={!onCancelAccess}
                                    >
                                        {classesDict[id]}
                                    </Checkbox>
                                </HBox>
                            )
                        )
                    }
                </VBox>
            }
        </TeacherModal>
    )
};
