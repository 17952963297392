import React, {useState}    from 'react';
import {BoxAlignment, HBox} from '@sirius/ui-lib/src/blocks/Layout';
import {cluster}            from "CheopsRoot/clusters/current";
import {headerBlock}        from 'Cheops/containers/TeacherRoomPage/blocks';
import {RightWidgets}       from 'Cheops/components/Landings/LandingsHeader';
import NotificationsList    from 'Cheops/components/Notifications/NotificationsList';
import {redirectTo}         from '../helpers';
import                           './style.less';


export const TextBookHeader = () => {
    const [menuIsOpen, setMenuIsOpen]               = useState<boolean>( false );
    const [showNotifications, setShowNotifications] = useState<boolean>(false);

    const openMenu  = (e: React.MouseEvent) => {
        e.stopPropagation();
        setMenuIsOpen(true);
    }

    const closeMenu = () => {
        setMenuIsOpen(false);
    }

    return (
        <HBox className = {headerBlock + headerBlock.mod('cluster', cluster.key)}
              align     = {BoxAlignment.between}
        >
            <div className={headerBlock.el('logo')}
                 onClick={() => redirectTo()}
            />
            <div>
                <RightWidgets showNotifications = {() => setShowNotifications(true)}
                              user_is_anon      = {false}
                              menu_is_open      = {menuIsOpen}
                              openMenu          = {openMenu}
                              closeMenu         = {closeMenu}
                />
            </div>
            {
                showNotifications &&
                <NotificationsList hideNotifications = {() => setShowNotifications(false)} />
            }
        </HBox>
    )

};
