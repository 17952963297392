import React, { FC }                         from "react";
import {
    DropdownSelect as LibDropdownSelect,
    DropdownSelectProps }                    from "@sirius/ui-lib/src/blocks/DropdownSelect";
import { Option }                            from "@sirius/ui-lib/src/blocks/DropdownSelect/Option";
import {dropdownBlock}                       from "../blocks";
import                                            "./index.less";


const DropdownSelect:FC<DropdownSelectProps> = ({className = '', children, ...props}) =>
    <LibDropdownSelect
        className = { dropdownBlock + className }
        {...props}
    >
        {children}
    </LibDropdownSelect>
;

export  {
    DropdownSelect,
    Option
}
