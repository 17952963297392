import React, {FC, useLayoutEffect, useState} from "react";
import bnc                                 from "bnc";
import {Link, Redirect, useParams}         from "react-router-dom";
import {useWindowIsMobile}                 from "@sirius/ui-lib/src/subjects/WindowIsMobile";
import {CircularProgress}                  from "@sirius/ui-lib/src/blocks/CircularProgress";
import {HBox, LGap, PopoverMenuItem, VBox} from "@sirius/ui-lib/src/blocks/Layout";
import {Icon}                              from "@sirius/ui-lib/src/blocks/Controls";
import MarkedWithVideo                     from "Smt/MarkedWithVideo";
import {TextBookHeader}                    from "Cheops/containers/TeacherRoomPage/TextBookHeader";
import {passResponse, passSuccess}         from "Cheops/containers/TeacherRoomPage/helpers";
import {
    getTeacherCourseInfo,
    getTeacherModuleMaterials,
    addTeacherModuleAccess,
    cancelTeacherModuleAccess,
    getTeacherModuleAccess,
}                                          from "Cheops/actions/noopolis-ts";
import {GrantClassesAccessModal}           from "Cheops/containers/TeacherRoomPage/GrantClassesAccessModal";
import {TeacherPopoverMenu}                from "Cheops/containers/TeacherRoomPage/TeacherPopoverMenu";
import {ProgressIcon}                      from "Cheops/containers/TeacherRoomPage/icons";
import {SubjectInfoType}                   from "Cheops/containers/TeacherRoomPage/GrantClassesAccessModal/@types";
import                                          './style.less';


const block = new bnc('teacher-materials-page');


export const TeacherMaterialsPage:FC = () => {

    const {course_id, module_id} = useParams<{course_id: string, module_id: string}>();
    const isMobile               = useWindowIsMobile();

    const [loading, setLoading]         = useState<boolean>(false);
    const [redirect, setRedirect]       = useState<string>(null);
    const [courseInfo, setCourseInfo]   = useState<Partial<NooBack.Teacher.Methods.CourseItem>>({});
    const [moduleInfo, setModuleInfo]   = useState<Partial<NooBack.Teacher.Methods.CourseModuleInfo>>({});
    const [classes, setClasses]         = useState<NooBack.Teacher.Methods.ClassesList>([]);
    const [teacherMaterials, setTeacherMaterials] = useState<NooBack.Teacher.Methods.TeacherMaterials>(null);
    const [showModuleAccessModal, setShowModuleAccessModal] = useState<SubjectInfoType | false>(false);

    const stopLoading = () => setLoading(false);

    useLayoutEffect(
        () => {
            setLoading(true);
            Promise.all([
                getTeacherModuleMaterials(module_id).then(passSuccess),
                getTeacherCourseInfo(course_id).then(passResponse),
            ])
            .then(
                ([{teacherMaterials = ''},  course]) => {
                    const module = course.modules.find(({id}) => `${id}` === module_id);
                    setModuleInfo({...module});
                    setCourseInfo({...course});
                    setClasses(course.classes);
                    setTeacherMaterials(teacherMaterials);
                }
            )
            .finally(stopLoading);
        }, 
        []
    );

    const checkoutModuleHandler     = () => setRedirect(`/course/${course_id}/${module_id}`);
    const closeModuleAccessHandler  = () => setShowModuleAccessModal(false);
    const openModuleAccessHandler   = () => setShowModuleAccessModal({
        id: module_id,
        title: `Доступ к уроку «${moduleInfo?.title}»`
    });

    return (
        redirect
            ? <Redirect push to={redirect}/>
            :   loading
                ?   <CircularProgress centerOfWindow={true}/>
                :   <div className={block}>
                        <TextBookHeader/>
                        <VBox className={block.el('content')}>
                            { !isMobile &&
                                <HBox className={block.el('breadcrumbs')}>
                                    <Link className = {block.el('breadcrumbs-back')}
                                          to        = {'/teacher-room'}
                                    >
                                        <Icon className = {block.el('breadcrumbs-back-icon')}
                                              icon = {'arrow_back'}
                                              size = {'xs'}
                                        />
                                        Учительская
                                    </Link>

                                    <LGap/>
                                    <span className={block.el('breadcrumbs-delim')}>/</span>
                                    <LGap/>

                                    <Link className = {block.el('breadcrumbs-link') + block.el('breadcrumbs-link').mod('nowrap')}
                                          to        = {`/teacher-room/course/${course_id}`}
                                    >
                                        {courseInfo?.title}
                                    </Link>
                                </HBox>
                            }

                            <h1 className={block.el('title')}>
                                {`${moduleInfo?.title ?? '...'}: Методические материалы`}
                            </h1>

                            <HBox className={block.el('section-menu')}>
                                <TeacherPopoverMenu placement  = {'bottom-start'}
                                                    size = {isMobile ? 'xs' : 's'}
                                >
                                    <PopoverMenuItem label      = {'Посмотреть модуль'}
                                                     icon       = {ProgressIcon}
                                                     onAction   = {checkoutModuleHandler}
                                    />
                                    <PopoverMenuItem label      = {'Управление доступом'}
                                                     icon       = {'visibility'}
                                                     onAction   = {openModuleAccessHandler}
                                    />
                                </TeacherPopoverMenu>
                            </HBox>

                            <HBox className={block.el('section')}>
                                <MarkedWithVideo  className={block.el('marked')} >
                                    {teacherMaterials}
                                </MarkedWithVideo>
                            </HBox>
                        </VBox>

                        {   showModuleAccessModal &&
                            <GrantClassesAccessModal
                                info             = {showModuleAccessModal}
                                classesList      = {classes}
                                courseId         = {course_id}
                                onClose          = {closeModuleAccessHandler}
                                onAddAccess      = {addTeacherModuleAccess}
                                onCancelAccess   = {cancelTeacherModuleAccess}
                                onGetAccesses    = {getTeacherModuleAccess}
                            />
                        }
                    </div>

    )
};
