import React                                  from 'react';
import {PopoverMenu, PopoverMenuItem}         from '@sirius/ui-lib/src/blocks/Layout';
import {HasTarget, HasClose}                  from "@sirius/ui-lib/src/blocks/Layout/Popover/@types";
import {ClassPageRowEditType, StudentRowType} from "Cheops/containers/TeacherRoomPage/blocks";


const CourseIcon = ({className}: {className?: string}) =>
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M17.606 1.875C19.2594 1.875 20.6157 3.21838 20.6157 4.88469C20.6157 6.2539 19.6986 7.41644 18.4327 7.77812V8.23022C19.944 7.32602 21.5845 6.7835 23.3412 6.57683L24 6.49933V12.7125H23.4058C22.6695 12.7125 21.9462 12.9966 21.3907 13.4875C20.9257 13.9008 20.577 14.495 20.577 15.1409V18.1376C20.577 20.101 18.2131 22.297 16.2756 22.297H14.6868H14.0926H12.0646H10.0366H9.44241H7.72443C5.79978 22.297 3.42304 20.101 3.42304 18.1376V15.1409C3.42304 14.5079 3.07427 13.9138 2.60926 13.4875C2.05382 12.9966 1.33046 12.7125 0.594187 12.7125H0V6.49933L0.658773 6.57683C2.46717 6.7835 4.15931 7.36477 5.70936 8.30772V7.80396C4.39182 7.48103 3.42304 6.29265 3.42304 4.88469C3.42304 3.21838 4.76642 1.875 6.41981 1.875C7.6986 1.875 8.78364 2.66294 9.22282 3.78673C9.662 2.66294 10.747 1.875 12.0129 1.875C13.2788 1.875 14.3767 2.66294 14.8159 3.78673C15.2551 2.66294 16.3402 1.875 17.606 1.875ZM8.5253 7.02893L8.57696 7.04185C9.76534 7.31311 10.9279 7.93313 12.0775 8.88899C13.2142 7.93313 14.3638 7.32602 15.5393 7.04185C15.2293 6.74475 14.9839 6.38307 14.8159 5.96973C14.3767 7.0806 13.2917 7.88146 12.0129 7.88146C10.747 7.88146 9.64909 7.09351 9.2099 5.96973C9.06781 6.37016 8.82239 6.73184 8.5253 7.02893Z" fill="#2979FF"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M0.594238 7.1582C2.41555 7.36488 4.13353 7.97198 5.70942 9.00535V12.1184C7.89241 12.1184 9.44246 13.7072 9.44246 15.8514V21.69H7.72449C6.09693 21.69 4.01727 19.7266 4.01727 18.1248V15.1281C4.01727 13.578 2.46722 12.1055 0.594238 12.1055V7.1582Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M23.4058 7.1582C21.6362 7.35196 19.9699 7.93323 18.4328 8.91493V12.1184C16.2498 12.1184 14.6997 13.7072 14.6997 15.8514V21.69H16.2885C17.9161 21.69 19.9957 19.7266 19.9957 18.1248V15.1281C19.9957 13.578 21.5458 12.1055 23.4188 12.1055V7.1582H23.4058Z" fill="#E30D15"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M6.41967 2.46924C7.75014 2.46924 8.83517 3.55428 8.83517 4.88474C8.83517 6.2152 7.75014 7.28732 6.41967 7.28732C5.08921 7.28732 4.01709 6.2152 4.01709 4.88474C4.01709 3.55428 5.08921 2.46924 6.41967 2.46924Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M17.6062 2.46924C18.9367 2.46924 20.0088 3.54136 20.0088 4.87182C20.0088 6.20229 18.9367 7.27441 17.6062 7.27441C16.2757 7.27441 15.2036 6.20229 15.2036 4.87182C15.2036 3.54136 16.2757 2.46924 17.6062 2.46924Z" fill="#E30D15"/>
    </svg>
;

export const getRowEditMenu = ({onActionModal, courses, onActionAsStudent}: ClassPageRowEditType) =>
    ({row, close, target}: { row: StudentRowType} & HasClose & HasTarget) =>
        <PopoverMenu
            close  = {close}
            target = {target}
        >
            <PopoverMenuItem
                icon     = 'edit'
                label    = 'Переименовать'
                onAction = {() => onActionModal(row, 'edit')}
            />
            <PopoverMenuItem
                icon     = 'school'
                label    = 'Смотреть как ученик'
                close    = {close}
                disabled = {courses.length === 0}
            >
                {
                    courses.length > 0 &&
                        courses.map(
                            (course) =>
                                <PopoverMenuItem
                                    key      = {course.id}
                                    icon     = {CourseIcon}
                                    label    = {course.title}
                                    onAction = {() => onActionAsStudent(row, course)}
                                    close    = {close}
                                />
                        )
                }
            </PopoverMenuItem>
            <PopoverMenuItem
                icon     = 'delete'
                label    = 'Удалить из класса'
                onAction = {() => onActionModal(row, 'delete')}
            />
        </PopoverMenu>
;

export const rowMapper = (row: NooBack.Teacher.Methods.StudentInfo, idx: number): StudentRowType => ({
    ...row,
    num:  idx + 1,
    name: (row?.name?.alias?.trim().length > 0 &&  row?.name?.alias) || row?.name?.studentName || row?.id
});
