import React                    from "react";
import {connect}                from "react-redux";
import {HBox, LGap, SGap}       from "@sirius/ui-lib/src/blocks/Layout";
import {DEPLOY_NAME}            from "Cheops/constants";
import * as auth_actions        from "Cheops/actions/auth";
import LandingBlockText         from "Cheops/components/Landings/LandingBlockText";
import LandingBlockLecturers    from "Cheops/components/Landings/LandingBlockLecturers";
import LandingBlockColumns      from "Cheops/components/Landings/LandingBlockColumns";
import LandingsHeader           from "../components/Landings/LandingsHeader";
import LandingsCourseCard       from "../components/Landings/LandingsCourseCard";
import {Footer}                 from "../components/Footer";
import {ym}                     from "../Utils/MetrikaWrapper";
import {LandingBlockChains}     from "Cheops/components/Landings/ChainsCourse";
import {InformationLinkCard}    from "Cheops/containers/InformationLinkCard/InformationLinkCard";
import {AdditionalHeaderLink}   from "Cheops/components/Landings/PlatformLandingTitle";
import {cluster as clusterV}    from "CheopsRoot/clusters/current";

const HEADER_HEIGHT = 56;

class PlatformLandingPage extends React.Component {

    static defaultProps = {
        video_url: null,
    };

    // eslint-disable-next-line react/sort-comp
    BUTTON_POSITION = {
        TOP: "top",
        MIDDLE: "middle",
        BOTTOM: "bottom",
    };

    constructor(props) {

        super(props);


        this.courses_movable = false;
        this.start_pos_pointer_in_courses = 0;
        this.courses_wrapper_margin = 0;

        this.state = {
            platform_button_position: this.BUTTON_POSITION.TOP,
            navigation_is_fixed: false,
            small_logo: false,
            nav_active_item: null,
            land_header_with_bg: false,
        };

    }

    componentDidMount() {

        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener("resize", this.updateDimensions);

        this.progressBar();
        this.navigationPositioning();

    }

    componentWillUnmount() {

        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener("resize", this.updateDimensions);

    }

    updateDimensions = () => {

        this.navigationPositioning();

    };

    handleScroll = () => {

        // animation for button

        let scroll_top = window.pageYOffset;
        let header_description = document.querySelector('.platform_landing_page__header_buttons_wrapper');
        let window_height = document.documentElement.clientHeight;

        let button_position = this.BUTTON_POSITION.TOP;

        if (header_description && ((header_description.getBoundingClientRect().top + header_description.clientHeight) < 0)) {

            button_position = this.BUTTON_POSITION.MIDDLE;

        }

        this.progressBar();

        this.navigationPositioning();

        // navigation item highlighting

        let sections = document.getElementsByClassName("course_landing_page__section");
        let header = document.querySelector(".platform_landing_page__header");
        let active_item;

        for (let i = 0; i < sections.length; i++) {

            let section = sections[i];
            let min_top = header.getBoundingClientRect().bottom;
            let top = section.getBoundingClientRect().top + scroll_top;
            let bottom = top + section.offsetHeight;
            let id = section.getAttribute('data-nav');

            if ((scroll_top > top - (window_height / 4)) && scroll_top <= bottom) {

                active_item = parseInt(id);

            } else if (scroll_top < min_top) {

                active_item = null;

            }

        }

        this.setState({
            platform_button_position: button_position,
            nav_active_item: active_item,
        });

    };

    progressBar() {

        let scroll_top = window.pageYOffset;
        let document_height = document.documentElement.clientHeight;
        let target = document.body;
        let progress_bar = document.querySelector('.platform_landing_page__navigation_progressbar');

        target.onscroll = updateProgressBar;
        updateProgressBar();

        function updateProgressBar() {

            if (!progress_bar) {

                return;

            }

            let percents = getScrollPercent(target);
            progress_bar.style.width = `${percents}%`;

        }

        function getScrollPercent(el) {

            return (scroll_top / (el.offsetHeight - document_height)) * 100;

        }

    }

    navigationPositioning = () => {

        if (window.matchMedia("(max-width: 760px)").matches) {

            if (window.scrollY > 0) {
                this.setState({land_header_with_bg: true});
                return;
            } else {
                this.setState({land_header_with_bg: false});
                return;
            }

        }

        let scroll_top = window.scrollY;
        let navigation = document.querySelector('.platform_landing_page__navigation');
        let header_height = document.querySelector('.platform_landing_page__header').clientHeight;
        let navigation_height = navigation?.clientHeight || 0;
        let trigger = header_height - navigation_height;
        let navigation_fixation = false;

        if (scroll_top >= trigger) {

            navigation_fixation = true;

        }

        let small_logo = false;
        let land_header_with_bg = false;

        if (scroll_top >= (trigger - navigation_height)) {

            small_logo = true;

        }

        if ((scroll_top > 0) && (scroll_top <= (trigger - navigation_height))) {

            land_header_with_bg = true;

        }

        this.setState({
            navigation_is_fixed: navigation_fixation,
            small_logo,
            land_header_with_bg,
        });

    };

    // smooth scrolling

    navigationItemClick = (e) => {

        const scrollTop   = window.scrollY;
        const navEl       = document.querySelector(".platform_landing_page__navigation");
        const navElHeight = navEl.offsetHeight;
        const source      = e.currentTarget.dataset.scroll;
        const targetEl    = document.querySelector(`.course_landing_page__${source}`);
        const targetElTop = targetEl.getBoundingClientRect().top + (scrollTop || -HEADER_HEIGHT) - navElHeight;

        window.scrollTo({
            top:      targetElTop,
            behavior: 'smooth'
        });
    }

    // TODO restrict the click event while moving courses list

    courseCardClick = (course_id) => {

        if (this.courses_movable) {

            return;

        }

        location.href = `/#/course/${course_id}`;

    };

    pageBlocksMapper = (block, i) => {
        const {text, lecturers, courseProgram, columns, courses} = block;

        const caption =
            text && text.caption
                ? text.caption
                : lecturers && lecturers.caption
                    ? lecturers.caption
                    : courseProgram && courseProgram.caption
                        ? courseProgram.caption
                        : columns && columns.caption
                            ? columns.caption
                            : courses && courses.caption
                                ? courses.caption
                                : ""
        ;

        return(
            caption
                ? <div className = "platform_landing_page__navigation_item platform_landing_page__navigation_item_about"
                       key       = {i}
                >
                    <a data-scroll = {i}
                       className   = {this.state.nav_active_item === i ? "active" : ""}
                       onClick     = {this.navigationItemClick}
                    >
                        {caption}
                    </a>
                </div>
                : null
        );
    };

    render() {
        const { page }                    = this.props.main_content;
        const { soon: fullCourseListSoon} = this.props.full_course_list;

        const cluster  = CONFIG.DeployName.toLowerCase();
        const plp__ssb = 'platform_landing_page__start_study_button'
        const platform_landing_page__start_study_button = `${plp__ssb} ${plp__ssb}--${this.state.platform_button_position} ${plp__ssb}--cluster-${cluster}`;

        return (<div className="platform_landing_page">

            <div className={"platform_landing_page__header "}>
                <LandingsHeader show_notifications={false} small_logo={this.state.small_logo}
                                land_header_with_bg={this.state.land_header_with_bg}
                />

                {
                    page.blocks && page.blocks.length > 0 &&
                    <div
                        className={`platform_landing_page__navigation platform_landing_page__navigation--${this.state.navigation_is_fixed ? 'fixed' : ''}`}
                    >
                        <div className="platform_landing_page__navigation_wrapper">
                            <div className="platform_landing_page__navigation_progress_wrapper">
                                <div className="platform_landing_page__navigation_progressbar" />
                            </div>

                            <div className="platform_landing_page__navigation_inner_wrapper">
                                { page.blocks.map(this.pageBlocksMapper) }
                            </div>
                        </div>
                    </div>
                }

                <div className="platform_landing_page__header_content">
                    <div className="platform_landing_page__block_wrapper">
                        <div className="platform_landing_page__sirius_title">
                            {
                                CONFIG.DeployName === DEPLOY_NAME.CPM &&
                                "Дистанционные курсы Центра педагогического мастерства"
                            }{
                                CONFIG.DeployName === DEPLOY_NAME.SIRIUS &&
                                <>
                                    Онлайн-курсы Образовательного центра&nbsp;
                                    <a href="https://sochisirius.ru" target="_blank">Сириус</a>
                                </>
                            }{
                                CONFIG.DeployName === DEPLOY_NAME.CORP &&
                                <>
                                    Портал корпоративного обучения образовательного центра&nbsp;
                                    <a href="https://sochisirius.ru" target="_blank">Сириус</a>
                                </>
                            }{
                                CONFIG.DeployName === DEPLOY_NAME.UNIV &&
                                <>
                                    Онлайн-курсы университета&nbsp;«Сириус»
                                </>
                            }
                            {   CONFIG.DeployName === DEPLOY_NAME.TEXTBOOK &&
                                "Школы Сириуса"
                            }
                        </div>
                        <div className="platform_landing_page__platform_description">
                            {
                                CONFIG.DeployName === DEPLOY_NAME.CPM &&
                                "Учитесь в Центре педагогического мастерства? — войдите, чтобы продолжить обучение в онлайн-формате"
                            }{
                                CONFIG.DeployName === DEPLOY_NAME.SIRIUS &&
                                "Продвинутые курсы для школьников, учителей и всех желающих. Если вы уверенно знаете школьную программу и хотите изучать предметы углублённо — присоединяйтесь к Сириус.Курсам."
                            }{
                                CONFIG.DeployName === DEPLOY_NAME.UNIV &&
                                "Образовательные программы носят междисциплинарный характер и отвечают реальным запросам российского общества и экономики будущего"
                            }
                        </div>
                        <HBox className="platform_landing_page__header_buttons_wrapper">
                            {
                                this.state.platform_button_position !== this.BUTTON_POSITION.BOTTOM &&
                                <>
                                    <button
                                        onClick={
                                            () => {
                                                ym('reachGoal','noo.main.Registration');
                                                auth_actions.redirectToRegistration();
                                            }
                                        }
                                            className={platform_landing_page__start_study_button}
                                    >
                                        <div className="platform_landing_page__start_study_button_arrow" />
                                        {
                                            this.state.platform_button_position !== this.BUTTON_POSITION.MIDDLE &&
                                            (CONFIG.DeployName === DEPLOY_NAME.TEXTBOOK ? 'Начать учиться' : 'Поступить на курсы')
                                        }
                                    </button>
                                    {
                                        CONFIG.DeployName === DEPLOY_NAME.SIRIUS &&
                                        <>
                                            <SGap/>
                                            <LGap/>
                                            <AdditionalHeaderLink text    = {'Курсы по искусственному интеллекту'}
                                                                  link    = {'/ai-navigator?bannerId=APrlpN'}
                                                                  short   = {false}
                                            />
                                        </>
                                    }

                                </>
                            }
                        </HBox>
                    </div>
                </div>
            </div>


            {page.blocks && page.blocks.length > 0
            && <div className="platform_landing_page__content">

                {/* eslint-disable-next-line array-callback-return */}
                {page.blocks.map((landing_block, i) => {

                    if (landing_block.text) {

                        let title = "";
                        let text = {};
                        let image = null;

                        if (landing_block.text.title) {

                            title = landing_block.text.title;

                        }

                        if (landing_block.text.text.text) {

                            text = landing_block.text.text.text;

                        }

                        if (landing_block.text.text.image) {

                            image = landing_block.text.text.image;

                        }


                        return <LandingBlockText title={title}
                                                 text={text}
                                                 image={image}
                                                 block_id={i}
                                                 key={i}
                        />;

                    }

                    if (landing_block.lecturers) {

                        let title = "";

                        if (landing_block.lecturers.title) {

                            title = landing_block.lecturers.title;

                        }


                        return <LandingBlockLecturers lecturers={landing_block.lecturers.list}
                                                      title={title}
                                                      block_id={i}
                                                      key={i}
                                                      openLecturerCard={() => null}
                        />;

                    }

                    if (landing_block.columns) {

                        let title = "";

                        if (landing_block.columns.title) {

                            title = landing_block.columns.title;

                        }

                        return <LandingBlockColumns block_id={i}
                                                    key={i}
                                                    title={title}
                                                    blocks={landing_block.columns.blocks}
                        />;

                    }

                    if (landing_block.courses) {

                        return <div key={i} data-target="courses"
                                    className={`platform_landing_page__courses course_landing_page__section course_landing_page__${i}`}
                                    data-nav={i}
                        >
                            <div className="platform_landing_page__block_wrapper">

                                {landing_block.courses.title
                                && <div className="platform_landing_page__courses_explanation">
                                    {landing_block.courses.title}
                                </div>}

                            </div>
                            {(
                                (this.props.courses.opened && this.props.courses.opened.length > 0)
                                || (this.props.courses.closed && this.props.courses.closed.length > 0)
                                || (this.props.courses.soon && this.props.courses.soon.length > 0))
                            && <div className="platform_landing_page__courses_content">
                                <div className="platform_landing_page__courses_content_wrapper">
                                    {(this.props.courses.opened && this.props.courses.opened.length > 0)
                                    && <div
                                        className="platform_landing_page__courses_list platform_landing_page__courses_available"
                                    >
                                        <div
                                            className="platform_landing_page__courses_title platform_landing_page__courses_title--non_authorized"
                                        >
                                            Сейчас
                                        </div>
                                        <HBox className="platform_landing_page__courses_list_wrapper">
                                            {
                                                this.props.courses.opened.map(
                                                    (course) =>
                                                        <LandingsCourseCard
                                                            key={course.id}
                                                            course={course}
                                                            course_status={LandingsCourseCard.COURSE_STATUS.AVAILABLE}
                                                            is_authorized={false}
                                                            courseCardClick={() => this.courseCardClick(course.id)}
                                                        />
                                                )
                                            }
                                        </HBox>
                                        <br clear="all"/>
                                    </div>}

                                    {
                                        CONFIG.DeployName === DEPLOY_NAME.CPM || CONFIG.DeployName === DEPLOY_NAME.SIRIUS &&
                                        <div className="platform_landing_page__courses_list">
                                            <div className="platform_landing_page__courses_title platform_landing_page__courses_title--non_authorized">
                                                Обратите внимание
                                            </div>
                                            <InformationLinkCard variant = "vos"        />
                                            <InformationLinkCard variant = "schedule"   />
                                            <InformationLinkCard variant = "ai"         />
                                        </div>
                                    }

                                    {
                                        (fullCourseListSoon && fullCourseListSoon.length > 0)
                                         && <LandingBlockChains
                                                soonList={fullCourseListSoon}
                                            />
                                    }

                                </div>
                            </div>}
                        </div>;

                    }

                })}

            </div>}

            {
                clusterV.type === "courses" &&
                <div className="platform_landing_page__footer">
                    <Footer has_navigation={true} />
                </div>
            }
        </div>);

    }

}

const mapStateToProps = (state) => ({
    lecturer_list: state.nooReducer.lecturer_list,
    main_info: state.nooReducer.main_info,
    main_content: state.nooReducer.main_content,
    full_course_list: state.nooReducer.full_course_list,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PlatformLandingPage);
