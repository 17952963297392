import React from "react";
import ProgressCircle from "Cheops/components/ProgressCircle/ProgressCircle";
import BemClassName from "Cheops/BemClassName";
import ReactDOM from "react-dom";
import ElementNavItem from "Cheops/components/ModulePassing/ElementNavItem";


import './ModulePassingHeader.less';
import ElementsNavList from "Cheops/components/ModulePassing/ElementsNavList";
import {META_THEME_COLOR_DEFAULT} from "Cheops/constants";
import ColorUtils from "Cheops/Utils/ColorUtils";


export default class ModulePassingHeader extends React.Component {

    static defaultProps = {
        back_link: null,
        course_color: null,
        fixed: false,
        selected_element_num: null,
        hide_module_progress: false,
        module_content: {},
        module_progress: {
            elements: {},
            summary: {},
        },
    };

    constructor(props) {

        super(props);

        this.state = {
            show_all_tasks: false,
            remove_from_left: 0,
            remove_from_right: 0,
        };

    }

    async componentDidMount() {

        window.addEventListener("resize", this.centerActiveElement);
        this.centerActiveElement();

    }

    componentDidUpdate(prevProps) {

        if (this.props.selected_element_num !== prevProps.selected_element_num) {

            this.setState({show_all_tasks: false}, this.centerActiveElement);

        }

    }

    componentWillUnmount() {

        window.removeEventListener("resize", this.centerActiveElement);

    }

    toggleShowAllTasks = () => {

        this.setState({show_all_tasks: !this.state.show_all_tasks}, this.centerActiveElement);

    };


    getGrouppedVariants = () => {

        return this.props.module_content.variant.map((el, i) => {

            el.index = i;
            return el;

        }).filter((el, i) => {

            let group_range = this.getGroupRange(i);
            return !(group_range[0] !== group_range[1] && group_range[0] !== i);

        });

    };

    centerActiveElement = () => {

        if (this.state.show_all_tasks) {

            return;

        }

        let wrapper_el = ReactDOM.findDOMNode(this).querySelector('.module_passing_header__elements');
        // let element = wrapper_el.querySelector('.module_passing_header__element--active');
        let more_el = ReactDOM.findDOMNode(this).querySelector('.module_passing_header__more');

        let groupped_variants = this.getGrouppedVariants();

        let total_elements_count = groupped_variants.length;

        // let el_width = element.offsetWidth;
        let wrapper_width = wrapper_el.clientWidth - (more_el.offsetWidth * 2);

        // el_width += parseInt(getComputedStyle(element).getPropertyValue('margin-left'));
        // el_width += parseInt(getComputedStyle(element).getPropertyValue('margin-right'));

        let el_width = 50; // TODO width is calculating wrong if there is too many items ;

        let count_in_row = Math.floor(wrapper_width / el_width);

        if (total_elements_count > count_in_row) {

            if (count_in_row % 2 === 0) {

                count_in_row--;

            }

        }

        let selected_element_num = 0;

        for (let group of groupped_variants) {

            if (group.index >= this.props.selected_element_num) {

                break;

            }

            selected_element_num++;

        }

        let elements_on_side = Math.floor((count_in_row - 1) / 2);

        let remove_from_left = selected_element_num - elements_on_side;

        if (remove_from_left < 0) {

            remove_from_left = 0;

        }

        let remove_from_right = total_elements_count - count_in_row - remove_from_left;

        if (remove_from_right <= 0) {

            remove_from_left = selected_element_num - elements_on_side + remove_from_right;

            if (remove_from_left < 0) {

                remove_from_left = 0;

            }

            remove_from_right = 0;

        }

        let elements = wrapper_el.querySelectorAll('.element_nav_item');

        for (let [index, element] of elements.entries()) {

            if (index < remove_from_left || index >= (total_elements_count - remove_from_right)) {

                element.style.display = "none";

            } else {

                element.style.display = "";

            }

        }

        this.setState({remove_from_left, remove_from_right});

    };

    getGroupRange(element_id) {

        let groups = this.props.module_content.groups;

        for (let group of groups) {

            if (group.from - 1 <= element_id && group.to - 1 >= element_id) {

                return [group.from - 1, group.to - 1];

            }

        }

        return [element_id, element_id];

    }


    elementsClick = (e) => {

        if (e.target !== e.currentTarget) {

            return;

        }

        this.toggleShowAllTasks();

    };

    render() {

        let wrapper_class = new BemClassName("module_passing_header", [], this.props.className);

        wrapper_class.appendStatusIf(this.props.fixed, 'fixed');
        wrapper_class.appendStatusIf(this.state.show_all_tasks, 'show_all_tasks');

        let course_color = this.props.course_color || META_THEME_COLOR_DEFAULT;


        return (
            <div className={wrapper_class}>
                <div style={{background: ColorUtils.veryDarken(course_color)}}>

                    <div className="module_passing_header__title_wrapper">
                        {this.props.back_link
                        && <a className="module_passing_header__back" href={this.props.back_link} />}
                        <div className="module_passing_header__title">{this.props.module_content.info.title}</div>
                    </div>

                    {!this.state.show_all_tasks
                    && <div className={new BemClassName("module_passing_header__elements", 'clickable')}
                            onClick={this.elementsClick}
                    >

                        <div className={new BemClassName('module_passing_header__more', "left")}
                             style={{visibility: this.state.remove_from_left ? "" : "hidden"}}
                             onClick={this.toggleShowAllTasks}
                        >
                            ...
                        </div>
                        {this.getGrouppedVariants().map((element, index) => {

                            let range = this.getGroupRange(element.index);

                            return <ElementNavItem
                                key={index}
                                element_index={element.index}
                                module_content={this.props.module_content}
                                module_progress={this.props.module_progress}
                                show_group_summary={true}
                                active={this.props.selected_element_num >= range[0] && this.props.selected_element_num <= range[1]}
                                onClick={() => this.props.changeSelectedElementNum(element.index)}
                                course_color={course_color}
                            />;

                        })}
                        <div className={new BemClassName('module_passing_header__more', "right")}
                             style={{visibility: this.state.remove_from_right ? "" : "hidden"}}
                             onClick={this.toggleShowAllTasks}
                        >
                            ...
                        </div>
                    </div>}
                    {this.state.show_all_tasks
                    && <div className="module_passing_header__elements">
                        <ElementsNavList
                            course_color={course_color}
                            module_content={this.props.module_content}
                            module_progress={this.props.module_progress}
                            selected_element_num={this.props.selected_element_num}
                            navElementClick={(el_index) => this.props.changeSelectedElementNum(el_index)}
                        />
                    </div>}
                    {this.state.show_all_tasks
                    && <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                            className="module_passing_header__close_all_tasks"
                            onClick={this.toggleShowAllTasks}
                    >
                        <path
                            d="M18.2997 5.70973C17.9097 5.31973 17.2797 5.31973 16.8897 5.70973L11.9997 10.5897L7.10973 5.69973C6.71973 5.30973 6.08973 5.30973 5.69973 5.69973C5.30973 6.08973 5.30973 6.71973 5.69973 7.10973L10.5897 11.9997L5.69973 16.8897C5.30973 17.2797 5.30973 17.9097 5.69973 18.2997C6.08973 18.6897 6.71973 18.6897 7.10973 18.2997L11.9997 13.4097L16.8897 18.2997C17.2797 18.6897 17.9097 18.6897 18.2997 18.2997C18.6897 17.9097 18.6897 17.2797 18.2997 16.8897L13.4097 11.9997L18.2997 7.10973C18.6797 6.72973 18.6797 6.08973 18.2997 5.70973Z"
                            fill="white"
                        />
                    </svg>}
                    {!this.state.show_all_tasks && !this.props.hide_module_progress
                    && <ProgressCircle
                        show_border={true}
                        progress={this.props.module_progress.summary}
                        onClick={this.toggleShowAllTasks}
                        starred={this.props.module_content.info.isAdvanced || false}
                    />}

                    <div className="module_passing_header__corners">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M16 0H0V16C0 7.16344 7.16344 0 16 0Z"
                                  fill={ColorUtils.veryDarken(course_color)}
                            />
                        </svg>
                        {this.state.show_all_tasks
                        && <div onClick={this.toggleShowAllTasks}
                                className="module_passing_header__bottom_close_bar"
                        />}
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M16 16L16 0L0 -6.99382e-07C8.83656 -3.13124e-07 16 7.16344 16 16Z"
                                  fill={ColorUtils.veryDarken(course_color)}
                            />
                        </svg>
                    </div>
                </div>
            </div>
        );

    }

}
