import React from "react";
import "./LandingActionPopup.less";


export default class LandingActionPopup extends React.Component {

    static defaultProps = {
        abortClick: null,
        refuseClick: null,
    };

    render() {

        return (
            <div className="landing_action_popup">
                <div className="landing_action_popup__header">Хотите отказаться от учёбы?</div>
                <div className="landing_action_popup__text">
                    Если передумаете — найдите ссылку на курс в уведомлениях
                </div>
                <div className="landing_action_popup__buttons">
                    <button className="landing_action_popup__buttons_item" onClick={this.props.abortClick}>Нет</button>
                    <button className="landing_action_popup__buttons_item" onClick={this.props.refuseClick}>
                        Отказаться
                    </button>
                </div>
            </div>
        );

    }

}
