import cytoscape from 'cytoscape';
import React from "react";
import edgehandles from 'cytoscape-edgehandles';
import compoundDragAndDrop from 'cytoscape-compound-drag-and-drop';
import automove from 'cytoscape-automove';
import {GRAPH_EXT_RELATION_SPACE} from "SrcRoot/constants";


export default class Graph extends React.Component {

    static defaultProps = {
        allow_copy: false,
        editable: false,
        is_environment: false,
        course_status: {},
        module_positions: {},
        modules_relations: [],
        expandMap: () => {
        },
    };


    constructor(props) {

        super(props);

        this.container_id = "graph";

        this.registerExtentions();

        this.state = {
            env_id: props.env_id,
            editable: props.editable || false,
            modules: props.modules,
            modules_relations: [],
            module_positions: {},
        };

    }

    async componentDidMount() {

        await this.modulePropToState();


        let elements = [];

        if (this.props.course_name) {

            let curse_classes = '';


            if (this.props.course_status.locked) {

                curse_classes += " course_locked";

            }

            elements.push({
                data: {
                    id: 'course',
                    name: this.props.course_name,
                },
                position: {
                    x: 0,
                    y: 0,
                },
                classes: curse_classes,
                grabbable: false,
                selected: false,
                selectable: false,
            });

        }


        this.cy = cytoscape({
            elements,
            minZoom: 0.3,
            maxZoom: 1.5,
            // TODO need to find out why zoom doesn't work here.
            container: document.getElementById(this.container_id),
            layout: {
                name: 'preset',
                animate: false, // whether to transition the node positions
            },
            style: this.props.styles,
            selectionType: 'single',
        });

        if (this.state.editable) {

            this.eh = this.cy.edgehandles({
                handlePosition: () => {

                    return 'middle bottom';

                },
            });

        }


        let min_x = 0;
        let min_y = 0;

        for (let module of this.state.modules) {

            let position = this.state.module_positions[module.id];

            if (!position) {

                continue;

            }


            if (min_x > this.state.module_positions[module.id].x) {

                min_x = this.state.module_positions[module.id].x;

            }

            if (min_y > this.state.module_positions[module.id].y) {

                min_y = this.state.module_positions[module.id].y;

            }

        }


        for (let module of this.state.modules) {

            let position = this.state.module_positions[module.id];

            if (!position) {

                position = {
                    x: min_x - 150,
                    y: min_y,
                };

                min_y += 100;

            }

            this.addModule({...module}, position, module.classes);

        }

        this.cy.on('pan', this.updateSectionNodesPosition);

        for (let relation of this.state.modules_relations) {

            let classes = `type_${relation.type}`;

            if (relation.parent_is_not_available) {

                classes += " parent_is_not_available";

            }

            this.cy.add({
                data: {
                    source: relation.parent,
                    target: relation.child,
                },
                classes,
            });

        }


        this.updateAllExtModules();


        // this.cy.compoundDragAndDrop();


        this.cy.on('mousedown touchstart', () => {

            let container = document.querySelector(`#${this.container_id}`);
            let old_height = container.clientHeight;
            let iterations_count = 0;

            this.props.expandMap();

            let interval = setInterval(() => {

                iterations_count++;

                if (iterations_count > 8) {

                    clearInterval(interval);

                }

                if (old_height !== container.clientHeight) {

                    old_height = container.clientHeight;
                    this.cy.resize();
                    return;

                }

                clearInterval(interval);

            }, 300);

        });


        this.cy.on('mouseover', 'edge', (e) => {

            e.target.addClass('hover');

        });

        this.cy.on('mouseout', 'edge', (e) => {

            e.target.removeClass('hover');

        });

        this.cy.on('unselect', 'node', () => {

            this.forceRerender();

        });

        if (this.state.editable) {


            // remove Edges event
            this.cy.on('click', async (event) => {

                if (event.target === this.cy) {

                    return;

                }

                if (!event.target.isEdge()) {

                    return;

                }

                if (event.target.data().source === 'course') {

                    return;

                }

                let source_id = parseInt(event.target.data().source);
                let target_id = parseInt(event.target.data().target);

                for (let [i, relation] of Object.entries(this.state.modules_relations)) {


                    if (relation.parent !== source_id) {

                        continue;

                    }

                    if (relation.child !== target_id) {

                        continue;

                    }

                    let array = [...this.state.modules_relations];

                    array.splice(i, 1);

                    await this.setState({
                        modules_relations: array,
                    });

                }

                event.target.remove();

                this.updateAllExtModules();

                this.createCourseToModuleEdges();
                this.updateEdges();

                this.removeRelation(source_id, target_id);

            });

            this.cy.on('ehcomplete', async (event, source_node, target_node) => {

                let source_node_id = source_node.data().id;
                let target_node_id = target_node.data().id;
                this.saveRelation(source_node_id, target_node_id, 'ordinary');

                let modules_relations = this.state.modules_relations;

                modules_relations.push({
                    parent: parseInt(source_node_id),
                    child: parseInt(target_node_id),
                    type: "ordinary",
                });


                await this.setState({modules_relations});

                this.updateAllExtModules();
                this.updateEdges();

            });

        }

        // TODO move to init config
        setTimeout(() => {

            this.cy.$('.type_section').addClass('hidden');
            this.cy.animate({
                fit: {
                    padding: 50,
                },
                duration: 350,
                complete: () => this.cy.$('.type_section').removeClass('hidden'),
            });

        }, 400);

        this.forceRerender();

        window.addEventListener('keypress', this.keyPress.bind(this));

    }

    componentDidUpdate({modules, selected_module_id}) {
        if (!this.cy) {
            return;
        }

        if (!Array.isArray(selected_module_id) && selected_module_id !== this.props.selected_module_id) {
            this.cy.$('.module').not(`[id="${this.props.selected_module_id}"]`).unselect();
            this.cy.$(`.module[id="${this.props.selected_module_id}"]`).select();
        }

        if (!this.state.editable) {
            return;
        }

        for (let module of modules) {
            let node = this.cy.$(`.module[id="${module.id}"]`);
            if (module.type !== 'section') {
                node.removeClass('type_section');
                node.grabify();
            } else {
                node.addClass('type_section');
                node.ungrabify();
                let modules_relations = [...this.state.modules_relations];
                for (let [i, relation] of Object.entries(this.state.modules_relations)) {
                    // eslint-disable-next-line eqeqeq
                    if (relation.parent == node.data('id') || relation.child == node.data('id')) {
                        modules_relations.splice(i, 1);
                        this.setState({modules_relations});
                    }
                }
            }
        }

        this.updateAllExtModules();
        this.updateEdges();
        this.updateSectionNodesPosition();
    }

    ext_automoves = {};

    registerExtentions() {

        if (!cytoscape('core', 'edgehandles')) {

            cytoscape.use(edgehandles);

        }

        if (!cytoscape('core', 'automove')) {

            cytoscape.use(automove);

        }

        if (!cytoscape('core', 'compoundDragAndDrop')) {

            cytoscape.use(compoundDragAndDrop);

        }

    }

    updateSectionNodesPosition = () => {

        let extent = this.cy.extent();
        let v_width = this.cy.width();
        let v_height = this.cy.height();
        let offset_w = 300 / v_width * extent.w;
        let offset_h = 30 / v_height * extent.h;

        this.cy.$('.module.type_section').positions((node, i) => {

            return {
                x: extent.x1 + offset_w,
                y: extent.y2 - offset_h - (i * 50),
            };

        });

    };

    removeRelation(parent, child) {

        let modules_relations = [...this.state.modules_relations];

        for (let [i, relation] of Object.entries(this.state.modules_relations)) {


            if (relation.parent !== parent) {

                continue;

            }

            if (relation.child !== child) {

                continue;

            }

            modules_relations.splice(i, 1);

        }


        this.props.lab_actions.removeRelation(this.state.env_id, parent, child);

        return this.setState({modules_relations});

    }

    updateAllExtModules() {


        // eslint-disable-next-line no-unused-vars
        for (let [i, _automove] of Object.entries(this.ext_automoves)) {

            _automove.destroy();

        }

        this.cy.$('[module_id]').removeClass('is_ext_child');

        for (let relation of this.state.modules_relations) {

            if (relation.type === 'extension' && relation.parent !== 'course') {

                let top_id = this.getTopNodeIdForExt(relation.parent);

                let nodes = this.getAllExtRelationsForNode(top_id);

                nodes.filter(`[id!="${top_id}"]`).addClass('is_ext_child');

                this.updatePositionsForExtModules(relation.parent);

                this.ext_automoves[top_id] = this.cy.automove({
                    reposition: 'drag',
                    dragWith: nodes,
                    nodesMatching: nodes,
                });

            }

        }

        this.forceRerender('.is_ext_child');

    }

    forceRerender = (selector = "*") => {
        this.cy.$(selector).addClass("force_refresh");
        setTimeout(() => {
            this.cy.$(selector).removeClass("force_refresh");
        }, 50);
    };

    getAllExtRelationsForNode(node_id, ids_only = false) {

        let ids = [node_id];

        for (let relation of this.state.modules_relations) {

            if (relation.type !== 'extension') {

                continue;

            }

            if (relation.parent === node_id) {

                if (node_id !== relation.child) {

                    ids = ids.concat(this.getAllExtRelationsForNode(relation.child, true));

                }

            }

        }

        if (ids_only) {

            return ids;

        }

        let collection = this.cy.collection();

        for (let id of ids) {

            collection.merge(this.cy.$(`.module[id="${id}"]`));

        }

        return collection;


    }

    updatePositionsForExtModules(parent_module_id) {


        let parent_node = this.cy.$(`.module[id="${parent_module_id}"]`);
        let parent_pos = parent_node.position();

        for (let relation of this.state.modules_relations) {

            if (relation.type === 'extension' && relation.parent === parent_module_id) {

                let new_pos = {...parent_pos};
                new_pos.x += GRAPH_EXT_RELATION_SPACE;

                let child_node = this.cy.$(`.module[id="${relation.child}"]`);
                child_node.position(new_pos);

                this.updatePositionsForExtModules(relation.child);

            }

        }

    }

    getTopNodeIdForExt(module_id) {
        let top_id = module_id;
        for (let relation of this.state.modules_relations) {
            if (relation.type !== 'extension') {
                continue;
            }
            if (relation.child === top_id) {
                if (top_id !== relation.parent) {
                    return this.getTopNodeIdForExt(relation.parent);
                }
            }
        }
        return top_id;
    }

    isExtRelationExist(child_id) {
        for (let relation of this.state.modules_relations) {
            if (relation.child === child_id) {
                if (relation.type === 'extension') {
                    return true;
                }
            }
        }
        return false;
    }

    getLastChildNodeIdForExt(module_id) {
        let last_id = module_id;
        for (let relation of this.state.modules_relations) {
            if (relation.type !== 'extension') {
                continue;
            }
            if (relation.parent === last_id) {
                if (last_id !== relation.child) {
                    return this.getLastChildNodeIdForExt(relation.child);
                }
            }
        }
        return last_id;
    }

    modulePropToState() {

        let modules = [];

        let modules_without_parent = [];
        let module_progress;
        let classes;
        let ext_children = [];
        let not_available_modules = [];


        for (let i in this.props.dependencies) {

            if (this.props.dependencies[i].type === 'extension') {

                ext_children.push(this.props.dependencies[i].child);

            }

        }


        // eslint-disable-next-line no-unused-vars
        for (let [module_index, module] of Object.entries(this.props.modules)) {

            if (module.type !== 'section') {

                modules_without_parent.push(module.id);

            }

            module_progress = null;
            classes = "module";


            if (this.props.modules_progress && this.props.modules_progress[module.id]) {

                module_progress = this.props.modules_progress[module.id];


                if (module_progress.isAchieved && module_progress.isClosed && module_progress.isDone) {

                    classes += " module_full_done";

                } else if (module_progress.isAchieved && module_progress.isClosed && !module_progress.isDone) {

                    classes += " module_failed";

                } else {

                    if (module_progress.isClosed) {

                        classes += " module_closed";

                    }

                    if (!module_progress.hash) {

                        classes += " module_not_available";
                        not_available_modules.push(module.id);

                    }

                }

                if (this.props.course_status.locked) {

                    classes += " course_locked";

                }

            }

            let version = module.version || {};

            let title = module.title;

            if (this.state.editable) {

                title += ` - ${version.textVersion}`;

                if (version.status) {

                    classes += ` module_status-${version.status}`;

                }

                if (module.autograde) {

                    classes += " module_autograde";

                }

                if (this.props.is_environment) {

                    classes += " env";

                } else {

                    if (module.hasPublished) {

                        classes += " module_has_published";

                    }

                    if (module.hasUnpublished) {

                        classes += " module_has_unpublished";
                        classes += ` module_has_unpublished-${module.hasUnpublished.status}`;

                    }

                }

            }

            if (ext_children.includes(module.id)) {

                classes += " is_ext_child";

            }

            classes += ` type_${module.type}`;


            modules.push({
                classes,
                module_progress,
                id: module.id,
                module_id: module.moduleId || module.id,
                module_version_id: version.versionId || null,
                name: title,
                perToPass: module.perToPass,
                starred: module.isAdvanced || false,
                type: module.type,
            });

        }


        let modules_relations = [];

        for (let dependency of this.props.dependencies) {

            modules_relations.push({
                parent: dependency.parent,
                child: dependency.child,
                type: dependency.type,
                parent_is_not_available: not_available_modules.includes(dependency.child),
            });

            if (modules_without_parent.indexOf(dependency.child) !== -1) {

                modules_without_parent.splice(modules_without_parent.indexOf(dependency.child), 1);

            }

        }

        if (this.props.course_name) {

            for (let module_id of modules_without_parent) {

                modules_relations.push({
                    parent: 'course',
                    child: module_id,
                });

            }

        }

        let module_positions = this.props.module_positions;

        return new Promise((resolve) => {

            this.setState({modules_relations, modules, module_positions}, resolve);

        });

    }

    keyPress(e) {

        if (!this.state.editable) {

            return;

        }

        if (e.keyCode === 127) {

            this.deleteSelectedModules();

        }

    }

    deleteSelectedModules() {

        if (!this.props.lab_actions.deleteModule) {

            return;

        }


        let modules = this.cy.$('.module:selected');

        let state_modules = this.state.modules;
        let modules_relations = this.state.modules_relations;

        if (this.props.setSelectedModuleId) {

            this.props.setSelectedModuleId(null);

        }

        let id;

        for (let i = 0; i < modules.length; i++) {

            id = parseInt(modules[i].data('id'));

            this.props.lab_actions.deleteModule(this.state.env_id, id);
            modules[i].remove();

            for (let [index, state_module] of Object.entries(state_modules)) {

                if (state_module.id === id) {

                    state_modules.splice(index, 1);
                    break;

                }

            }

            for (let [index, relation] of Object.entries(modules_relations)) {

                if (relation.child === id || relation.parent === id) {

                    modules_relations.splice(index, 1);

                }

            }

            this.cy.remove(`edge[target="${id}"]`);
            this.cy.remove(`edge[source="${id}"]`);

        }

        this.setState({modules: state_modules, modules_relations});

    }

    zoomIn = () => {
        let current_zoom = this.cy.zoom();
        this.cy.zoom(current_zoom + 0.5);
    }

    zoomOut = () => {
        let current_zoom = this.cy.zoom();
        this.cy.zoom(current_zoom - 0.5);
    }

    toCenter = () => {
        // TODO
        let offset_x = window.innerWidth / 2;
        let offset_y = window.innerHeight / 2;
        this.cy.pan({
            x: offset_x,
            y: offset_y,
        });
    }

    addModule(module_data, position, classes) {


        let module = this.cy.add({
            data: {
                id: module_data.id,
                module_id: module_data.module_id,
                module_version_id: module_data.module_version_id,
                name: module_data.name,
                module_progress: module_data.module_progress,
                perToPass: module_data.perToPass,
                starred: module_data.starred,
            },
            classes,
            position,
            selected: false,
            grabbable: this.state.editable && module_data.type !== 'section',
            selectable: true,
        });


        this.addModuleEvents(module);

    }

    addModuleEvents(module) {

        if (this.props.onMarkerDoubleClick) {

            let last_click_ts = 0;

            module.on('click', (evt) => {

                let now_ts = (new Date()).getTime();

                if (now_ts - last_click_ts < 500) {

                    this.props.onMarkerDoubleClick(evt.target.data());

                }

                last_click_ts = now_ts;

            });

        }

        module.on('select', (evt) => {

            let module_id = evt.target.data().module_id;

            if (this.state.editable) {

                if (this.props.addSelectedModuleId) {

                    this.props.addSelectedModuleId(parseInt(evt.target.data().id));

                }

            } else {

                this.props.setSelectedModuleId(module_id);

            }

            this.forceRerender();

        });

        module.on('unselect', (evt) => {

            let module_id = null;

            if (this.state.editable) {

                if (this.props.removeSelectedModuleId) {

                    this.props.removeSelectedModuleId(parseInt(evt.target.data().id));

                }

            } else {

                this.props.setSelectedModuleId(module_id);

            }

        });

        module.on('mouseover', () => document.querySelector('body').classList.add('mouseover'));
        module.on('mouseout', () => document.querySelector('body').classList.remove('mouseover'));


        if (this.state.editable) {
            module.on('dragfree', async (evt) => {
                let module_positions = this.state.module_positions;
                let new_pos = {...evt.target.position()};
                let modules_relations = this.state.modules_relations;
                this.cy.$('[module_id]').forEach(
                    (node) => {
                        let position = node.position();
                        if (new_pos.x >= position.x - 10 && new_pos.x <= position.x + node.width() + 10) {
                            if (new_pos.y >= position.y - 10 && new_pos.y <= position.y + node.height() + 10) {
                                if (evt.target.data("id") !== node.data('id')) {
                                    let last_child_id = this.getLastChildNodeIdForExt(parseInt(node.data('id')));
                                    let child_id = parseInt(evt.target.data("id"));
                                    if (this.isExtRelationExist(child_id)) { return; }
                                    if (last_child_id === child_id) { return; }
                                    modules_relations.push({
                                        parent: last_child_id,
                                        child: child_id,
                                        type: "extension",
                                    });
                                    this.cy.add({
                                        data: {
                                            source: last_child_id,
                                            target: child_id,
                                        },
                                        classes: "type_extension",
                                    });
                                    this.saveRelation(last_child_id, evt.target.data("id"), "extension");
                                    // eslint-disable-next-line consistent-return
                                    return false; // break
                                }
                            }
                        }
                    }
                );

                this.updateAllExtModules();
                module_positions[evt.target.data().id] = new_pos;
                await this.setState({module_positions, modules_relations});
                if (this.state.editable) {
                    this.saveCoords();
                }
                this.updateEdges();
            });
        }
    }

    createCourseToModuleEdges() {

        // add course edges
        let modules_with_parent = [];
        for (let module of this.state.modules) {
            for (let relation of this.state.modules_relations) {
                if (relation.parent !== 'course' && module.id === relation.child) {
                    modules_with_parent.push(module.id);
                }
            }
        }

        let modules_relations = this.state.modules_relations;
        this.cy.$('[module_id]').forEach((node) => {
            if (node.hasClass("type_section")) {
                return;
            }
            if (modules_with_parent.includes(parseInt(node.data('id')))) {
                return;
            }
            if (!modules_relations.find((rel) => rel.child === parseInt(node.data('id')) && rel.parent === 'course')) {
                modules_relations.push({
                    parent: 'course',
                    child: parseInt(node.data('id')),
                });
            }
        });
    }

    updateEdges() {
        if (this.props.course_name) {
            // add course edges
            let modules_with_parent = [];
            for (let module of this.state.modules) {
                for (let relation of this.state.modules_relations) {
                    if (relation.parent !== 'course' && module.id === relation.child) {
                        modules_with_parent.push(module.id);
                        break;
                    }
                }
            }

            let section_ids = [];
            this.cy.$('[module_id]').forEach(
                (node) => {
                    if (modules_with_parent.includes(parseInt(node.data('id')))) { return; }
                    if (node.hasClass('type_section')) {
                        section_ids.push(node.data('id'));
                        return;
                    }
                    if (!this.cy.$(`edge[source="course"][target="${node.data('id')}"]`).length) {
                        this.cy.add({
                            data: {
                                source: "course",
                                target: node.data('id'),
                            },
                        });
                    }
                }
            );

            // remove exceed

            for (let section_id of section_ids) {
                this.cy.remove(`edge[target="${section_id}"]`);
                this.cy.remove(`edge[source="${section_id}"]`);
            }

            // eslint-disable-next-line no-unused-vars
            for (let [relation_index, relation] of Object.entries(this.state.modules_relations)) {
                if (relation.parent !== 'course' && modules_with_parent.includes(relation.child)) {
                    this.cy.remove(`edge[source="course"][target="${relation.child}"]`);
                }
                if (!this.cy.$(`edge[source="${relation.parent}"][target="${relation.child}"]`).length) {
                    let classes = `type_${relation.type}`;
                    if (relation.parent_is_not_available) {
                        classes += " parent_is_not_available";
                    }
                    if (this.cy.$(`.module[id="${relation.parent}"]`).length && this.cy.$(`.module[id="${relation.child}"]`).length) {
                        this.cy.add({
                            data: {
                                source: relation.parent,
                                target: relation.child,
                            },
                            classes,
                        });
                    }
                }
            }
        }
    }

    saveCoords() {
        this.props.lab_actions.updateCoordinates(
            this.state.env_id,
            this.state.module_positions
        );
    }

    saveRelation(from_id, to_id, type) {
        this.props.lab_actions.saveRelation(
            this.state.env_id,
            from_id,
            to_id,
            type
        );
    }

    async insertPastedModules(pasted_modules, new_modules_obj) {
        let linked_modules = pasted_modules.linkedModules;
        let new_linked_modules_obj = {};
        for (let module of linked_modules) {
            new_linked_modules_obj[module.moduleId] = module;
        }
        let module_positions = this.state.module_positions;
        let modules = this.state.modules;
        let module;
        let course_module_id;
        let modules_with_parent = [];
        for (let edge of pasted_modules.edges) {
            modules_with_parent.push(edge.to);
        }
        this.cy.$('*').unselect();
        for (let env_module_id in new_modules_obj) {
            course_module_id = new_linked_modules_obj[env_module_id].courseModuleId;
            new_modules_obj[env_module_id].data.id = parseInt(course_module_id);
            new_modules_obj[env_module_id].data.perToPass = new_linked_modules_obj[env_module_id].perToPass;
            this.cy.add(new_modules_obj[env_module_id]);
            module = this.cy.$(`.module[module_id=${new_modules_obj[env_module_id].data.module_id}]`);
            module.removeClass('env');
            this.addModuleEvents(module);
            module_positions[course_module_id] = pasted_modules.layout.module_positions[course_module_id];
            let data = {...new_modules_obj[env_module_id].data};
            data.id = parseInt(data.id);
            modules.push({
                classes: new_modules_obj[env_module_id].classes,
                type: new_modules_obj[env_module_id].type,
                ...data,
            });
        }
        let modules_relations = this.state.modules_relations;
        for (let edge of pasted_modules.edges) {
            // TODO Check for relation existence. It can be if was pasted already exist module.
            modules_relations.push({
                parent: edge.from,
                child: edge.to,
                type: edge.type || "ordinary",
            });
        }
        this.setState({
            modules,
            modules_relations,
            module_positions,
        });
    }

    copyModules = () => {
        let modules = this.cy.$('.module:selected');
        let modules_json = modules.jsons();
        let module_relations = [];
        let module_ids = [];
        for (let i in modules_json) {
            module_ids.push(parseInt(modules_json[i].data.id));
            delete modules_json[i].data.id;
        }
        for (let relation of this.state.modules_relations) {
            if (module_ids.indexOf(relation.parent) >= 0 && module_ids.indexOf(relation.child) >= 0) {
                module_relations.push(relation);
            }
        }
        localStorage.setItem('graph_copied_modules', JSON.stringify({
            modules_json,
            module_relations,
        }));
    }

    async createModule() {
        let module_name = window.prompt("Введите название модуля", "Новый модуль");
        if (!module_name) {
            return;
        }
        let response = await this.props.lab_actions.putModule(this.state.env_id, module_name);
        if (response.error) {
            return;
        } else if (response.success) {
            const {
                info_id,
                version_id
            } = response.success;
        }
        let min_x = this.cy.nodes().min( node => node.position().x );
        let min_y = this.cy.nodes().min( node => node.position().y );
        let position = {
            x: min_x.value - 150,
            y: min_y.value,
        };
        this.addModule(
            {
                id:                info_id,
                module_id:         info_id,
                module_version_id: version_id,
                name:              module_name,
            },
            position,
            "module module_completed"
        );
    }

    render() {
        const {allow_copy, course_status:{locked}} = this.props
        return  <div
                    className={
                        `graph ${ locked ? ' graph--locked' : '' }`
                    }
                >
                    <div
                        id        = {this.container_id}
                        className = "graph__container"
                    />
                    { allow_copy && this.renderGraphCopyControls() }
                    { this.renderGraphControls() }
                </div>
        ;
    }
    renderGraphCopyControls = () =>
        <div className="graph__controls_copy_paste_modules"
                onClick={this.copyModules}
                title="Копировать модули"
        >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                <rect x="6" y="5" width="15" height="18" rx="2" fill="#828282" />
                <path
                    d="M15 1H4C2.9 1 2 1.9 2 3V16C2 16.55 2.45 17 3 17C3.55 17 4 16.55 4 16V4C4 3.45 4.45 3 5 3H15C15.55 3 16 2.55 16 2C16 1.45 15.55 1 15 1Z"
                    fill="#828282"
                />
            </svg>
        </div>
    ;
    renderGraphControls = () =>
        <div className="graph__controls">
            <div className="graph__controls_zoom">
                <div className="graph__controls_zoom_in" onClick={this.zoomIn}>
                    <div className="graph__controls_svg_wrapper" style={{paddingBottom: "92%"}}>
                        <svg viewBox="0 0 55 60">
                            <path
                                d="M20 12.5H12.5V20C12.5 20.825 11.825 21.5 11 21.5C10.175 21.5 9.5 20.825 9.5 20V12.5H2C1.175 12.5 0.5 11.825 0.5 11C0.5 10.175 1.175 9.5 2 9.5H9.5V2C9.5 1.175 10.175 0.5 11 0.5C11.825 0.5 12.5 1.175 12.5 2V9.5H20C20.825 9.5 21.5 10.175 21.5 11C21.5 11.825 20.825 12.5 20 12.5Z"
                                fill="#9194A1"
                            />
                        </svg>
                    </div>
                </div>
                <div className="graph__controls_zoom_out" onClick={this.zoomOut}>
                    <div className="graph__controls_svg_wrapper" style={{paddingBottom: "92%"}}>
                        <svg viewBox="0 0 55 60">
                            <path
                                d="M20 3.5H2C1.175 3.5 0.5 2.825 0.5 2C0.5 1.175 1.175 0.5 2 0.5H20C20.825 0.5 21.5 1.175 21.5 2C21.5 2.825 20.825 3.5 20 3.5Z"
                                fill="#9194A1"
                            />
                        </svg>
                    </div>
                </div>
            </div>
            <div className="graph__controls_to_center" onClick={this.toCenter}>
                <div className="graph__controls_svg_wrapper" style={{paddingBottom: "92%"}}>
                    <svg viewBox="0 0 55 60">
                        <path
                            d="M23.1225 0.910196L1.10248 10.1202C-0.14252 10.6452 -0.11252 12.4152 1.13248 12.8952L9.14248 16.0002C9.53248 16.1502 9.84748 16.4652 9.99748 16.8552L13.0875 24.8502C13.5675 26.1102 15.3525 26.1402 15.8775 24.8952L25.1025 2.8902C25.5975 1.6452 24.3525 0.400196 23.1225 0.910196Z"
                            fill="#9194A1"
                        />
                    </svg>
                </div>
            </div>
        </div>
    ;
}
