import React, {FC}                            from "react";
import bnc                                    from "bnc";

import {HBox}                                 from "@sirius/ui-lib/src/blocks/Layout";

import {TextbookStylesComplexityControlsType} from "Cheops/containers/ModulePage__Types";
import {ComplexityControl}                    from "Cheops/containers/ModulePage__ComplexityControl";
import {StylesControl}                        from "Cheops/containers/ModulePage__StylesControl";
import {getComplexityByCourseContents}        from "Cheops/containers/ModulePage__getComplexityByCourseContents";
import {getStyleStructByCourseContents}       from "Cheops/containers/ModulePage__getStyleStructByCourseContents";
import {POST_CourseSwitchComplexity,
        POST_CourseSwitchStyle}               from "Cheops/actions/noopolis-ts";

import                                             "./ModulePage__TextbookStylesComplexityControls.less";

const block = new bnc("module-page-textbook-styles-complexity-controls");

export const TextbookStylesComplexityControls:FC<TextbookStylesComplexityControlsType> = ({
    groups,
    params,
    selected_element_num,
    current_module_learn
}) => {

    const {module_id, course_id} = params;

    const complexity = groups ? getComplexityByCourseContents(groups, selected_element_num, current_module_learn, module_id) : void(0);
    const styles     = groups ? getStyleStructByCourseContents(groups, selected_element_num, current_module_learn) : void(0);
    const show       = styles || complexity;

    //console.log({groups, selected_element_num, complexity, styles})

    const switchStyle = (style: string) => {
        POST_CourseSwitchStyle(course_id, module_id, style)
            .then(
                (_res)=>{
                    location.reload();
                }
            )
    }

    const switchComplexity = (complexity: number) => {
        POST_CourseSwitchComplexity(course_id, module_id, complexity)
            .then(
                ({courseModuleId})=>{
                    location.href = `/#/course/${course_id}/${courseModuleId}`;
                }
            )
    }

    return show
            ?   <HBox className={block} mobOverturn>
                    {styles     && <StylesControl     styles     = {styles}     switchStyle      = {switchStyle}     />}
                    {complexity && <ComplexityControl complexity = {complexity} switchComplexity = {switchComplexity}/>}
               </HBox>
            : null
    ;
};
