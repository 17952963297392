import React from "react";
import Helpers from "Cheops/Helpers";


import "./ElementVerdict.less";
import {ELEMENT_VERDICT} from "Cheops/constants";
import {TaskAnswerType} from "Cheops/Model/Task";


interface Props {
    elementProgress: any;
    taskType: TaskAnswerType[];
}

export default class ElementVerdictBlock extends React.Component<Props> {

    static defaultProps = {
        elementProgress: {},
    };

    render(): React.ReactNode {

        const {elementProgress, taskType} = this.props;

        if (elementProgress.isAdvanced) {

            return null;

        }

        if (taskType.length === 1 && taskType.includes(TaskAnswerType.NONE)) {

            return <div className="element_verdict" />;

        }

        let text = '';

        let maxTies;
        let currentTry;

        if (elementProgress.taskInfo) {

            maxTies = parseInt(elementProgress.taskInfo.maxTries);
            currentTry = parseInt(elementProgress.taskInfo.currentTry);

        }


        if (elementProgress.isClosed) {

            switch (elementProgress.verdict) {
                case ELEMENT_VERDICT.OK:
                    text = 'Верно';
                    break;
                case ELEMENT_VERDICT.WRONG:
                    text = 'Неверно';
                    break;
                case ELEMENT_VERDICT.PARTLY:
                    text = 'Частично верно';
                    break;
                case ELEMENT_VERDICT.NOTREADY:
                    text = 'На проверке';
                    break;
                case ELEMENT_VERDICT.ANNULLED:
                    text = 'Задание признано некорректным. Решение не оценивается';
                    break;
                case ELEMENT_VERDICT.NONE:
                    text = 'Вы не отвечали на задание';
                    break;
            }

        } else if (Number.isFinite(maxTies) && Number.isFinite(currentTry)) {

            text = `${maxTies - currentTry} ${Helpers.formatPlural((maxTies - currentTry), ['попытка', 'попытки', 'попыток'])}`;

        }

        return (
            <div className="element_verdict">
                {text}
            </div>
        );

    }

}
