import React, {FC, useState}                from 'react';
import {IconButton}                         from '@sirius/ui-lib/src/blocks/Controls';
import {HBox, VBox, Box}                    from '@sirius/ui-lib/src/blocks/Layout';
import { coursePageTOCBlock,
         TeacherCoursePageTOCProps,
         TOCChapterLessonsProps,
         TOCChapterParagraphsProps,
         TOCParagraphLessonsProps,
         TOCChapterSelectorProps  }         from '../blocks';
import {TeacherModuleCard}                  from '../ModuleCard';
import {DropdownSelect, Option}             from '../Dropdown';
import {getModuleCardMenuItems}             from './CardMenuItems';


export const TOCParagraphLessons:FC<TOCParagraphLessonsProps> = ({
    name,
    lessons,
    modules,
    courseId,
    menuActions,
}) => {
    const [open, setOpen] = useState<boolean>(false);
    const toggleOpen = () => {
        setOpen(!open)
    }
    return  <VBox
                className={
                    coursePageTOCBlock.el('paragraph-lessons') +
                    coursePageTOCBlock.el('paragraph-lessons').mod('open', open ? 'true' : 'false')
                }
            >
                <HBox className={coursePageTOCBlock.el('paragraph-lessons').mod('title')} onClick={toggleOpen}>
                    <HBox className={`${coursePageTOCBlock.el('paragraph-lessons').mod('name')} text-xl`}>
                        {name}
                    </HBox>
                    <HBox className={coursePageTOCBlock.el('paragraph-lessons').mod('arrow')}>
                        <IconButton icon={'expand_more'} onAction={toggleOpen} />
                    </HBox>
                </HBox>
                <HBox className={coursePageTOCBlock.el('paragraph-lessons-content')}>
                    {
                        lessons?.map(
                            (lesson) => ({
                                lesson,
                                module: modules[`${lesson.moduleId}`]
                            })
                        ).map(
                            ({lesson, module}, li) =>
                                <TeacherModuleCard
                                    {...module}
                                    key              = {li}
                                    courseId         = {courseId}
                                    popoverMenuItems = {
                                        getModuleCardMenuItems(
                                            {course_id: courseId, ...module},
                                            {...menuActions}
                                        )
                                    }
                                />
                        )
                    }
                </HBox>
            </VBox>
};

export const TOCChapterParagraphs:FC<TOCChapterParagraphsProps> = ({
    name,
    paragraphs,
    modules,
    courseId,
    menuActions
}) =>
    <VBox className={coursePageTOCBlock.el('chapter-paragraphs')}>
        <HBox className={`${coursePageTOCBlock.el('chapter-paragraphs-name')} text-xl`}>
            {name}
        </HBox>
        <VBox className={coursePageTOCBlock.el('chapter-paragraphs-content')}>
            {
                paragraphs.map(
                    (paragraph, pi) =>
                        <TOCParagraphLessons
                            {...paragraph}
                            key         = {pi}
                            modules     = {modules}
                            courseId    = {courseId}
                            menuActions = {menuActions}
                        />
                )
            }
        </VBox>
    </VBox>
;

export const TOCChapterLessons:FC<TOCChapterLessonsProps> = ({
    name,
    lessons,
    modules,
    courseId,
    menuActions
}) =>
    <VBox className={coursePageTOCBlock.el('chapter-lessons')}>
        <HBox className={`${coursePageTOCBlock.el('chapter-lessons-name')} text-xl`}>
            {name}
        </HBox>
        <HBox className={coursePageTOCBlock.el('chapter-lessons-content')}>
            {
                lessons?.map(
                    (lesson) => ({
                        lesson,
                        module: modules[`${lesson.moduleId}`]
                    })
                ).map(
                    ({lesson, module}, li) =>
                        <TeacherModuleCard
                            {...module}
                            key              = {li}
                            courseId         = {courseId}
                            popoverMenuItems = {
                                getModuleCardMenuItems(
                                    {course_id: courseId, ...module},
                                    {...menuActions}
                                )
                            }
                        />
                )
            }
        </HBox>
    </VBox>
;

export const TOCChapterSelector:FC<TOCChapterSelectorProps> = ({
    toc,
    currentChapter,
    selectChapter
}) => {
    const onSelectChapter = ([item]:[{name:string} | any]) => {
        const selected = item
            ? (toc as {name:string, id: string}[])
                .find(
                    ({name}) => name === item.name
                ).id
            : void(0)
        ;
        selectChapter(
            selected ?? null
        );
    }
    return <HBox className={coursePageTOCBlock.el('chapter-selector')}>
        <DropdownSelect
            onChange    = {onSelectChapter}
            placeholder = {'Все главы'}
            cleanable   = {false}
        >
            <Option value    = {null}
                    label    = {'Все главы'}
                    selected = {currentChapter === null}
                    key      = {'all-chapters'}
            />
            {
                toc.length > 0 &&
                toc.map(
                    ({name, id}) =>
                        <Option value    = {{name}}
                                label    = {name}
                                selected = {currentChapter === id}
                                key      = {id}
                        />
                )
            }
        </DropdownSelect>
    </HBox>
};


export const TeacherCoursePageTOC:FC<TeacherCoursePageTOCProps> = ({
    toc,
    modules,
    courseId,
    menuActions
}) => {
    const [first] = toc;
    const {type} = first;
    const secondary = 'lessons' in first
                        ? 'lessons'
                        : 'paragraphs' in first
                            ? 'paragraphs'
                            : null
    ;
    const layout:`${"chapter" | "paragraph"}-${"lessons" | "paragraphs"}` = `${type}-${secondary}`;
    const [currentChapter, setCurrentChapter] = useState<string | null>(null);

    return <Box className={
        coursePageTOCBlock +
        coursePageTOCBlock.mod('layout', layout)
    }>
        {
            type === "chapter" &&
            <TOCChapterSelector
                toc            = {toc}
                currentChapter = {currentChapter}
                selectChapter  = {setCurrentChapter}
            />
        }{
            layout === "chapter-lessons" &&
            (toc as NooBack.Teacher.Methods.ChaptersWithLessonsList).map(
                (chapter , i) =>
                    (currentChapter == null || (chapter && chapter.id === currentChapter))
                        ? <TOCChapterLessons
                            {...chapter}
                            key         = {i}
                            modules     = {modules}
                            courseId    = {courseId}
                            menuActions = {menuActions}
                        />
                        : null
            )
        }{
            layout === "chapter-paragraphs" &&
            (toc as NooBack.Teacher.Methods.ChaptersWithParagraphsList).map(
                (chapter , i)=>
                    (currentChapter == null || (chapter && chapter.id === currentChapter))
                        ? <TOCChapterParagraphs
                            {...chapter}
                            key         = {i}
                            modules     = {modules}
                            courseId    = {courseId}
                            menuActions = {menuActions}
                        />
                        : null
            )
        }{
            layout === "paragraph-lessons" &&
            (toc as NooBack.Teacher.Methods.ParagraphsWithLessonsList).map(
                (paragraph, pi) =>
                    <TOCParagraphLessons
                        {...paragraph}
                        key         = {pi}
                        modules     = {modules}
                        courseId    = {courseId}
                        menuActions = {menuActions}
                    />
            )
        }
    </Box>
}
