import React, {FC, useState}                              from 'react';
import {createPortal}                                     from 'react-dom';
import {Column, DataCellType, Row}                        from '@sirius/ui-lib/src/blocks/Layout/UITable/@types';
import {BoxAlignment, HBox, PopoverMenu, PopoverMenuItem} from '@sirius/ui-lib/src/blocks/Layout';
import {Button}                                           from '@sirius/ui-lib/src/blocks/Controls';
import {defaultUiSchema}                                  from '@sirius/ui-lib/src/blocks/Layout/UITable/uischema';
import {progressPage, ProgressPageTableProps}             from 'Cheops/containers/TeacherRoomPage/blocks';

const ColumnNames: Record<string, string> = {
    'user':           'ФИО\u00A0/\u00A0ID',
    'class':          'Класс',
    'sumScore':       'Сумма\u00A0баллов',
};

const ColumnMapper = ({ id, i18n }: NooBack.Teacher.Methods.Column, i: number, columns: NooBack.Teacher.Methods.Columns): Column => ({
    title:  i18n ? `${i18n.props.number}` : (ColumnNames[id] || id),
    name:   id,
    grow:   columns.length - 1 === i,
    sticky: id === 'user'
});

const RowMapper = (row: NooBack.Teacher.Methods.Cells & {user: NooBack.Teacher.Methods.StudentInfo}): Row => {
    const userName =
        (row?.user?.name?.alias?.trim().length > 0 && row?.user?.name?.alias) ||
        row?.user?.name?.studentName ||
        row?.user.id
    ;

    return {
        ...row,
        user:     userName,
        userInfo: row?.user,
    }
};

const getTableSchema = (tasks: string[], onActionAsStudent: ProgressPageTableProps['seeAsStudent']) =>  {

    const TaskDataCell: FC<DataCellType> = ({ row, column }) => {

        const currentTask = Object.entries(row).find(([item]) => item === column.name)[1];

        return currentTask && 'score' in currentTask && typeof currentTask.score === 'number'
            ? <HBox className = {
                progressPage.el('table-task-cell') +
                progressPage.el('table-task-cell').mod('verdict') +
                progressPage.el('table-task-cell').mod('verdict', currentTask.verdict || 'none')
            }
            >
                {currentTask.score}
            </HBox>
            : <HBox className = {progressPage.el('table-task-cell')}/>
    };

    const getTaskCellsSchema = (tasks: string[]) => {
        const schemaData = {
            DataCell:   TaskDataCell,
            ColumnCell: defaultUiSchema.text.ColumnCell
        };

        return Object.fromEntries(tasks.map((task) => [task, schemaData]));
    };

    const UserDataCell: FC<DataCellType> = ({ row }) => {
        const [target, setTarget] = useState(null);

        const toggleMenu = (e: React.MouseEvent): void => {
            e && e.stopPropagation();
            setTarget(!!target ? null : e.currentTarget);
        };

        const uiScreenElement = document.querySelector('.ui-screen');

        return(
            <HBox className = {progressPage.el('table-user-cell')}
                  align     = {BoxAlignment.between}
            >
                {row.user}
                <Button onClick = {toggleMenu}
                        icon    = {'more_vert'}
                        size    = {'s'}
                        mode    = {'text'}
                        active  = {!!target}
                />
                {
                    createPortal(
                        <PopoverMenu target    = {target}
                                     close     = {() => setTarget(null)}
                                     placement = {'bottom-start'}
                                     wsize     = {'s'}
                        >
                            <PopoverMenuItem icon     = {'school'}
                                             label    = {'Смотреть как ученик'}
                                             onAction = {() => onActionAsStudent(row.userInfo.id)}
                            />
                        </PopoverMenu>,
                        uiScreenElement
                    )
                }
            </HBox>
        );
    };

    const SumScoreDataCell: FC<DataCellType> = ({ row }) =>
        <div className={progressPage.el('table-custom-cell')}>
            {row.sumScore}
        </div>
    ;

    return {
        ...defaultUiSchema,
        user: {
            DataCell:   UserDataCell,
            ColumnCell: defaultUiSchema.text.ColumnCell
        },
        sumScore: {
            DataCell:   SumScoreDataCell,
            ColumnCell: defaultUiSchema.text.ColumnCell
        },
        ...getTaskCellsSchema(tasks)

    }
};

export {
    ColumnNames,
    ColumnMapper,
    RowMapper,
    getTableSchema
};
