import React from "react";
import Marked from "@sirius/ui-shared/src/components/DisplayEngine/Marked";
import "./Hints.less";
import CircularProgress from "Smt/Ui";
import HintPopup from "./HintsPopup";

export default class Hints extends React.Component {

    static defaultProps = {
        hints: [],
        showHintPopup: null,
        reduceHints: null,
        newHintsAllowed: true,
        sendHintRequest: null,
    };

    constructor(props) {

        super(props);

        this.state = {
            showHintPopup: false,
            loading: false,
        };

    }

    componentWillMount() {

        if (this.props.hints.length === 0) {

            this.setState({showHintPopup: true});

        }

    }


    closeHints = () => {

        this.props.reduceHints();
        this.setState({showHintPopup: false});

    };

    sendHintRequest = async () => {

        this.setState({
            showHintPopup: false,
            loading: true,
        });

        await this.props.sendHintRequest();

        this.setState({
            loading: false,
        });

    };

    cancelHintRequest = () => {

        this.setState({showHintPopup: false});

        if (this.props.hints.length === 0) {

            this.props.reduceHints();

        }

    };

    render() {

        return (
            <div className="hints">
                <div className="hints__close" onClick={this.closeHints} />
                <div className="hints__wrapper">
                    <div>
                        {this.props.hints.map((hint) => <div className="hints__item" key={hint.id}>
                            <Marked>{hint.hint}</Marked>
                        </div>)}

                        {(this.state.showHintPopup)
                        && <HintPopup cancelHintRequest={this.cancelHintRequest}
                                      sendHintRequest={this.sendHintRequest}
                        />}

                        {this.state.loading
                        && <CircularProgress marginCenter />}
                    </div>
                    {this.props.newHintsAllowed && !this.state.showHintPopup && !this.state.loading
                    && <div className="hints__bottom">
                        <div className="hints__request_btn" onClick={() => this.setState({showHintPopup: true})}>
                            Ещё подсказка
                        </div>
                    </div>}
                </div>
            </div>
        );

    }

}
