import React, {FC}              from "react";
import {HBox, VBox}             from "@sirius/ui-lib/src/blocks/Layout";
import {Button, Icon}           from "@sirius/ui-lib/src/blocks/Controls";
import {PermissionsClassProps}  from "Cheops/containers/TeacherRoomPage/blocks";

export const PermissionsClass: FC<PermissionsClassProps> = (
    {
        grantAccessAction,
        permissions,
        owner,
        block,
    }
) => (
    <section className={block.el('permissions-section')}>
        <h2 className={block.el('subtitle')}>
            Доступ к классу
        </h2>
        <VBox className={block.el('permissions')}>
            { permissions.map(
                ({firstName = '', lastName = '', middleName = '', isOwner}, i) => (
                    <HBox className={block.el('permissions-content')} key={i}>
                        { isOwner &&
                            <Icon className = { block.el('permissions-icon')}
                                  icon      = { 'security' }
                                  size      = { 's' }
                            />
                        }
                        <span className={block.el('permissions-name')} >
                           {`${lastName} ${firstName} ${middleName}`}
                       </span>
                    </HBox>
                )
             )
            }
        </VBox>
        { owner &&
            <Button className = {block.el('permissions-button')}
                    onAction  = {grantAccessAction}
                    icon      = {'security'}
                    mode      = {'outlined'}
                    size      = {'l'}
            >
                Дать доступ
            </Button>
        }
    </section>
);
