import bnc from "bnc";

type HasContest  = {contest:  NooBack.ContestsPage.Static.ContestsPageContest};
type HasStatuses = {statuses: NooBack.ContestsPage.Status.ContestStatuses};
type HasContests = {contests: NooBack.ContestsPage.Static.ContestsPageContest[]}
type HasId       = {id: string};

type SubjectCardType     = HasContest & HasStatuses;
type SubjectCardListType = HasContests & HasStatuses & HasId;


const block = new bnc('contests-task-page');
const blockCard = new bnc('subject-card');
const subjectCardList = new bnc('subject-card-list');

const CONTENT_URL = `${CONFIG.Api.noopolis.url}/content/_image`;
const CONTEST_URL = `${CONFIG.Uts.url}/#/contest`;

export {
    SubjectCardType,
    SubjectCardListType,
    blockCard,
    block,
    subjectCardList,
    CONTENT_URL,
    CONTEST_URL
};
