export const ColumnNames: Record<string, string> = {
    'user':           'Имя Фамилия / ID',
    'sum':            '∑\u00A0баллов',
    'progress':       '(прогресс)',
    'score':          '(баллы)'
};

export enum ComplexityTitle {
    'Для\u00A0жизни' = 1,
    'Для\u00A0профессии' ,
    'Для\u00A0творчества'
}
